import React from 'react';
import PropTypes from 'prop-types';
import { PulseLoader } from 'react-spinners';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../../../components/CustomButtons/Button';

const useStyles = makeStyles({
  outerDiv: {
    textAlign: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    '@media (max-width: 960px)': {
      height: '90%',
    },
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  amazingWork: {
    fontSize: '22px',
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  largeText: {
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    fontSize: '22px',
  },
  messageIcon: {
    color: '#6CCA98',
    height: '100px',
    width: '100px',
  },
  image: {
    width: '160px',
    marginBottom: '5px',
  },
});
const WordCloudCompleted = (props) => {
  const classes = useStyles();
  const { isHosted, nextModule, wordCloudIcon } = props;
  return (
    <div className={classes.outerDiv}>
      <div>
        {wordCloudIcon ? (
          <img src={wordCloudIcon} className={classes.image} alt="Word Cloud" />
        ) : (
          <img src="https://s3.amazonaws.com/join.host/stereotypeMasks-sample.png" className={classes.image} alt="Word Cloud Finished" />
        )}
      </div>
      <p className={classes.amazingWork}>
        Thank you for participating in this Translator  experience!
      </p>
      {isHosted ? (
        <div className={classes.center}>
          <p className={classes.largeText}>
            Please wait for instructions from your Host.
          </p>
          <PulseLoader
            sizeUnit="px"
            size={15}
            color="#60A681"
            loading
          />
        </div>
      ) : (
        <div>
          <Button
            type="submit"
            color="lightGreen"
            onClick={() => nextModule()}
          >
            Next Module
          </Button>
        </div>
      )}
    </div>
  );
};

WordCloudCompleted.propTypes = {
  isHosted: PropTypes.bool.isRequired,
  nextModule: PropTypes.func,
  wordCloudIcon: PropTypes.string.isRequired,
};

export default WordCloudCompleted;
