import React from 'react';
import PropTypes from 'prop-types';
import {
  DataGrid,
} from '@material-ui/data-grid';

export default function AutoHostSlideGrid(props) {
  const {
    data,
  } = props;

  const headers = [
    {
      headerName: 'Title', field: 'title', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Description', field: 'description', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Type', field: 'type', width: 100, disableClickEventBubbling: true,
    },
    {
      headerName: 'Date Created', field: 'createdAt', width: 200, disableClickEventBubbling: true,
    },
    {
      headerName: 'Video Url',
      field: 'videoUrl',
      width: 200,
      disableClickEventBubbling: true,
    },
  ];

  return (
    <div style={{ height: 600, width: '100%' }}>
      <DataGrid
        rows={data}
        columns={headers}
        sortingOrder={['asc', 'desc']}
        sortModel={[
          {
            field: 'title',
            sort: 'asc',
          },
        ]}
      />
    </div>
  );
}

AutoHostSlideGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};
