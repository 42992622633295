import React from 'react';
import ReactDOM from 'react-dom';
import LogRocket from 'logrocket';
import App from './router';

import './assets/scss/material-dashboard-pro-react.scss';

LogRocket.init(`${process.env.REACT_APP_LOGROCKET_APP_ID}`);
ReactDOM.render(
  <App />,
  document.getElementById('root'),
);
