/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({

  card: {
    // width: '50%',
    height: '95%',
    borderRadius: 15,
    background: 'white',
    alignItems: 'center',
    display: 'flex',
    zIndex: 5000,
    justifyContent: 'center',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    margin: '10px',
    '@media (max-width: 960px)': {
      width: '100%',
      fontSize: '20px',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      // backgroundSize: '100%',
    },
  },
  contentDiv: {
    opacity: 1,
    marginTop: '25vh',
    fontSize: '1.6rem',
    lineHeight: 1.2,
    fontWeight: '400',
    // padding: '5px',
  },
  content: {
    background: '#ffffffe8',
    borderRadius: '10px',
  },
});

const ImageComponent = ({ question }) => {
  const classes = useStyles();
  console.log(question)
  return (
    <Card
      id="card"
      className={classes.card}
      style={{ backgroundImage: `url(${question.image})` }}
    >
      <CardContent>
        <div className={classes.contentDiv}>
          <div align="center" className={classes.content}>
            <p>
              Swipe
              {' '}
              <b>{ question.direction.toUpperCase()}</b>
              {' '}
              {question.question}
            </p>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

ImageComponent.propTypes = {
  question: PropTypes.object.isRequired,
};

export default ImageComponent;
