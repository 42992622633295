/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import SelfIdReportModal from './SelfIdReportModal';

const useStyles = makeStyles({
  tableContainer: {
    height: '75vh',
    margin: '5px 0',
  },
});

function SimpleTable(props) {
  const classes = useStyles();
  const {
    questions, sessionId, companyId, moduleInfo, eventId,
  } = props;
  const [selfIdModalOpen, setSelfIdModalOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState({});

  const togalModal = (question = {}) => {
    setSelfIdModalOpen(!selfIdModalOpen);
    setSelectedQuestion(question);
  };

  const clickCell = (question) => {
    togalModal(question);
  };

  const clickCompanyCell = (question) => {
    togalModal(question);
  };

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <SelfIdReportModal
        isCompany={!Boolean(sessionId)}
        companyId={companyId}
        eventId={eventId}
        moduleInfo={moduleInfo}
        open={selfIdModalOpen}
        togalModal={togalModal}
        question={selectedQuestion}
        sessionId={sessionId}
      />
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Question</TableCell>
            <TableCell align="right">percentage</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {questions.map((row, i) => (
            <React.Fragment key={i + 11500}>
              <TableRow key={row.id}>
                <Tooltip title="View self-id report for this question">
                  <TableCell
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      sessionId
                        ? clickCell(row)
                        : clickCompanyCell(row);
                    }}
                    component="th"
                    scope="row"
                  >
                    Swipe
                    {' '}
                    <b>{row.direction.toUpperCase()}</b>
                    {' '}
                    {row.question}
                  </TableCell>
                </Tooltip>
                <TableCell align="right">{row.percent ? row.percent.toFixed(2) : 0}</TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

SimpleTable.propTypes = {
  questions: PropTypes.array.isRequired,
  sessionId: PropTypes.number,
  companyId: PropTypes.number,
  moduleInfo: PropTypes.object,
  eventId: PropTypes.number,
};

export default SimpleTable;
