import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import withStyles from '@material-ui/core/styles/withStyles';

import {
  Select, MenuItem, InputLabel, FormControl, Paper } from '@material-ui/core';

import { loadAuthToken } from '../../../utils/auth';
import { AuthContext } from '../../../Auth';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';

const style = {
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center',
  },
  inputAdornmentIcon: {
    color: '#555',
  },
  inputAdornment: {
    position: 'relative',
  },
  emailListItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  highLightIcon: {
    color: '#60A681',
  },
};

class Step2 extends React.Component {
   // eslint-disable-next-line react/static-property-placement
   static contextType = AuthContext;

   constructor(props) {
     super(props);
     this.state = {
       resources: [],
       resource: {},
     };
   }

   componentDidMount() {
     const { sendToLogin } = this.context;
     axios({
       method: 'get',
       url: `${process.env.REACT_APP_MODULE_SERVICE}/resource`,
       headers: {
         Authorization: `bearer ${loadAuthToken()}`,
       },
     }).then((res) => {
       if (res.error === 'your token is not valid' || res.error === 'Your token is expired') {
         sendToLogin();
       } else {
         this.setState({ resources: res.data.data });
       }
     }).catch((err) => this.setError(err));
   }

   componentDidUpdate() {
     const { resource } = this.state;
     console.log('resource', resource);
   }

   handleAddResource(selecedResource) {
     //  const { resource } = this.state;
     this.setState({ resource: selecedResource });
   }

   setError(err) {
     const { setMessageState, setSnackBarMessage } = this.context;
     setMessageState('error');
     setSnackBarMessage(`${err.response.data.message}`);
   }

   sendState() {
     return this.state;
   }

   render() {
     const { resources, resource } = this.state;
     const { classes } = this.props;
     return (
       <>
         <h4 className={classes.infoText}>
           <strong>Optional!</strong>
           {' '}
           Select a resource that will be linked to the update
         </h4>
         <GridContainer justifyContent="center">
           <GridItem xs={12}>
             <FormControl fullWidth className={classes.selectFormControl}>
               <InputLabel
                 htmlFor="resources"
                 className={classes.selectLabel}
               >
                 Select A Resource (Optional)
               </InputLabel>
               <Select
                 MenuProps={{
                   className: classes.selectMenu,
                 }}
                 onChange={(e) => this.handleAddResource(e.target.value)}
                 classes={{
                   select: classes.select,
                 }}
                 inputProps={{
                   name: 'resources',
                   id: 'resources',
                 }}
               >
                 <MenuItem
                   disabled
                   classes={{
                     root: classes.selectMenuItem,
                   }}
                 >
                   Select A Resource (Optional)
                 </MenuItem>
                 <MenuItem value={{}}> None </MenuItem>
                 {resources.map((r) => (
                   <MenuItem
                     key={r.title}
                     classes={{
                       root: classes.selectMenuItem,
                       selected: classes.selectMenuItemSelected,
                     }}
                     value={r}
                   >
                     {`${r.title} - ${r.description}`}
                   </MenuItem>
                 ))}
               </Select>
             </FormControl>
           </GridItem>
           <GridItem xs={12}>

             <Paper elevation={3} variant="outlined" square style={{ margin: '50px 50px 0 50px' }}>
               <h6 style={{ textAlign: 'center' }}>Update will link to resource page: </h6>
               {Object.keys(resource).length === 0 ? (
                 <div className={classes.emailListItem}>None Selected</div>
               ) : (
               //  <ul className={classes.unorderedList}>
               //    {groupsToAdd.map((g, index) => (
                 <div key={resource.title} className={classes.emailListItem}>
                   <p>{`${resource.title} - ${resource.description}`}</p>
                   {/* <IconButton onClick={() => this.removeGroup(index)}>
                 <HighlightOffIcon className={classes.highLightIcon} />
               </IconButton> */}
                 </div>
               //    ))}
               //  </ul>
               )}
             </Paper>
           </GridItem>
         </GridContainer>
       </>
     );
   }
}

Step2.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object,
};

export default withStyles(style)(Step2);
