/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-await-in-loop */
import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import Paper from '@material-ui/core/Paper';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import { AuthContext } from '../../Auth';
import { loadAuthToken } from '../../utils/auth';
import Button from '../../components/CustomButtons/Button';
import customSelectStyle from '../../assets/jss/material-dashboard-pro-react/customSelectStyle';
import customListStyle from '../../assets/jss/material-dashboard-pro-react/customListStyle';

const styles = {
  unorderedList: {
    listStyleType: 'none',
    overflow: 'auto',
    maxHeight: '200px',
  },
  emailListItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  highLightIcon: {
    color: '#60A681',
  },
  addUserInput: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  select: {
    width: '100%',
  },
  errorText: {
    color: 'red',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    // position: 'absolute',
  },
  ...customSelectStyle,
  ...customListStyle,

};

const useStyles = makeStyles(styles);

const HostForm = (props) => {
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [userInputBox, setInput] = useState('');
  // const [number, setNumber] = useState('');
  // const [listedPhoneNumbers, setListedPhoneNumbers] = useState([]);
  // const [individualNumber, setIndividualNumber] = useState({});
  const context = useContext(AuthContext);
  const classes = useStyles();
  const {
    toggleModal, open, sessionId, pin,
  } = props;

  const handleAddPhoneNumber = () => {
    if (userInputBox.match(/^\d{10}$/)) {
      if (!phoneNumbers.includes(userInputBox)) setPhoneNumbers([...phoneNumbers, userInputBox]);
      setInput('');
    }
  };

  const history = useHistory();

  useEffect(() => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_AUTH_SERVICE}/users`,
      headers: {
        Authorization: loadAuthToken(),
      },
    }).then((res) => {
      // eslint-disable-next-line max-len
      const userPhoneNumber = res.data.data.filter((user) => user.id === context.user.id)[0];
      // setListedPhoneNumbers(res.data.data.filter((user) => user.phoneNumber).map((user) => ({
      //  phoneNumber: user.phoneNumber, name: user.firstName
      // })));
      // setIndividualNumber(userPhoneNumber);
      setInput(userPhoneNumber.phoneNumber ? userPhoneNumber.phoneNumber : '');
    })
      .catch((e) => {
        console.log(e);
        context.setMessageState('error');
        context.setSnackBarMessage(`${e.response.data.message}`);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createHosts = async () => {
    const phoneNumbersCopy = [...phoneNumbers];
    if (userInputBox.match(/^\d{10}$/)) {
      if (!phoneNumbers.includes(userInputBox)) phoneNumbersCopy.push(userInputBox);
    }
    for (let i = 0; i < phoneNumbersCopy.length; i++) {
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_LIVE_CHAT_SERVICE}/hosts`,
        headers: {
          Authorization: `bearer ${loadAuthToken()}`,
        },
        data: {
          phoneNumber: `+1${phoneNumbersCopy[i]}`,
          externalUserId: context.user.id,
          name: context.user.name,
          sessionId,
        },
      })
        .then(() => {
          axios({
            method: 'post',
            url: `${process.env.REACT_APP_LIVE_CHAT_SERVICE}/questions`,
            data: {
              question: `${phoneNumbersCopy[i]} is now receiving live chat messages for session ${pin}`,
              sessionId,
              audienceUserId: 1,
            },
          });
        })
        .catch((err) => {
          try {
            context.setMessageState('error');
            context.setSnackBarMessage(`${err.response.data.message}`);
          } catch (e) {
            context.setMessageState('error');
            context.setSnackBarMessage(e.message);
          }
          if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
            history.push('auth/login');
          }
        });
    }
    context.setMessageState('success');
    context.setSnackBarMessage(`Successfully created hosts with phone numbers: ${phoneNumbersCopy.map((num) => ` ${num} `)}`);
  };

  const handleSubmit = async () => {
    await createHosts();
    setPhoneNumbers([]);
    setInput('');
    toggleModal();
  };

  const removePhoneNumber = (index) => {
    const phoneNumbersCopy = [...phoneNumbers];
    phoneNumbersCopy.splice(index, 1);
    setPhoneNumbers(phoneNumbersCopy);
  };

  const closeModal = () => {
    setPhoneNumbers([]);
    setInput('');
    toggleModal();
  };
  //  console.log('phone #s', phoneNumbers);
  return (
    <div>
      <GridContainer>
        <Dialog
          open={open}
          onClose={() => toggleModal()}
          aria-labelledby="form-dialog-title"
        >

          <IconButton
            aria-label="close"
            onClick={closeModal}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle
            id="form-dialog-title"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            Add host(s)

          </DialogTitle>

          <DialogContent>
            <DialogContentText>
              To receive anonymous chats during the session,
              enter a 10 digit phone number.
            </DialogContentText>
            <DialogContentText>Phone numbers submitted will receive texts from 9179609801</DialogContentText>

            <GridItem>
              <TextField
                error={!userInputBox.match(/^\d{10}$/)}
                helperText="Enter a 10 digit phone number, ex: 5551234567"
                autoFocus
                margin="dense"
                label="Phone Number"
                type="text"
                style={{ width: '72%' }}
                value={userInputBox}
                onChange={(e) => {
                  setInput(e.target.value);
                }}
              />
              <Button onClick={() => handleAddPhoneNumber()} color="primary">
                {phoneNumbers.length > 0 ? 'Add additional phone #' : 'Add phone #'}
              </Button>
            </GridItem>
            {/* <div>OR</div>
            {context.user.role === 'admin' ? (
              <GridItem xs={12} md={12}>
                <FormControl
                  style={{ width: '72%' }}
                >
                  <InputLabel
                  id="phone-number" className={classes.selectLabel}>Select Number From Drop-down
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    labelId="phone-number"
                    id="simple-select"
                    onChange={(e) => setInput(e.target.value)}
                    fullWidth
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Select Phone Number From Drop-down
                    </MenuItem>
                    {listedPhoneNumbers.map((user) => (
                      <MenuItem
                        key={user.phoneNumber}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={user.phoneNumber}
                      >
                        {user.name}
                        {' '}
                        -
                        {' '}
                        {user.phoneNumber}

                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* <Button onClick={() => handleAddPhoneNumber()} color="primary">
                  Add
                </Button>
              </GridItem>
            ) : (
              <GridItem xs={12} md={12}>
                <FormControl
                  style={{ width: '72%' }}
                >
                  <InputLabel
                   id="phone-number" className={classes.selectLabel}>Select number from drop-down
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    labelId="phone-number"
                    id="simple-select"
                    onChange={(e) => setInput(e.target.value)}
                    fullWidth
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Select Phone Number From Drop-down
                    </MenuItem>

                    <MenuItem
                      key={individualNumber.phoneNumber}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={individualNumber.phoneNumber}
                    >
                      {individualNumber.firstName}
                      {' '}
                      -
                      {' '}
                      {individualNumber.phoneNumber}

                    </MenuItem>

                  </Select>
                </FormControl>
                {/* <Button onClick={() => handleAddPhoneNumber()} color="primary">
                  Add Phone #
                </Button>
              </GridItem>
            )} */}
            {/* <GridItem>

            </GridItem> */}
          </DialogContent>
          <Paper elevation={3} variant="outlined" square style={{ margin: '0 80px 0 80px' }}>
            <h6 style={{ textAlign: 'center' }}>Phone numbers:</h6>
            {phoneNumbers.length === 0 ? (
              <div className={classes.emailListItem}><ul>Empty</ul></div>
            ) : (
              <ul className={classes.unorderedList}>
                {phoneNumbers.map((phoneNumber, index) => (
                  <div key={index + 90000} className={classes.emailListItem}>
                    <li>{phoneNumber}</li>
                    <IconButton onClick={() => removePhoneNumber(index)}>
                      <HighlightOffIcon className={classes.highLightIcon} />
                    </IconButton>
                  </div>
                ))}
              </ul>
            )}

          </Paper>

          <DialogActions>
            {/* <Button onClick={() => closeModal()} color="primary">
              Cancel
            </Button> */}
            <Button onClick={() => handleSubmit()} color="primary" disabled={phoneNumbers.length < 1}>
              Submit number(s)
            </Button>
          </DialogActions>
        </Dialog>
      </GridContainer>
    </div>
  );
};

HostForm.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  sessionId: PropTypes.number.isRequired,
  pin: PropTypes.number,
};

export default HostForm;
