import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import FreqTable from './FreqTable';

export default function WordCloudReportGrid(props) {
  const [questions, setQuestions] = useState([]);

  const {
    sessionId, context, moduleId,
  } = props;

  // const headers = [
  //   {
  //     headerName: 'Answer', field: 'answer', width: 250, disableClickEventBubbling: true,
  //   },
  //   {
  //     headerName: 'Frequency', field: 'freq', width: 250, disableClickEventBubbling: true,
  //   },
  // ];

  useEffect(() => {
    if (sessionId) {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_REPORT_SERVICE}/word-count?sessionId=${sessionId}&moduleId=${moduleId}`,
      }).then((res) => {
        setQuestions(res.data.data);
        // if (res.data.total > 0) {
        //   setCompleted(res.data.total);
        //   setResults(res.data.data);
        // } else {
        //   setShouldDisplay(false);
        // }
      }).catch((e) => {
        context.setMessageState('error');
        context.setSnackBarMessage(`${e.response.data.message}`);
      });
    }
  }, [sessionId, moduleId]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{
      height: 600, width: '100%', display: 'flex', flexDirection: 'row',
    }}
    >
      {questions.map((question, i) => (
        question && question.answers
          ? (
            <div style={{ margin: '10px', width: '100%' }} key={i}>
              {question.question}
              <FreqTable answers={question.answers} />
            </div>
          )
          : <div />
      ))}
    </div>
  );
}

WordCloudReportGrid.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  context: PropTypes.object.isRequired,
  moduleId: PropTypes.number.isRequired,
  sessionId: PropTypes.number.isRequired,
};
