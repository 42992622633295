/* eslint-disable max-len */
import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import CardBody from '../../../../components/Card/CardBody';
import NavPills from '../../../../components/NavPills/NavPillsOriginal';
import WordCloudWordTable from './WordCloudWordTable';
import TaggedWords from './WordCloudTaggedWords';
import { AuthContext } from '../../../../Auth';
import { loadAuthToken } from '../../../../utils/auth';

function WordCloudWords() {
  const context = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [wordTags, setWordTags] = useState([]);
  // const [words, setWords]= useState([]);

  const tagWord = (answer, answerId, taggedAs, tagId) => {
    axios({
      method: 'patch',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/word-cloud-answer/${answerId}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
      data: {
        answer,
        wordCloudTagId: tagId,
      },
    }).then(() => {
      context.setMessageState('success');
      context.setSnackBarMessage(`Successfully tagged ${answer} as ${taggedAs}`);
      // setData(data.filter((ans) => ans.answer !== answer));
    })
      .catch((e) => {
        console.log(e);
        context.setMessageState('error');
        context.setSnackBarMessage(`${e.response.data.message}`);
        if (e.error === 'your token is not valid' || e.error === 'Your token is expired') {
          context.sendToLogin();
        }
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(!isLoading);
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_EVENT_SERVICE}/untagged-cloud-words`,
        headers: {
          Authorization: `bearer ${loadAuthToken()}`,
        },
      }).then((res) => {
        setData(res.data.data);
        setIsLoading(false);
        return res;
      })
        .catch((e) => {
          console.log(e);
          context.setMessageState('error');
          context.setSnackBarMessage(`${e.response.data.message}`);
          if (e.error === 'your token is not valid' || e.error === 'Your token is expired') {
            context.sendToLogin();
          }
        });
    };

    fetchData();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/word-cloud-tag`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).then((res) => {
      setWordTags(res.data.data.sort((a, b) => a.id - b.id));
    })
      .catch((e) => {
        console.log(e);
        context.setMessageState('error');
        context.setSnackBarMessage(`ERROR ${e.response}`);
        if (e.error === 'your token is not valid' || e.error === 'Your token is expired') {
          context.sendToLogin();
        }
      });
  }, [data]);// eslint-disable-line react-hooks/exhaustive-deps

  const tabs = [
    {
      tabButton: 'Untagged words',
      tabContent: (<WordCloudWordTable data={data} isLoading={isLoading} wordTags={wordTags} tagWord={tagWord} />),
    },
    {
      tabButton: 'Tagged words',
      tabContent: (<TaggedWords wordTags={wordTags} tagWord={tagWord} />),
    },
  ];

  return (
    <div>
      <CardBody style={{ overflow: 'auto' }}>
        <NavPills
          tabs={tabs}
          // alignCenter="true"
        />
      </CardBody>
    </div>
  );
}

export default WordCloudWords;
