import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  DataGrid,
} from '@material-ui/data-grid';

// import Button from '@material-ui/core/Button';
import Button from '../../../components/CustomButtons/Button';
import EditResourceModal from './EditResourceModal';
// import UserInfoModal from './UserInfoModal';

export default function ResourceTableGrid(props) {
  const [newHeaders, setNewHeaders] = useState([]);
  const [resourceDetails, setResourceDetails] = useState({});
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  // const [isUserInfoModalOpen, setIsUserInfoModalOpen] = useState(false);

  const {
    data,
  } = props;
  // console.log(data, 'data');

  const headers = [
    {
      headerName: 'Title', field: 'title', width: 150, disableClickEventBubbling: true,
    },
    {
      headerName: 'Description', field: 'description', width: 150, disableClickEventBubbling: true,
    },
    {
      headerName: 'Categories', field: 'tags', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Link', field: 'link', width: 250, disableClickEventBubbling: true,
    },
  ];

  useEffect(() => {
    const headersWithAddedButton = [
      {
        headerName: 'Edit Resource',
        field: 'id',
        width: 200,
        disableClickEventBubbling: true,
        renderCell: (resource) => (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                // alert('button clicked')
                setResourceDetails(resource.row);
                setIsEditModalOpen(!isEditModalOpen);
              }}
            >
              Edit Resource
            </Button>
          </div>
        ),
      },
      ...headers];

    setNewHeaders(headersWithAddedButton);
  }, [data]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ height: 600, width: '100%' }}>
      <EditResourceModal
        open={isEditModalOpen}
        resource={resourceDetails}
        toggleModal={() => {
          setIsEditModalOpen(!isEditModalOpen);
        }}
      />
      <DataGrid
        rows={data}
        columns={newHeaders}
        sortingOrder={['asc', 'desc']}
        sortModel={[
          {
            field: 'title',
            sort: 'asc',
          },
        ]}
      />
    </div>
  );
}

ResourceTableGrid.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object),
};
