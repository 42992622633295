/* eslint-disable react/static-property-placement */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-pascal-case */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { loadAuthToken } from '../../../utils/auth';
import { AuthContext } from '../../../Auth';

class ImageUploader extends Component {

  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      success: false,
      url: '',
    };
  }

  handleChange = () => {
    this.setState({ success: false, url: '' });
  }

  // Perform the upload
  handleUpload = () => {
    const file = this.uploadInput.files[0];
    // Split the filename to get the name and type
    const fileParts = this.uploadInput.files[0].name.split('.');
    const fileName = fileParts[0];
    const fileType = fileParts[1];
    // axios.post("http://localhost:3001/sign_s3",{
    //   fileName : fileName,
    //   fileType : fileType
    // })
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_MODULE_SERVICE}/image-uploader`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
      data: {
        fileName,
        fileType,
      },
    })
      .then((response) => {
      // console.log(response, '******************************')
        const returnData = response.data;
        const { signedRequest } = returnData;
        const { url } = returnData;
        // const queryIndex = signedRequest.indexOf('?');
        // const new
        this.setState({ url });
        // Put the fileType in the headers for the upload
        const options = {
          headers: {
            'Content-Type': fileType,
          },
        };
        axios.put(signedRequest, file, options)
          .then(() => {
            this.props.setIconUrl(url);
            this.context.setMessageState('success');
            this.context.setSnackBarMessage('Successfully uploaded image.');
            this.setState({
              success: true,
            });
          })
          .catch((error) => {
            this.context.setMessageState('error');
            this.context.setSnackBarMessage(`${error.response.data.message}`);
          });
      })
      .catch((error) => {
        this.context.setMessageState('error');
        this.context.setSnackBarMessage(`${error.response.data.message}`);
      });
  }

  render() {
    const Success_message = () => (
      <div>
        <p style={{ color: 'green' }}>
          SUCCESSFUL UPLOAD. can be found
          <a target="_blank" href={this.state.url}>{this.state.url}</a>
        </p>
      </div>
    );
    return (
      <div style={{ padding: 5 }}>
        {this.state.success ? <Success_message /> : <input onChange={this.handleUpload} ref={(ref) => { this.uploadInput = ref; }} type="file" />}
      </div>
    );
  }
}

ImageUploader.propTypes = {
  setIconUrl: PropTypes.func.isRequired,
};

export default ImageUploader;
