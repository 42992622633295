import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '../../../../components/CustomButtons/Button';

import GridContainer from '../../../../components/Grid/GridContainer';
import BubbleGraph from './ComfortChart';
import { loadAuthToken } from '../../../../utils/auth';

const useStyles = makeStyles((theme) => ({
  results: {
    height: '80vh',
    // overflow: 'auto',
    marginTop: '40px',
  },
  container: {
    width: '80vw',
    margin: 0,
    justifyContent: 'center',
  },
  backBtn: {
    right: '2vw',
    bottom: '1vh',
    position: 'absolute',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function Results(props) {
  const classes = useStyles();
  const {
    setViewingFalse, isViewingResults, sessionId, moduleId, usersAnswered,
  } = props;
  const [comfortData, setComfortData] = useState([]);
  useEffect(() => {
    if (isViewingResults) {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_EVENT_SERVICE}/host-comfort-report?sessionId=${sessionId}&moduleId=${moduleId}`,
        headers: {
          Authorization: `bearer ${loadAuthToken()}`,
        },
      }).then((res) => {
        setComfortData(res.data);
      });
    }
  }, [isViewingResults, usersAnswered]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.results}>
      <GridContainer className={classes.container}>
        {comfortData.length > 0 ? (
          <BubbleGraph
            comfortData={comfortData}
          />
        ) : null}
      </GridContainer>
      <div className={classes.backBtn}>
        <Button
          onClick={() => setViewingFalse()}
          // color="lightGreen"
        >
          View Module
        </Button>
      </div>
    </div>
  );
}

Results.propTypes = {
  setViewingFalse: PropTypes.func.isRequired,
  isViewingResults: PropTypes.bool.isRequired,
  sessionId: PropTypes.number.isRequired,
  moduleId: PropTypes.number.isRequired,
  usersAnswered: PropTypes.number.isRequired,
};
