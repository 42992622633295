import React, { useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import GridItem from '../../../components/Grid/GridItem';
import Card from '../../../components/Card/Card';
import NewSlideModal from './NewAutoHostModal';

const useStyles = makeStyles({
  image: {
    height: '225px',
    width: '325px',
  },
  slideName: {
    marginTop: '0px',
    marginBottom: '25px',
  },
});

const NewSlide = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [type, setType] = useState('');

  const classes = useStyles();

  const toggleModal = (typeName) => {
    setIsModalOpen(!isModalOpen);
    setType(typeName);
  };

  return (
    <>
      <NewSlideModal open={isModalOpen} type={type} toggleModal={() => toggleModal()} />

      <Card styles={{ height: '50vh' }}>
        <Grid container style={{ paddingTop: '30px' }}>
          <GridItem onClick={() => toggleModal('welcome')}>
            <img src="https://s3.amazonaws.com/join.host/Welcome_Template+(2).png" alt="welcome" className={classes.image} />
            <h4 className={classes.slideName}>Welcome Slide</h4>
          </GridItem>
          <GridItem onClick={() => toggleModal('video')}>
            <img src="https://s3.amazonaws.com/join.host/VideoSlide_Template+(2).png" alt="video" className={classes.image} />
            <h4 className={classes.slideName}>Video Slide</h4>
          </GridItem>
          <GridItem onClick={() => toggleModal('discussion')}>
            <img src="https://s3.amazonaws.com/join.host/DiscussionSlide_Template+(2).png" alt="discussion" className={classes.image} />
            <h4 className={classes.slideName}>Discussion Slide</h4>
          </GridItem>
          <GridItem onClick={() => toggleModal('report')}>
            <img src="https://s3.amazonaws.com/join.host/ReportSlide_Template.png" alt="report" className={classes.image} />
            <h4 className={classes.slideName}>Report Slide</h4>
          </GridItem>
          <GridItem onClick={() => toggleModal('closing')}>
            <img src="https://s3.amazonaws.com/join.host/ClosingSlide_Template+(2).png" alt="closing" className={classes.image} />
            <h4 className={classes.slideName}>Closing Slide</h4>
          </GridItem>
          <GridItem onClick={() => toggleModal('welcomeNoVideo')}>
            <img src="https://s3.amazonaws.com/join.host/Welcome_Template+(2).png" alt="closing" className={classes.image} />
            <h4 className={classes.slideName}>Welcome Slide- No Video</h4>
          </GridItem>
        </Grid>
      </Card>
    </>
  );
};

export default NewSlide;
