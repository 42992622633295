/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import clsx from 'clsx';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components


import routes from '../routes';

import styles from '../assets/jss/material-dashboard-pro-react/layouts/hostStyle';

const useStyles = makeStyles(styles);

export const HostLayoutContext = React.createContext({});

export default function Pages(props) {
  const { ...rest } = props;
  // ref for the wrapper div
  const [isWop, setIsWop] = useState(false);
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();
  React.useEffect(() => {
    document.body.style.overflow = 'unset';
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });
  const getRoutes = (allRoutes) => allRoutes.map((prop, key) => {
    if (prop.collapse) {
      return getRoutes(prop.views);
    }
    if (prop.layout === '/host') {
      return (
        <Route
          path={prop.path}
          component={prop.component}
          key={key}
        />
      );
    }
    return null;
  });

  return (
    <div>
      <div className={clsx(classes.wrapper, 'outerDiv')} ref={wrapper}>
        <div className={isWop ? classes.wopPage : classes.innerPage}>
          <HostLayoutContext.Provider value={{ setIsWop }}>
            <Switch>
              {getRoutes(routes)}
            </Switch>
          </HostLayoutContext.Provider>
        </div>
      </div>
    </div>
  );
}
