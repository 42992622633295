/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import {
  FormControl, InputLabel, Select, MenuItem, Paper, IconButton, FormHelperText,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
// core components

import GridItem from '../../../components/Grid/GridItem';
import CustomInput from '../../../components/CustomInput/CustomInput';
import GridContainer from '../../../components/Grid/GridContainer';
// import Button from '../../../components/CustomButtons/Button';
import customSelectStyle from '../../../assets/jss/material-dashboard-pro-react/customSelectStyle';
import customListStyle from '../../../assets/jss/material-dashboard-pro-react/customListStyle';

const style = {
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center',
  },
  inputAdornmentIcon: {
    color: '#555',
  },
  inputAdornment: {
    position: 'relative',
  },
  tagListItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  highLightIcon: {
    color: '#60A681',
  },
  ...customSelectStyle,
  ...customListStyle,
};
// const tags = [];

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      titleState: '',
      descriptionState: '',
      tags: [],
      // userInputBox: '',
    };
  }

  // componentDidUpdate() {
  //   console.log('tags', this.state.tags);
  // }

  handleAddTag(value) {
    const { tags } = this.state;
    // console.log('tags', tags);
    if (!tags.includes(value)) {
      this.setState(() => ({ tags: [...tags, value] }));
    }
    // this.setState({ userInputBox: '' });
  }

  removeTag(index) {
    const { tags } = this.state;
    const tagsCopy = [...tags];
    tagsCopy.splice(index, 1);
    this.setState({ tags: tagsCopy });
  }

  sendState() {
    return this.state;
  }

  // function that verifies if a string has a given length or not
  // eslint-disable-next-line class-methods-use-this
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    if (this.verifyLength(event.target.value, stateNameEqualTo)) {
      this.setState({ [`${stateName}State`]: 'success' });
    } else {
      this.setState({ [`${stateName}State`]: 'error' });
    }

    this.setState({ [stateName]: event.target.value });
  }

  isValidated() {
    const {
      titleState, descriptionState, imageState,
    } = this.state;
    if (
      titleState === 'success'
      && descriptionState === 'success'
    ) {
      return true;
    }
    if (titleState !== 'success') {
      this.setState({ titleState: 'error' });
    }
    if (descriptionState !== 'success') {
      this.setState({ descriptionState: 'error' });
    }
    if (imageState !== 'success') {
      this.setState({ imageState: 'error' });
    }

    return false;
  }

  render() {
    const {
      titleState, descriptionState, tags,
    } = this.state;
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>
            Let
            '
            s start with the basic information (with validation)
          </h4>
        </GridItem>
        <GridItem xs={10}>
          <CustomInput
            success={titleState === 'success'}
            error={titleState === 'error'}
            labelText={(
              <span>
                Title
                {' '}
                <small>(required)</small>
              </span>
            )}
            id="title"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => this.change(event, 'title', 'length', 3),
            }}
          />
        </GridItem>
        <GridItem xs={10}>
          <CustomInput
            success={descriptionState === 'success'}
            error={descriptionState === 'error'}
            labelText={(
              <span>
                Description
                {' '}
                <small>(required)</small>
              </span>
            )}
            id="description"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => this.change(event, 'description', 'length', 3),
            }}
          />
        </GridItem>
        <GridItem xs={10}>
          <CustomInput
            // success={imageState === 'success'}
            // error={imageState === 'error'}
            labelText={(
              <span>
                Image Url
                {' '}
                <small>(optional)</small>
              </span>
            )}
            id="image"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => this.change(event, 'image', 'image'),
            }}
          />
          <FormHelperText>
            The Translator logo is the default image, if none is added here.
          </FormHelperText>
        </GridItem>
        <GridItem xs={10}>
          <FormControl
            style={{ width: '72%' }}
          >
            <InputLabel
              id="tags"
              className={classes.selectLabel}
            >
              Category
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              labelId="phone-number"
              id="simple-select"
              onChange={(e) => this.handleAddTag(`${e.target.value}`)}
              fullWidth
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                Select Category From Drop-down
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value="Facilitator Training Resources"
              >
                Facilitator Training Resources
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value="Platform Updates"
              >
                Platform Updates
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value="Scenarios Training Resources"
              >
                Scenarios Training Resources
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value="Workshop Handouts"
              >
                Workshop Handouts
              </MenuItem>
            </Select>
          </FormControl>
          {/* <TextField
            id="tags"
            // error={!userInputBox.match(/^\d{10}$/)}
            helperText="Enter a category name, then press 'add category'"
            autoFocus
            margin="dense"
            label="Category(-ies) (optional)"
            type="text"
            style={{ width: '72%' }}
            value={userInputBox}
            onChange={(e) => {
              this.setState({ userInputBox: `${e.target.value}` });
            }}
          />
          <Button onClick={() => this.handleAddTag()} color="primary">
            Add category
          </Button> */}
          <Paper elevation={3} variant="outlined" square>
            <h6 style={{ textAlign: 'center' }}>Category(-ies):</h6>
            {tags.length < 1 ? (
              <div className={classes.tagListItem}><ul>Other Resources</ul></div>
            ) : (
              <ul className={classes.unorderedList}>
                {tags.map((tag, index) => (
                  <div key={tag} className={classes.tagListItem}>
                    <li>{tag}</li>
                    <IconButton onClick={() => this.removeTag(index)}>
                      <HighlightOffIcon className={classes.highLightIcon} />
                    </IconButton>
                  </div>
                ))}
              </ul>
            )}
          </Paper>
        </GridItem>
      </GridContainer>
    );
  }
}

Step1.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object,
};

export default withStyles(style)(Step1);
