import React, { useRef, useEffect, useState } from 'react';
import './messageStyle.scss';

const LiveChatDisplay = (props) => {
  const { liveChats } = props;
//   const [messages, setMessages] = useState(liveChats);
  const chatRef = useRef(null);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    // eslint-disable-next-line no-unused-expressions
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [liveChats]);

  return (
    <div className="wrapper" ref={chatRef}>
      <div className="inner">
        {liveChats.map((msg) => (
          <div className="message-wrapper them" key={msg}>
            {/* <div className="circle-wrapper">{msg.userId}</div> */}
            <div className="text-wrapper">{msg}</div>
          </div>
        ))}
        {/* <div ref={messagesEndRef} /> */}
      </div>
    </div>
  );
};

export default LiveChatDisplay;
