import React from 'react';
import PropTypes from 'prop-types';
import { PulseLoader } from 'react-spinners';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../../components/CustomButtons/Button';
import Comment from '../../../assets/img/comment.png';

const useStyles = makeStyles({
  outerDiv: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  amazingWork: {
    fontSize: '22px',
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  largeText: {
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    fontSize: '22px',
  },
  messageIcon: {
    color: '#6CCA98',
    height: '100px',
    width: '100px',
  },
  image: {
    width: '160px',
    marginBottom: '5px',
  },
});

const SignUpCompleted = (props) => {
  const classes = useStyles();
  const { isHosted } = props;
  return (
    <div className={classes.outerDiv}>
      <div>
        <img src={Comment} className={classes.image} alt="Survey" />
      </div>
      <p className={classes.amazingWork}>
        Thank you for signing up with Translator!
      </p>
      <p className={classes.largeText}>
        Remember all conversations are confidential and annonymous.
      </p>
      <p className={classes.largeText}>
        You will be able to use our chat service for 60 days.
      </p>
      {isHosted ? (
        <div className={classes.outerDiv}>
          <p className={classes.largeText}>
            Please wait for instructions from your host.
          </p>
          <PulseLoader
            sizeUnit="px"
            size={15}
            color="#60A681"
            loading
          />
        </div>
      ) : (
        <div>
          <Button onClick={() => console.log('heyoo')}>
            Next Module
          </Button>
        </div>
      )}
    </div>
  );
};

SignUpCompleted.propTypes = {
  isHosted: PropTypes.bool.isRequired,
};

export default SignUpCompleted;
