import React from 'react';
import PropTypes from 'prop-types';
import Joyride from 'react-joyride';

const WordCloudTutorial = (props) => (
  <>
    <Joyride
      continuous
      styles={{
        options: {
          padding: '60px',
          primaryColor: '#60A681',
          textColor: '#004a14',
        },
        buttonClose: {
          display: 'none',
        },
      }}
      locale={{
        back: 'Back', close: 'Close', last: 'Start!', next: 'Next', skip: 'Skip',
      }}
      callback={(step) => {
        props.setTutorial(step.step.target);
        if (step.action === 'reset') {
          window.localStorage.setItem('word_cloud_tutorial_completed', 'true');
          props.setTutorial('');
          props.startWordCloud();
        }
      }}
      steps={[
        {
          target: 'body',
          placement: 'center',

          content: (
            <>
              <p style={{ fontSize: '24px' }}>
                Let’s get orientated to the platform!
              </p>
            </>
          ),
          disableBeacon: true,
          floaterProps: {
            disableAnimation: true,
          },
        },
        {
          target: '#question',
          content: (
            <>
              <p style={{ fontSize: '24px' }}>
                Read the question carefully.
              </p>
            </>
          ),
          disableBeacon: true,
          floaterProps: {
            disableAnimation: true,
          },
        },
        {
          target: 'form',
          content: (
            <>
              <p style={{ fontSize: '24px' }}>
                Type your response here and click the (+) symbol to add it to your list.
              </p>
            </>
          ),
          disableBeacon: true,
          floaterProps: {
            disableAnimation: true,
          },
        },
        {
          target: 'ul',
          content: (
            <>
              <p style={{ fontSize: '24px' }}>
                Your response will be added here. Click the (-) symbol to remove the word from the list.
              </p>
            </>
          ),
          disableBeacon: true,
          floaterProps: {
            disableAnimation: true,
          },
        },
        {
          target: '#submitButton',
          content: (
            <>
              <p style={{ fontSize: '24px' }}>
                Click this button to submit your list of words.
              </p>
            </>
          ),
          disableBeacon: true,
          floaterProps: {
            disableAnimation: true,
          },
        },
        {
          target: 'body',
          placement: 'center',

          content: (
            <>
              <p style={{ fontSize: '24px' }}>
                Ready to get started?
              </p>
            </>
          ),
          disableBeacon: true,
          floaterProps: {
            disableAnimation: true,
          },
        },
      ]}
    />
  </>
);

WordCloudTutorial.propTypes = {
  setTutorial: PropTypes.func.isRequired,
  startWordCloud: PropTypes.func.isRequired,
};

export default WordCloudTutorial;
