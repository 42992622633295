const words = [
  { word: 'this', count: 5 },
  { word: 'that', count: 5 },
  { word: 'fat', count: 5 },
  { word: 'lazy', count: 4 },
  { word: 'privileged', count: 4 },
  { word: 'asian', count: 4 },
  { word: 'loner', count: 3 },
  { word: 'quiet', count: 3 },
  { word: 'geek', count: 3 },
  { word: 'smart', count: 1 },
  { word: 'funny', count: 1 },
  { word: 'generous', count: 1 },
  { word: 'friendly', count: 1 },
  { word: 'nurturing', count: 1 },
  { word: 'compassionate', count: 1 },
  { word: 'strong', count: 1 },
  { word: 'hardworking', count: 1 },
];

const stereoTypeWords = [
  { word: 'nerd', count: 15 },
  { word: 'dumb', count: 9 },
  { word: 'stupid', count: 6 },
  { word: 'white', count: 5 },
  { word: 'boring', count: 5 },
  { word: 'fat', count: 5 },
  { word: 'lazy', count: 4 },
  { word: 'privileged', count: 4 },
  { word: 'asian', count: 4 },
  { word: 'loner', count: 3 },
  { word: 'quiet', count: 3 },
  { word: 'geek', count: 3 },
  { word: 'aggressive', count: 3 },
  { word: 'queer', count: 3 },
  { word: 'loser', count: 3 },
  { word: 'immigrant', count: 3 },
  { word: 'spoiled', count: 3 },
  { word: 'insensitive', count: 3 },
  { word: 'mentally ill', count: 3 },
  { word: 'terrorist', count: 3 },
  { word: 'rich', count: 2 },
  { word: 'foreigner', count: 2 },
  { word: 'woman', count: 2 },
  { word: 'promiscuous', count: 2 },
  { word: 'loud', count: 2 },
  { word: 'naive', count: 2 },
  { word: 'ghetto', count: 2 },
  { word: 'serious', count: 2 },
  { word: 'slut', count: 2 },
  { word: 'confused', count: 2 },
  { word: 'bitch', count: 2 },
  { word: 'old', count: 2 },
  { word: 'karen', count: 2 },
  { word: 'shy', count: 2 },
  { word: 'white male', count: 2 },
  { word: 'selfish', count: 2 },
  { word: 'passive', count: 2 },
  { word: 'bossy', count: 2 },
  { word: 'emotional', count: 2 },
  { word: 'weak', count: 2 },
  { word: 'chink', count: 2 },
  { word: 'nerdy', count: 2 },
  { word: 'oppressed', count: 2 },
  { word: 'lesbian', count: 2 },
  { word: 'arrogant', count: 2 },
  { word: 'weird', count: 1 },
  { word: 'snob', count: 1 },
  { word: 'reserved', count: 1 },
  { word: 'awkward', count: 1 },
  { word: 'outsider', count: 1 },
  { word: 'faker', count: 1 },
];

const authenticWords = [
  { word: 'caring', count: 19 },
  { word: 'loving', count: 13 },
  { word: 'thoughtful', count: 12 },
  { word: 'kind', count: 11 },
  { word: 'smart', count: 9 },
  { word: 'funny', count: 6 },
  { word: 'generous', count: 6 },
  { word: 'friendly', count: 6 },
  { word: 'nurturing', count: 5 },
  { word: 'compassionate', count: 5 },
  { word: 'strong', count: 5 },
  { word: 'hardworking', count: 5 },
  { word: 'loyal', count: 5 },
  { word: 'hard working', count: 4 },
  { word: 'helpful', count: 4 },
  { word: 'fun', count: 4 },
  { word: 'confident', count: 4 },
  { word: 'curious', count: 3 },
  { word: 'open minded', count: 3 },
  { word: 'genuine', count: 3 },
  { word: 'ambitious', count: 3 },
  { word: 'honest', count: 3 },
  { word: 'inclusive', count: 3 },
  { word: 'empathetic', count: 3 },
  { word: 'forgiving', count: 2 },
  { word: 'respectful', count: 2 },
  { word: 'intelligent', count: 2 },
  { word: 'dedicated', count: 2 },
  { word: 'independent', count: 2 },
  { word: 'successful', count: 2 },
  { word: 'warm', count: 2 },
  { word: 'happy', count: 2 },
  { word: 'creative', count: 2 },
  { word: 'patient', count: 2 },
  { word: 'responsible', count: 2 },
  { word: 'real', count: 2 },
  { word: 'committed', count: 2 },
  { word: 'sincere', count: 2 },
  { word: 'healing', count: 1 },
  { word: 'woman', count: 1 },
  { word: 'leader', count: 1 },
  { word: 'anxious', count: 1 },
  { word: 'happy go-lucky', count: 1 },
  { word: 'unique', count: 1 },
  { word: 'assertive', count: 1 },
  { word: 'motivated', count: 1 },
  { word: 'involved', count: 1 },
  { word: 'competitive', count: 1 },
  { word: 'goofy', count: 1 },
  { word: 'cares about others', count: 1 },
  { word: 'responsive', count: 1 },
  { word: 'polite', count: 1 },
  { word: 'easy to talk', count: 1 },
  { word: 'emotional intelligen', count: 1 },
  { word: 'diligent', count: 1 },
  { word: 'dope', count: 1 },
  { word: 'action driven', count: 1 },
  { word: 'therapist', count: 1 },
  { word: 'self-motivated', count: 1 },
  { word: 'nice', count: 1 },
  { word: 'connected', count: 1 },
  { word: 'wholesome', count: 1 },
  { word: 'passionate', count: 1 },
  { word: 'authentic', count: 1 },
  { word: 'reliable', count: 1 },
  { word: 'open heart', count: 1 },
  { word: 'understanding', count: 1 },
  { word: 'introvert', count: 1 },
  { word: 'sensible', count: 1 },
  { word: 'trustworthy', count: 1 },
  { word: 'father', count: 1 },
  { word: 'tenacious', count: 1 },
  { word: 'family man', count: 1 },
];

export { authenticWords, stereoTypeWords, words };
