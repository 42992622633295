/* eslint-disable quotes */
/* eslint-disable import/no-cycle */
// Icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import Image from '@material-ui/icons/Image';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import AssessmentIcon from '@material-ui/icons/Assessment';
import EventNoteIcon from '@material-ui/icons/EventNote';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import InfoIcon from '@material-ui/icons/Info';
import SubjectIcon from '@material-ui/icons/Subject';
import CloudQueueIcon from '@material-ui/icons/CloudQueue';
import BusinessIcon from '@material-ui/icons/Business';
import BlurLinearIcon from '@material-ui/icons/BlurLinear';
import PeopleIcon from '@material-ui/icons/People';
import SpeedIcon from '@material-ui/icons/Speed';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import { PollOutlined } from '@material-ui/icons';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
// Views
import Brightness5Icon from '@material-ui/icons/Brightness5';
import Host from './views/Host/Host';
import Analytics from './views/Analytics/Analytics';
import Chat from './views/Chat/Chat';
import LoginPage from './views/Pages/LoginPage';
import SettingsForm from './views/Pages/SettingsForm/SettingsPage2';
import WalkOfPrivilegeTable from './views/Modules/WalkOfPrivilege/WOPTable/WOPTableNew';
import WalkOfPrivilegeQuestionTable from './views/Modules/WalkOfPrivilege/WOPQuestionsTable/WOPQuestionsTableNew';
// import SurveyQuestionTable from './views/Modules/Survey/SurveyQuestionTable';
import SurveyQuestionTable from './views/Modules/Survey/SurveyQuestionsTable/SurveyQuestionTableNew';
import NewWalkOfPrivilege from './views/Modules/WalkOfPrivilege/NewWop';
import NewWordCloud from './views/Modules/WordCloud/NewWordCloud';
// import InfoModuleTable from './views/Modules/InfoModule/InfoModule';
import InfoModuleTable from './views/Modules/InfoModule/InfoModuleTableNew';
import ChatUserTable from './views/ChatUsers/ChatUserTableNew';
import NewInfoModule from './views/Modules/InfoModule/Wizard/NewInfoModule';
// import SurveyTable from './views/Modules/Survey/SurveyTable';
import SurveyTable from './views/Modules/Survey/SurveyTable/SurveyTableNew';
// import ContinuumTable from './views/Modules/Continuum/ContinuumTableNew';
// import ContinuumWizard from './views/Modules/Continuum/Wizard/NewContinuum';
// import ContinuumQuestionTable from './views/Modules/Continuum/ContinuumQuestionTable';
import WordCloudTable from './views/Modules/WordCloud/WordCloudTable/WordCloudTableNew';
import WordCloudQuestionTable from './views/Modules/WordCloud/WordCloudQuestionsTable/WordCloudQuestionsTableNew';
// import WordPopcornTable from './views/Modules/WordPopcorn/WordPopcornTableNew';
import Events from './views/Events/EventsTableNew';
// import TagReports from './views/Reports/TagsReports';
import SessionReportTable from './views/Reports/Sessions/SessionReportTableNew';
import SessionReport from './views/Reports/SessionReport';
import CompanyReportTable from './views/Reports/Companies/CompanyTable';
import CompanyReport from './views/Reports/CompanyReport';
import ComfortZoneReport from './views/Reports/ComfortZoneReport';
import CompanyWizard from './views/Companies/NewCompany';
import CompanyTable from './views/Companies/CompanyTableNew';
import EventWizard from './views/Events/Wizard/NewEvent';
import SessionsTable from './views/Sessions/SessionsTableNew';
import SessionHistoryTable from './views/Sessions/SessionHistory/SessionHistoryTable';
import Audience from './views/Audience/Audience';
import Terms from './views/Legal/Terms';
import Privacy from './views/Legal/Privacy';
import SurveyWizard from './views/Modules/Survey/NewSurvey';
import ComfortWizard from './views/Modules/ComfortMeter/Wizard/NewComfortMeter';
import ComfortTable from './views/Modules/ComfortMeter/ComfortZoneTable/ComfortTableNew';
import CZStatementsTable from './views/Modules/ComfortMeter/ComfortZoneStatementTable/CZStatementTableNew';
import ChatSignUp from './views/Chat/SignUp';
import UsersTable from './views/Users/Users/UsersTable';
import TrainingGroups from './views/Users/TraningGroups/TrainingGroups';
import AutoHostSlides from './views/Modules/AutoHost/AutoHostTable/AutoHostSlidesTable';
import AutoHostQuestions from './views/Modules/AutoHost/AutoHostQuestionTable/AHQuestionsTable';
import NewSlide from './views/Modules/AutoHost/NewAutoHostSlide';
import PollTable from './views/Modules/Poll/PollTable/PollTableNew';
import PollQuestionsTable from './views/Modules/Poll/PollQuestionsTable/PollQuestionsTable';
import NewPoll from './views/Modules/Poll/NewPoll';
// import WordCloudWordTable from './views/Modules/WordCloud/WordCloudTagTable/WordCloudWordTable';
import WordCloudNavPills from './views/Modules/WordCloud/WordCloudTagTable/WordCloudNavPills';
import WordPopcornTable from './views/Modules/WordPopcorn/WordPopcornTable';
import NewWordPopcorn from './views/Modules/WordPopcorn/NewWordPopcorn';
import NewUser from './views/Users/Wizard/NewUser';
import Resources from './views/Resources/ResourcesPage';
import Resource from './views/Resources/Resource';
import NewResource from './views/Resources/NewResourceWizard/NewResource';
import ResourceTable from './views/Resources/ResourceTable/ResourceTable';
import NewUpdate from './views/Updates/NewUpdateWizard/NewUpdate';

const dashRoutes = [
  {
    path: '/login',
    name: 'Login Page',
    component: LoginPage,
    layout: '/auth',
    role: ['admin', 'user', 'tester'],
    showInSidebar: false,
  },
  {
    path: '/settings',
    name: 'Settings',
    icon: DashboardIcon,
    component: SettingsForm,
    layout: '/admin',
    role: ['admin', 'user', 'tester'],
    showInSidebar: false,
  },
  {
    path: '/host',
    name: 'Host',
    icon: DashboardIcon,
    component: Host,
    layout: '/host',
    role: ['admin', 'user', 'tester'],
    showInSidebar: false,
  },
  {
    path: '/analytics',
    name: 'Analytics',
    icon: DashboardIcon,
    component: Analytics,
    layout: '/admin',
    role: ['admin', 'user', 'tester'],
    showInSidebar: true,
  },
  {
    path: '/sessionHistory',
    name: 'Session History',
    icon: AssessmentIcon,
    component: SessionHistoryTable,
    layout: '/admin',
    role: ['admin', 'user', 'tester'],
    showInSidebar: true,
  },
  {
    path: '/analytics/:id',
    name: 'Session Report',
    component: Analytics,
    layout: '/admin',
    role: ['admin', 'user', 'tester'],
    showInSidebar: false,
  },
  {
    collapse: true,
    name: 'Chat',
    icon: Image,
    state: 'chat',
    showInSidebar: false,
    role: ['admin'],
    views: [
      {
        path: '/chatUsers',
        name: 'Chat Users',
        mini: 'CU',
        component: ChatUserTable,
        layout: '/admin',
        role: ['admin'],
        showInSidebar: true,
      },
      {
        path: '/conversations',
        name: 'Conversations',
        mini: 'CO',
        component: Chat,
        layout: '/admin',
        role: ['admin'],
        showInSidebar: true,
      },

    ],
  },
  {
    collapse: true,
    name: 'Companies',
    icon: BusinessIcon,
    state: 'company',
    role: ['admin'],
    views: [
      {
        path: '/newCompany',
        name: 'New Company',
        mini: 'New',
        component: CompanyWizard,
        layout: '/admin',
        role: ['admin'],
        showInSidebar: true,
      },
      {
        path: '/companies',
        name: 'Company Table',
        mini: 'COMP',
        component: CompanyTable,
        layout: '/admin',
        role: ['admin'],
        showInSidebar: true,
      },
    ],
  },
  {
    collapse: true,
    name: 'Modules',
    icon: ViewModuleIcon,
    state: 'modules',
    role: ['admin'],
    views: [
      {
        collapse: true,
        name: 'Slider',
        icon: DirectionsWalkIcon,
        state: 'wop',
        views: [
          {
            path: '/walkOfPrivilege',
            name: 'Slider Table',
            mini: 'S',
            component: WalkOfPrivilegeTable,
            layout: '/admin',
            role: ['admin'],
            showInSidebar: true,
          },
          {
            path: '/newWop',
            name: 'New Slider',
            mini: 'New',
            component: NewWalkOfPrivilege,
            layout: '/admin',
            role: ['admin'],
            showInSidebar: true,
          },
          {
            path: '/wopQuestions',
            name: 'Slider Questions Table',
            mini: '?',
            component: WalkOfPrivilegeQuestionTable,
            layout: '/admin',
            role: ['admin'],
            showInSidebar: true,
          },
        ],
      },
      {
        collapse: true,
        name: 'Info',
        icon: InfoIcon,
        state: 'info',
        views: [
          {
            path: '/infoModule',
            name: 'Info Table',
            mini: 'I',
            component: InfoModuleTable,
            layout: '/admin',
            role: ['admin'],
            showInSidebar: true,
          },
          {
            path: '/newInfo',
            name: 'New Info',
            mini: 'New',
            component: NewInfoModule,
            layout: '/admin',
            role: ['admin'],
            showInSidebar: true,
          },
        ],
      },
      {
        collapse: true,
        name: 'Survey',
        icon: SubjectIcon,
        state: 'survey',
        role: ['admin'],
        views: [
          {
            path: '/survey',
            name: 'Surveys Table',
            mini: 'SU',
            component: SurveyTable,
            layout: '/admin',
            role: ['admin'],
            showInSidebar: true,
          },
          {
            path: '/newSurvey',
            name: 'New Survey',
            mini: 'New',
            component: SurveyWizard,
            layout: '/admin',
            role: ['admin'],
            showInSidebar: true,
          },
          {
            path: '/SurveyQuestions',
            name: 'Survey Questions Table',
            mini: '?',
            component: SurveyQuestionTable,
            layout: '/admin',
            role: ['admin'],
            showInSidebar: true,
          },
        ],
      },
      {
        collapse: true,
        name: 'Poll',
        icon: PollOutlined,
        state: 'poll',
        role: ['admin'],
        views: [
          {
            path: '/poll',
            name: 'Polls Table',
            mini: 'P',
            component: PollTable,
            layout: '/admin',
            role: ['admin'],
            showInSidebar: true,
          },
          {
            path: '/newPoll',
            name: 'New Poll',
            mini: 'NP',
            component: NewPoll,
            layout: '/admin',
            role: ['admin'],
            showInSidebar: true,
          },
          {
            path: '/PollQuestions',
            name: 'Poll Questions Table',
            mini: 'PQ',
            component: PollQuestionsTable,
            layout: '/admin',
            role: ['admin'],
            showInSidebar: true,
          },
        ],
      },
      {
        collapse: true,
        name: 'Likert',
        icon: SpeedIcon,
        state: 'comfortMeter',
        role: ['admin'],
        views: [
          {
            path: '/newComfortZone',
            name: 'New Likert',
            mini: 'New',
            component: ComfortWizard,
            layout: '/admin',
            role: ['admin'],
            showInSidebar: true,
          },
          {
            path: '/comfortZones',
            name: 'Likert Table',
            mini: 'L',
            component: ComfortTable,
            layout: '/admin',
            role: ['admin'],
            showInSidebar: true,
          },
          {
            path: '/comfortStatements',
            name: 'Likert Statements Table',
            mini: 'S\'s',
            component: CZStatementsTable,
            layout: '/admin',
            role: ['admin'],
            showInSidebar: true,
          },
        ],
      },
      {
        collapse: true,
        name: 'Word Cloud',
        icon: CloudQueueIcon,
        state: 'wordCloud',
        role: ['admin'],
        views: [
          {
            path: '/wordCloud',
            name: 'Word Cloud Table',
            mini: 'WC',
            component: WordCloudTable,
            layout: '/admin',
            role: ['admin'],
            showInSidebar: true,
          },
          {
            path: '/newWordCloud',
            name: 'New Word Cloud',
            mini: 'New',
            component: NewWordCloud,
            layout: '/admin',
            role: ['admin'],
            showInSidebar: true,
          },
          {
            path: '/wordCloudQuestions',
            name: 'Word Cloud Questions Table',
            mini: 'Qs',
            component: WordCloudQuestionTable,
            layout: '/admin',
            role: ['admin'],
            showInSidebar: true,
          },
          {
            path: '/tagsWordCloud',
            name: 'Word Cloud Word Table',
            mini: 'Tags',
            component: WordCloudNavPills,
            layout: '/admin',
            role: ['admin'],
            showInSidebar: true,
          },
        ],
      },
      {
        collapse: true,
        name: 'Word Popcorn',
        icon: Brightness5Icon,
        state: 'wordPopcorn',
        role: ['admin'],
        views: [
          {
            path: '/wordPopcorn',
            name: 'Word Popcorn Table',
            mini: 'Pop',
            component: WordPopcornTable,
            layout: '/admin',
            role: ['admin'],
            showInSidebar: true,
          },
          {
            path: '/newWordPopcorn',
            name: 'New Word Popcorn',
            mini: 'New',
            component: NewWordPopcorn,
            layout: '/admin',
            role: ['admin'],
            showInSidebar: true,
          },
        ],
      },
    ],
  },
  {
    collapse: true,
    name: 'Events',
    icon: BlurLinearIcon,
    state: 'events',
    role: ['admin'],
    views: [
      {
        path: '/newEvent',
        name: 'New Event',
        mini: 'New',
        component: EventWizard,
        layout: '/admin',
        role: ['admin'],
        showInSidebar: true,
      },
      {
        path: '/events',
        name: 'Events Table',
        mini: 'Eve',
        component: Events,
        layout: '/admin',
        role: ['admin'],
        showInSidebar: true,
      },
    ],
  },
  {
    collapse: true,
    name: 'Sessions',
    icon: EventNoteIcon,
    state: 'session',
    role: ['admin', 'user', 'tester'],
    views: [
      {
        path: '/sessions',
        name: 'Sessions Table',
        mini: ` S `,
        component: SessionsTable,
        layout: '/admin',
        role: ['admin', 'user', 'tester'],
        showInSidebar: true,
      },
    ],
  },
  {
    collapse: true,
    name: 'Auto-Host Slides',
    icon: AllInclusiveIcon,
    state: 'autoHost',
    role: ['admin'],
    views: [
      {
        path: '/autoHostSlides',
        name: 'Auto-Host Slides Table',
        mini: 'AH',
        component: AutoHostSlides,
        layout: '/admin',
        role: ['admin'],
      },
      {
        path: '/newAutoHostSlide',
        name: 'New Auto-Host Slide',
        mini: 'NEW',
        component: NewSlide,
        layout: '/admin',
        role: ['admin'],
      },
      {
        path: '/autoHostQuestions',
        name: 'Auto-Host Question Table',
        mini: 'QT',
        component: AutoHostQuestions,
        layout: '/admin',
        role: ['admin'],
      },
      {
        path: '/newAutoHostQuestion',
        name: 'New Auto-Host Question',
        mini: 'NEW',
        component: '',
        layout: '/admin',
        role: ['admin'],
        showInSidebar: false,
      },
    ],
  },
  {
    collapse: true,
    name: 'Reports',
    icon: AssessmentIcon,
    state: 'reports',
    role: ['admin'],
    views: [
      {
        path: '/sessionReports/:pin',
        name: 'Session Report',
        component: SessionReport,
        layout: '/admin',
        role: ['admin'],
        showInSidebar: false,
      },
      {
        path: '/sessionReports',
        name: 'Session Reports Table',
        mini: 'Ses',
        component: SessionReportTable,
        layout: '/admin',
        role: ['admin'],
        showInSidebar: true,
      },
      {
        path: '/companyReports',
        name: 'Company Reports Table',
        mini: 'Com',
        component: CompanyReportTable,
        layout: '/admin',
        role: ['admin'],
        showInSidebar: true,
      },
      {
        path: '/companyReport/:id',
        name: 'Company Report',
        component: CompanyReport,
        layout: '/admin',
        role: ['admin'],
        showInSidebar: false,
      },
      {
        path: '/comfortZoneReport',
        name: 'Comfort Zone temp',
        mini: 'CZ',
        component: ComfortZoneReport,
        layout: '/admin',
        role: ['admin'],
        showInSidebar: true,
      },
    ],
  },
  {
    collapse: true,
    name: 'Users',
    icon: PeopleIcon,
    state: 'users',
    role: ['admin'],
    views: [
      {
        path: '/users',
        name: 'Users Table',
        mini: 'U',
        component: UsersTable,
        layout: '/admin',
        role: ['admin', 'user', 'tester'],
        showInSidebar: true,
      },
      {
        path: '/NewUser',
        name: 'New User',
        mini: 'New',
        component: NewUser,
        layout: '/admin',
        role: ['admin'],
        showInSidebar: true,
      },
      {
        path: '/TrainingGroups',
        name: 'Training Table',
        mini: 'T',
        component: TrainingGroups,
        layout: '/admin',
        role: ['admin'],
        showInSidebar: true,
      },

    ],
  },
  {
    path: '/resources',
    name: 'Resources',
    icon: LibraryBooksIcon,
    // mini: 'R',
    component: Resources,
    layout: '/admin',
    role: ['admin', 'user', 'tester'],
    showInSidebar: true,
  },
  {
    collapse: true,
    name: 'Platform Updates',
    icon: LibraryAddIcon,
    role: ['admin'],
    views: [
      {
        path: '/NewUpdate',
        name: 'New Platform Update',
        mini: ` NU `,
        component: NewUpdate,
        layout: '/admin',
        role: ['admin'],
        showInSidebar: true,
      },
      {
        path: '/NewResource',
        name: 'New Resource',
        mini: ` NR `,
        component: NewResource,
        layout: '/admin',
        role: ['admin'],
        showInSidebar: true,
      },
      {
        path: '/ResourceTable',
        name: 'Resource Table',
        mini: `  RT  `,
        component: ResourceTable,
        layout: '/admin',
        role: ['admin'],
        showInSidebar: true,
      },
    ],
  },
  {
    path: '/resource/:id',
    name: 'Resource',
    component: Resource,
    layout: '/admin',
    state: 'resource',
    role: ['admin', 'user', 'tester'],
    showInSidebar: false,
  },
  {
    path: '/event/:pin',
    name: 'Audience',
    component: Audience,
    layout: '/host',
    role: ['admin'],
    showInSidebar: false,
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
    layout: '/host',
    role: ['admin'],
    showInSidebar: false,
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
    layout: '/host',
    role: ['admin'],
    showInSidebar: false,
  },
  {
    path: '/letschat',
    name: 'Chat',
    component: ChatSignUp,
    layout: '/host',
    role: ['admin'],
    showInSidebar: false,
  },
  {
    path: '/',
    name: 'Audience',
    component: Audience,
    layout: '/host',
    role: ['admin'],
    showInSidebar: false,
  },
];
export default dashRoutes;
