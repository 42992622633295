/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Modules from './modules';
// core components
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import styles from '../../assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import CompanyAudienceUserTable from './Companies/CompanyAudienceUserTable';
import LiveChatReport from './liveChat/LiveChatReport';
import { loadAuthToken } from '../../utils/auth';
import { AuthContext } from '../../Auth';

const useStyles = makeStyles(styles);

function CompanyReport() {
  const classes = useStyles();
  const context = useContext(AuthContext);
  const [events, setEvents] = useState([]);
  const [modules, setModules] = useState([]);
  const [company, setCompany] = useState({});
  const [audienceUserData, setAudienceUserData] = useState({});
  const id = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  useEffect(() => {
    const fetchData = async () => {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_EVENT_SERVICE}/get-company-report?companyId=${id}`,
        headers: {
          Authorization: `bearer ${loadAuthToken()}`,
        },
      }).then((res) => {
        setModules(res.data.modules);
        setEvents(res.data.events);
        setCompany(res.data.company);
        return res.data.company;
      }).then((comp) => {
        if (comp) {
          return axios({
            method: 'get',
            url: `${process.env.REACT_APP_REPORT_SERVICE}/get-company-audience-user-report?companyId=${comp.id}`,
          }).then((_res) => {
            setAudienceUserData(_res.data);
          }).catch((e) => {
            context.setMessageState('error');
            try {
              context.setSnackBarMessage(`${e.response.data.message}`);
            } catch (err) {
              context.setSnackBarMessage(`${e}`);
            }
          });
        }
      }).catch((e) => {
        context.setMessageState('error');
        context.setSnackBarMessage(`${e.response.data.message}`);
      });
    };
    fetchData();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={4}>
          <Card>
            <CardHeader>
              <p className={classes.cardCategory}>Company Report</p>
              <h3 className={classes.cardTitle}>
                {' '}
                {company.name}
              </h3>
              {events.map((event, index) => (
                <Accordion key={5000 + index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p>{event.title}</p>
                  </AccordionSummary>
                  <AccordionDetails style={{ display: 'block', textAlign: 'center' }}>
                    <p>
                      Audience Users:
                      {event.audienceUsers}
                    </p>
                    <Divider />
                    <p>
                      Type:
                      {event.type}
                    </p>
                    <Divider />
                    <p>Modules</p>
                    <Divider />
                    {event.modules.map((mod, i) => (
                      <React.Fragment key={i + 9000}>
                        {mod.moduleTitle}
                        <Divider />
                      </React.Fragment>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        {
          modules ? modules.map((module, index) => {
            if (module.type !== 'infoModule') {
              return (
                <GridItem key={2000 + index} xs={12} sm={12} md={12} lg={12}>
                  <Modules module={module} companyId={company.id} />
                </GridItem>

              );
            }
            return null;
          })
            : ''
          }
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <LiveChatReport companyId={company.id} />
        </GridItem>
        {audienceUserData.headers ? (
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <CompanyAudienceUserTable
              csvHeaders={audienceUserData.csvHeaders}
              data={audienceUserData.data}
              headers={audienceUserData.headers}
            />
          </GridItem>

        ) : null}
      </GridContainer>
    </>
  );
}

export default CompanyReport;
