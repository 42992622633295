import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import AddIcon from '@material-ui/icons/Add';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../../../../components/CustomButtons/Button';
import CustomInput from '../../../../components/CustomInput/CustomInput';
import { AuthContext } from '../../../../Auth';
import { loadAuthToken } from '../../../../utils/auth';

const AddQuestionDialog = (props) => {
  const { open, togalModal, history } = props;
  const [questionError, setQuestionError] = useState(false);
  const [userQuestion, setUserQuestion] = useState('');
  const { setMessageState, setSnackBarMessage } = useContext(AuthContext);

  const handleSubmit = () => {
    let isError = false;
    if (userQuestion.length === 0) {
      isError = true;
      setQuestionError(true);
    }
    if (!isError) {
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_MODULE_SERVICE}/slide-question`,
        headers: {
          Authorization: `bearer ${loadAuthToken()}`,
        },
        data: {
          questionText: userQuestion,
        },
      }).then(() => {
        setMessageState('success');
        setSnackBarMessage(`Successfully created Discussion question: ${userQuestion}`);
        setUserQuestion('');
        setQuestionError(false);
        togalModal();
      }).catch((err) => {
        setMessageState('error');
        setSnackBarMessage(`${err.response.data.message}`);
        if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
          history.push('auth/login');
        }
      });
    }
  };

  return (
    <div>
      <Dialog
        maxWidth="md"
        open={open}
        onClose={() => togalModal()}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: '100%',
            minHeight: '60%',
          },
        }}
      >
        <DialogTitle id="form-dialog-title">Add New Question</DialogTitle>
        <DialogContent>
          <CustomInput
            error={questionError}
            labelText={(
              <span>
                Question
                {' '}
                <small>(required)</small>
              </span>
            )}
            id="question"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: userQuestion,
              onChange: (event) => {
                if (questionError) setQuestionError(false);
                setUserQuestion(event.target.value);
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => togalModal()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleSubmit()}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AddQuestionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  togalModal: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object,
};

export default AddQuestionDialog;
