import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/Accordion';
import ExpansionPanelSummary from '@material-ui/core/AccordionSummary';
import ExpansionPanelDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxHeight: '50vh',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  table: {
    overflow: 'auto',
  },
  details: {
    maxHeight: '450px',
    overflow: 'auto',
  },
}));

export default function SimpleExpansionPanel({ percents }) {
  const classes = useStyles();
  const [questionPercents, setQuestionPercents] = useState(percents);
  useEffect(() => {
    setQuestionPercents(percents);
  }, [percents]);
  return (
    <div className={classes.root}>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Analysis</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Question</TableCell>
                <TableCell align="right">Percent</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {questionPercents.map((row) => (
                <TableRow key={row.question}>
                  <TableCell component="th" scope="row">
                    Swipe
                    {' '}
                    {row.direction}
                    {' '}
                    {row.question}
                  </TableCell>
                  <TableCell align="right">
                    {row.percent ? row.percent.toFixed(2) : 0}
                    %
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}

SimpleExpansionPanel.propTypes = {
  percents: PropTypes.arrayOf(PropTypes.object).isRequired,
};
