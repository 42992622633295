import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  stats: {
    bottom: '35px',
    marginLeft: '-20px',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: '215px',
  },
  innerDiv: {
    background: '#6CCA98',
    borderRadius: '5px',
    color: 'white',
    padding: '0px 20px',
    margin: '0 10px',
  },
});

function Stats(props) {
  const { pin } = props;

  const classes = useStyles();

  return (
    <div className={classes.stats}>
      <div className={classes.innerDiv}><h4>www.join.host</h4></div>
      <div className={classes.innerDiv}>
        <h4>
          Pin:{' '}
          {pin}
        </h4>
      </div>
    </div>
  );
}

Stats.propTypes = {
  pin: PropTypes.number.isRequired,
};

export default Stats;
