/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
// import styled from 'styled-components'
import Button from '../../components/CustomButtons/Button';

function ArchiveWarning({
  isOpen, close, name, archiveConvo,
}) {
  return (
    <Dialog
      open={isOpen}
      onClose={close}
    >
      <p>
        Are you sure you want to archive
        {name}
        ?
      </p>
      <Button onClick={() => archiveConvo()}>I'm Sure!</Button>
      <Button onClick={() => close()}>No, don't archive</Button>
    </Dialog>
  );
}

ArchiveWarning.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  archiveConvo: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default ArchiveWarning;
