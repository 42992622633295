import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import Logo from '../../assets/img/full-logo-color.png';

// @material-ui/icons

// core components
import styles from '../../assets/jss/material-dashboard-pro-react/components/authNavbarStyle';


const useStyles = makeStyles(styles);

export default function AuthNavbar(props) {
  // verifies if routeName is the one active (in browser input)
  const classes = useStyles();
  const { color } = props;
  const appBarClasses = cx({
    [` ${classes[color]}`]: color,
  });

  return (
    <AppBar position="static" className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown>
          <div className={classes.flex}>
            <img className={classes.headerLogo} src={Logo} alt="logo" />
          </div>
        </Hidden>
        <Hidden mdUp>
          <div className={classes.flex}>
            <img className={classes.headerLogo} src={Logo} alt="logo" />
          </div>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

AuthNavbar.propTypes = {
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
};
