import { primaryColor } from '../../material-dashboard-pro-react';
// import watercolors from '../../../img/watercolors_opt.jpg';

// We listen to the resize event
window.addEventListener('resize', () => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  const vh = window.innerHeight * 0.01;
  // // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

const pagesStyle = (theme) => ({

  wrapper: {
    fontSize: '16px',
    display: 'flex',
    // minHeight: '100vh',
    // position: 'relative',
    // overflowX: 'hidden',
    margin: '0',
    border: '0',
    position: 'fixed',
    width: '100%',

    padding: '10px',
    top: '0',
    backgroundColor: primaryColor[2],

    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },

    // overflow: 'hidden',
  },
  // fullPage: {
  //   position: 'relative',
  //   minHeight: '100vh',
  //   margin: '0',
  //   border: '0',
  //   color: blackColor,
  //   backgroundSize: 'cover',
  //   backgroundPosition: 'center center',
  //   height: '100%',

  //   [theme.breakpoints.down('sm')]: {
  //     minHeight: 'fit-content!important',
  //   },
  //   '& footer': {
  //     position: 'absolute',
  //     bottom: '0',
  //     width: '100%',
  //     border: 'none !important',
  //   },
  //   '&:before,&:after': {
  //     display: 'block',
  //     // content: '""',
  //     position: 'absolute',
  //     width: '100%',
  //     height: '100%',
  //     top: '0',
  //     left: '0',
  //     zIndex: '2',
  //   },
  // },
  innerPage: {
    width: '100%',
    display: 'flex !important',
    justifyContent: 'center',
    // alignItems: 'center',
    padding: '10px',
    backgroundColor: 'white',
    overflow: 'scroll !important',
  },
  wopPage: {
    width: '100%',
    display: 'flex !important',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      // backgroundImage: `url(${watercolors})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      overflow: 'hidden',
      width: '100%',
    },
  },
});

export default pagesStyle;
