import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NavPills from '../../../../components/NavPills/NavPillsOriginal';
import Card from '../../../../components/Card/Card';
import Dialog from '../../../../components/Alert/alert';
import TagContent from './TagContent';

const TaggedWords = (props) => {
  const { wordTags, tagWord } = props;

  // const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedWord, setSelectedWord] = useState({});
  const [selectedTag, setSelectedTag] = useState({});
  // const [tagId, setTagId] = useState(0);

  const tabs = [];

  // eslint-disable-next-line arrow-body-style
  wordTags.map((tag) => {
    // setTagId(tag.id);

    return tabs.push({
      tabButton: tag.tag,
      tabContent: (
        <TagContent
          wordTags={wordTags}
          tagId={tag.id}
          tag={tag.tag}
          setIsDialogOpen={setIsDialogOpen}
          setSelectedTag={setSelectedTag}
          setSelectedWord={setSelectedWord}
          isDialogOpen={isDialogOpen}
        />
      ),
    });
  });
  return (
    <>
      <Dialog
        open={isDialogOpen}
        toggleDialog={setIsDialogOpen}
        text={`Are you sure you want to tag  "${selectedWord.answer}"  as "${selectedTag.tag}" ?`}
        action={
          () => tagWord(selectedWord.answer, selectedWord.id, selectedTag.tag, selectedTag.id)
        }
      />
      <Card>
        <NavPills
          tabs={tabs}
          // active={0}
          horizontal={{
            tabsGrid: { xs: 12, sm: 4 },
            contentGrid: { xs: 12, sm: 8 },
          }}
        />
      </Card>

    </>

  );
};

TaggedWords.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  wordTags: PropTypes.arrayOf(PropTypes.object),
  tagWord: PropTypes.func,
};

export default TaggedWords;
