/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import PropTypes from 'prop-types';
import EnhancedTable from './EnhancedTable';

const App = (props) => {
  const columns = React.useMemo(
    () => props.headers, [],
  );

  const [skipPageReset, setSkipPageReset] = React.useState(false);

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = () => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
  };
  const {
    data, name, isChatUser, history, buttons, getChatUsers
  } = props;
  return (
    <div>
      <CssBaseline />
      <EnhancedTable
        columns={columns}
        isChatUser={isChatUser}
        getChatUsers={() => getChatUsers()}
        name={name}
        data={data}
        history={history}
        updateMyData={updateMyData}
        skipPageReset={skipPageReset}
        buttons={buttons}
      />
    </div>
  );
};

App.propTypes = {
  data: PropTypes.array,
  headers: PropTypes.array,
  history: PropTypes.object,
  name: PropTypes.string.isRequired,
  getChatUsers: PropTypes.func,
  isChatUser: PropTypes.bool,
};
export default App;
