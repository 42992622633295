import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '../../components/CustomButtons/Button';
import Logo from '../../assets/img/full-logo-color.png';
import T from '../../assets/img/logo.png';

const useStyles = makeStyles({
  paper: {
    justifyContent: 'space-between',
    color: '#60A681',
    minWidth: '235px',
  },
  left: {
    // display: 'flex',
    // justifyContent: 'center',
    justifyContent: 'left',
    textAlign: 'left',
    width: '100%',
    padding: '1rem',
  },
  navBtn: {
    padding: '10px',
    position: 'absolute',
    // justifyContent: 'center',
    top: '0px',
    left: '0px',
    borderRadius: '5px 0',
    opacity: '0 !important',
    transition: '0.9s',

    '&:hover': {
      opacity: '1 !important',
      background: '#60A681',

    },
  },
  navCenter: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: '1rem',
  },
  navLogo: {
    width: '60px',
    height: '55px',
  },
  logo: {
    width: '175px',
  },
});

function SideBar(props) {
  const {
    modules, sessionComplete, handleChangeModule, handleEndSession, title,
  } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpen(!open);
  };

  const list = (allModules) => (
    <div
      role="presentation"
      onClick={toggleDrawer()}
      onKeyDown={toggleDrawer()}
    >
      <List>
        {allModules.map((mod) => (
          <ListItem button key={mod.title + mod.id} onClick={() => { handleChangeModule(mod); }}>
            <ListItemText primary={mod.title} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <div>
      <Button className={clsx(classes.navBtn)} onClick={toggleDrawer()}>
        <img className={classes.navLogo} src={T} alt="logo" />
      </Button>
      <Drawer anchor="left" open={open} onClose={toggleDrawer()} classes={{ paper: classes.paper }}>
        <div>
          <div className={classes.navCenter}>
            <img className={classes.logo} src={Logo} alt="Logo" />
          </div>
          <Divider />
          <div className={clsx(classes.title, classes.left)}>
            <h4>{title}</h4>
          </div>
          <Divider />
          { list(modules) }
        </div>
        <div>
          <Divider />
          <Button
            disabled={sessionComplete}
            // className={clsx(classes.left)}
            color="primary"
            simple
            onClick={() => { handleEndSession(); }}
          >
            End Session
          </Button>
        </div>
      </Drawer>
    </div>
  );
}

SideBar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  modules: PropTypes.arrayOf(PropTypes.object).isRequired,
  sessionComplete: PropTypes.bool,
  handleEndSession: PropTypes.func.isRequired,
  handleChangeModule: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default SideBar;
