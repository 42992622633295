/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
// import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
// import Input from '../../../../components/CustomInput/CustomInput';
import Input from '@material-ui/core/Input';
import GreenRadio from '../../../../assets/jss/material-dashboard-pro-react/components/greenRadio';

export default function MultipleChoice({ options, setAnswer, value }) {
  const [customAnswer, setCustomAnswer] = useState('');
  useEffect(() => {
    setAnswer('');
  }, []);
  return (
    <FormControl component="fieldset">
      <RadioGroup
        name="Mutiple choice"
        value={value}
        onChange={(e) => {
          setCustomAnswer('');
          setAnswer(e.target.value);
        }}
      >
        {options.map((option, index) => {
          if (option.type) {
            if (option.type === 'input') {
              return (
                <div style={{ color: '#000' }} key={index + 5000}>
                  {option.option}
                  <Input
                    value={customAnswer}
                    onChange={(e) => {
                      setCustomAnswer(e.target.value);
                      setAnswer(e.target.value);
                    }}
                  />
                </div>
              );
            }
            return (
              <FormControlLabel
                key={index + 5000}
                value={option.option}
                control={<GreenRadio />}
                label={option.option}
              />
            );
          }
          return (
            <FormControlLabel
              key={index + 5000}
              value={option}
              control={<GreenRadio />}
              label={option}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}

MultipleChoice.propTypes = {
  options: PropTypes.array.isRequired,
  setAnswer: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
