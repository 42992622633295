/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import { loadAuthToken } from '../../../utils/auth';
import SurveyResults from './sentimentSurvey/SurveyResults';
import { AuthContext } from '../../../Auth';
import Button from '../../../components/CustomButtons/Button';
import Comment from '../../../assets/img/comment.png';

const useStyles = makeStyles({
  outerDiv: {
    margin: 'auto',
    textAlign: 'center',
  },
  imageDiv: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  contentDiv: {
    paddingTop: '.5rem',
  },
  text: {
    fontSize: '1.4rem',
  },
  largeText: {
    fontSize: '2rem',
  },
  messageIcon: {
    color: '#6CCA98',
    height: '100px',
    width: '100px',
  },
  image: {
    width: '160px',
    marginBottom: '5px',
  },
});

const Survey = ({
  moduleTitle,
  sessionId,
  moduleId,
  surveyQuestions,
}) => {
  const [usersAnswered, setUsersAnswered] = useState(0);
  const [isViewingResults, setViewingResults] = useState(false);
  const [sentimentPercent, setSentimentPercent] = useState([]);
  const [hydratedQuestionPercents, setHydratedQuestionPercents] = useState([]);
  const context = useContext(AuthContext);

  const setQuestionsWithPercents = (questions = sentimentPercent) => {
    const rawQuestionsCopy = surveyQuestions;
    const rawPercentsCopy = questions;
    rawPercentsCopy.forEach((questionObj) => {
      const index = rawQuestionsCopy.findIndex((el) => el.id === questionObj.questionId);
      rawQuestionsCopy[index] = {
        ...rawQuestionsCopy[index],
        positive: questionObj.positive,
        neutral: questionObj.neutral,
        negative: questionObj.negative,
      };
    });
    setHydratedQuestionPercents(rawQuestionsCopy);
  };

  const getFinishedUsers = () => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/sentiment-survey-answered?sessionId=${sessionId}&moduleId=${moduleId}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).then((res) => {
      if (res.data === 'your token is not valid' || res.data === 'Your token is expired') {
        context.sendToLogin();
      }
      setUsersAnswered(res.data.usersAnswered);
      setSentimentPercent(res.data.sentimentPercent);
      if (isViewingResults) {
        setQuestionsWithPercents(res.data.sentimentPercent);
      }
    }).catch((err) => {
      if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
        context.sendToLogin();
      }
    });
    context.eventsClient.service('sentiment-survey-answer').once('created', () => getFinishedUsers());
  };

  useEffect(() => {
    getFinishedUsers();
  }, [moduleId]);

  const classes = useStyles();
  if (isViewingResults) {
    return (
      <SurveyResults
        setViewingFalse={() => setViewingResults(false)}
        percents={hydratedQuestionPercents}
      />
    );
  }
  return (
    <div className={classes.outerDiv}>
      <div className={classes.imageDiv}>
        <img src={Comment} className={classes.image} alt="Survey" />
      </div>
      <Divider />
      <div className={classes.contentDiv}>
        <p className={classes.largeText}>{moduleTitle}</p>
        <br />
        <p className={classes.text}>
          Text for survey goes here
        </p>
        <p className={classes.text}>
          More text goes here
        </p>
        <p className={classes.text}>
          Even more text goes here
        </p>
        <br />
        <p className={classes.largeText}>RESPONSES</p>
        <strong>
          <p className={classes.text}>
            {usersAnswered
              ? String(usersAnswered)
              : '0'}
          </p>
        </strong>
        <div
          width="100%"
          align="flex-end"
          style={{ right: '5vw', bottom: '7vh', position: 'absolute' }}
        >
          <Button
            type="button"
            // className={classes.Btn}
            color="lightGreen"
            onClick={() => {
              setQuestionsWithPercents();
              setViewingResults(!isViewingResults);
            }}
          >
            Results
          </Button>
        </div>
      </div>
    </div>
  );
};

Survey.propTypes = {
  moduleTitle: PropTypes.string.isRequired,
  sessionId: PropTypes.number.isRequired,
  moduleId: PropTypes.number.isRequired,
  surveyQuestions: PropTypes.array.isRequired,
};

export default Survey;
