import React from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles({
  outerDiv: {
    margin: 'auto',
    textAlign: 'center',
  },
  imageDiv: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  contentDiv: {
    paddingTop: '.5rem',
  },
  text: {
    fontSize: '1.4rem',
  },
  largeText: {
    fontSize: '2rem',
  },
  infoIcon: {
    color: '#6CCA98',
    height: '100px',
    width: '100px',
  },
});

const InfoModule = (props) => {
  const classes = useStyles();
  const { moduleTitle } = props;
  return (
    <div className={classes.outerDiv}>
      <div className={classes.imageDiv}>
        <InfoIcon className={classes.infoIcon} />
      </div>
      <Divider />
      <div className={classes.contentDiv}>
        <p className={classes.largeText}>{moduleTitle}</p>
        <br />
        <p className={classes.text}>
          Please take a moment to read over the info provided.
        </p>
      </div>
    </div>
  );
};

InfoModule.propTypes = {
  moduleTitle: PropTypes.string.isRequired,
};

export default InfoModule;
