import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';


 const GreenRadio = withStyles({
    root: {
      color: '#000',
      '&$checked': {
        color: '#60A681',
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  export default GreenRadio