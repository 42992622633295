/* eslint-disable no-plusplus */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
// import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Card from '../../../../components/Card/Card';
import CardBody from '../../../../components/Card/CardBody';
import GridContainer from '../../../../components/Grid/GridContainer';
import GridItem from '../../../../components/Grid/GridItem';
import Button from '../../../../components/CustomButtons/Button';
/*
SUDO CODE:
  Count total question, assume each is one. Double that number, thats your range.
  check if divisible by ten. (totalNum % 10) if 0. split range up by 10.
  if not zero take 10 - remainder = what to be added to range
  then split range by 10

*/

const useStyles = makeStyles({
  outerDiv: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    height: '100%',
    width: '40vw',
    '@media (max-width: 600px)': {
      width: '95vw',

    },
    explainerText: {
      fontSize: '88px',
      textAlign: 'left',
    },
  },
});

// const chartLabels = [
//   '35 to 25',
//   '25 to 19',
//   '19 to 15',
//   '14 to 10',
//   '9 to 5',
//   '4 to 0',
//   '-1 to -6',
//   '-7 to -13',
//   '-14 to -20',
//   '-21 to -35'
// ];
const chartLabels = [
  '-35 to -21',
  '-20 to -14',
  '-13 to -7',
  '-6 to -1',
  '0 to 4',
  '5 to 9',
  '10 to 14',
  '15 to 19',
  '20 to 25',
  '26 to 35',
];

// eslint-disable-next-line consistent-return
const getRange = (points) => {
  if (points >= 26) {
    return '26 to 35';
  } if (points >= 20 && points <= 25) {
    return '20 to 25';
  } if (points >= 15 && points <= 19) {
    return '15 to 19';
  } if (points >= 10 && points <= 14) {
    return '10 to 14';
  } if (points >= 5 && points <= 9) {
    return '5 to 9';
  } if (points >= 0 && points <= 4) {
    return '0 to 4';
  } if (points <= -1 && points >= -6) {
    return '-6 to -1';
  } if (points <= -7 && points >= -13) {
    return '-13 to -7';
  } if (points <= -14 && points >= -20) {
    return '-20 to -14';
  } if (points <= -21 && points >= -35) {
    return '-35 to -21';
  }
};

const getColors = (usersPoints) => {
  const colorList = [];
  const range = getRange(usersPoints);
  chartLabels.map((obj) => {
    if (obj === range) {
      return colorList.push('orange');
    }
    return colorList.push('#60A681');
  });

  return colorList;
};

const CurrentData = (chartData, usersPoints) => ({
  labels: chartLabels,
  datasets: [
    {
      label: 'Privilege Distribution',
      backgroundColor: getColors(usersPoints),
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(255,99,132,0.4)',
      hoverBorderColor: 'rgba(255,99,132,1)',
      data: chartData,
    },
  ],
});

const options = (points) => ({
  maintainAspectRatio: true,

  responsive: true,
  legend: {
    display: false,
  },
  tooltips: {
    enabled: false,

    custom(tooltipModel) {
      // Tooltip Element
      let tooltipEl = document.getElementById('chartjs-tooltip');

      // Create element on first render
      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip';
        tooltipEl.innerHTML = '<p></p>';
        document.body.appendChild(tooltipEl);
      }

      if (tooltipModel.dataPoints) {
        if (!(tooltipModel.dataPoints[0].xLabel === getRange(points))) {
          tooltipEl.style.opacity = 0;
          const tableRoot = tooltipEl.querySelector('p');
          tableRoot.innerHTML = `${tooltipModel.dataPoints[0].xLabel}`;
        } else {
          const tableRoot = tooltipEl.querySelector('p');
          tableRoot.innerHTML = `Your position: ${points}`;
        }
      }

      // Hide if no tooltip
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }

      // // Set caret Position
      // tooltipEl.classList.remove('above', 'below', 'no-transform');
      // if (tooltipModel.yAlign) {
      //     tooltipEl.classList.add(tooltipModel.yAlign);
      // } else {
      //     tooltipEl.classList.add('no-transform');
      // }

      // function getBody(bodyItem) {
      //   console.log(bodyItem);
      //     return bodyItem.lines;
      // }

      // Set Text
      if (tooltipModel.body) {
        // var titleLines = tooltipModel.title || [];
        // var bodyLines = tooltipModel.body.map(getBody);
        // tooltipModel.body.forEach(bod => console.log(bod))
        // var innerHtml = '<thead>';

        // titleLines.forEach(function(title) {
        //     innerHtml += '<tr><th>' + title + '</th></tr>';
        // });
        // innerHtml += '</thead><tbody>';

        // bodyLines.forEach(function(body, i) {
        //     var colors = tooltipModel.labelColors[i];
        //     var style = 'background:' + colors.backgroundColor;
        //     style += '; border-color:' + colors.borderColor;
        //     style += '; border-width: 2px';
        //     var span = '<span style="' + style + '"></span>';
        //     innerHtml += '<tr><td>' + span + body + '</td></tr>';
        // });
        // innerHtml += '</tbody>';

        // var tableRoot = tooltipEl.querySelector('p');
        // tableRoot.innerHTML = `Your position: ${points}`;
      }

      // `this` will be the overall tooltip
      // eslint-disable-next-line no-underscore-dangle
      const position = this._chart.canvas.getBoundingClientRect();

      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1;
      tooltipEl.style.background = 'white';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.left = `${position.left + window.pageXOffset + tooltipModel.caretX}px`;
      tooltipEl.style.top = `${position.top + window.pageYOffset + tooltipModel.caretY}px`;
      // tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
      tooltipEl.style.fontSize = '16px';
      tooltipEl.style.fontStyle = 'tooltipModel._bodyFontStyle';
      tooltipEl.style.border = '1px solid black';
      tooltipEl.style.padding = `${tooltipModel.yPadding}px ${tooltipModel.xPadding}px`;
      tooltipEl.style.pointerEvents = 'none';
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          suggestedMin: 0,
          suggestedMax: 10,
          stepSize: 5,
          fontSize: 25,
          display: false,
        },
        scaleLabel: {
          display: true,
          labelString: '# Of Participants',
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          fontSize: 20,
        },
      },
    ],
  },
  plugins: {
    labels: false,
  },
});

const formatData = (allUsersAnswers) => {
  // allUsersAnswers.push({points: 23})
  // '34 to 25',
  // '25 to 19',
  // '19 to 15',
  // '14 to 10',
  // '9 to 5',
  // '4 to 0',
  // '-1 to -6',
  // '-7 to -13',
  // '-14 to -20',
  // '-21 to -34'
  /*
    Count total question, assume each is one. Double that number, thats your range.
    check if divisible by ten. (totalNum % 10) if 0. split range up by 10.
    if not zero take 10 - remainder = what to be added to range
    then split range by 10
  */
  // const range = questionsLength * 2;
  // if (range % 10 === 0) {
  //   const step = (range / 10);
  //   const positiveRange = _.range(0, questionsLength + 1, step);
  //   const negativeRange = _.range((questionsLength - (questionsLength * 2)), 0, step);
  //   console.log([...negativeRange, ...positiveRange])
  // } else {

  // }
  const returnObj = {
    3525: 0,
    2519: 0,
    1915: 0,
    1410: 0,
    95: 0,
    40: 0,
    '-1-6': 0,
    '-7-13': 0,
    '-14-20': 0,
    '-21-35': 0,
  };
  const returnArr = [];
  allUsersAnswers.forEach((userAnswer) => {
    if (userAnswer.points >= 26) {
      returnObj['3525']++;
    } else if (userAnswer.points >= 20 && userAnswer.points <= 25) {
      returnObj['2519']++;
    } else if (userAnswer.points >= 15 && userAnswer.points <= 19) {
      returnObj['1915']++;
    } else if (userAnswer.points >= 10 && userAnswer.points <= 14) {
      returnObj['1410']++;
    } else if (userAnswer.points >= 5 && userAnswer.points <= 9) {
      returnObj['95']++;
    } else if (userAnswer.points >= 0 && userAnswer.points <= 4) {
      returnObj['40']++;
    } else if (userAnswer.points <= -1 && userAnswer.points >= -6) {
      returnObj['-1-6']++;
    } else if (userAnswer.points <= -7 && userAnswer.points >= -13) {
      returnObj['-7-13']++;
    } else if (userAnswer.points <= -14 && userAnswer.points >= -20) {
      returnObj['-14-20']++;
    } else if (userAnswer.points <= -21 && userAnswer.points >= -34) {
      returnObj['-21-35']++;
    }
  });

  returnArr[0] = returnObj['-21-35'];
  returnArr[1] = returnObj['-14-20'];
  returnArr[2] = returnObj['-7-13'];
  returnArr[3] = returnObj['-1-6'];
  returnArr[4] = returnObj['40'];
  returnArr[5] = returnObj['95'];
  returnArr[6] = returnObj['1410'];
  returnArr[7] = returnObj['1915'];
  returnArr[8] = returnObj['2519'];
  returnArr[9] = returnObj['3525'];
  return returnArr;
};

const WalkOfPrivilegeBarGraph = (props) => {
  const classes = useStyles();
  const [formattedArray, setformattedArray] = useState([]);
  const {
    sessionId,
    moduleId,
    points,
    // isHosted,
    handleNext,
  } = props;
  useEffect(() => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/wop-results-for-event?sessionId=${sessionId}&moduleId=${moduleId}`,
    }).then((res) => {
      setformattedArray(formatData(res.data, props.questionsLength));
    }).catch((err) => {
      console.log(err);
    });
  }, []);
  return (
    <div className={classes.outerDiv}>
      <GridContainer justifyContent="center" style={{ overflow: 'auto' }}>
        <GridItem xs={12} sm={12} md={12}>
          <h3>
            What Is Privilege?
          </h3>
          <h5>
            A special right or competitive advantage available only to a particular group of people.
          </h5>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <p>
            Privilege Distribution
          </p>
          <Bar
            data={CurrentData(formattedArray, points)}
            options={options(points)}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'baseline',
            }}
            >
              <div>
                <p style={{ textAlign: 'left', fontSize: '18px' }}>
                  The orange bar represents your privilege position relative
                  to all other participants.
                </p>
                <br />
                <p style={{ textAlign: 'left', fontSize: '18px' }}>
                  The people that hold the least privilege are on the left and people that hold
                  the most are on the right.
                </p>
                <br />
                <p style={{ textAlign: 'left', fontSize: '18px' }}>
                  And while everyone has some privilege in different contexts
                  (and some more often than others,)having awareness of one’s
                  privilege or marginalizations is critical for developing
                  empathy and being an ally.
                </p>
              </div>
            </CardBody>

          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Button
            style={{ marginBottom: '50px' }}
            color="primary"
            onClick={() => {
              handleNext();
            }}
          >
            Next
          </Button>
        </GridItem>

      </GridContainer>
    </div>
  );
};

WalkOfPrivilegeBarGraph.propTypes = {
  moduleId: PropTypes.number.isRequired,
  sessionId: PropTypes.number.isRequired,
  questionsLength: PropTypes.number.isRequired,
  // isHosted: PropTypes.bool.isRequired,
  handleNext: PropTypes.func.isRequired,
  points: PropTypes.number.isRequired,
};

export default WalkOfPrivilegeBarGraph;
