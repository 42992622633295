import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// import DialogContentText from '@material-ui/core/DialogContentText';
import {
  List, DialogTitle, DialogContent, DialogActions, Dialog,
} from '@material-ui/core';
import Button from '../../../../components/CustomButtons/Button';
// import { AuthContext } from '../../Auth';
// import { loadAuthToken } from '../../utils/auth';

function WPStatementsModal(props) {
  const { toggleModal, statements, open } = props;
  const statementsInOrder = _.orderBy(statements.wop_question_joins, ['order'], ['asc']);


  return (
    <div>
      <Dialog
        open={open}
        onClose={() => toggleModal()}
        maxWidth="md"
        togg
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: '100%',
            minHeight: '60%',
          },
        }}
      >
        <DialogTitle id="form-dialog-title">
          {'Title: '}
          {statements.title}
          <br />
          {'Description: '}
          {statements.description}
          <br />
          Length:
          { ' ' }
          { statements.wop_question_joins ? statements.wop_question_joins.length : ' ' }
        </DialogTitle>
        <DialogContent>
          <List>
            {statements.wop_question_joins ? statementsInOrder.map((s) => (
              <ul key={s.id}>
                <li>
                  {s.walk_of_privilege_question.question}
                  {' '}
                  --- Swipe
                  {' '}
                  {s.walk_of_privilege_question.direction}

                </li>
              </ul>
            )) : ' '}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggleModal()} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

WPStatementsModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  statements: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default WPStatementsModal;
