/* eslint-disable no-return-assign */
/* eslint-disable no-sequences */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

function getScore(i) {
  if (i === 0) {
    return '35 - 20';
  } else if (i === 1) {
    return '19 - 4';
  } else if (i === 2) {
    return '3 - (-35)';
  }
}

export default function BasicTable({ options }) {
  const [displayArr, setDisplayArr] = useState([]);
  useEffect(() => {
    setDisplayArr(
      Object.entries(Object.keys(options[0])
        .reduce((acc, curr) => (acc[curr] = [options[0][curr], options[1][curr], options[2][curr]], acc), {})),
    );
  }, [options]);
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            {options.map((rowHead, i) => (
              <TableCell align="left">{getScore(i)}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {displayArr.map((optionObj, i) => (
            <TableRow key={i + 5000}>
              {optionObj[1].map((opCount) => (
                <TableCell>
                  {optionObj[0]}
                  {' ('}
                  {opCount}
                  {')'}

                </TableCell>
              ))}
            </TableRow>

          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

BasicTable.propTypes = {
  options: PropTypes.object.isRequired,
};
