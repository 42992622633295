import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
// core components
import Wizard from '../../../components/Wizard/Wizard';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';

import Step1 from './Step1';
import Step2 from './Step2';
// import Step3 from './Step3';
import { loadAuthToken } from '../../../utils/auth';
import { AuthContext } from '../../../Auth';

export default function UserWizardView(props) {
  const { setMessageState, setSnackBarMessage } = useContext(AuthContext);
  const { history } = props;
  const createUser = async (state) => {
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_AUTH_SERVICE}/users`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
      data: {
        firstName: state.about.firstname,
        lastName: state.about.lastname,
        email: state.about.email,
        phoneNumber: state.about.phone,
        training_groups_users: state.group.groupsToAdd,
      },
    }).then(() => {
      setMessageState('success');
      setSnackBarMessage(`Successfully created ${state.about.firstname}.`);
      history.push('/admin/users');
    }).catch((err) => {
      setMessageState('error');
      console.log(err, 'error!!!!!!!');
      setSnackBarMessage(`${err.response.data.message}`);
      if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
        history.push('auth/login');
      }
    });
  };

  return (
    <GridContainer justifyContent="center">
      <GridItem xs={12} sm={8}>
        <Wizard
          validate
          history={history}
          steps={[
            { stepName: 'About', stepComponent: Step1, stepId: 'about' },
            { stepName: 'Group', stepComponent: Step2, stepId: 'group' },
          ]}
          title="New User"
          subtitle=""
          finishButtonClick={(state) => createUser(state)}
          color="primary"
        />
      </GridItem>
    </GridContainer>
  );
}

UserWizardView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};
