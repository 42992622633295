import React from 'react';
import { TagCloud } from 'react-tagcloud';
import PropTypes from 'prop-types';

const SimpleCloud = (props) => {
  const { data } = props;
  return (
    <TagCloud
      minSize={12}
      maxSize={35}
      tags={data}
      onClick={(tag) => alert(`'${tag.value}' was selected!`)}
    />
  );
};

export default SimpleCloud;
