import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

export default function RadioButtonsGroup(props) {
  const {setWordCloudView, value} = props;
  const handleChange = (event) => {
    setWordCloudView(event.target.value);
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Report Type</FormLabel>
      <RadioGroup row aria-label="wordcloudreport" name="wordcloudreport" value={value} onChange={handleChange}>
        <FormControlLabel value="answerList" control={<Radio />} label="Answers" />
        <FormControlLabel value="frequencyList" control={<Radio />} label="Frequency" />
      </RadioGroup>
    </FormControl>
  );
}