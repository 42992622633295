import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
// import PieChart from './PollDoughnutChart';
import BarGraph from './PollBarGraph';

const SurveyGraph = (props) => {
  const { results } = props;
  return (
    <div>
      {results.map((questionObj) => (
        <Accordion key={questionObj.question.id + 2000}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <p>{questionObj.question}</p>
          </AccordionSummary>
          <AccordionDetails>
            {/* <PieChart question={questionObj.question} answers={questionObj.answers} /> */}
            <BarGraph question={questionObj.question} answers={questionObj.answers} />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

SurveyGraph.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  results: PropTypes.array.isRequired,
};

export default SurveyGraph;
