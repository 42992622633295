/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import Joyride from 'react-joyride';
// import InfoIcon from '@material-ui/icons/Info';

const WalkOfPrivilegeTutorial = (props) => (
  <>
    <Joyride
      disableOverlay
      continuous
      styles={{
        options: {
          padding: '60px',
          primaryColor: '#60A681',
          textColor: '#004a14',
        },
        buttonClose: {
          display: 'none',
        },

        // this needs work for mobile offset fix
        // spotlight: {
        //   marginTop: '30px',
        //   height: '300%',
        // },
      }}
      locale={{
        back: 'Back', close: 'Close', last: 'Start!', next: 'Next', skip: 'Skip',
      }}
      callback={(step) => {
        props.setTutorial(step.step.target);
        if (step.action === 'reset') {
          window.localStorage.setItem('wop_completed', 'true');
          props.setTutorial('');
        }
      }}
      steps={[
        {
          target: 'body',
          placement: 'center',

          content: (
            <>
              <p style={{ fontSize: '24px' }}>
                Let’s get orientated to the platform!
              </p>
            </>
          ),
          disableBeacon: true,
          floaterProps: {
            disableAnimation: true,
          },
        },
        {
          target: '#card',
          content: (
            <>
              <p style={{ fontSize: '24px' }}>
                To answer questions swipe this whole image up or down.
              </p>
            </>
          ),
          disableBeacon: true,
          floaterProps: {
            disableAnimation: true,
          },
        },
        {
          target: '#buttons',
          isFixed: true,
          content: (
            <>
              <p style={{ fontSize: '24px' }}>
                If it is easier, click these arrow buttons up or down.
              </p>
            </>
          ),
          disableBeacon: true,
          floaterProps: {
            disableAnimation: true,
          },
        },
        {
          target: 'body',
          placement: 'center',

          content: (
            <>
              <p style={{ fontSize: '24px' }}>
                IMPORTANT: if the question does not apply or you want to say ‘no’,
                {' '}
                <u><i>then swipe the opposite direction.</i></u>
              </p>
            </>
          ),
          disableBeacon: true,
          floaterProps: {
            disableAnimation: true,
          },
        },
        // {
        //   target: 'body',
        //   placement: 'center',

        //   content: (
        //     <>
        //       <p style={{ fontSize: '24px' }}>
        //         IMPORTANT: if you want to see a translation, click the
        //         {' '}
        //         <InfoIcon />
        //         {' '}
        //         icon.

        //       </p>
        //     </>
        //   ),
        //   disableBeacon: true,
        //   floaterProps: {
        //     disableAnimation: true,
        //   },
        // },

        {
          target: '#score',
          isFixed: true,
          content: (
            <>
              <p style={{ fontSize: '24px' }}>
                Track your Privilege Position here as you progress.
              </p>
            </>
          ),
          disableBeacon: true,
          floaterProps: {
            disableAnimation: true,
          },
        },
        {
          target: 'body',
          placement: 'center',
          content: (
            <>
              <p style={{ fontSize: '24px' }}>
                There isn’t a “skip” or “go back” option so answer each question carefully!
              </p>
            </>
          ),
          disableBeacon: true,
          floaterProps: {
            disableAnimation: true,
          },
        },
        {
          target: 'body',
          placement: 'center',
          content: (
            <>
              <p style={{ fontSize: '24px' }}>
                If you swipe the wrong way don’t worry,
                just mentally adjust your number up or down as needed.
              </p>
            </>
          ),
          disableBeacon: true,
          floaterProps: {
            disableAnimation: true,
          },
        },
        {
          target: 'body',
          placement: 'center',
          content: (
            <>
              <p style={{ fontSize: '24px' }}>
                Ready to get started?
              </p>
            </>
          ),
          disableBeacon: true,
          floaterProps: {
            disableAnimation: true,
          },
        },
      ]}
    />
  </>
);

WalkOfPrivilegeTutorial.propTypes = {
  setTutorial: PropTypes.func.isRequired,
};

export default WalkOfPrivilegeTutorial;
