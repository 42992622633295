/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AuthContext } from './Auth';

const PrivateRoute = ({ component: Component, ...otherProps }) => {
  const { isAuthenticated, isLoading } = useContext(AuthContext);

  return (
    <Route
      {...otherProps}
      render={(props) => (
        !isLoading
          ? (
            isAuthenticated
              ? <Component {...props} />
              : <Redirect to="/auth/login" />
          )
          : <div />
      )}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default PrivateRoute;
