import React from 'react';
import PropTypes from 'prop-types';
import { PulseLoader } from 'react-spinners';
import { makeStyles } from '@material-ui/core/styles';
import Feet from '../../../../assets/img/feetcolors.png';
import Button from '../../../../components/CustomButtons/Button';

const useStyles = makeStyles({
  outerDiv: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    height: '100%',
  },
  amazingWork: {
    fontSize: '22px',
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  largeText: {
    paddingTop: '1rem',
    fontSize: '22px',
  },
  largeTextScore: {
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    fontSize: '30px',
  },
  image: {
    width: '115px',
    marginBottom: '10px',
  },
});
const WopFinalScore = (props) => {
  const classes = useStyles();
  const {
    points, isHosted, handleNext,
  } = props;
  return (
    <div className={classes.outerDiv}>
      <div>
        <img src={Feet} className={classes.image} alt="walk of privilege" />
      </div>
      <p className={classes.largeText}>
        Thank you for participating in this exercise!
      </p>
      <p className={classes.largeText}>
        Your final Privilege Position
        for this set of questions was:
      </p>
      <p className={classes.largeTextScore}>
        <strong>
          {(points) > 0
            ? '+'
            : null}
          {' '}
          {points}
        </strong>
      </p>
      {isHosted ? (
        <div>
          <p className={classes.largeText}>
            Please wait for instructions from your Host.
          </p>
          <PulseLoader
            sizeUnit="px"
            size={15}
            color="#60A681"
            loading
          />
        </div>
      )
        : (
          <Button
            color="primary"
            onClick={() => {
              handleNext();
            }}
          >
            Next
          </Button>
        )}
    </div>
  );
};

WopFinalScore.propTypes = {
  points: PropTypes.number.isRequired,
  handleNext: PropTypes.func.isRequired,
  isHosted: PropTypes.bool.isRequired,
};

export default WopFinalScore;
