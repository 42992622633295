import React, { useState } from 'react';
// import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../../../components/CustomButtons/Button';
import Bar from './Bar';
import Doughnut from './Doughnut';

const useStyles = makeStyles({
  outerDiv: {
    display: 'flex',
    marginTop: '110px',
    maxWidth: '90vw',
  },
  labelOuterDiv: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  individualLabel: {
    display: 'flex',
    alignItems: 'center',
    width: '450px',
    textAlign: 'left',
  },
  colorKey: {
    minWidth: '25px',
    height: '20px',
  },
  text: {
    fontSize: '20px',
  },
  buttonDiv: {
    display: 'flex',
    justifyContent: 'center',
    // alignItems: "flex-end",
    marginBottom: '90px',
    marginTop: '20px',
    // position: "absolute",
  },
  button: {
    backgroundColor: '#6CCA98',
    text: 'white',
  },
  questionTItle: {
    marginLeft: '-30vw',
    position: 'absolute',
    height: '65vh',
  },
});

const PollResults = (props) => {
  const {
    sessionData,
    setViewingResults,
  } = props;
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const { data } = sessionData;

  const classes = useStyles();

  const myLabels = [];
  const myColors = [
    '#0EB79D',
    '#AE3C82',
    '#AACE8C',
    '#14658B',
    '#DA9E89',
    '#4AB0AD',
    '#FFAD18',
    '#E4C2B2',
    '#42A231',
    '#C4580B',
  ];

  if (data.count) {
    // sessionData.data.results[currentQuestion].answers.map((x) =>
    //   myData.push(Object.values(x)[0])
    // );
    data.results[currentQuestion].answers.map((x) => myLabels.push(Object.keys(x)[0]));
  }

  const handleClick = () => {
    if (data.results.length > currentQuestion + 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setViewingResults(false);
      setCurrentQuestion(0);
    }
  };

  // const options = {
  //   responsive: true,
  //   parsing: {
  //     xAxisKey: 'option',
  //     yAxisKey: 'count',
  //   },
  //   tooltips: {
  //     mode: 'label',
  //   },
  //   plugins: {
  //     labels: false,
  //   },
  //   legend: {
  //     display: false,
  //     labels: {
  //       fontSize: 25,
  //       fontFamily: '"Filson Pro Regular","Roboto", "Helvetica", "Arial", sans-serif',
  //     },
  //   },
  //   scales: {
  //     yAxes: [
  //       {
  //         ticks: {
  //           suggestedMin: 0,
  //           // suggestedMax: 10,
  //           stepSize: 1,
  //           fontSize: 25,
  //         },
  //       },
  //     ],
  //     xAxes: [
  //       {
  //         ticks: {
  //           fontSize: 25,
  //         },
  //       },
  //     ],
  //   },
  // };
  return (
    <>
      {data ? (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            <div className={classes.outerDiv}>
              <div className={classes.labelOuterDiv}>
                {myLabels.map((label, index) => (
                  <div className={classes.individualLabel} key={index}>
                    <div
                      className={classes.colorKey}
                      style={{ backgroundColor: myColors[index] }}
                    />

                    <p className={classes.text}>{label}</p>
                  </div>
                ))}
              </div>
              {data.results.map((x, index) => {
                if (x.chartType === 'bar') {
                  return (
                    <div
                      key={x.id}
                      style={{
                        display: currentQuestion === index ? 'flex' : 'none',
                        alignItems: 'center',
                        width: '40vw',
                        // height: '40vh',
                      }}
                    >
                      <h3
                        className={classes.questionTItle}
                      >
                        {x.question}
                      </h3>
                      <Bar answers={x.answers} />
                    </div>
                  );
                }
                if (x.chartType === 'doughnut') {
                  return (
                    <div
                      key={x.id}
                      style={{
                        display: currentQuestion === index ? 'flex' : 'none',
                        alignItems: 'center',
                        width: '40vw',
                        height: '40vh',
                      }}
                    >
                      {/* <h3
                        style={{
                          marginLeft: '-35vw',
                          // margingBottom: '55vh',
                          position: 'absolute',
                          height: '65vh',
                        }}
                      >
                        {x.question}
                      </h3> */}
                      <Doughnut answers={x.answers} />
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <div className={classes.buttonDiv}>
            <Button color="inheret" className={classes.button} onClick={() => handleClick()}>
              Next
            </Button>
          </div>
        </div>
      ) : (
        <div />
      )}
    </>
  );
};

PollResults.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  sessionData: PropTypes.object.isRequired,
  setViewingResults: PropTypes.func.isRequired,

};
export default PollResults;
