/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// import TextField from '@material-ui/core/TextField';
import _ from 'lodash';

export default function CheckboxLabels(props) {
  const { question, setUserAnswer, userAnswers, setCurrentQNotAnswered } = props;

  const onChange = (e) => {
    const isChecked = e.target.checked;
    setCurrentQNotAnswered(isChecked);

    const questionData = { questionId: question.id, answer: [e.target.value] };
    const isIdFound = (answers) => answers.questionId === question.id;
    const found = userAnswers.find(isIdFound);
    if (isChecked) {
      if (found) {
        found.answer.push(e.target.value);
        const index = userAnswers.indexOf(found);
        const removeOption = userAnswers.splice(index, 1);
        const newArray = _.remove(userAnswers, (n) => n !== removeOption);
        setUserAnswer([...newArray, found]);
      } else if (!found) {
        setUserAnswer([...userAnswers, questionData]);
      }
    } else if (!isChecked) {
      const index = found.answer.indexOf(e.target.value);
      const removeOption = found.answer.splice(index, 1);
      const newArray = _.remove(found.answer, (n) => n !== removeOption);
      found.answer = newArray;
      if (found.answer.length === 0) {
        const index = userAnswers.indexOf(found);
        const removeOption = userAnswers.splice(index, 1);
        const updatedArray = _.remove(userAnswers, (n) => n !== removeOption);
        setUserAnswer(updatedArray);
      } else {
        setUserAnswer([...userAnswers]);
      }
    }
  };

  return (
    <FormGroup>
      {/* <h3>{question.question}</h3> */}
      {question.options.options.map((option) => (
        <FormControlLabel
          key={option.option}
          control={(
            <Checkbox onChange={(e) => {
              onChange(e);
            }}
            />
        )}
          label={option.option}
          value={option.option}
        />
      ))}

    </FormGroup>
  );
}

CheckboxLabels.propTypes = {
  question: PropTypes.object.isRequired,
  setUserAnswer: PropTypes.func.isRequired,
  setCurrentQNotAnswered: PropTypes.bool.isRequired,
  userAnswers: PropTypes.array.isRequired,

};
