import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { CSVLink } from 'react-csv';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Card from '../../../components/Card/Card';
import CardFooter from '../../../components/Card/CardFooter';
import CardBody from '../../../components/Card/CardBody';
import CardHeader from '../../../components/Card/CardHeader';
import styles from '../../../assets/jss/material-dashboard-pro-react/views/reportPageStyle';
import CompanyTable from './support/CompanyMaterialTable';

const useStyles = makeStyles(styles);

function CompanyAudienceUserTable(props) {
  const classes = useStyles();
  const [toggleCard, setToggleCard] = useState(false);

  const {
    csvHeaders, data, headers,
  } = props;

  return (
    <>
      <Card>
        <CardHeader className={classes.headerAlign}>
          <div className={classes.cardCategory}>
            Company User Report
          </div>
          <div>
            {toggleCard
              ? (
                <KeyboardArrowUpIcon
                  onClick={() => setToggleCard(!toggleCard)}
                  className={classes.clickable}
                />
              )
              : (
                <KeyboardArrowDownIcon
                  onClick={() => setToggleCard(!toggleCard)}
                  className={classes.clickable}
                />
              )}
          </div>
        </CardHeader>
        <Collapse in={toggleCard} unmountOnExit>

          <CardBody className={classes.reportCardBody}>
            <CompanyTable
              data={data}
              headers={headers}
            />

          </CardBody>
        </Collapse>

        <CardFooter stats>
          <div className={classes.stats}>
            <p className={classes.cardTitle}>
              Completed:
              {' '}
              {data.length}
            </p>
          </div>
          <CSVLink data={data} headers={csvHeaders}>Download Full User Report</CSVLink>

        </CardFooter>
      </Card>

    </>
  );
}

CompanyAudienceUserTable.propTypes = {
  csvHeaders: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  headers: PropTypes.arrayOf(PropTypes.object),
};

export default CompanyAudienceUserTable;
