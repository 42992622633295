/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-template */
/* eslint-disable react/no-unescaped-entities */

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import SpectrumCompletedQuestion from './SpectrumCompletedQuestion';

const styles = () => ({
  label: {
    display: 'flex',
    alignItems: 'center',
  },

  input: {
    marginRight: '10px',
  },
  h3: {
    marginBottom: '0px',
    marginTop: '5px',
  },

  leftSpan: {
    position: 'absolute',
    marginLeft: '9px',
    left: '0px',
    fontSize: '13px',
    fontWeight: '600',
    color: '#fff',
    lineHeight: '17px',
  },

  rightSpan: {
    marginRight: '9px',
    position: 'absolute',
    right: '0px',
    color: '#fff',
    fontSize: '13px',
    fontWeight: '600',
    lineHeight: '17px',
    textAlign: 'right',
  },

  centerSpan: {
    position: 'absolute',
    left: '0px',
    right: '0px',
    color: '#fff',
    fontSize: '13px',
    fontWeight: '600px',
    lineHeight: '17px',
    textAlign: 'center',
  },

  half_width: {
    width: '50%',
    margin: 'auto',
    position: 'relative',
    paddingTop: '20px',
  },

  hide: {
    display: 'none',
  },

  myCanvas: {
    position: 'absolute',
    left: '0.1px',
    height: '100%',
    width: '90%',
    zIndex: '0',
  },
});

class SpectrumCompleted extends Component {
  constructor() {
    super();
    this.canvas = React.createRef();
    // this.handleChange = this.handleChange.bind(this);
    // this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.drawCanvas();
  }

  getHeight() {
    console.log(window.innerWidth, 'width', window.innerHeight, 'Height');
    return window.innerHeight;
  }

  drawCanvas() {
    const { answers } = this.props;
    answers.forEach(() => {
      const c = this.canvas.current;
      const ctx = c.getContext('2d');
      const color = '#' + (Math.random().toString(16) + '0000000').slice(2, 8);
      for (let j = 1; j <= answers.length; j++) {
        console.log('logging', answers[j - 1].answer);
        const first = j === 1 ? 45 : 45 + 90 * (j - 1);
        const second = 45 + 90 * j;
        ctx.beginPath();
        ctx.lineWidth = '3';
        ctx.strokeStyle = color;
        ctx.moveTo(980 * (answers[j - 1].answer / 101), first);
        if (j !== answers.length) {
          console.log(980 * (answers[j - 1].answer / 101), 'start', 980 * (answers[j - 1].answer / 101), 'end');
          ctx.lineTo(980 * (answers[j].answer / 101), second);
        }
        ctx.stroke();
      }
    });
  }

  render() {
    const { classes, questions, answers } = this.props;
    return (
      <div>
        <canvas
          className={classes.myCanvas}
          ref={this.canvas}
          width={window.innerWidth}
          height={window.innerHeight}
        />
        {questions.map((questionObj, index) => (
          <SpectrumCompletedQuestion
            key={questionObj.id}
            answer={answers[index]}
            question={questionObj.question}
            isOdd={index % 2 === 1}
            description={questionObj.description}
            options={questionObj.options}
          />
        ))}
      </div>
    );
  }
}

SpectrumCompleted.propTypes = {
  questions: PropTypes.array.isRequired,
  answers: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SpectrumCompleted);
