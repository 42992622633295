/* eslint-disable react/forbid-prop-types */
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { loadAuthToken } from '../../utils/auth';
import { AuthContext } from '../../Auth';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function SimpleSelect(props) {
  const classes = useStyles();
  const [companies, setCompanies] = useState([]);
  // const [selectedCompany, setSelectedCompany] = useState({});

  const context = useContext(AuthContext);
  const history = useHistory();

  const { setMessageState, setSnackBarMessage } = context;
  const { selectedCompany, setSelectedCompany } = props;

  useEffect(() => {
    const fetchData = () => {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_AUTH_SERVICE}/companies`,
        headers: {
          Authorization: `bearer ${loadAuthToken()}`,
        },
      }).catch((err) => {
        try {
          setMessageState('error');
          setSnackBarMessage(`${err.response.data.message}`);
        } catch (e) {
          setMessageState('error');
          setSnackBarMessage(e.message);
        }
        if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
          history.push('auth/login');
        }
        return { data: [] };
      }).then((res) => {
        setCompanies(res.data.data);
      });
    };
    fetchData();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const companySelect = (e) => {
    setSelectedCompany(e.target.value);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="company-select-label">Select Company</InputLabel>
        <Select
          style={{ width: 300 }}
          labelId="company-select"
          id="company-select"
          value={selectedCompany}
          onChange={(e) => companySelect(e)}
        >
          <MenuItem value="" disabled>
            Select Company
          </MenuItem>
          {companies.map((company) => (
            <MenuItem value={company} key={company.id}>{company.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

SimpleSelect.propTypes = {
  setSelectedCompany: PropTypes.func,
  selectedCompany: PropTypes.string,
};
