import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles({
  stats: {
    top: '10px',
    right: '10px',
    position: 'absolute',
  },
  li: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

function Stats(props) {
  const { liveQuestions, audienceUsers, channel } = props;
  const [audienceUserCount, setAudienceUserCount] = useState(0);
  const [liveQuestionCount, setLiveQuestionCount] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    if (channel) {
      channel.bind('audience-user-created', (au) => {
        setAudienceUserCount(au);
      });
      channel.bind('live-chat', (lq) => setLiveQuestionCount(lq));
    }
  }, [channel]);

  useEffect(() => {
    setAudienceUserCount(audienceUsers);
    setLiveQuestionCount(liveQuestions);
  }, [audienceUsers, liveQuestions]);

  return (
    <div className={classes.stats}>
      <List>
        <ListItem classes={{ root: classes.li }}>
          {audienceUserCount === 1 ? <ListItemText primary={`${audienceUserCount} Participant`} /> : <ListItemText primary={`${audienceUserCount} Participants`} />}
        </ListItem>
        <ListItem classes={{ root: classes.li }}>
          {liveQuestionCount === 1 ? <ListItemText primary={`${liveQuestionCount} Question`} /> : <ListItemText primary={`${liveQuestionCount} Questions`} />}
        </ListItem>
      </List>
    </div>
  );
}

Stats.propTypes = {
  audienceUsers: PropTypes.number.isRequired,
  liveQuestions: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  channel: PropTypes.object,
};

export default Stats;
