import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  DataGrid,
} from '@material-ui/data-grid';
import {
  Select, FormControl, InputLabel, MenuItem,
} from '@material-ui/core';
import Dialog from '../../../../components/Alert/alert';

export default function WordCloudWordGrid(props) {
  const [newHeaders, setNewHeaders] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [tag, setTag] = useState({});
  const { data, wordTags, tagWord } = props;
  const headers = [
    {
      headerName: 'Word', field: 'answer', width: 350, disableClickEventBubbling: true,
    },
  ];

  useEffect(() => {
    const headersWithAddedButton = [
      {
        headerName: 'Tag Word',
        // eslint-disable-next-line no-useless-concat
        field: 'id',
        width: 200,
        disableClickEventBubbling: true,
        renderCell: (cloud) => (
          <div>
            <FormControl>
              <InputLabel id="word-tag-label">Tag Word</InputLabel>
              <Select
                labelId="word-tag-label"
                id="demo-simple-select"
                onChange={(e) => {
                  setTag(e.target.value);
                  setSelectedRowId(cloud.row);
                  setIsDialogOpen(!isDialogOpen);
                }}
                fullWidth
              >
                <MenuItem disabled>Select Tag</MenuItem>
                {wordTags.map((t) => (
                  <MenuItem key={t.tag} value={t}>
                    {t.tag}
                  </MenuItem>
                ))}

              </Select>
            </FormControl>
          </div>
        ),
      },
      ...headers];
    setFilteredData(data);
    setNewHeaders(headersWithAddedButton);
  }, [data, wordTags]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ height: 600, width: '100%' }}>
      <Dialog
        open={isDialogOpen}
        toggleDialog={setIsDialogOpen}
        text={`Are you sure you want to tag  "${selectedRowId.answer}"  as "${tag.tag}" ?`}
        rowId={selectedRowId.id ? selectedRowId.id : 0}
        action={() => tagWord(selectedRowId.answer, selectedRowId.id, tag.tag, tag.id)}
      />
      <DataGrid
        rows={filteredData}
        columns={newHeaders}
        sortingOrder={['asc', 'desc']}
        sortModel={[
          {
            field: 'answer',
            sort: 'asc',
          },
        ]}
      />
    </div>
  );
}

WordCloudWordGrid.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  wordTags: PropTypes.arrayOf(PropTypes.object),
  tagWord: PropTypes.func,
};
