/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { PulseLoader } from 'react-spinners';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Button from '../../../components/CustomButtons/Button';
import Feet from '../../../assets/img/feetcolors.png';
import { AuthContext } from '../../../Auth';
import Results from './wop/WopResults';
import { loadAuthToken } from '../../../utils/auth';

const useStyles = makeStyles({
  outerDiv: {
    margin: 'auto',
    textAlign: 'center',
  },
  image: {
    width: '120px',
    marginBottom: '20px',
  },
  contentDiv: {
    paddingTop: '1.4rem',
  },
  text: {
    fontSize: '1.4rem',
  },
  largeText: {
    fontSize: '1.6rem',
  },
});

const WalkOfPrivilege = (props) => {
  const [isViewingResults, setViewingResults] = useState(false);
  const [usersAnswered, setUsersAnswered] = useState([]);
  const [questionPercents, setQuestionPercents] = useState([]);
  const [hydratedQuestionPercents, setHydratedQuestionPercents] = useState([]);
  const [completed, setCompleted] = useState(0);
  const [isResultsLoading, setIsResultsLoading] = useState(false);
  const [isAllTime, setIsAllTime] = useState(false);
  const [isCompany, setIsCompany] = useState(false);

  const classes = useStyles();
  const {
    moduleTitle, moduleId, sessionId, isDemo, channel,
  } = props;
  const context = useContext(AuthContext);
  const setQuestionsWithPercents = (questions = questionPercents) => {
    const rawQuestionsCopy = props.walkOfPrivilegeQuestions;
    const rawPercentsCopy = questions;
    rawPercentsCopy.forEach((questionObj) => {
      const index = rawQuestionsCopy.findIndex((el) => el.id === questionObj.id);
      rawQuestionsCopy[index] = { ...rawQuestionsCopy[index], percent: questionObj.percent };
    });
    setHydratedQuestionPercents(rawQuestionsCopy);
  };
  const getFinishedUsers = async (displayType = 'basic') => {
    setIsResultsLoading(true);
    await axios({
      method: 'get',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/wop-answered?sessionId=${sessionId}&moduleId=${moduleId}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).then(async (res) => {
      if (res.data === 'your token is not valid' || res.data === 'Your token is expired') {
        context.sendToLogin();
      }
      setCompleted(res.data.userAnswers.length);
      if (displayType === 'company') {
        setIsCompany(true);
        await axios({
          method: 'get',
          url: `${process.env.REACT_APP_EVENT_SERVICE}/get-company-wop-graph-by-session?sessionId=${sessionId}&moduleId=${moduleId}`,
          headers: {
            Authorization: `bearer ${loadAuthToken()}`,
          },
        }).then((_res) => {
          if (res.data === 'your token is not valid' || res.data === 'Your token is expired') {
            context.sendToLogin();
          }
          setUsersAnswered(_res.data.userAnswers);
          setQuestionPercents(_res.data.questions);
          setQuestionsWithPercents(_res.data.questions);
          setIsResultsLoading(false);
        }).catch((err) => {
          if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
            context.sendToLogin();
          }
          console.error(err);
        });
      } else if (displayType === 'all') {
        await axios({
          method: 'get',
          url: `${process.env.REACT_APP_EVENT_SERVICE}/get-wop-graph-for-everyone?moduleId=${moduleId}`,
          headers: {
            Authorization: `bearer ${loadAuthToken()}`,
          },
        }).then((_res) => {
          if (res.data === 'your token is not valid' || res.data === 'Your token is expired') {
            context.sendToLogin();
          }
          setUsersAnswered(_res.data.userAnswers);
          setQuestionPercents(_res.data.questions);
          setQuestionsWithPercents(_res.data.questions);
          setIsResultsLoading(false);
        }).catch((err) => {
          if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
            context.sendToLogin();
          }
          console.error(err);
        });
      } else {
        setUsersAnswered(res.data.userAnswers);
        setQuestionPercents(res.data.questions);
        setQuestionsWithPercents(res.data.questions);
        setIsResultsLoading(false);
      }
    }).catch((err) => {
      if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
        context.sendToLogin();
      }
      console.error(err);
    });
  };

  useEffect(() => {
    getFinishedUsers();
    channel.bind('wop-answer', () => {
      getFinishedUsers();
    });
    return () => {
      // Clean up the subscription
      channel.unbind('wop-answer');
    };
  }, [moduleId]);

  if (isViewingResults) {
    return (
      <Results
        isDemo={isDemo}
        isAllTime={isAllTime}
        isCompany={isCompany}
        usersAnswered={usersAnswered}
        percents={hydratedQuestionPercents}
        setViewingFalse={() => setViewingResults(false)}
        setIsAllTime={() => setIsAllTime(false)}
        setIsCompany={() => setIsCompany(false)}
      />
    );
  }
  return (
    <div className={classes.outerDiv}>
      <div>
        <img src={Feet} className={classes.image} alt="walk of privilege" />
      </div>
      <Divider />
      <div className={classes.contentDiv}>
        <p className={classes.largeText}><strong>{moduleTitle}</strong></p>
        <br />
        <div>
          <p className={classes.text}>
            Please read each question carefully and respond appropriately.
          </p>
          <br />
          <br />
          <p className={classes.largeText}>COMPLETIONS</p>
          <strong>
            <p className={classes.largeText}>
              {String(completed)}
            </p>
          </strong>
          <br />
        </div>
        {isResultsLoading ? (
          <PulseLoader
            sizeUnit="px"
            size={15}
            color="#60A681"
            loading
          />
        ) : null}
        <div
          width="100%"
          align="flex-end"
          style={{ right: '2vw', bottom: '1vh', position: 'absolute' }}
        >
          <Button
            // color="lightGreen"
            onClick={async () => {
              await getFinishedUsers('basic');
              setViewingResults(!isViewingResults);
            }}
          >
            Results
          </Button>
          <Button
            // color="lightGreen"
            onClick={async () => {
              await getFinishedUsers('company');
              setViewingResults(!isViewingResults);
            }}
          >
            Company Results
          </Button>
          <Button
            // color="lightGreen"
            onClick={async () => {
              setIsAllTime(true);
              setViewingResults(!isViewingResults);
            }}
          >
            Total Results
          </Button>
        </div>
      </div>
    </div>
  );
};

WalkOfPrivilege.propTypes = {
  moduleTitle: PropTypes.string.isRequired,
  moduleId: PropTypes.number.isRequired,
  sessionId: PropTypes.number.isRequired,
  isDemo: PropTypes.bool.isRequired,
  walkOfPrivilegeQuestions: PropTypes.array.isRequired,
  channel: PropTypes.object.isRequired,
};

export default WalkOfPrivilege;
