/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Button from '../../../components/CustomButtons/Button';
import { loadAuthToken } from '../../../utils/auth';
import { AuthContext } from '../../../Auth';
import Comment from '../../../assets/img/comment.png';
import PollResultsScreen from './Poll/PollResultScreen';
// import PollWelcomeScreen from './Poll/PollWelcomeSceen';

// using word cloud as a model

const useStyles = makeStyles({
  outerDiv: {
    margin: 'auto',
    textAlign: 'center',
  },
  imageDiv: {
    // paddingTop: '1rem',
    paddingBottom: '2rem',
  },
  contentDiv: {
    paddingTop: '2.5rem',
  },
  text: {
    fontSize: '1.4rem',
  },
  largeText: {
    fontSize: '1.6rem',
  },
  messageIcon: {
    color: '#6CCA98',
    height: '100px',
    width: '100px',
  },
  image: {
    width: '160px',
    marginBottom: '5px',
  },
});

const Poll = ({
  moduleTitle,
  sessionId,
  moduleId,
  channel,
  pollQuestions,
}) => {
  const [viewingResults, setViewingResults] = useState(false);
  const [resultIndex, setResultIndex] = useState(0);
  const [currentResultQuestion, setCurrentResultQuestion] = useState({});
  const [usersAnswered, setUsersAnswered] = useState(0);
  const [sessionData, setSessionData] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);

  const context = useContext(AuthContext);

  useEffect(() => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/auto-host-reports?sessionId=${sessionId}&moduleId=${moduleId}&reportType=poll`,
      // headers: {
      //   Authorization: `bearer ${loadAuthToken()}`,
      // },
    }).then((res) => {
      // console.log('poll report res', res.data.sessionData);
      setSessionData(res.data.sessionData);
      // setIsLoading(false);
    });
  }, [viewingResults]);

  const startViewingResults = () => {
    setViewingResults(true);
    setCurrentResultQuestion(pollQuestions[resultIndex]);
    setResultIndex(0);
  };

  const nextResult = () => {
    if (pollQuestions.length - 1 <= resultIndex) {
      setViewingResults(false);
      setResultIndex(0);
      setCurrentResultQuestion({});
    } else {
      setCurrentResultQuestion(pollQuestions[resultIndex + 1]);
      setResultIndex(resultIndex + 1);
    }
  };

  const getFinishedUsers = () => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/poll-answer?sessionId=${sessionId}&moduleId=${moduleId}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).then((res) => {
      if (res.data === 'your token is not valid' || res.data === 'Your token is expired') {
        context.sendToLogin();
      }
      setUsersAnswered(res.data.usersAnswered);
    }).catch((err) => {
      if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
        context.sendToLogin();
      }
    });
  };

  useEffect(() => {
    getFinishedUsers();
    const binds = [];
    if (channel) {
      channel.bind(`poll_completion_${sessionId}_${moduleId}`, (completed) => {
        setUsersAnswered(completed);
      });

      return () => {
        binds.forEach((bind) => {
          channel.unbind(bind);
        });
        channel.unbind(`poll_completion_${sessionId}_${moduleId}`);
      };
    }
  }, [moduleId, sessionId, channel]);

  const classes = useStyles();
  if (viewingResults) {
    return (
      <div className={classes.outerDiv}>
        <PollResultsScreen
          moduleTitle={moduleTitle}
          sessionId={sessionId}
          moduleId={moduleId}
          question={currentResultQuestion}
          nextResult={() => nextResult()}
          sessionData={sessionData}
          setViewingResults={setViewingResults}
        />
      </div>
    );
  }
  return (
    <div className={classes.outerDiv}>
      <div className={classes.imageDiv}>
        <img src={Comment} className={classes.image} alt="poll" />
      </div>
      <Divider />
      <div className={classes.contentDiv}>
        <p className={classes.largeText}><strong>{moduleTitle}</strong></p>
        <br />
        <p className={classes.text}>
          Thank you for your participation today!
        </p>
        <br />
        <p className={classes.text}>
          Please take a moment to take a quick poll.
        </p>
        <br />
        <p className={classes.largeText}>COMPLETIONS:</p>
        <p className={classes.largeText}>
          <strong>
            {' '}
            {usersAnswered ? String(usersAnswered) : '0'}
          </strong>
        </p>
        <Button
          width="100%"
          onClick={() => startViewingResults()}
          align="flex-end"
          style={{ right: '5vw', bottom: '7vh', position: 'absolute' }}
        >
          View Results
        </Button>
      </div>
    </div>
  );
};

Poll.propTypes = {
  moduleTitle: PropTypes.string.isRequired,
  sessionId: PropTypes.number.isRequired,
  moduleId: PropTypes.number.isRequired,
  channel: PropTypes.object.isRequired,
  pollQuestions: PropTypes.array.isRequired,
};

export default Poll;
