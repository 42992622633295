import * as React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import PropTypes from 'prop-types';

export default function DataTable(props) {
  const { data, headers } = props;

  const columns = headers.map((header) => (
    { field: header, headerName: header, width: 115 }
  ));

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={50}
        // disableSelectionOnClick
      />
    </div>
  );
}

DataTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object),
  headers: PropTypes.arrayOf(PropTypes.string),
};
