/* eslint-disable react/static-property-placement */
/* eslint-disable import/no-cycle */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import Join from './Join';
import HostedAudience from './HostedAudience';
import { AuthContext } from '../../Auth';

class Audience extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);
    this.state = {
      session: {},
      inviteData: '',
      isLoadingDone: false,
      isLoading: Boolean(window.localStorage.getItem('pin')),
    };
  }

  async componentDidMount() {
    if (!this.context.isLoading) {
      this.setState({ isLoadingDone: true }, () => this.setPin());
    //   this.setPin();
    }
  }

  componentDidUpdate() {
    if (!this.context.isLoading && !this.state.isLoadingDone) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isLoadingDone: true }, () => {
        this.setPin();
      });
    }
  }

  async setPin() {
    let pin;
    const prevPin = window.localStorage.getItem('pin');

    if (this.props.location.search.includes('?data=')) {
      await this.setState({ inviteData: this.props.location.search.slice(6) });
      pin = this.props.match.params.pin;
    }
    if (this.props.match.params.pin) {
      // remove the previous data of session info in url and stored do not match
      if (prevPin && (this.props.match.params.pin !== prevPin)) {
        this.removeSession();
      }
      // use the pin in the url to set the pin
      pin = this.props.match.params.pin;
    } else if (window.localStorage.getItem('pin')) {
      pin = window.localStorage.getItem('pin');
    }
    if (pin) {
      this.submitPin(pin, false);
    }
  }

  removeSession() {
    const localStorageKeys = Object.keys(localStorage);
    localStorageKeys.forEach((lsKey) => {
      if (lsKey.includes('wop')) {
        // could be both wop_completed, and wop_moduleId_sessionId
        localStorage.removeItem(lsKey);
      }
      if (lsKey.includes('pin')) {
        localStorage.removeItem(lsKey);
      }
      if (lsKey.includes('audience_user_id')) {
        localStorage.removeItem(lsKey);
      }
      if (lsKey.includes('comfort')) {
        localStorage.removeItem(lsKey);
      }
      if (lsKey.includes('word')) {
        // could be either word_cloud, or word_popcorn
        localStorage.removeItem(lsKey);
      }
      if (lsKey.includes('poll')) {
        localStorage.removeItem(lsKey);
      }
    });
    this.setState({ session: {}, isLoading: false });
  }

  createAudienceUser(sessionId) {
    const audienceUserId = window.localStorage.getItem('audience_user_id');
    if (!audienceUserId) {
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_EVENT_SERVICE}/audience-user`,
        data: {
          sessionId,
        },
      }).then((res) => {
        window.localStorage.setItem('audience_user_id', String(res.data.id));
      }).catch((err) => {
        console.log(err);
      });
    }
  }

  submitPin(pin) {
    const audienceUserId = window.localStorage.getItem('audience_user_id');
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/audience-user-get-session?&pin=${pin}&audienceUserId=${audienceUserId}`,
    })
      .then((res) => {
        if (res.data === 'not started') {
          return 'not started';
        }
        if (res.data === 'completed') {
          // window.localStorage.removeItem('pin');
          // window.localStorage.removeItem('audience_user_id');
          this.removeSession();
          this.setState({ isLoading: false });
          return false;
        }
        if (res.data.session[0]) {
          window.localStorage.setItem('pin', pin);
          if (res.data.audienceUser) {
            window.localStorage.setItem('audience_user_id', String(res.data.audienceUser.id));
          }
          this.setState({ session: res.data.session[0] });
          return true;
        }
        // window.localStorage.removeItem('pin');
        // window.localStorage.removeItem('audience_user_id');
        this.removeSession();
        this.setState({ isLoading: false });
        return false;
      });
  }

  render() {
    const { session, isLoading, inviteData } = this.state;
    if (session.type === 'hosted' || session.type === 'autohosted') {
      return (
        <HostedAudience
          session={session}
          inviteDate={inviteData}
          removeSession={() => this.removeSession()}
        />
      );
    }
    return (
      <Join
        isLoading={isLoading}
        submitPin={(pin) => this.submitPin(pin)}
      />
    );
  }
}

Audience.propTypes = {
};

export default Audience;
