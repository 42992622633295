import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// import _ from 'lodash';
import NavPills from '../../../../components/NavPills/NavPillsOriginal';
import styles from '../../../../assets/jss/material-dashboard-pro-react/views/reportPageStyle';

const useStyles = makeStyles(styles);

const ComfortTable = (props) => {
  const classes = useStyles();
  const { data } = props;

  const tabs = [];

  const uniqueTags = [...new Set(data.map((item) => item.tags[0].tag))];

  uniqueTags.map((tag) => {
    const resultsByTag = data.filter((result) => result.tags[0].tag === tag);
    return tabs.push({
      tabButton: tag,
      tabContent: (
        <>

          {resultsByTag.map((result) => (
            <>
              <h4 style={{ backgroundColor: 'lightgray' }}>
                {result.statement}
              </h4>
              <TableContainer component={Paper} className={classes.tableQuestion}>
                <Table key={result.statement} aria-label="simple table">
                  <TableBody>

                    <TableRow>
                      {result.answersByOptions.map((answer) => (
                        <>
                          <TableCell align="center">
                            {answer.option}
                          </TableCell>
                        </>
                      ))}
                    </TableRow>
                    <TableRow>
                      {result.answersByOptions.map((answer) => (
                        <TableCell align="center">
                          {answer.count}
                        </TableCell>
                      ))}
                    </TableRow>

                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ))}
        </>
      ),
    });
  });

  return (
    <>
      <NavPills
        tabs={tabs}
        active={0}
        horizontal={{
          tabsGrid: { xs: 12, sm: 2, md: 2 },
          contentGrid: { xs: 12, sm: 10, md: 10 },
        }}
      />
    </>
  );
};

ComfortTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string),
};

export default ComfortTable;
