/* eslint-disable array-callback-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
// import { loadUserId } from '../utils/LocalStorage'
import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider';

// import Divider from '@material-ui/core/Divider'

const styles = () => ({
  text: {
    margin: 0,
    display: 'inline',
    width: '25%',
    // '@media (max-width: 1200px)': {
    //   width: '40%',
    // },
  },
  surveySlider: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 'auto',
  },
  lastText: {
    margin: 0,
    display: 'inline',
    width: '25%',
    textAlign: 'end',
    // '@media (max-width: 1200px)': {
    //   width: '40%',
    // },
  },
});

const PrettoSlider = withStyles({
  root: {
    color: '#52af77',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid #52af77',
    marginTop: -8,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const SurveySlider = ({
  options, setAnswer,
}) => {
  const [sliderValue, setSliderValue] = useState(1);

  // console.log('slider', question, options);

  const handleChange = (event, value) => {
    setSliderValue(value);
    setAnswer(options[value - 1]);
  };

  return (

    <>
      <PrettoSlider
        min={1}
        step={1}
        max={options.length}
        defaultValue={0}
        onChange={(event, value) => handleChange(event, value)}
        value={sliderValue}
        name="comfort"
      />
      {/* <div className={classes.surveySlider}>
        {options.map((option, optionIndex) => {
          if (optionIndex === 0) {
            return (
              <p
                key={optionIndex + 1000}
                className={classes.text}
              >
                {option.type ? option.option : option}
              </p>
            );
          }
          if (optionIndex === last) {
            return (
              <p
                key={optionIndex + 1000}
                className={classes.lastText}
              >
                {option.type ? option.option : option}
              </p>
            );
          }
        })}
      </div> */}
    </>
  );
};

SurveySlider.propTypes = {
  options: PropTypes.array.isRequired,
  // classes: PropTypes.object.isRequired,
  setAnswer: PropTypes.func.isRequired,
  // question: PropTypes.object.isRequired,
};

export default withStyles(styles)(SurveySlider);
