/* eslint-disable react/forbid-prop-types */
import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { PulseLoader } from 'react-spinners';
import { makeStyles } from '@material-ui/core/styles';
import Card from '../../../../components/Card/Card';
import CardFooter from '../../../../components/Card/CardFooter';
import CardBody from '../../../../components/Card/CardBody';
import CardHeader from '../../../../components/Card/CardHeader';
import Button from '../../../../components/CustomButtons/Button';
import styles from '../../../../assets/jss/material-dashboard-pro-react/views/reportPageStyle';
import WOPQuestionsGrid from './WOPQuestionsGridNew';
import NewQuestionModal from './NewQuestionModal';
import { AuthContext } from '../../../../Auth';
import { loadAuthToken } from '../../../../utils/auth';

const useStyles = makeStyles(styles);

// function compare(a, b) {
//   const bandA = a.id;
//   const bandB = b.id;
//   let comparison = 0;
//   if (bandA > bandB) {
//     comparison = 1;
//   } else if (bandA < bandB) {
//     comparison = -1;
//   }
//   return comparison;
// }

function WOPQuestionsTable() {
  const classes = useStyles();
  const context = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isNewQuestionModalOpen, setIsNewQuestionModalOpen] = useState(false);

  const fetchData = async () => {
    setIsLoading(!isLoading);
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_MODULE_SERVICE}/walk-of-privilege-question?status=active`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).then((res) => {
      setData(res.data.data.map((walkOfPrivilegeQuestion) => ({
        id: walkOfPrivilegeQuestion.id,
        question: `SWIPE ${walkOfPrivilegeQuestion.direction.toUpperCase()} ${walkOfPrivilegeQuestion.question}`,
        direction: walkOfPrivilegeQuestion.direction,
        points: walkOfPrivilegeQuestion.points,
        image: walkOfPrivilegeQuestion.image,
      })));
      setIsLoading(false);
      return res;
    })
      .catch((e) => {
        console.log(e);
        context.setMessageState('error');
        context.setSnackBarMessage(`${e.response.data.message}`);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <NewQuestionModal
        togalModal={() => setIsNewQuestionModalOpen(!isNewQuestionModalOpen)}
        open={isNewQuestionModalOpen}
      />

      <Card>
        <CardHeader className={classes.headerAlign}>
          <div className={classes.header}>
            <h3>WALK OF PRIVILEGE QUESTIONS</h3>
            <Button onClick={() => setIsNewQuestionModalOpen(!isNewQuestionModalOpen)}>Create New Question</Button>
          </div>

        </CardHeader>
        <CardBody className={classes.reportCardBody}>
          {isLoading ? (
            <PulseLoader
              sizeUnit="px"
              size={15}
              color="#6CCA98"
              loading
            />
          ) : <WOPQuestionsGrid data={data} fetchData={fetchData} />}
        </CardBody>

        <CardFooter stats>
          <div className={classes.stats}>
            <p className={classes.cardTitle}>
              Total:
              {data.length}
            </p>
          </div>
        </CardFooter>
      </Card>
    </>
  );
}

export default WOPQuestionsTable;
