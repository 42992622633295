/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import AuthNavbar from '../components/Navbars/AuthNavbar';
import Footer from '../components/Footer/Footer';

import routes from '../routes';

import styles from '../assets/jss/material-dashboard-pro-react/layouts/authStyle';

const useStyles = makeStyles(styles);

export default function Pages(props) {

  const { ...rest } = props;
  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();
  React.useEffect(() => {
    document.body.style.overflow = 'unset';
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });
  const getRoutes = (allRoutes) => allRoutes.map((prop, key) => {
    if (prop.collapse) {
      return getRoutes(prop.views);
    }
    if (prop.layout === '/auth') {
      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    }
    return null;
  });

  const getActiveRoute = (allRoutes) => {
    const activeRoute = 'Default Brand Text';
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < allRoutes.length; i++) {
      if (allRoutes[i].collapse) {
        const collapseActiveRoute = getActiveRoute(allRoutes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (
        window.location.href.indexOf(allRoutes[i].layout + allRoutes[i].path) !== -1
      ) {
        return allRoutes[i].name;
      }
    }
    return activeRoute;
  };
  return (
    <div>
      <AuthNavbar
        brandText={getActiveRoute(routes)}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
      <div className={classes.wrapper} ref={wrapper}>
        <div
          className={classes.fullPage}
        >
          <Switch>
            {getRoutes(routes)}
            <Redirect from="/auth" to="/auth/login" />
          </Switch>
          <Footer primary />
        </div>
      </div>
    </div>
  );
}
