/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState, useEffect, useContext,
} from 'react';
import axios from 'axios';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
// import PropTypes from 'prop-types';
// core components
import Moment from 'react-moment';
import moment from 'moment';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import NavPills from '../../components/NavPills/NavPills';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';
import CardFooter from '../../components/Card/CardFooter';
import Button from '../../components/CustomButtons/Button';
import { AuthContext } from '../../Auth';
import { cardTitle } from '../../assets/jss/material-dashboard-pro-react';
import timelineStyle from '../../assets/jss/material-dashboard-pro-react/components/timelineStyle';
import ArchiveModal from './ArchiveModal';

const styles = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: '#000',
    textDecoration: 'none',
    textAlign: 'center',
  },
  cardCategory: {
    margin: '0',
    color: '#999999',
  },
  cardHight: {
    height: '75vh',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  message: {
    // backgroundColor: '#3C4858 !important',
  },
  replyField: {
    width: '90%',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    fontSize: '14px',
  },
  formArea: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
};

const useStyles = makeStyles(styles);
const useTimelineStyle = makeStyles(timelineStyle);

function compare(left, right) {
  return moment.utc(left.createdAt).diff(moment.utc(right.createdAt));
}

export default function Panels() {
  const classes = useStyles();
  const timeLineClasses = useTimelineStyle();
  const [text, setText] = useState('');
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState({});
  const [isArchiveModalOpen, toggleArchiveModal] = useState(false);
  const {
    user,
    chatClient,
    sendToLogin,
    setMessageState,
    setSnackBarMessage,
  } = useContext(AuthContext);

  const sendReply = () => {
    setText('');
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_CHAT_SERVICE}/sms-send`,
      data: {
        message: text,
        author: user.email,
        chatCompanyId: selectedConversation.chatCompanyId,
        conversationId: selectedConversation.id,
        to: selectedConversation.chatUsersPhoneNumber,
      },
    }).catch((err) => {
      setMessageState('error');
      setSnackBarMessage(`${err.response.data.message}`);
      if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
        sendToLogin();
      }
    });
  };

  const getConversations = () => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_CHAT_SERVICE}/conversations?status=active`,
    }).then(async (_conversations) => {
      if (_conversations.error === 'your token is not valid' || _conversations.error === 'Your token is expired') {
        setConversations([]);
        sendToLogin();
      } else {
        setConversations(_conversations.data.data);
        setSelectedConversation(_conversations.data.data[0]);
      }
    }).catch((err) => {
      setMessageState('error');
      setSnackBarMessage(`${err.response.data.message}`);
    });
  };

  const archiveConvo = () => {
    axios({
      method: 'patch',
      url: `${process.env.REACT_APP_CHAT_SERVICE}/conversations${selectedConversation.id}`,
      data: {
        status: 'archived',
      },
    }).then(() => {
      setMessageState('success');
      setSnackBarMessage(`Successfully archived ${selectedConversation.name} conversation`);
    }).catch((err) => {
      setMessageState('error');
      setSnackBarMessage(`${err.response.data.message}`);
      console.log(err);
      if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
        sendToLogin();
      }
    });
  };

  // conversation is message, body, is message. footer is timestamp, footerTitle is the author
  useEffect(() => {
    getConversations();
    chatClient.service('messages').on('created', async (newMessage) => {
      setMessageState('success');
      setSnackBarMessage('You have a new message!');
      const foundConvo = conversations.find(
        // eslint-disable-next-line no-shadow
        (conversation) => conversation.id === newMessage.conversationId,
      );
      if (foundConvo) {
        const conversationsCopy = [...conversations];
        const convoIndex = conversationsCopy.findIndex(
          (_conversation) => _conversation.id === newMessage.conversationId,
        );
        const tempConvo = conversationsCopy[convoIndex];
        tempConvo.messages = [...tempConvo.messages, newMessage];
        conversationsCopy[convoIndex] = tempConvo;
        if (tempConvo.id === selectedConversation.id) {
          setSelectedConversation(tempConvo);
        }
        setConversations([...conversationsCopy]);
      } else {
        getConversations();
        // get the new conversations
      }
    });
  }, []);

  const panelLeft = `
  ${timeLineClasses.timelinePanel}
  ${timeLineClasses.timelinePanelInverted} 
  ${timeLineClasses.timelineGreyInverted} 
  ${timeLineClasses.timelineSimplePanel} 
  ${classes.message}`;

  const panelRight = `
  ${timeLineClasses.timelinePanel}
  ${timeLineClasses.timelineGrey} 
  ${timeLineClasses.timelineSimplePanel} 
  ${classes.message}`;

  return (
    <div>
      {isArchiveModalOpen ? (
        <ArchiveModal
          isOpen={isArchiveModalOpen}
          close={() => toggleArchiveModal(false)}
          name={selectedConversation.name}
          archiveConvo={(id) => archiveConvo(id)}
        />
      ) : null}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card className={classes.cardHight}>
            <CardHeader className={classes.header}>
              <h4 className={classes.cardTitle}>
                Conversations
              </h4>
              <Button onClick={() => {
                if (selectedConversation) {
                  if (selectedConversation.name) toggleArchiveModal(true);
                }
              }}
              >
                Archive
              </Button>
            </CardHeader>
            <CardBody>
              <NavPills
                conversations={conversations}
                setConversation={(convo) => {
                  setSelectedConversation(convo);
                }}
                color="primary"
                horizontal={{
                  tabsGrid: { xs: 12, sm: 12, md: 2 },
                  contentGrid: { xs: 12, sm: 12, md: 10 },
                }}
                tabs={conversations.map((_convo) => ({
                  tabButton: _convo.name,
                  tabContent: _convo.messages.length !== 0 ? (
                    <div className={classes.mainArea}>
                      {_convo.messages.sort(compare).map((message) => (
                        <div key={message.id}>
                          <div className={(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(message.author)) ? panelLeft : panelRight}>
                            <div className={timeLineClasses.timelineBody}>
                              {message.message}
                            </div>
                            {message.author ? (
                              <h6 className={timeLineClasses.footerTitle}>
                                {message.author}
                              </h6>
                            ) : null}
                            {message.createdAt ? <hr className={timeLineClasses.footerLine} />
                              : null}
                            {message.createdAt ? (
                              <div className={timeLineClasses.timelineFooter}>
                                <Moment format="MM/DD/YY h:mma">{message.createdAt}</Moment>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : <div />,
                }))}
              />
            </CardBody>
            <CardFooter>
              <form
                className={classes.formArea}
                onSubmit={(e) => {
                  e.preventDefault();
                  sendReply();
                }}
              >
                <TextareaAutosize
                  rowsMax={3}
                  className={classes.replyField}
                  aria-label="maximum height"
                  placeholder="Reply"
                  value={text}
                  onChange={(e) => {
                    setText(e.currentTarget.value);
                  }}
                />
                <Button color="primary" type="submit">Submit</Button>
              </form>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

// Panels.propTypes = {

// };
