/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { isIOS } from 'react-device-detect';
import { makeStyles } from '@material-ui/core/styles';
import ComfortQuestion from './ComfortQuestion';
import GridContainer from '../../../../components/Grid/GridContainer';
import GridItem from '../../../../components/Grid/GridItem';
import ComfortCompleted from './ComfortCompleted';

const useStyles = makeStyles({
  wrapperStyles: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },

  gridContainer: {
    paddingBottom: '10px',
  },

  displayCard: {
    display: 'block',
  },

  hideCard: {
    display: 'none',
  },
});

export default function Comfort({
  sessionId,
  moduleTitle,
  questions,
  moduleId,
  isHosted,
  nextModule,
  averageComfortAnswers,
}) {
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [hasCompleted, setHasCompleted] = useState(false);
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [results, setResults] = useState([]);
  const [currentUserId, setCurrentUserId] = useState(1);
  const [tutorialCompleted, setTutorialCompleted] = useState(Boolean(window.localStorage.getItem('comfort_tutorial_completed')));

  const classes = useStyles();

  useEffect(() => {
    setCurrentUserId(Number(window.localStorage.getItem('audience_user_id')));
    console.log('line 64', Number(window.localStorage.getItem('audience_user_id')));
  }, []);

  useEffect(() => {
    const index = localStorage.getItem(`comfort_index_${sessionId}_${moduleId}`);
    const questionIndex = index || 0;
    setCurrentAnswer(questions[questionIndex].options[0]);
  }, [currentQuestion]);

  const submitAnswers = () => {
    const answers = JSON.parse(localStorage.getItem(`comfort_answers_${sessionId}_${moduleId}`));
    // const audienceUserId = Number(window.localStorage.getItem('audience_user_id'));
    const answersForSubmit = answers.map((answer) => ({
      ...answer,
      moduleId,
      sessionId,
      audienceUserId: currentUserId > 1 ? currentUserId : 1,
    }));
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/comfort-answer`,
      data: answersForSubmit,
    }).then((res) => {
      setResults(res.data);
      console.log(res);
    });
  };

  useEffect(() => {
    // const audienceUserId = Number(window.localStorage.getItem('audience_user_id'));
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/verify-comfort-completed?audienceUserId=${currentUserId}&sessionId=${sessionId}&moduleId=${moduleId}`,
    }).then((res) => {
      setHasCompleted(res.data.hasCompleted);
      if (res.data.hasCompleted) {
        setResults(res.data.results);
      } else {
        const localStorageIndex = localStorage.getItem(`comfort_index_${sessionId}_${moduleId}`);
        if (localStorageIndex) {
          if (Number(localStorageIndex) >= questions.length) {
            setHasCompleted(true);
          } else {
            setCurrentQuestion(questions[Number(localStorageIndex)]);
          }
        } else {
          localStorage.setItem(`comfort_index_${sessionId}_${moduleId}`, 0);
          setCurrentQuestion(questions[0]);
        }
      }
    })
      .catch((err) => {
        console.error(err);
      });
  }, [moduleId]);

  useEffect(() => {
    if (hasCompleted && averageComfortAnswers.length !== 0) {
      setResults(averageComfortAnswers);
    }
  });

  const startComfort = () => {
    setTutorialCompleted(Boolean(window.localStorage.getItem('comfort_tutorial_completed')));
    window.localStorage.setItem(`comfort_index_${sessionId}_${moduleId}`, '0');
    setCurrentQuestion(questions[0]);
  };

  const nextQuestion = () => {
    const localStorageIndex = localStorage.getItem(`comfort_index_${sessionId}_${moduleId}`);
    const answers = JSON.parse(localStorage.getItem(`comfort_answers_${sessionId}_${moduleId}`));
    if (Number(localStorageIndex) + 1 >= questions.length) {
      localStorage.setItem(`comfort_index_${sessionId}_${moduleId}`, Number(localStorageIndex) + 1);
      if (answers) {
        localStorage.setItem(`comfort_answers_${sessionId}_${moduleId}`, JSON.stringify([...answers, { answer: currentAnswer, statementId: currentQuestion.id }]));
      } else {
        localStorage.setItem(`comfort_answers_${sessionId}_${moduleId}`, JSON.stringify([{ answer: currentAnswer, statementId: currentQuestion.id }]));
      }
      setHasCompleted(true);
      submitAnswers();
    } else {
      localStorage.setItem(`comfort_index_${sessionId}_${moduleId}`, Number(localStorageIndex) + 1);
      if (answers) {
        localStorage.setItem(`comfort_answers_${sessionId}_${moduleId}`, JSON.stringify([...answers, { answer: currentAnswer, statementId: currentQuestion.id }]));
      } else {
        localStorage.setItem(`comfort_answers_${sessionId}_${moduleId}`, JSON.stringify([{ answer: currentAnswer, statementId: currentQuestion.id }]));
      }
      setCurrentQuestion(questions[Number(localStorageIndex) + 1]);
    }
  };
  if (hasCompleted) {
    return (
      <ComfortCompleted
        results={results}
        questions={questions}
        moduleTitle={moduleTitle}
        isHosted={isHosted}
        nextModule={nextModule}
      />
    );
  }

  const questionIndex = Number(localStorage.getItem(`comfort_index_${sessionId}_${moduleId}`));
  return (
    <GridContainer className={classes.gridContainer} justifyContent="center">
      <GridItem sm={12} md={8} lg={6}>
        {tutorialCompleted ? (
          <div className={clsx(classes.wrapperStyles, isIOS ? 'innerDivIos' : 'innerDiv')}>

            {questionIndex + 1}
            /
            {questions.length}

            {questions.map((question, i) => (

              // eslint-disable-next-line max-len
              <div key={question.id} className={questionIndex === i ? classes.displayCard : classes.hideCard}>
                <ComfortQuestion
                  setCurrentAnswer={(answer) => setCurrentAnswer(answer)}
                  question={question}
                  nextQuestion={nextQuestion}
                  currentAnswer={currentAnswer}
                  tutorialCompleted={tutorialCompleted}
                  startComfort={startComfort}
                />
              </div>
            ))}

          </div>
        )
          : (
            <ComfortQuestion
              startComfort={startComfort}
              question={questions[0]}
              tutorialCompleted={tutorialCompleted}
              nextQuestion={nextQuestion}
              currentAnswer={currentAnswer}
              setCurrentAnswer={(answer) => setCurrentAnswer(answer)}
            />
          )}

      </GridItem>

    </GridContainer>
  );
}

Comfort.propTypes = {
  sessionId: PropTypes.number,
  questions: PropTypes.array.isRequired,
  moduleTitle: PropTypes.string.isRequired,
  moduleId: PropTypes.number.isRequired,
  averageComfortAnswers: PropTypes.array.isRequired,
  nextModule: PropTypes.func,
  isHosted: PropTypes.bool.isRequired,
};
