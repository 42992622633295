import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  DataGrid,
} from '@material-ui/data-grid';
import { useHistory } from 'react-router-dom';

// import Button from '@material-ui/core/Button';
import Button from '../../../../components/CustomButtons/Button';

export default function CompaniesTable(props) {
  const [newHeaders, setNewHeaders] = useState([]);
  const history = useHistory();

  const {
    data,
  } = props;

  const headers = [
    {
      headerName: 'Name', field: 'name', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Contact Name', field: 'contactName', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Contact Email', field: 'contactEmail', width: 250, disableClickEventBubbling: true,
    },

  ];

  useEffect(() => {
    const headersWithAddedButton = [
      {
        headerName: 'Company Report',
        field: 'id',
        width: 200,
        disableClickEventBubbling: true,
        renderCell: (params) => (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                history.push(`/admin/companyReport/${params.value}`);
              }}
            >
              View Report
            </Button>
          </div>
        ),
      },
      ...headers];

    setNewHeaders(headersWithAddedButton);
  }, [data]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ height: 600, width: '100%' }}>
      <DataGrid
        rows={data}
        columns={newHeaders}
        sortingOrder={['asc', 'desc']}
        sortModel={[
          {
            field: 'name',
            sort: 'asc',
          },
        ]}
      />
    </div>
  );
}

CompaniesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};
