/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import SpectrumSlider from './SpectrumSlider';

const useStyles = makeStyles({
  evenQuestion: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  oddQuestion: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    padding: '0',
    backgroundColor: '#e1e1ea',
  },
  text: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  largeText: {
    fontSize: '2.4rem',
  },
  smallText: {
    maxWidth: '75%',
  },
  slider: {
    paddingTop: '1rem',
    width: '95%',
  },
});

const ComfortQuestion = ({
  question,
  description,
  isOdd,
  setAnswer,
  options,
}) => {
  const classes = useStyles();
  return (
    <div className={isOdd ? classes.oddQuestion : classes.evenQuestion}>
      <div className={classes.text}>
        <h3 className={classes.largeText}>{question}</h3>
        <p className={classes.smallText}>{description}</p>
      </div>
      <div className={classes.Slider}>
        {options
          ? (
            <SpectrumSlider
              setAnswer={(answer) => setAnswer(answer)}
              options={options}
            />
          )
          : ''}
      </div>
    </div>
  );
};

ComfortQuestion.propTypes = {
  question: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  setAnswer: PropTypes.func.isRequired,
  isOdd: PropTypes.bool.isRequired,
};

export default ComfortQuestion;
