import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';

const Resources = [
  {
    id: 1,
    icon: OndemandVideoIcon,
    image: 'https://s3.amazonaws.com/join.host/compass.png',
    link: 'https://s3.amazonaws.com/join.host/resource_video_one.mp4',
    title: 'Introduction to the Translator platform (video)',
    description: 'How to log in, update user settings, and update your password.',
    tags: ['Facilitator Training Resources'],
    updatedAt: '2023-03-09T19:04:05.210Z',
  },
  {
    id: 2,
    icon: OndemandVideoIcon,
    image: 'https://s3.amazonaws.com/join.host.dashboard/CC%26B+Facilitator+Guide.png',
    link: 'https://s3.amazonaws.com/join.host.dashboard/Facilitator+Guide_+Cultivating+Connection+%26+Belonging+Learning+Experience.pdf',
    title: 'Cultivating Connection & Belonging Facilitator Guide',
    description: 'Everything you need to facilitate the Cultivating Connection & Belonging learning experience.',
    tags: ['Facilitator Training Resources'],
    updatedAt: '2023-06-30T19:04:05.210Z',
  },
  // {
  //   id: 3,
  //   icon: OndemandVideoIcon,
  //   image: 'https://s3.amazonaws.com/join.host/compass.png',
  //   link: 'https://s3.amazonaws.com/join.host/resource_video_one.mp4',
  //   title: 'Introduction to the Translator platform (video)',
  //   description: 'How to log in, update user settings, and update your password.',
  //   tags: ['Facilitator Training Resources', 'Resource Area 3'],
  //   updatedAt: '2023-03-09T19:04:05.210Z',

  // },
  // {
  //   id: 4,
  //   icon: OndemandVideoIcon,
  //   image: 'https://s3.amazonaws.com/join.host/compass.png',
  //   link: 'https://s3.amazonaws.com/join.host/resource_video_one.mp4',
  //   title: 'Introduction to the Translator platform (video)',
  //   description: 'How to log in, update user settings, and update your password.',
  //   tags: ['Facilitator Training Resources', 'Resource Area 2', 'Resource Area 4'],
  //   updatedAt: '2023-03-09T19:04:05.210Z',

  // },
  // {
  //   id: 5,
  //   icon: OndemandVideoIcon,
  //   image: 'https://s3.amazonaws.com/join.host/compass.png',
  //   link: 'https://s3.amazonaws.com/join.host/resource_video_one.mp4',
  //   title: 'Introduction to the Translator platform (video)',
  //   description: 'How to log in, update user settings, and update your password.',
  //   tags: ['Facilitator Training Resources'],
  //   updatedAt: '2023-03-09T19:04:05.210Z',

  // },
  // {
  //   id: 6,
  //   icon: OndemandVideoIcon,
  //   image: 'https://s3.amazonaws.com/join.host/compass.png',
  //   link: 'https://s3.amazonaws.com/join.host/resource_video_one.mp4',
  //   title: 'Introduction to the Translator platform (video)',
  //   description: 'How to log in, update user settings, and update your password.',
  //   tags: ['Facilitator Training Resources', 'Resource Area 2'],
  //   updatedAt: '2023-03-09T19:04:05.210Z',

  // },
  // {
  //   id: 7,
  //   icon: OndemandVideoIcon,
  //   image: 'https://s3.amazonaws.com/join.host/compass.png',
  //   link: 'https://s3.amazonaws.com/join.host/resource_video_one.mp4',
  //   title: 'Introduction to the Translator platform (video)',
  //   description: 'How to log in, update user settings, and update your password.',
  //   tags: ['Facilitator Training Resources'],
  //   updatedAt: '2023-03-09T19:04:05.210Z',

  // },
  // {
  //   id: 8,
  //   icon: OndemandVideoIcon,
  //   image: 'https://s3.amazonaws.com/join.host/compass.png',
  //   link: 'https://s3.amazonaws.com/join.host/resource_video_one.mp4',
  //   title: 'Introduction to the Translator platform (video)',
  //   description: 'How to log in, update user settings, and update your password.',
  //   tags: ['Facilitator Training Resources'],
  //   updatedAt: '2023-03-09T19:04:05.210Z',

  // },
  // {
  //   id: 9,
  //   icon: OndemandVideoIcon,
  //   image: 'https://s3.amazonaws.com/join.host/compass.png',
  //   link: 'https://s3.amazonaws.com/join.host/resource_video_one.mp4',
  //   title: 'Introduction to the Translator platform (video)',
  //   description: 'How to log in, update user settings, and update your password.',
  //   tags: ['Facilitator Training Resources', 'Resource Area 2'],
  //   updatedAt: '2023-03-09T19:04:05.210Z',

  // },
];

export default Resources;
