import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Comment from '../../../assets/img/comment.png';

const useStyles = makeStyles({
  outerDiv: {
    margin: 'auto',
    textAlign: 'center',
  },
  imageDiv: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  contentDiv: {
    paddingTop: '.5rem',
  },
  text: {
    fontSize: '1.4rem',
  },
  largeText: {
    fontSize: '2rem',
  },
  messageIcon: {
    color: '#6CCA98',
    height: '100px',
    width: '100px',
  },
  image: {
    width: '160px',
    marginBottom: '5px',
  },
});

const SignUp = () => {
  const classes = useStyles();
  return (
    <div className={classes.outerDiv}>
      <div className={classes.imageDiv}>
        <img src={Comment} className={classes.image} alt="Survey" />
      </div>
      <Divider />
      <div className={classes.contentDiv}>
        <p className={classes.largeText}>Sign Up</p>
        <br />
        <p className={classes.text}>
          If your enjoyed your experience with Translator
        </p>
        <p className={classes.text}>
          Please sign up for our 100% Anonymous
        </p>
        <p className={classes.text}>
          Diversity and Inclusion chat service
        </p>
      </div>
    </div>
  );
};

export default SignUp;
