/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import InfoModule from './InfoModule';
import WalkOfPrivilege from './WalkOfPrivilege';
import Default from './Default';
import SignUp from './SignUp';
import Survey from './Survey';
import SentimentSurvey from './SentimentSurvey';
import KeyWordSurvey from './keyWordSurvey/KeyWordSurvey';
import WordCloud from './WordCloud/WordCloud';
import Comfort from './Comfort';
import Spectrum from './Spectrum';
import Poll from './Poll';
import WordPopcorn from './WordPopcorn/WordPopcorn';

const index = (props) => {
  const {
    moduleType,
    moduleTitle,
    moduleId,
    sessionId,
    history,
    walkOfPrivilegeQuestions,
    surveyQuestions,
    initialWordCloudCount,
    channel,
    wordCloudIcon,
    wordCloudQuestions,
    isDemo,
    pollQuestions,
    wordPopcornQuestion,
    wordPopcornAnswerLimit,
    wordPopcornCount,
    wordPopcornIcon,
  } = props;
  
  switch (moduleType) {
    case 'walkOfPrivilege': {
      return (
        <WalkOfPrivilege
          history={history}
          channel={channel}
          isDemo={isDemo}
          moduleId={moduleId}
          moduleTitle={moduleTitle}
          sessionId={sessionId}
          walkOfPrivilegeQuestions={walkOfPrivilegeQuestions}
        />
      );
    }
    case 'infoModule': {
      return (
        <InfoModule
          moduleTitle={moduleTitle}
        />
      );
    }
    case 'signUp': {
      return (
        <SignUp />
      );
    }
    case 'survey': {
      return (
        <Survey
          moduleId={moduleId}
          channel={channel}
          moduleTitle={moduleTitle}
          sessionId={sessionId}
          surveyQuestions={surveyQuestions}
        />
      );
    }
    case 'sentimentSurvey': {
      return (
        <SentimentSurvey
          moduleId={moduleId}
          channel={channel}
          moduleTitle={moduleTitle}
          isDemo={isDemo}
          sessionId={sessionId}
          surveyQuestions={surveyQuestions}
        />
      );
    }
    case 'keyWordSurvey': {
      return (
        <KeyWordSurvey
          moduleId={moduleId}
          channel={channel}
          moduleTitle={moduleTitle}
          isDemo={isDemo}
          sessionId={sessionId}
          surveyQuestions={surveyQuestions}
        />
      );
    }
    case 'wordCloud': {
      return (
        <>
          <WordCloud
            moduleId={moduleId}
            wordCloudQuestions={wordCloudQuestions}
            channel={channel}
            wordCloudIcon={wordCloudIcon}
            initialWordCloudCount={initialWordCloudCount}
            moduleTitle={moduleTitle}
            isDemo={isDemo}
            sessionId={sessionId}
          />
        </>
      );
    }
    case 'comfort': {
      return (
        <Comfort
          moduleId={moduleId}
          channel={channel}
          moduleTitle={moduleTitle}
          isDemo={isDemo}
          sessionId={sessionId}
        />
      );
    }
    case 'spectrum': {
      return (
        <Spectrum
          moduleId={moduleId}
          channel={channel}
          moduleTitle={moduleTitle}
          isDemo={isDemo}
          sessionId={sessionId}
        />
      );
    }
    case 'poll': {
      return (
        <Poll
          moduleId={moduleId}
          channel={channel}
          moduleTitle={moduleTitle}
          sessionId={sessionId}
          pollQuestions={pollQuestions}
        />
      );
    }
    case 'wordPopcorn': {
      return (
        <WordPopcorn
          moduleId={moduleId}
          channel={channel}
          moduleTitle={moduleTitle}
          sessionId={sessionId}
          wordPopcornQuestion={wordPopcornQuestion}
          wordPopcornAnswerLimit={wordPopcornAnswerLimit}
          wordPopcornCount={wordPopcornCount}
          wordPopcornIcon={wordPopcornIcon}
        />
      );
    }
    default: {
      return <Default />;
    }
  }
};

index.propTypes = {
  moduleType: PropTypes.string.isRequired,
  moduleTitle: PropTypes.string,
  channel: PropTypes.object,
  moduleId: PropTypes.number.isRequired,
  sessionId: PropTypes.number,
  isDemo: PropTypes.bool.isRequired,
  wordCloudIcon: PropTypes.string,
  wordCloudQuestions: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  initialWordCloudCount: PropTypes.number.isRequired,
  walkOfPrivilegeQuestions: PropTypes.array.isRequired,
  surveyQuestions: PropTypes.array.isRequired,
  wordPopcornIcon: PropTypes.string,
};

export default index;
