/* eslint-disable no-console */
/* eslint-disable react/forbid-prop-types */
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../../../components/CustomButtons/Button';
import SpectrumQuestion from './SpectrumQuestion';
import SpectrumCompleted from './SpectrumCompleted/SpectrumCompleted';

const useStyles = makeStyles({
  container: {
    width: '100%',
    paddingBottom: '10px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    justifyContent: 'center',
    alighItems: 'center',
  },
});

export default function Spectrum({
  sessionId,
  moduleTitle,
  questions,
  moduleId,
  isHosted,
  nextModule,
}) {
  const [hasSubmitted, setHasSubmitted] = useState([]);
  const [hasCompleted, setHasCompleted] = useState([]);
  const [answers, setAnswers] = useState([]);
  // const [isIncorrect, setIncorrect] = React.useState(false);
  useEffect(() => {
    const audienceUserId = Number(window.localStorage.getItem('audience_user_id'));
    // answer
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/spectrum-answer?audienceUserId=${audienceUserId}&sessionId=${sessionId}&moduleId=${moduleId}`,
    }).then((res) => {
      console.log(res);
      if (res.data?.data.length !== 0) {
        setAnswers([...res.data.data.map((answerObj) => (
          { questionId: answerObj.questionId, answer: Number(answerObj.answer) }
        ))]);
        setHasSubmitted(
          [...hasSubmitted, { module: moduleId, completed: true }],
        );
      }
    })
      .catch((err) => {
        console.error(err);
      });
  }, [moduleId]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log(answers);
  }, [answers]);

  useEffect(() => {
    const intialAnswerArray = questions.map((questionObj) => ({
      questionId: questionObj.id,
      answer: 50,
    }));
    setAnswers(intialAnswerArray);
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const submitAnswers = async () => {
    setHasSubmitted(
      [...hasSubmitted, { module: moduleId, completed: Boolean(true) }],
    );
    const audienceUserId = Number(window.localStorage.getItem('audience_user_id'));
    const answersCopy = [];
    answers.forEach((answer) => {
      answersCopy.push({
        ...answer,
        moduleId,
        sessionId,
        audienceUserId,
      });
    });
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/spectrum-answer`,
      data: answersCopy,
    });
  };

  const classes = useStyles();

  useEffect(() => {
    setHasCompleted(hasSubmitted.reverse().find(({ module }) => module === moduleId));
  }, [hasSubmitted]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {(hasCompleted ? hasCompleted.completed : false) ? (
        <SpectrumCompleted
          nextModule={nextModule}
          isHosted={isHosted}
          moduleTitle={moduleTitle}
          questions={questions}
          answers={answers}
        />
      ) : (
        <div className={classes.container}>
          <form
            autoComplete="off"
            classes={classes.form}
            onSubmit={async (e) => {
              e.preventDefault();
              submitAnswers();
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <h2>{moduleTitle}</h2>
            </div>
            {questions.map((questionObj, index) => (
              <SpectrumQuestion
                setAnswer={(answer) => {
                  const newAnswers = [...answers];
                  newAnswers[index] = {
                    questionId: questionObj.id,
                    answer,
                  };
                  setAnswers(newAnswers);
                }}
                key={questionObj.id}
                question={questionObj.question}
                isOdd={index % 2 === 1}
                description={questionObj.description}
                options={questionObj.options}
              />
            ))}
            <div style={{ marginBottom: '50px', display: 'flex', justifyContent: 'center' }}>
              <Button
                type="submit"
                color="lightGreen"
              >
                {isHosted ? 'Submit' : 'Next'}
              </Button>
            </div>

          </form>
        </div>
      )}
    </>
  );
}

Spectrum.propTypes = {
  sessionId: PropTypes.number,
  questions: PropTypes.array.isRequired,
  moduleTitle: PropTypes.string.isRequired,
  moduleId: PropTypes.number.isRequired,
  nextModule: PropTypes.func,
  isHosted: PropTypes.bool.isRequired,
};
