/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from './OptionsTable';

const useStyles = makeStyles({
  root: {
    padding: '20px',
  },
  tableDiv: {
    // maxHeight: '20vh',
    overflow: 'auto',
    padding: '0 45px',
  },
});

function Question({ questionObj }) {
  const classes = useStyles();
  const [optionsArr, setOptionsArr] = useState([]);

  useEffect(() => {
    const opArr = [];
    opArr[0] = questionObj.high;
    opArr[1] = questionObj.med;
    opArr[2] = questionObj.low;
    setOptionsArr(opArr);
  }, [questionObj]);
  return (
    <div className={classes.root}>
      <div align="center">
        <h3>
          {questionObj.question.question}
        </h3>
        <div className={classes.tableDiv}>
          {optionsArr.length ? <Table options={optionsArr} /> : null}
        </div>
      </div>
    </div>
  );
}

Question.propTypes = {
  questionObj: PropTypes.object.isRequired,
};
export default Question;
