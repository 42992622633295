/* eslint-disable react/no-array-index-key */
/* eslint-disable no-lonely-if */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Tooltip from '@material-ui/core/Tooltip';
// core components
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import customSelectStyle from '../../../assets/jss/material-dashboard-pro-react/customSelectStyle';
import customListStyle from '../../../assets/jss/material-dashboard-pro-react/customListStyle';
import customCheckboxRadioSwitch from '../../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch';
import { loadAuthToken } from '../../../utils/auth';
import { AuthContext } from '../../../Auth';

const style = {
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center',
  },
  inputAdornmentIcon: {
    color: '#555',
  },
  inputAdornment: {
    position: 'relative',
  },
  ...customListStyle,
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
};

function compare(a, b) {
  // Use toUpperCase() to ignore character casing
  const titleA = a.title.toUpperCase();
  const titleB = b.title.toUpperCase();

  let comparison = 0;
  if (titleA > titleB) {
    comparison = 1;
  } else if (titleA < titleB) {
    comparison = -1;
  }
  return comparison;
}

function compareDescription(a, b) {
  // Use toUpperCase() to ignore character casing
  const titleA = a.description.toUpperCase();
  const titleB = b.description.toUpperCase();

  let comparison = 0;
  if (titleA > titleB) {
    comparison = 1;
  } else if (titleA < titleB) {
    comparison = -1;
  }
  return comparison;
}

const SortableItem = SortableElement(({ value }) => (
  <li style={style.listItem}>
    {value.title}
    {' '}
    -
    {' '}
    {value.description ? value.description : ''}
    {' '}
  </li>
));

const SortableList = SortableContainer(({ modules, removeModule }) => (
  <ul style={{ listStyle: 'none', padding: '0' }}>
    {modules.map((value, index) => (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <SortableItem
          key={`item-${index}`}
          index={index}
          sortIndex={index}
          value={value}
        />
        <Tooltip title={`Remove ${value.title}`}>
          <IconButton onClick={() => removeModule(index)}>
            <HighlightOffIcon style={{ color: '#60A681' }} />
          </IconButton>
        </Tooltip>
      </div>
    ))}
  </ul>
));

class Step2 extends React.Component {
  // eslint-disable-next-line react/static-property-placement
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      modules: [],
      infoModules: [],
      walkOfPrivileges: [],
      surveys: [],
      wordClouds: [],
      // spectrums: [],
      comfort: [],
      autoHostSlide: [],
      polls: [],
      wordPopcorns: [],
    };
  }

  componentDidMount() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_MODULE_SERVICE}/walk-of-privilege?status=active`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).then((res) => {
      if (res.error === 'your token is not valid' || res.error === 'Your token is expired') {
        this.context.sendToLogin();
      } else {
        this.setState({ walkOfPrivileges: res.data.data.sort(compare) });
      }
    }).catch((err) => this.setError(err));
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_MODULE_SERVICE}/info-module?status=active`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).then((res) => {
      if (res.error === 'your token is not valid' || res.error === 'Your token is expired') {
        this.context.sendToLogin();
      } else {
        this.setState({ infoModules: res.data.data.sort(compare) });
      }
    }).catch((err) => this.setError(err));
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_MODULE_SERVICE}/survey?status=active`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).then((res) => {
      if (res.error === 'your token is not valid' || res.error === 'Your token is expired') {
        this.context.sendToLogin();
      } else {
        this.setState({ surveys: res.data.data.sort(compare) });
      }
    }).catch((err) => this.setError(err));

    axios({
      method: 'get',
      url: `${process.env.REACT_APP_MODULE_SERVICE}/word-cloud?status=active`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).then((res) => {
      if (res.error === 'your token is not valid' || res.error === 'Your token is expired') {
        this.context.sendToLogin();
      } else {
        this.setState({ wordClouds: res.data.data.sort(compare) });
      }
    }).catch((err) => this.setError(err));
    // axios({
    //   method: 'get',
    //   url: `${process.env.REACT_APP_MODULE_SERVICE}/spectrum?status=active`,
    //   headers: {
    //     Authorization: `bearer ${loadAuthToken()}`,
    //   },
    // }).then((res) => {
    //   if (res.error === 'your token is not valid' || res.error === 'Your token is expired') {
    //     this.context.sendToLogin();
    //   } else {
    //     this.setState({ spectrums: res.data.data.sort(compare) });
    //   }
    // }).catch((err) => this.setError(err));
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_MODULE_SERVICE}/comfort-meter?status=active`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).then((res) => {
      if (res.error === 'your token is not valid' || res.error === 'Your token is expired') {
        this.context.sendToLogin();
      } else {
        this.setState({ comfort: res.data.data });
      }
    }).catch((err) => this.setError(err));
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_MODULE_SERVICE}/slide?status=active`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).then((res) => {
      if (res.error === 'your token is not valid' || res.error === 'Your token is expired') {
        this.context.sendToLogin();
      } else {
        this.setState({ autoHostSlide: res.data.data.sort(compareDescription) });
      }
    }).catch((err) => this.setError(err));
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_MODULE_SERVICE}/poll?status=active`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).then((res) => {
      if (res.error === 'your token is not valid' || res.error === 'Your token is expired') {
        this.context.sendToLogin();
      } else {
        this.setState({ polls: res.data.data.sort(compare) });
      }
    }).catch((err) => this.setError(err));
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_MODULE_SERVICE}/word-popcorn?status=active`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).then((res) => {
      if (res.error === 'your token is not valid' || res.error === 'Your token is expired') {
        this.context.sendToLogin();
      } else {
        this.setState({ wordPopcorns: res.data.data.sort(compare) });
      }
    }).catch((err) => this.setError(err));
  }

  setError(err) {
    this.context.setMessageState('error');
    this.context.setSnackBarMessage(`${err.response.data.message}`);
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ modules }) => ({
      modules: arrayMove(modules, oldIndex, newIndex),
    }));
  };

  sendState() {
    return this.state;
  }

  // this is used by the wizard to see if all required are filled out
  isValidated() {
    const { modules } = this.state;
    if (modules.length > 0) {
      return true;
    }
    return false;
  }

  selectModule(mod, type) {
    const { modules } = this.state;
    this.setState({
      modules: [...modules, {
        id: mod.id,
        title: mod.title,
        type,
        description: mod.description,
      }],
    });
  }

  removeModule(index) {
    const modulesCopy = [...this.state.modules];
    modulesCopy.splice(index, 1);
    this.setState({ modules: modulesCopy });
  }

  render() {
    const { classes } = this.props;
    return (
      <GridContainer justifyContent="center">
        <GridItem xs={12} md={12}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel
              htmlFor="walk-of-privilege"
              className={classes.selectLabel}
            >
              Select Walk Of Privilege
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              onChange={(e) => this.selectModule(e.target.value, 'walkOfPrivilege')}
              classes={{
                select: classes.select,
              }}
              inputProps={{
                name: 'walkOfPrivilege',
                id: 'walk-of-privilege',
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                Select Walk Of Privilege(s)
              </MenuItem>
              {this.state.walkOfPrivileges.map((walkOfPrivilege) => (
                <MenuItem
                  key={walkOfPrivilege.id + 10000}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={walkOfPrivilege}
                >
                  {`${walkOfPrivilege.title}  -- ${walkOfPrivilege.description ? walkOfPrivilege.description : ''}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} md={12}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel
              htmlFor="info-module"
              className={classes.selectLabel}
            >
              Select Info Module(s)
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              onChange={(e) => this.selectModule(e.target.value, 'infoModule')}
              classes={{
                select: classes.select,
              }}
              inputProps={{
                name: 'infoModule',
                id: 'info-module',
              }}
            >
              {this.state.infoModules.map((infoModule) => (
                <MenuItem
                  key={infoModule.id + 100000}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={infoModule}
                >
                  {`${infoModule.title}  -- ${infoModule.description ? infoModule.description : ''}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} md={12}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel
              htmlFor="survey"
              className={classes.selectLabel}
            >
              Select Survey(s)
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              onChange={(e) => this.selectModule(e.target.value, 'survey')}
              classes={{
                select: classes.select,
              }}
              inputProps={{
                name: 'survey',
                id: 'survey',
              }}
            >
              {this.state.surveys.map((survey) => (
                <MenuItem
                  key={survey.id + 20000}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={survey}
                >
                  {`${survey.title}  -- ${survey.description ? survey.description : ''}`}
                </MenuItem>
              ))}
            </Select>

          </FormControl>
        </GridItem>
        <GridItem xs={12} md={12}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel
              htmlFor="poll"
              className={classes.selectLabel}
            >
              Select Poll(s)
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              onChange={(e) => this.selectModule(e.target.value, 'poll')}
              classes={{
                select: classes.select,
              }}
              inputProps={{
                name: 'poll',
                id: 'poll',
              }}
            >
              {this.state.polls.map((poll) => (
                <MenuItem
                  key={poll.id + 20000}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={poll}
                >
                  {`${poll.title}  -- ${poll.description ? poll.description : ''}`}
                </MenuItem>
              ))}
            </Select>

          </FormControl>
        </GridItem>
        <GridItem xs={12} md={12}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel
              htmlFor="info-module"
              className={classes.selectLabel}
            >
              Select Word Cloud(s)
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              onChange={(e) => this.selectModule(e.target.value, 'wordCloud')}
              classes={{
                select: classes.select,
              }}
              inputProps={{
                name: 'infoModule',
                id: 'info-module',
              }}
            >
              {this.state.wordClouds.map((wordCloud) => (
                <MenuItem
                  key={wordCloud.id + 50000}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={wordCloud}
                >
                  {`${wordCloud.title}  -- ${wordCloud.description ? wordCloud.description : ''}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} md={12}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel
              htmlFor="comfort-zone"
              className={classes.selectLabel}
            >
              Select Comfort Zone(s)
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              onChange={(e) => this.selectModule(e.target.value, 'comfort')}
              classes={{
                select: classes.select,
              }}
              inputProps={{
                name: 'comfort',
                id: 'comfort-meter',
              }}
            >
              {this.state.comfort.map((cz) => (
                <MenuItem
                  key={cz.id + 50000}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={cz}
                >
                  {`${cz.title}  -- ${cz.description ? cz.description : ''}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel
              htmlFor="word-popcorn"
              className={classes.selectLabel}
            >
              Select Word Popcorn
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              onChange={(e) => this.selectModule(e.target.value, 'wordPopcorn')}
              classes={{
                select: classes.select,
              }}
              inputProps={{
                name: 'wordPopcorn',
                id: 'word-popcorn',
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                Select Word Popcorn(s)
              </MenuItem>
              {this.state.wordPopcorns.map((pop) => (
                <MenuItem
                  key={pop.id + 10000}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={pop}
                >
                  {`${pop.title}  -- ${pop.question}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} md={12}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel
              htmlFor="auto-host"
              className={classes.selectLabel}
            >
              Select Auto-Host Slide
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              onChange={(e) => this.selectModule(e.target.value, 'autoHostSlide')}
              classes={{
                select: classes.select,
              }}
              inputProps={{
                name: 'autoHostSlide',
                id: 'auto-host-slide',
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                Select Auto-Host Slide(s)
              </MenuItem>
              {this.state.autoHostSlide.map((slide) => (
                <MenuItem
                  key={slide.id + 10000}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={slide}
                >
                  {`${slide.title}  -- ${slide.description ? slide.description : ''} -- ${slide.type}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridContainer justifyContent="center">
          <GridItem xs={10} md={6} className={classes.selected}>
            <SortableList removeModule={(index) => this.removeModule(index)} modules={this.state.modules} onSortEnd={this.onSortEnd} />
          </GridItem>
        </GridContainer>
      </GridContainer>
    );
  }
}

Step2.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(style)(Step2);
