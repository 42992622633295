/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from './OptionsTable';

const useStyles = makeStyles({
  root: {
    padding: '20px',
  },
  tableDiv: {
    maxHeight: '20vh',
    overflow: 'auto',
    padding: '0 45px',
  },
});

function Question({ question }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div align="center">
        <h3>
          {question.question}
        </h3>
        <div className={classes.tableDiv}>
          <Table options={question.optionPercents} />
        </div>
      </div>
    </div>
  );
}

Question.propTypes = {
  question: PropTypes.object.isRequired,
};
export default Question;
