import React from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';

const BarGraph = (props) => {
  const { answers } = props;

  const myLabels = [];
  const myColors = [
    '#0EB79D',
    '#AE3C82',
    '#AACE8C',
    '#14658B',
    '#DA9E89',
    '#4AB0AD',
    '#FFAD18',
    '#E4C2B2',
    '#42A231',
    '#C4580B',
  ];

  answers.map((x) => myLabels.push(Object.keys(x)[0]));

  const myData = [];

  answers.map((x) => myData.push(Object.values(x)[0]));

  const data = {
    labels: myLabels,
    datasets: [
      {
        label: 'Dataset',
        backgroundColor: myColors,
        borderColor: 'rgb(255, 99, 132)',
        data: myData,
      },
    ],
  };

  const options = {
    responsive: true,
    parsing: {
      xAxisKey: 'option',
      yAxisKey: 'count',
    },
    tooltips: {
      mode: 'label',
    },
    plugins: {
      labels: false,
    },
    legend: {
      display: false,
      labels: {
        fontSize: 25,
        fontFamily:
          '"Filson Pro Regular","Roboto", "Helvetica", "Arial", sans-serif',
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            suggestedMin: 0,
            // suggestedMax: 10,
            stepSize: 1,
            fontSize: 15,
          },
        },
      ],
      xAxes: [
        {
          display: true,
          ticks: {
            fontSize: 15,
          },
        },
      ],
    },
  };
  return (
    <Bar
      options={options}
      data={data}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      width="10vw"
      height="5vh"
    />
  );
};

BarGraph.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  answers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default BarGraph;
