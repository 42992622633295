/* eslint-disable react/forbid-prop-types */
import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Card from '../../components/Card/Card';
import CardFooter from '../../components/Card/CardFooter';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import styles from '../../assets/jss/material-dashboard-pro-react/views/reportPageStyle';
import ChatUserGrid from './ChatUserGridNew';
import { AuthContext } from '../../Auth';
import { loadAuthToken } from '../../utils/auth';

const useStyles = makeStyles(styles);

function ChatUserTable() {
  const classes = useStyles();
  const context = useContext(AuthContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_CHAT_SERVICE}/chat-users`,
        headers: {
          Authorization: `bearer ${loadAuthToken()}`,
        },
      }).then((res) => {
        const mappedData = res.data.data.map((chatUser) => ({
          id: chatUser.id,
          email: chatUser.email,
          company: chatUser.chat_company ? chatUser.chat_company.name : 'No Company Associated',
          verified: chatUser.approved,
        }));
        setData(mappedData);
        return res;
      })
        .catch((e) => {
          console.log(e);
          context.setMessageState('error');
          context.setSnackBarMessage(`${e.response.data.message}`);
        });
    };

    fetchData();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps


  return (
    <Card>
      <CardHeader className={classes.headerAlign}>
        <div className={classes.header}>
          <h3>Chat Users</h3>
        </div>

      </CardHeader>
      <CardBody className={classes.reportCardBody}>
        <ChatUserGrid data={data} />
      </CardBody>

      <CardFooter stats>
        <div className={classes.stats}>
          <p className={classes.cardTitle}>
            Total:
            {data.length}
          </p>
        </div>
      </CardFooter>
    </Card>
  );
}

export default ChatUserTable;
