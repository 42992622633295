/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// core components
import CardBody from '../../components/Card/CardBody';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import styles from '../../assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import { loadAuthToken } from '../../utils/auth';
import { AuthContext } from '../../Auth';
// import sampleData from './czSampleData3';
import TagTabs from './comfort/TagTabs';

import HeaderDropdowns from './comfort/HeaderDropdowns';

const useStyles = makeStyles(styles);

function CompanyReport() {
  const classes = useStyles();
  const context = useContext(AuthContext);

  const [sessionIds, setSessionIds] = useState([]);
  const [comfortData, setComfortData] = useState([]);
  const [headers, setHeaders] = useState([]);
  // const [comfortData] = useState(sampleData.data);
  // const [headers] = useState(sampleData.header);

  useEffect(() => {
    const searchQuerry = (String(sessionIds.map((id) => `sessionId=${id}&`)).split(',').join(''));

    const fetchData = async () => {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_REPORT_SERVICE}/get-comfort-demographics?${searchQuerry}`,
        headers: {
          Authorization: `bearer ${loadAuthToken()}`,
        },
      }).then((res) => {
        // console.log('useEffect 1');
        setComfortData(res.data.data);
        setHeaders(res.data.header);
        return res.data.company;
      }).catch((e) => {
        context.setMessageState('error');
        context.setSnackBarMessage(`${e.response.data.message}`);
      });
    };
    if (sessionIds.length > 0) {
      fetchData();
    }
  }, [sessionIds]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardHeader>
              <p className={classes.cardCategory}>Comfort Zone Report</p>
              <h3 className={classes.cardTitle}>
                {' '}
                {/* {company.name} */}
              </h3>

            </CardHeader>

            <HeaderDropdowns setSessionIds={setSessionIds} />
          </Card>

          <Card>
            {comfortData.map((data) => (
              <div key={data.tag}>
                <Card>
                  <CardHeader>
                    {data.tag}
                  </CardHeader>
                  <CardBody>
                    <TagTabs tagData={data} headers={headers} />

                  </CardBody>
                </Card>
              </div>
            ))}
          </Card>

        </GridItem>
      </GridContainer>
    </>
  );
}

export default CompanyReport;
