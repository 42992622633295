import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  DataGrid,
} from '@material-ui/data-grid';

// import Button from '@material-ui/core/Button';
import Button from '../../../components/CustomButtons/Button';
import EditGroupModal from './EditGroupModal';

export default function UsersTableGrid(props) {
  const [newHeaders, setNewHeaders] = useState([]);
  const [trainingGroupToEdit, setTrainingGroupToEdit] = useState({});
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const {
    data,
  } = props;

  const headers = [
    {
      headerName: 'Training Group', field: 'name', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Contact Email', field: 'contactEmail', width: 250, disableClickEventBubbling: true,
    },

  ];

  useEffect(() => {
    const headersWithAddedContent = [
      {
        headerName: 'View Group',
        field: 'id',
        width: 200,
        disableClickEventBubbling: true,
        renderCell: (group) => (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                setTrainingGroupToEdit(group.row);
                setIsEditModalOpen(!isEditModalOpen);
              }}
            >
              View
            </Button>
          </div>
        ),
      },

      ...headers,
      {
        headerName: 'Companies',
        field: 'companyName',
        width: 200,
        disableClickEventBubbling: true,
        renderCell: (group) => (
          <div>
            {group.row.companies.map((company) => (
              <p>{company.name}</p>
            ))}
          </div>
        ),
      },

    ];

    setNewHeaders(headersWithAddedContent);
  }, [data]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ height: 600, width: '100%' }}>
      <EditGroupModal
        open={isEditModalOpen}
        group={trainingGroupToEdit}
        togalModal={() => {
          setIsEditModalOpen(!isEditModalOpen);
        }}
      />
      <DataGrid
        rows={data}
        columns={newHeaders}
        sortingOrder={['asc', 'desc']}
        sortModel={[
          {
            field: 'name',
            sort: 'asc',
          },
        ]}
      />
    </div>
  );
}

UsersTableGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};
