/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-trailing-spaces */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// import Card from '@material-ui/core/Card';
// import { makeStyles } from '@material-ui/core/styles';
// import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Carousel from 'react-material-ui-carousel';
import Button from '../../../../components/CustomButtons/Button';
import { loadAuthToken } from '../../../../utils/auth';
import { AuthContext } from '../../../../Auth'; 
import ImageComponent from './modal/ImageComponent';
import QuestionComponent from './modal/QuestionComponent';

const SelfIdReportModal = ({
  open, togalModal, question, sessionId, isCompany, companyId, moduleInfo, eventId,
}) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const context = useContext(AuthContext);
  // const classes = useStyles();
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (open) {
      if (isCompany && question.id) {
        setIsLoading(true);
        axios({
          method: 'post',
          url: `${process.env.REACT_APP_EVENT_SERVICE}/company-self-id-report`,
          headers: {
            Authorization: `bearer ${loadAuthToken()}`,
          },
          data: {
            companyId,
            questionId: question.id,
            eventId,
            moduleInfo,
          },
        }).then((res) => {
          setIsLoading(false);
          setData(res.data);
        }).catch((e) => {
          context.setMessageState('error');
          context.setSnackBarMessage(`${e.response.data.message}`);
          togalModal();
        });
      } else if (question.id) {
        setIsLoading(true);
        axios({
          method: 'get',
          url: `${process.env.REACT_APP_EVENT_SERVICE}/wop-question-self-id-report?sessionId=${sessionId}&questionId=${question.id}`,
          headers: {
            Authorization: `bearer ${loadAuthToken()}`,
          },
        }).then((res) => {
          setIsLoading(false);
          setData(res.data);
        }).catch((e) => {
          context.setMessageState('error');
          context.setSnackBarMessage(`${e.response.data.message}`);
          togalModal();
        });
      }
    }
  }, [question, sessionId, open]);

  const closeModal = () => {
    setData({});
    setIsLoading(true);
    togalModal();
  };

  return (
    <Dialog
      open={open}
      onClose={() => closeModal()}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          width: '100%',
          minHeight: '60%',
        },
      }}
    >
      <DialogTitle id="form-dialog-title">
        {isLoading ? <CircularProgress /> : null}
      </DialogTitle>
      <DialogContent />
      {question.id ? <ImageComponent question={question} /> : null} 
      {data.question ? (
        <div>
          <Carousel
            autoPlay={false}
            navButtonsAlwaysVisible
            indicators={false}
          >
            {data.question.surveyQuestions.map((surveyQuestion, index) => (
              <div key={index + 500000}> 
                <QuestionComponent question={surveyQuestion} />
              </div>
            ))}
          </Carousel>
        </div>
      ) : null}
      <DialogActions>
        <Button onClick={() => closeModal()} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SelfIdReportModal.propTypes = {
  open: PropTypes.bool.isRequired,
  togalModal: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  isCompany: PropTypes.bool.isRequired,
  sessionId: PropTypes.number,
  companyId: PropTypes.number,
  moduleInfo: PropTypes.object,
  eventId: PropTypes.number,
};

export default SelfIdReportModal;
