/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
// import { loadUserId } from '../utils/LocalStorage'
import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider';

// import Divider from '@material-ui/core/Divider'

const styles = () => ({
  text: {
    margin: 0,
    display: 'inline',
    width: '20%',
    '@media (max-width: 1200px)': {
      width: '40%',
    },
  },
  surveySlider: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 'auto',
  },
  lastText: {
    margin: 0,
    display: 'inline',
    width: '20%',
    textAlign: 'end',
    '@media (max-width: 1200px)': {
      width: '40%',
    },
  },
  centerText: {
    margin: 0,
    display: 'inline',
    width: '20%',
    textAlign: 'center',
    '@media (max-width: 1200px)': {
      width: '40%',
    },
  },
});

const PrettoSlider = withStyles({
  root: {
    color: '#52af77',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid #52af77',
    marginTop: -8,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const SurveySlider = ({
  json_options, options, classes, setAnswer,
}) => {
  const [sliderValue, setSliderValue] = useState(Math.ceil(json_options.length / 2));

  const handleChange = (event, value) => {
    setSliderValue(value);
    if (json_options) {
      setAnswer(json_options[value - 1].option);
    } else {
      setAnswer(options[value - 1]);
    }
  };

  return (
    <>
      <PrettoSlider
        min={1}
        step={1}
        max={json_options.length}
        defaultValue={Math.ceil(json_options.length / 2)}
        onChange={(event, value) => handleChange(event, value)}
        value={sliderValue}
        name="Demo"
      />
      <div className={classes.surveySlider}>
        {json_options.map((option, optionIndex) => {
          if (
            optionIndex % 2 === 1
                && optionIndex
                === Math.floor((json_options.length * 1) / 2)
          ) {
            return (
              <p
                key={optionIndex + 1000}
                className={classes.text}
              >
                {option.type ? option.option : option}
              </p>
            );
          }
          if (optionIndex % 2 === 0) {
            const last = json_options.length === (optionIndex + 1);
            if (optionIndex === 2) {
              return (
                <p
                  key={optionIndex + 1000}
                  className={classes.centerText}
                >
                  {option.type ? option.option : option}
                </p>
              );
            }
            return (
              <p
                key={optionIndex + 1000}
                className={!last ? classes.text : classes.lastText}
              >
                {option.type ? option.option : option}
              </p>
            );
          }
        })}
      </div>
    </>
  );
};

SurveySlider.propTypes = {
  options: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  setAnswer: PropTypes.func.isRequired,
  json_options: PropTypes.array,
};

export default withStyles(styles)(SurveySlider);
