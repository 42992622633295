/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
// @material-ui/icons
import Face from '@material-ui/icons/Face';
import Email from '@material-ui/icons/Email';
import SmartphoneIcon from '@material-ui/icons/Smartphone';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';

// core components
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import CustomInput from '../../../components/CustomInput/CustomInput';
import Card from '../../../components/Card/Card';
import CardBody from '../../../components/Card/CardBody';
import CardFooter from '../../../components/Card/CardFooter';
import Button from '../../../components/CustomButtons/Button';
import { AuthContext } from '../../../Auth';
import { loadAuthToken } from '../../../utils/auth';

const style = {
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center',
  },
  inputAdornmentIcon: {
    color: '#555',
  },
  inputAdornment: {
    position: 'relative',
  },
};

function InfoForm(props) {
  const [firstName, setFirstName] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [firstNameState, setFirstNameState] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [lastNameState, setLastNameState] = useState('');
  const [email, setEmail] = useState('');
  const [emailState, setEmailState] = useState('');
  const [phoneState, setPhoneState] = useState('');
  const [userId, setUserId] = useState('');

  const context = useContext(AuthContext);
  const { history } = props;

  useEffect(() => {
    setFirstName(context.user.firstName);
    setLastName(context.user.lastName);
    setEmail(context.user.email);
    setUserId(context.user.id);
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_AUTH_SERVICE}/users`,
      headers: {
        Authorization: loadAuthToken(),
      },
    }).then((res) => {
      // eslint-disable-next-line max-len
      const userPhoneNumber = res.data.data.filter((user) => user.id === context.user.id)[0];
      setPhoneNumber(userPhoneNumber.phoneNumber ? userPhoneNumber.phoneNumber : '');
    })
      .catch((e) => {
        console.log(e);
        context.setMessageState('error');
        context.setSnackBarMessage(`${e.response.data.message}`);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // function that returns true if value is email, false otherwise
  const verifyEmail = (value) => {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  const verifyPhone = (value) => {
    const phoneRex = /^$|^\d{10}$/;
    if (phoneRex.test(value)) {
      return true;
    }
    return false;
  };

  const change = (event, stateName) => {
    switch (stateName) {
      case 'email':
        setEmail(event.target.value);
        if (verifyEmail(event.target.value)) {
          setEmailState('success');
          setButtonDisabled(false);
        } else {
          setEmailState('error');
          setButtonDisabled(true);
        }
        break;
      case 'firstName':
        setFirstName(event.target.value);
        if (event.target.value.length >= 3) {
          setFirstNameState('success');
          setButtonDisabled(false);
        } else {
          setButtonDisabled(true);
          setFirstNameState('error');
        }
        break;
      case 'lastName':
        setLastName(event.target.value);
        if (event.target.value.length >= 3) {
          setLastNameState('success');
          setButtonDisabled(false);
        } else {
          setLastNameState('error');
          setButtonDisabled(true);
        }
        break;
      case 'phoneNumber':
        setPhoneNumber(event.target.value);
        if (verifyPhone(event.target.value)) {
          setPhoneState('success');
          setButtonDisabled(false);
        } else {
          setPhoneState('error');
          setButtonDisabled(true);
        }
        break;

      default:
        break;
    }
  };

  const handleSubmitClick = () => {
    const token = localStorage.getItem('feathers-jwt');
    const userUpdateObj = {};
    if (firstNameState === 'success' && firstName !== context.user.firstName) {
      userUpdateObj.firstName = firstName;
    }

    if (lastNameState === 'success' && lastName !== context.user.lastName) {
      userUpdateObj.lastName = lastName;
    }

    if (emailState === 'success' && email !== context.user.email) {
      userUpdateObj.email = email;
    }

    if (phoneState === 'success') {
      userUpdateObj.phoneNumber = phoneNumber;
    }

    if (Object.entries(userUpdateObj).length > 0) {
      axios(`${process.env.REACT_APP_AUTH_SERVICE}/users/${userId}`, {
        method: 'patch',
        headers: { Authorization: `Bearer ${token}` },
        data: { ...userUpdateObj },
      })
        .then((res) => {
          context.setMessageState('success');
          context.setSnackBarMessage('User information successfully updated.');
          setFirstName(res.data.firstName);
          setLastName(res.data.lastName);
          setEmail(res.data.email);
        })
        .catch((err) => {
          context.setMessageState('error');
          context.setSnackBarMessage(`${err.response.data.message}`);
          if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
            history.push('auth/login');
          }
        });
    } else {
      context.setMessageState('error');
      context.setSnackBarMessage('Your info has not been updated');
    }
  };
  const { classes } = props;
  return (
    <GridContainer justifyContent="center">
      <GridItem xs={12} lg={10}>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      success={firstNameState === 'success'}
                      error={firstNameState === 'error'}
                      labelText={(
                        <span>
                          First Name
                        </span>
                      )}
                      id="firstName"
                      formControlProps={{
                        fullWidth: true,
                        margin: 'dense',
                      }}
                      inputProps={{
                        onChange: (event) => change(event, 'firstName'),
                        placeholder: firstName || 'First Name',
                        value: firstName,
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            className={classes.inputAdornment}
                          >
                            <Face className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>

                    <CustomInput
                      success={lastNameState === 'success'}
                      error={lastNameState === 'error'}
                      labelText={(
                        <span>
                          Last Name
                        </span>
                      )}
                      id="lastName"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (event) => change(event, 'lastName'),
                        value: lastName,
                        placeholder: lastName || 'Last Name',
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            className={classes.inputAdornment}
                          >
                            <Face className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12}>

                    <CustomInput
                      success={emailState === 'success'}
                      error={emailState === 'error'}
                      labelText={(
                        <span>
                          Email
                        </span>
                      )}
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (event) => change(event, 'email'),
                        value: email,
                        placeholder: email || 'Email',
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            className={classes.inputAdornment}
                          >
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12}>

                    <CustomInput
                      helperText="10 digit number with no prefix, spaces or characters (example: 5551234567)"
                      success={phoneState === 'success'}
                      error={phoneState === 'error'}
                      labelText={(
                        <span>
                          Phone Number
                        </span>
                      )}
                      id="phoneNumber"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (event) => change(event, 'phoneNumber'),
                        value: phoneNumber,
                        placeholder: phoneNumber || 'Phone Number',
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            className={classes.inputAdornment}
                          >
                            <SmartphoneIcon className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>

            </GridContainer>
          </CardBody>
          <GridContainer justifyContent="flex-end">
            <CardFooter>
              <GridItem xs={12} sm={2}>
                <Button disabled={buttonDisabled} onClick={() => handleSubmitClick()}>
                  Update Settings
                </Button>
              </GridItem>
            </CardFooter>
          </GridContainer>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

InfoForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withStyles(style)(InfoForm);
