import io from 'socket.io-client';
import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import auth from '@feathersjs/authentication-client';
// import {callResponseFail, callResponseSuccess} from './actions/actions'
import { loadAuthToken } from '../utils/auth';

export default {
  connect: () => {
    const socket = io(process.env.REACT_APP_AUTH_SERVICE, {
      query: {
        token: loadAuthToken(),
      },
    });
    const client = feathers();
    client.configure(socketio(socket));
    client.configure(auth({
      storage: window.localStorage,
    }));
    return client;
  },
  client: {},
  on: () => console.log('on for chat called'),
  service: (service) => console.log(service, 'called for chat'),
};
