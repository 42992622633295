import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';


import styles from '../../assets/jss/material-dashboard-pro-react/components/footerStyle';

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { fluid, white } = props;
  const container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white,
  });
  const anchor = classes.a
    + cx({
      [` ${classes.whiteColor}`]: white,
    });
  // const block = cx({
  //   [classes.block]: true,
  //   [classes.whiteColor]: white,
  // });
  return (
    <footer className={classes.footer}>
      <div className={container}>
        <div className={classes.left}>
          {/* <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a href="https://www.translator.company" className={block}>
                Home
              </a>
            </ListItem>
          </List> */}
        </div>
        <p className={classes.right}>
          <a
            href="https://www.translator.company"
            className={anchor}
            target="_blank"
            rel="noopener noreferrer"
          >
            &copy;
            {' '}
            {1900 + new Date().getYear()}
            {' '}
            Translator
          </a>
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  fluid: PropTypes.bool,
  white: PropTypes.bool,
};
