/* eslint-disable react/no-unused-state */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';

// @material-ui/icons
import ViewModuleIcon from '@material-ui/icons/ViewModule';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
// core components
import GridContainer from '../../../../components/Grid/GridContainer';
import GridItem from '../../../../components/Grid/GridItem';
import CustomInput from '../../../../components/CustomInput/CustomInput';

const style = {
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center',
  },
  inputAdornmentIcon: {
    color: '#555',
  },
  inputAdornment: {
    position: 'relative',
  },
};

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nameState: '',
      desc: '',
    };
  }

  sendState() {
    return this.state;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'length':
        if (validator.isLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [`${stateName}State`]: 'success' });
        } else {
          this.setState({ [`${stateName}State`]: 'error' });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }

  isValidated() {
    const { nameState } = this.state;

    if (
      nameState === 'success'
    ) {
      return true;
    }
    if (nameState !== 'success') {
      this.setState({ nameState: 'error' });
    }

    return false;
  }

  render() {
    const { classes } = this.props;
    const { nameState } = this.state;
    return (
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>
            Let &apos;s start with the basic information about your module
          </h4>
        </GridItem>

        <GridItem xs={12} sm={6}>
          <CustomInput
            success={nameState === 'success'}
            error={nameState === 'error'}
            labelText={(
              <span>
                <small>Name of the module (required)</small>
              </span>
            )}
            id="name"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => this.change(event, 'name', 'length', 3),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <ViewModuleIcon className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />
          <CustomInput
            labelText={(
              <span>
                Description of the module
              </span>
            )}
            id="desc"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => {
                this.setState({ desc: event.target.value });
              },
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <ViewModuleIcon className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

Step1.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(style)(Step1);
