import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  DataGrid,
} from '@material-ui/data-grid';
import EditQuestionModal from './EditQuestionModal';
import Button from '../../../../components/CustomButtons/Button';

export default function AutoHostQuestionGrid(props) {
  const {
    data,
  } = props;
  const [newHeaders, setNewHeaders] = useState([]);
  const [questionToEdit, setQuestionToEdit] = useState({});
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const headers = [
    {
      headerName: 'Text', field: 'questionText', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Status', field: 'status', width: 250, disableClickEventBubbling: true,
    },
  ];

  useEffect(() => {
    const headersWithAddedButton = [
      {
        headerName: 'Edit User',
        field: 'id',
        width: 200,
        disableClickEventBubbling: true,
        renderCell: (user) => (
          <div>
            <Button
              disabled
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                setQuestionToEdit(user.row);
                setIsEditModalOpen(!isEditModalOpen);
              }}
            >
              Edit
            </Button>
          </div>
        ),
      },
      ...headers];
    setNewHeaders(headersWithAddedButton);
  }, [data]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ height: 600, width: '100%' }}>
      <EditQuestionModal
        open={isEditModalOpen}
        question={questionToEdit}
        toggleModal={() => setIsEditModalOpen(!isEditModalOpen)}
        data={data}
      />
      <DataGrid
        rows={data}
        columns={newHeaders}
        sortingOrder={['asc', 'desc']}
        sortModel={[
          {
            field: 'questionText',
            sort: 'asc',
          },
        ]}
      />
    </div>
  );
}

AutoHostQuestionGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};
