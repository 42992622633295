import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { PulseLoader } from 'react-spinners';
import { makeStyles } from '@material-ui/core/styles';
import Card from '../../../components/Card/Card';
import CardFooter from '../../../components/Card/CardFooter';
import CardBody from '../../../components/Card/CardBody';
import CardHeader from '../../../components/Card/CardHeader';
import styles from '../../../assets/jss/material-dashboard-pro-react/views/reportPageStyle';
import SessionHistoryGrid from './SessionHistoryGrid';
import { AuthContext } from '../../../Auth';
import { loadAuthToken } from '../../../utils/auth';

const useStyles = makeStyles(styles);

function SessionHistoryTable() {
  const classes = useStyles();
  const context = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { user, setMessageState, setSnackBarMessage } = context;
  //   const history = useHistory();

  useEffect(() => {
    const fetchData = () => {
      setIsLoading(true);
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_EVENT_SERVICE}/session?completed=true&userId=${user.id}`,
        headers: {
          Authorization: `bearer ${loadAuthToken()}`,
        },
      }).then((res) => {
        // console.log('res', res.data);
        setData(
          res.data.map((session) => ({
            id: session.id,
            title: session.title,
            session: session.id,
            pin: session.pin,
            type: session.type,
            company: session.company ? session.company.name : '',
            audienceUsers: session.audienceUsers,
            started: session.started,
            completedAt: session.completedAt,
          })),
        );
        setIsLoading(false);
        return res;
      })
        .catch((e) => {
          console.log(e.response, 'Error response');
          setMessageState('error');
          setSnackBarMessage(`${e.response.data.message}`);
        });
    };
    fetchData();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card style={{ minHeight: '80vh' }}>
      <CardHeader className={classes.headerAlign}>
        <div className={classes.header}>
          <h3>
            Session History for
            {' '}
            {user.firstName}
          </h3>
        </div>

      </CardHeader>
      <CardBody className={classes.reportCardBody}>
        {isLoading ? (
          <div style={{ margin: 'auto' }}>
            <PulseLoader
              sizeUnit="px"
              size={15}
              color="#6CCA98"
              loading
            />
          </div>
        ) : <SessionHistoryGrid data={data} />}
      </CardBody>

      <CardFooter stats>
        <div className={classes.stats}>
          <p className={classes.cardTitle}>
            Total:
            {data.length}
          </p>
        </div>
      </CardFooter>
    </Card>
  );
}

export default SessionHistoryTable;
