/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import { loadAuthToken } from '../../../utils/auth';
import { AuthContext } from '../../../Auth';
import Comment from '../../../assets/img/comment.png';

const useStyles = makeStyles({
  outerDiv: {
    margin: 'auto',
    textAlign: 'center',
  },
  imageDiv: {
    // paddingTop: '1rem',
    paddingBottom: '2rem',
  },
  contentDiv: {
    paddingTop: '2.5rem',
  },
  text: {
    fontSize: '1.4rem',
  },
  largeText: {
    fontSize: '1.6rem',
  },
  messageIcon: {
    color: '#6CCA98',
    height: '100px',
    width: '100px',
  },
  image: {
    width: '160px',
    marginBottom: '5px',
  },
});

const Survey = ({
  moduleTitle,
  sessionId,
  moduleId,
  channel,
}) => {
  const [usersAnswered, setUsersAnswered] = useState(0);
  const context = useContext(AuthContext);

  const getFinishedUsers = () => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/survey-answered?sessionId=${sessionId}&moduleId=${moduleId}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).then((res) => {
      if (res.data === 'your token is not valid' || res.data === 'Your token is expired') {
        context.sendToLogin();
      }
      setUsersAnswered(res.data.usersAnswered);
    }).catch((err) => {
      if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
        context.sendToLogin();
      }
    });
  };

  useEffect(() => {
    getFinishedUsers();
    if (channel) {
      channel.bind(`survey_completion_${sessionId}_${moduleId}`, (message) => {
        setUsersAnswered(message);
      });
      return () => {
        channel.unbind(`survey_completion_${sessionId}_${moduleId}`);
      };
    }
  }, [moduleId, channel, sessionId]);

  const classes = useStyles();
  return (
    <div className={classes.outerDiv}>
      <div className={classes.imageDiv}>
        <img src={Comment} className={classes.image} alt="walk of privilege" />
      </div>
      <Divider />
      <div className={classes.contentDiv}>
        <p className={classes.largeText}><strong>{moduleTitle}</strong></p>
        <br />
        <p className={classes.text}>
          Thank you for your participation today!
        </p>
        <br />
        <p className={classes.text}>
          Please take a moment to answer these last few questions
        </p>
        <br />
        <p className={classes.largeText}>COMPLETIONS</p>
        <p className={classes.largeText}>
          <strong>
            {' '}
            {usersAnswered
              ? String(usersAnswered)
              : '0'}
          </strong>
        </p>
        <div
          width="100%"
          align="flex-end"
          style={{ right: '5vw', bottom: '7vh', position: 'absolute' }}
        />
      </div>
    </div>
  );
};

Survey.propTypes = {
  moduleTitle: PropTypes.string.isRequired,
  sessionId: PropTypes.number.isRequired,
  moduleId: PropTypes.number.isRequired,
  channel: PropTypes.object.isRequired,
};

export default Survey;
