/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-trailing-spaces */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import validator from 'validator';
import Switch from '@material-ui/core/Switch';
import Button from '../../../../components/CustomButtons/Button';
import CustomInput from '../../../../components/CustomInput/CustomInput';
import { loadAuthToken } from '../../../../utils/auth';
import { AuthContext } from '../../../../Auth';
import ImageUploader from '../ImageUploader';
import Tags from '../Wizard/QuestionTags';

const AddQuestionDialog = (props) => {
  const {
    open, togalModal, question, fetchData, 
  } = props;
  const [iconUrl, setIconUrl] = useState('');
  const context = useContext(AuthContext);
  const [userQuestionState, setUserQuestionState] = useState('');
  const [userQuestion, setUserQuestion] = useState('');
  const [points, setPoints] = useState(0);
  const [direction, setDirection] = useState('down');
  const [tags, setTags] = useState([]);

  useEffect(() => {
    setIconUrl(question.image);
    setUserQuestion(question.question);
    setPoints(question.points);
    setDirection(question.direction);
    setTags(question.wopTags);
  }, [question]);

  const handleDelete = (i) => {
    const tagsCopy = tags.slice(0);
    tagsCopy.splice(i, 1);
    setTags(tagsCopy);
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleSubmit = () => {
    const editObj = {};
    if (userQuestion) {
      if (userQuestion.includes('SWIPE DOWN')) {
        editObj.question = userQuestion.replace('SWIPE DOWN', '').trim();
      }
      if (userQuestion.includes('SWIPE UP')) {
        editObj.question = userQuestion.replace('SWIPE UP', '').trim();
      }
    }
    if (points) {
      editObj.points = points;
    }
    if (direction) {
      editObj.direction = direction;
    }
    if (iconUrl) {
      editObj.image = iconUrl;
    }
    if (tags && tags.length !== 0) {
      editObj.tags = tags;
    }
    axios({
      method: 'patch',
      url: `${process.env.REACT_APP_MODULE_SERVICE}/walk-of-privilege-question/${question.id}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
      data: { ...editObj },
    }).then(() => {
      console.log(fetchData)
      setIconUrl('');
      setUserQuestionState('');
      context.setMessageState('success');
      context.setSnackBarMessage(`Successfully updated wop question:  ${userQuestion}`);
      setUserQuestion('');
      setPoints(0);
      setDirection('down');
      setTags([]);
      togalModal();

      // not ideal to fetch again but good enough for now
      fetchData();
    }).catch((err) => {
      console.log(err);
      context.setMessageState('error');
      context.setSnackBarMessage(`${err && err.response ? err.response.data.message : 'something broke'}`);
      if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
        props.history.push('auth/login');
      }
    });
  };

  const change = (userProps) => {
    const {
      event, type, state, validState, extra,
    } = userProps;

    switch (type) {
      case 'length':
        if (validator.isLength(event.target.value, extra)) {
          validState('success');
        } else {
          validState('error');
        }
        break;
      case 'url':
        if (validator.isURL(event.target.value)) {
          validState('success');
        } else {
          validState('error');
        }
        if (event.target.value.length === 0) {
          validState('');
        }
        break;
      case 'email':
        if (validator.isEmail(event.target.value)) {
          validState('success');
        } else {
          validState('error');
        }
        if (event.target.value.length === 0) {
          validState('');
        }
        break;
      default:
        break;
    }
    state(event.target.value);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => togalModal()}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: '100%',
            minHeight: '60%',
          },
        }}
      >
        <DialogTitle id="form-dialog-title">Add New Question</DialogTitle>
        <DialogContent>
          <CustomInput
            success={userQuestionState === 'success'}
            error={userQuestionState === 'error'}
            labelText={(
              <span>
                Question
                {' '}
                <small>(required)</small>
              </span>
            )}
            id="question"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: userQuestion,
              onChange: (event) => change({
                event, state: setUserQuestion, validState: setUserQuestionState, type: 'length', extra: 3,
              }),
            }}
          />
          <div>
            <Switch
              onChange={() => (direction === 'up' ? setDirection('down') : setDirection('up'))}
            />
            <p>
              Direction: 
              {' '}
              {direction}
            </p>
          </div>
          <TextField
            label="Points For Question"
            fullWidth
            type="number"
            value={points}
            onChange={(e) => setPoints(e.target.value)}
          />
          <ImageUploader setIconUrl={(url) => setIconUrl(url)} />
          {/* <CustomInput
            success={iconUrlState === 'success'}
            error={iconUrlState === 'error'}
            labelText={(
              <span>
                Icon Url
              </span>
            )}
            id="url"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: iconUrl,
              onChange: (event) => change({
                event, state: setIconUrl, validState: setIconUrlState, type: 'url',
              }),
            }}
          /> */}
          <Tags
            tags={tags}
            handleAddition={(tag) => handleAddition(tag)}
            handleDelete={(i) => handleDelete(i)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => togalModal()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleSubmit()}>
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AddQuestionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  togalModal: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  history: PropTypes.object,
};

export default AddQuestionDialog;
