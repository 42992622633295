/* eslint-disable react/no-danger */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '../../../components/Card/Card';
import CardBody from '../../../components/Card/CardBody';
import CardFooter from '../../../components/Card/CardFooter';
import Button from '../../../components/CustomButtons/Button';

const useStyles = makeStyles({
  center: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: '1rem',
  },
  card: {
    width: '50vw',
    height: '75vh',
    display: 'flex',
    alignItems: 'center',
    overflow: 'auto',
    '@media (max-width: 900px)': {
      width: '95vw',
      height: 'calc(var(--vh, 1vh) * 80)',

    },
  },
  body: {
    // width: '50%',
  },
  innerBody: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: '800px',
    height: '100%',
    justifyContent: 'center',
    textAlign: 'center',
  },
  largeText: {
    fontSize: '18px',
  },
});

// const useStyles = makeStyles(styles);

const InfoModule = (props) => {
  const classes = useStyles();
  const [isVideoComplete, setVideoComplete] = useState(true);
  const {
    text, nextModule, isHosted,
  } = props;

  useEffect(() => {
  // Update the document title using the browser API
    const links = document.getElementsByTagName('a');
    const el = links[0];
    let parentDiv = '';
    // map through links. if src has mp4 than run
    if (el) {
      if (el.href.includes('.mp4')) {
        setVideoComplete(false);
        const video = document.createElement('video');
        video.src = `${el.href}#t=0.75`;
        video.setAttribute('controls', 'controls');
        video.setAttribute('width', '100%');

        parentDiv = el.parentNode;
        video.onended = () => {
          setVideoComplete(true);
        };
        if (parentDiv) {
          parentDiv.replaceChild(video, el);
        }
      } else {
        el.target = '_blank';
      }
    }
  });
  return (
    <div>
      <Card className={classes.card}>
        <CardBody className={classes.body}>
          <div className={classes.innerBody} dangerouslySetInnerHTML={{ __html: text }} />
        </CardBody>
      </Card>
      {!isHosted ? (
        <CardFooter style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
          <Button
            type="submit"
            color="lightGreen"
            disabled={!isVideoComplete}
            onClick={() => nextModule()}
          >
            Next
          </Button>
        </CardFooter>
      ) : null}
    </div>

  );
};

InfoModule.propTypes = {
  isHosted: PropTypes.bool,
  nextModule: PropTypes.func,
  text: PropTypes.string,
  // moduleTitle: PropTypes.string,
};

export default InfoModule;
