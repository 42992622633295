import React from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';

const BarGraph = (props) => {
  const { answers } = props;

  const myLabels = [];


  const myData = [];

  answers.map((x) => myLabels.push(Object.keys(x)[0]));

  // console.log(myLabels, 'labels');
  answers.map((x) => myData.push(Object.values(x)[0]));

  const data = {
    labels: myLabels,
    datasets: [
      {
        label: '# of people',
        backgroundColor: '#AE3C82',
        borderColor: 'rgb(255, 99, 132)',
        data: myData,
      },
    ],
  };

  const options = {
    responsive: true,
    parsing: {
      xAxisKey: 'option',
      yAxisKey: 'count',
    },
    tooltips: {
      mode: 'label',
    },
    plugins: {
      labels: false,
    },
    legend: {
      display: true,
      labels: {
        fontSize: 10,
        fontFamily: '"Montserrat","Roboto", "Helvetica", "Arial", sans-serif',
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            suggestedMin: 0,
            suggestedMax: 10,
            stepSize: 1,
            fontSize: 10,
            fontFamily:
              '"Montserrat","Roboto", "Helvetica", "Arial", sans-serif',
          },
        },
      ],
      xAxes: [
        {
          display: false,
          ticks: {
            fontSize: 10,
          },
        },
      ],
    },
  };
  return (
    <Bar
      options={options}
      data={data}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      width="20vw"
      height="10vh"
    />
  );
};

BarGraph.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  answers: PropTypes.array.isRequired,
};

export default BarGraph;
