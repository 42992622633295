import axios from 'axios';
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import Phone from '@material-ui/icons/Phone';
import CardBody from '../../../components/Card/CardBody';
import CardFooter from '../../../components/Card/CardFooter';
import CustomInput from '../../../components/CustomInput/CustomInput';
import Button from '../../../components/CustomButtons/Button';
import { loadAuthToken } from '../../../utils/auth';
import { AuthContext } from '../../../Auth';
import styles from '../../../assets/jss/material-dashboard-pro-react/views/loginPageStyle';

const useStyles = makeStyles(styles);

function Form(props) {
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [agreed, setAgreed] = React.useState(false);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const classes = useStyles();
  const context = useContext(AuthContext);
  const { signedUp, chatUserId } = props;
  // const { chatClient } = useContext(AuthContext);
  const verifyPhoneNumber = (number) => {
    setIsValidPhoneNumber(number.match(/^\d{10}$/));
    setPhoneNumber(number);
  };
  return (
    <form
      autoComplete="off"
      onSubmit={async (e) => {
        e.preventDefault();
        if (agreed && isValidPhoneNumber) {
          axios({
            method: 'patch',
            url: `${process.env.REACT_APP_CHAT_SERVICE}/chat-users/${chatUserId}`,
            data: {
              approved: true,
              phoneNumber: `+1${phoneNumber}`,
            },
            headers: {
              Authorization: `bearer ${loadAuthToken()}`,
            },
          }).then(() => {
            context.setMessageState('success');
            context.setSnackBarMessage('Thank you for signing up to Translator\'s chat service!');
            signedUp(true);
          }).catch((err) => {
            context.setMessageState('error');
            context.setSnackBarMessage(`${err.response.data.message}`);
            console.error(err);
          });
        }
      }}
    >
      <CardBody>
        <h4>
          Welcome to Translator’s private Diversity, Equity, & Inclusion chat service.
        </h4>
        <h4>
          By entering your information below and using this service you
          are agreeing to our
          {' '}
          <a href="/terms" target="_blank">Terms of Service</a>
          {' '}
          and our
          {' '}
          <a href="/privacy" target="_blank">Privacy Policy</a>
          .
        </h4>
        <br />
        <div className={classes.acceptTerms}>
          <Checkbox
            onChange={() => setAgreed(!agreed)}
          />
          <h4>
            I accept the
            <a href="/terms" target="_blank">Terms and Conditions</a>
          </h4>
        </div>
        {!isValidPhoneNumber ? <div style={{ color: 'red', fontSize: '12px' }}>Incorrect formatting. Example: 5555555555</div> : null}
        <CustomInput
          labelText="Phone Number..."
          id="phoneNumber"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            classes: {
              input: classes.resize,
            },
            endAdornment: (
              <InputAdornment position="end">
                <Phone className={classes.inputAdornmentIcon} />
              </InputAdornment>
            ),
            value: phoneNumber,
            onChange: (e) => {
              verifyPhoneNumber(e.currentTarget.value);
              setPhoneNumber(e.currentTarget.value);
            },
          }}
        />
      </CardBody>
      <CardFooter className={classes.justifyContentCenter}>
        <Button
          type="submit"
          block
          color={agreed ? 'darkGreen' : null}
          simple={agreed}
          size="lg"
          disabled={!agreed && isValidPhoneNumber}
        >
          Submit
        </Button>
      </CardFooter>
    </form>
  );
}

Form.propTypes = {
  signedUp: PropTypes.bool.isRequired,
  chatUserId: PropTypes.number.isRequired,
};

export default Form;
