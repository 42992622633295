import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
// core components
import Wizard from '../../../../components/Wizard/Wizard';
import GridContainer from '../../../../components/Grid/GridContainer';
import GridItem from '../../../../components/Grid/GridItem';

import Step1 from './Step1';
import Step2 from './Step2';
import { loadAuthToken } from '../../../../utils/auth';
import { AuthContext } from '../../../../Auth';

export default function WizardView(props) {
  const { user, setMessageState, setSnackBarMessage } = useContext(AuthContext);

  const createComfortModule = (state) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_MODULE_SERVICE}/comfort-meter`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
      data: {
        title: state.about.name,
        userId: user.id,
        description: state.about.description,
        statements: state.statements.selectedQuestions,
      },
    }).then(() => {
      setMessageState('success');
      setSnackBarMessage(`Successfully created ${state.about.name} comfort zone module.`);
      setTimeout(() => props.history.push('/admin/comfortMeters'), 500);
    }).catch((err) => {
      setMessageState('error');
      setSnackBarMessage(`${err.response.data.message}`);

      if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
        props.history.push('auth/login');
      }
    });
  };

  return (
    <GridContainer justifyContent="center">
      <GridItem xs={12} sm={8}>
        <Wizard
          validate
          steps={[
            { stepName: 'About', stepComponent: Step1, stepId: 'about' },
            { stepName: 'Statements', stepComponent: Step2, stepId: 'statements' },
          ]}
          title="New Comfort Zone Module"
          subtitle=""
          finishButtonClick={(state) => createComfortModule(state)}
          color="primary"
        />
      </GridItem>
    </GridContainer>
  );
}

WizardView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};
