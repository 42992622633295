/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import _ from 'lodash';
import styles from '../../../../assets/jss/material-dashboard-pro-react/views/reportPageStyle';

const useStyles = makeStyles(styles);

const SurveyTable = (props) => {
  const classes = useStyles();
  const { questions } = props;
  const [filtered, updateFiltered] = useState([]);

  useEffect(() => {
    questions.forEach((question) => {
      let filteredQ = {};
      const obj = _.countBy(question.answers);
      const arr = Object.keys(obj).map((key) => ({ key, value: obj[key] }));
      filteredQ = { question: question.question, answers: arr };
      updateFiltered((oldArray) => [...oldArray, filteredQ]);
    });
  }, [questions]);

  return (
    <>
      {filtered.map((row, index) => (
        <TableContainer component={Paper} key={index + 300000} className={classes.tableQuestion}>
          <Table aria-label="simple table" key={index}>
            <TableHead>
              <TableRow>
                <TableCell style={{ backgroundColor: 'lightgray' }}>{row.question ? row.question.question : 'question-unavailble'}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {row.answers.map((answer, i) => (
                <TableRow key={i}>
                  <TableCell component="th" scope="row" className={classes.questionTableRow}>
                    <p>{ (answer.key !== '') ? answer.key : ('Not Answered')}</p>
                    <p>{answer.value}</p>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ))}
    </>
  );
};

SurveyTable.propTypes = {
  questions: PropTypes.array.isRequired,
};

export default SurveyTable;
