import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { CSVLink } from 'react-csv';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {
  DataGrid,
} from '@material-ui/data-grid';
import CardFooter from '../../components/Card/CardFooter';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import styles from '../../assets/jss/material-dashboard-pro-react/views/reportPageStyle';
// import CompanyTable from './support/CompanyMaterialTable';

const useStyles = makeStyles(styles);

function AudienceUserTable(props) {
  const classes = useStyles();
  const [toggleCard, setToggleCard] = useState(false);

  const {
    csvHeaders, data, headers,
  } = props;

  return (
    <>
      <CardHeader className={classes.headerAlign}>
        <div className={classes.cardCategory}>
          Session User Report
        </div>
        <div>
          {toggleCard
            ? (
              <KeyboardArrowUpIcon
                onClick={() => setToggleCard(!toggleCard)}
                className={classes.clickable}
              />
            )
            : (
              <KeyboardArrowDownIcon
                onClick={() => setToggleCard(!toggleCard)}
                className={classes.clickable}
              />
            )}
        </div>
      </CardHeader>
      <Collapse in={toggleCard} unmountOnExit>

        <CardBody className={classes.reportCardBody} style={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={data}
            columns={headers}
          />

        </CardBody>
      </Collapse>

      <CardFooter stats>
        <div className={classes.stats}>
          <p className={classes.cardTitle}>
            Completed:
            {' '}
            {data.length}
          </p>
        </div>
        <CSVLink data={data} headers={csvHeaders}>Download Full User Report</CSVLink>

      </CardFooter>

    </>
  );
}

AudienceUserTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  csvHeaders: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  headers: PropTypes.arrayOf(PropTypes.object),
};

export default AudienceUserTable;
