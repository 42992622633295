/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';
// import theme from '../../../theme'
const buttonStyles = {
  padding: '16px 24px',
  // background: 'whitesmoke',
  cursor: 'pointer',
  border: 'none',
  borderRadius: '100%',
  backgroundColor: '#fff',
};

const Button = ({ children, onClick }) => (
  <Fab type="button" onClick={onClick} style={{ ...buttonStyles }}>
    {children}
  </Fab>
);

Button.propTypes = {
  children: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Button;
