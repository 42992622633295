import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { PulseLoader } from 'react-spinners';
import { makeStyles } from '@material-ui/core/styles';
import Card from '../../../../components/Card/Card';
import CardFooter from '../../../../components/Card/CardFooter';
import CardBody from '../../../../components/Card/CardBody';
import CardHeader from '../../../../components/Card/CardHeader';
import Button from '../../../../components/CustomButtons/Button';
import styles from '../../../../assets/jss/material-dashboard-pro-react/views/reportPageStyle';
import AutoHostQuestionsGrid from './AHQuestionsGrid';
import { AuthContext } from '../../../../Auth';
import { loadAuthToken } from '../../../../utils/auth';

import NewQuestionModal from './CreateNewDiscussionQuestion';

const useStyles = makeStyles(styles);

function AutoHostQuestionTable() {
  const classes = useStyles();
  const context = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isNewQuestionModalOpen, setIsNewQuestionModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(!isLoading);
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_MODULE_SERVICE}/slide-question?status=active`,
        headers: {
          Authorization: `bearer ${loadAuthToken()}`,
        },
      }).then((res) => {
        setData(res.data.data);
        setIsLoading(false);
        return res;
      })
        .catch((e) => {
          console.log(e);
          context.setMessageState('error');
          context.setSnackBarMessage(`${e.response.data.message}`);
          if (e.error === 'your token is not valid' || e.error === 'Your token is expired') {
            context.sendToLogin();
          }
        });
    };

    fetchData();
  }, [isNewQuestionModalOpen]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card style={{ minHeight: '80vh' }}>
      <CardHeader className={classes.headerAlign}>
        <div className={classes.header}>
          <NewQuestionModal
            togalModal={() => setIsNewQuestionModalOpen(!isNewQuestionModalOpen)}
            open={isNewQuestionModalOpen}
          />
          <h3>AUTO-HOST QUESTIONS</h3>
          <Button
            onClick={() => setIsNewQuestionModalOpen(!isNewQuestionModalOpen)}
          >
            Create New Question
          </Button>
        </div>

      </CardHeader>
      <CardBody className={classes.reportCardBody}>
        {isLoading ? (
          <div style={{ margin: 'auto' }}>
            <PulseLoader
              sizeUnit="px"
              size={15}
              color="#6CCA98"
              loading
            />
          </div>
        ) : <AutoHostQuestionsGrid data={data} />}
      </CardBody>

      <CardFooter stats>
        <div className={classes.stats}>
          <p className={classes.cardTitle}>
            Total:
            {data.length}
          </p>
        </div>
      </CardFooter>
    </Card>
  );
}

export default AutoHostQuestionTable;
