import React, { useContext } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
// core components
import Wizard from '../../../components/Wizard/Wizard';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';

import Step1 from './Wizard/Step1';
import Step2 from './Wizard/Step2';
import { loadAuthToken } from '../../../utils/auth';
import { AuthContext } from '../../../Auth';

export default function WizardView(props) {
  const { user, setMessageState, setSnackBarMessage } = useContext(AuthContext);
  const { history } = props;

  const createWordCloud = (state) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_MODULE_SERVICE}/word-cloud`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
      data: {
        title: state.about.name,
        description: state.about.description,
        userId: user.id,
        questions: state.questions.selectedQuestions,
      },
    }).then(() => {
      setMessageState('success');
      setSnackBarMessage(`Successfully created ${state.about.name} Word Cloud`);
      setTimeout(() => props.history.push('/admin/wordCloud'), 500);
    }).catch((err) => {
      setMessageState('error');
      setSnackBarMessage(`${err.response.data.message}`);
      if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
        history.push('auth/login');
      }
    });
  };

  return (
    <GridContainer justifyContent="center">
      <GridItem xs={12} sm={8}>
        <Wizard
          history={history}
          validate
          steps={[
            { stepName: 'About', stepComponent: Step1, stepId: 'about' },
            { stepName: 'Questions', stepComponent: Step2, stepId: 'questions' },
          ]}
          title="New Word Cloud"
          subtitle=""
          finishButtonClick={(state) => createWordCloud(state)}
          color="primary"
        />
      </GridItem>
    </GridContainer>
  );
}

WizardView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};
