/* eslint-disable react/forbid-prop-types */
import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Card from '../../../components/Card/Card';
import CardFooter from '../../../components/Card/CardFooter';
import CardBody from '../../../components/Card/CardBody';
import CardHeader from '../../../components/Card/CardHeader';
import styles from '../../../assets/jss/material-dashboard-pro-react/views/reportPageStyle';
import SessionsGrid from './SessionsGridNew';
import { AuthContext } from '../../../Auth';
import { loadAuthToken } from '../../../utils/auth';

const useStyles = makeStyles(styles);

function SessionsTable() {
  const classes = useStyles();
  const context = useContext(AuthContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_EVENT_SERVICE}/get-sessions-for-reports`,
        headers: {
          Authorization: `bearer ${loadAuthToken()}`,
        },
      }).then((res) => {
        setData(
          res.data.map((session) => ({
            title: session.title,
            id: session.id,
            event: session.event,
            completedAt: moment(session.completedAt).format('MMMM Do YYYY, h:mm:ss a'),
            sessionTable: true,
            pin: session.pin,
            type: session.type,
            started: session.started,
            company: session.company ? session.company : 'company unavailable',
            audienceUsers: session.audienceUsers,
          })),
        );
        return res;
      })
        .catch((e) => {
          console.log(e);
          context.setMessageState('error');
          context.setSnackBarMessage(`${e.response.data.message}`);
        });
    };

    fetchData();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card>
      <CardHeader className={classes.headerAlign}>
        <div className={classes.header}>
          <h3>Sessions</h3>
        </div>

      </CardHeader>
      <CardBody className={classes.reportCardBody}>
        <SessionsGrid data={data} />
      </CardBody>

      <CardFooter stats>
        <div className={classes.stats}>
          <p className={classes.cardTitle}>
            Total:
            {data.length}
          </p>
        </div>
      </CardFooter>
    </Card>
  );
}

export default SessionsTable;
