/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import Button from '../../../components/CustomButtons/Button';
import Card from '../../../components/Card/Card';
import CardBody from '../../../components/Card/CardBody';
import CardHeader from '../../../components/Card/CardHeader';
import styles from '../../../assets/jss/material-dashboard-pro-react/views/loginPageStyle';
import SurveyCompleted from './Survey/SurveyCompleted';

const useStyles = makeStyles(styles);

export default function KeyWordSurvey({
  sessionId,
  moduleTitle,
  questions,
  moduleId,
  isHosted,
}) {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [cardAnimaton, setCardAnimation] = useState('cardHidden');
  const [answers, setAnswers] = useState(questions.map((questionObj) => ({ questionId: questionObj.id, answer: '' })));
  // const [isIncorrect, setIncorrect] = React.useState(false);
  useEffect(() => {
    const audienceUserId = Number(window.localStorage.getItem('audience_user_id'));
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/key-word-survey-answer?audienceUserId=${audienceUserId}&sessionId=${sessionId}&moduleId=${moduleId}`,
    }).then((res) => setHasSubmitted(Boolean(res.data.data.length)))
      .catch((err) => {
        console.error(err);
      });
    setTimeout(() => {
      setCardAnimation('');
    }, 200);
  }, []);

  const submitAnswers = () => {
    const audienceUserId = Number(window.localStorage.getItem('audience_user_id'));
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/key-word-survey-answer`,
      data: answers.map((answerObj) => ({
        ...answerObj,
        moduleId,
        sessionId,
        audienceUserId,
      })),
    }).then(() => setHasSubmitted(true))
      .catch((err) => {
        console.error(err);
      });
  };

  const classes = useStyles();
  return (
    <>
      {hasSubmitted ? (
        <SurveyCompleted
          isHosted={isHosted}
          moduleTitle={moduleTitle}
        />
      ) : (
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={6} md={4}>
              <form
                autoComplete="off"
                onSubmit={async (e) => {
                  e.preventDefault();
                  submitAnswers();
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <h2>{moduleTitle}</h2>
                </div>
                {questions.map((questionObj, index) => (
                  <Card login key={questionObj.id} className={classes[cardAnimaton]} style={{ marginBottom: '50px' }}>
                    <CardHeader
                      className={`${classes.cardHeader}`}
                      color="primary"
                    >
                      <h4 className={classes.cardTitle}>{questionObj.question}</h4>
                    </CardHeader>
                    <CardBody>
                      <TextField
                        id="outlined-textarea"
                        value={answers[index].answer}
                        onChange={(e) => {
                          const newAnswers = [...answers];
                          newAnswers[index] = {
                            questionId: questionObj.id,
                            answer: e.target.value,
                          };
                          setAnswers(newAnswers);
                        }}
                        multiline
                        rows={4}
                        style={{ width: '100%' }}
                        variant="outlined"
                      />
                    </CardBody>
                  </Card>
                ))}
                <div style={{ marginBottom: '50px' }}>
                  <Button
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>

              </form>
            </GridItem>
          </GridContainer>
        </div>
      )}
    </>
  );
}

KeyWordSurvey.propTypes = {
  sessionId: PropTypes.number,
  questions: PropTypes.array.isRequired,
  moduleTitle: PropTypes.string.isRequired,
  moduleId: PropTypes.number.isRequired,
  isHosted: PropTypes.bool.isRequired,
};
