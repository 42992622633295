/* eslint-disable spaced-comment */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Carousel from 'react-material-ui-carousel';

import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import PieChart from '../survey/PieChart';

const useStyles = makeStyles({
  container: {
    margin: '5px 0',
  },
  chart: {
    padding: '20px 0',
  },
  title: {
    textAlign: 'center',
  },

});
const WalkOfPrivilegeDoughNut = ({ selfIdSplitData }) => {
  const classes = useStyles();
  const [questions, setQuestions] = useState([]);
  useEffect(() => {
    console.log(selfIdSplitData, 'broken data');
    if (selfIdSplitData.length) {
      setQuestions(selfIdSplitData[0].answers.map((a) => a.question));
    }
  }, [selfIdSplitData]);
  return (
    <div className={classes.container}>
      {questions.map((questionObj, index) => {
        if (questionObj.type === 'multipleChoice' || questionObj.type === 'slider' || questionObj.type === 'multiSelect') {
          return (
            <Accordion key={questionObj.question.id + 2000}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p>{questionObj.question}</p>
              </AccordionSummary>
              <AccordionDetails>

                <Carousel
                  autoPlay={false}
                  navButtonsAlwaysVisible
                  indicators={false}
                >
                  <div className={classes.chart}>
                    <h3 className={classes.title}>High Privilege Position</h3>
                    <PieChart
                      question={questionObj}
                      answers={selfIdSplitData[0].answers[index].answers}
                    />
                  </div>
                  <div className={classes.chart}>
                    <h3 className={classes.title}>Medium Privilege Position</h3>
                    <PieChart
                      question={questionObj}
                      answers={selfIdSplitData[1].answers[index].answers}
                    />
                  </div>
                  <div className={classes.chart}>
                    <h3 className={classes.title}>Low Privilege Position</h3>
                    <PieChart
                      question={questionObj}
                      answers={selfIdSplitData[2].answers[index].answers}
                    />
                  </div>
                </Carousel>

              </AccordionDetails>
            </Accordion>
          );
        }
      })}
    </div>
  );
};

WalkOfPrivilegeDoughNut.propTypes = {
  selfIdSplitData: PropTypes.array.isRequired,
};

export default WalkOfPrivilegeDoughNut;
