import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Chip from '@material-ui/core/Chip';
import logo from '../../../assets/img/logo.png';

const useStyles = makeStyles(() => ({
  root: {
    width: 375,
    margin: '5px',
  },
  media: {
    height: '355px',
    // paddingTop: '56.25%', // 16:9
  },
  actionArea: {
    overflow: 'scroll',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function RecipeReviewCard(props) {
  const classes = useStyles();
  const history = useHistory();

  const { resource } = props;

  const downloadTxtFile = () => {
    if (resource.link.includes('pdf')) {

    }
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={(
          <Avatar aria-label="recipe" className={classes.avatar}>
            <img src={logo} alt="logo" width="20" height="20" />
          </Avatar>
        )}
        action={(
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        )}
        title={resource.title}
        subheader={`Updated: ${new Date(Date.parse(resource.updatedAt)).toLocaleDateString('en-us', {
          weekday: 'long', year: 'numeric', month: 'short', day: 'numeric',
        })}`}

      />
      <CardActionArea
        onClick={(e) => {
          if (resource.link.includes('pdf')) {
            const element = document.createElement('a');
            element.href = resource.link;
            element.download = `100ideas-${Date.now()}.txt`;
            element.target = '_blank';
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
          } else {
            e.preventDefault();
            history.push(`/admin/resource/${resource.id}`);
          }
        }}
      >

        <CardMedia
          className={classes.media}
          image={resource.image ? resource.image : 'https://s3.amazonaws.com/join.host/translator-color-stacked.png'}
          title={resource.title}
        />
        <CardActions className={classes.actionArea}>
          {resource.tags.map((tag) => (<Chip label={tag} variant="outlined" />))}

        </CardActions>
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {resource.description}
          </Typography>
        </CardContent>
      </CardActionArea>

    </Card>
  );
}

RecipeReviewCard.propTypes = {
  // index of the default active collapse
  // eslint-disable-next-line react/forbid-prop-types
  resource: PropTypes.object.isRequired,
};
