/* eslint-disable react/static-property-placement */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import ReactTags from 'react-tag-autocomplete';
import axios from 'axios'
import PropTypes from 'prop-types';
import { loadAuthToken } from '../../../../utils/auth';
import { AuthContext } from '../../../../Auth';
import './tagStyleSheet.css';

class QuestionTags extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      suggestions: [],
    };
  }

  componentDidMount() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_MODULE_SERVICE}/tags`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).then((res) => {
      this.setState({ suggestions: res.data.data.map((tag) => ({ id: tag.id, name: tag.tag })) });
    })
      .catch((err) => {
        if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
          this.props.history.push('auth/login');
        }
      });
  }

  render() {
    return (
      <ReactTags
        tags={this.props.tags}
        suggestions={this.state.suggestions}
        handleDelete={(i) => this.props.handleDelete(i)}
        handleAddition={(tag) => this.props.handleAddition(tag)}
        allowNew
      />
    );
  }
}
QuestionTags.propTypes = {
  history: PropTypes.object,
};

export default QuestionTags;
