/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import Tooltip from '@material-ui/core/Tooltip';

// import emojiStrip from 'emoji-strip';
import Button from '../../../../components/CustomButtons/Button';
import styles from '../../../../assets/jss/material-dashboard-pro-react/views/wordPopcornStyle';

const useStyles = makeStyles(styles);

const WordPopcornQuestion = ({
  moduleId,
  sessionId,
  moduleData,
  setModuleCompleted,
}) => {
  const [input, setInput] = useState('');
  const [showSubmitted, setShowSubmitted] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(1);

  // const [answers, setAnswers] = useState(Number(window.localStorage.getItem(`word_popcorn_answers_${moduleData.id}`)));
  const [answers, setAnswers] = useState(0);
  const CHARACTER_LIMIT = 75;

  const audienceUserId = Number(window.localStorage.getItem('audience_user_id'));

  const classes = useStyles();
  useEffect(() => {
    setCurrentUserId(Number(window.localStorage.getItem('audience_user_id')));
    console.log('line 37', Number(window.localStorage.getItem('audience_user_id')));
  }, []);

  useEffect(() => {
    // setAnswers(Number(window.localStorage.getItem(`word_popcorn_answers_${moduleData.id}`)));
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/word-popcorn-answer?audienceUserId=${currentUserId}&moduleId=${moduleId}`,
    }).then((res) => {
      // console.log("audience res", res.data.data.length);
      setAnswers(res.data.data.length);
    });
  }, [moduleData, moduleId, audienceUserId, input]);

  const submitAnswer = async () => {
    if (input.length) {
      setAnswers(answers + 1);
      // window.localStorage.setItem(`word_popcorn_answers_${moduleData.id}`, Number(answers + 1));

      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_EVENT_SERVICE}/word-popcorn-answer`,
        data: {
          answer: input,
          question: moduleData.question,
          wordPopcornId: moduleData.id,
          moduleId,
          sessionId,
          audienceUserId: currentUserId > 1 ? currentUserId : 1,
        },
      }).then(() => {
        setInput('');
        setShowSubmitted(true);
        setTimeout(() => setShowSubmitted(false), 1500);
        if ((answers + 1) >= moduleData.answerLimit) {
          window.localStorage.setItem(`word_popcorn_completed_${moduleData.id}`, String(true));
          setModuleCompleted(true);
        }
      });
    }
  };

  const filterInput = (str) => {
    setInput(str.replace(/[^a-zA-Z0-9!@#$&()`.+,/"-^\w+(\s\w+)*$]/g, ''));
  };

  return (
    <div className={classes.subContainer}>
      {/* <h3 className={classes.textColor} style={{ marginBottom: '-35px' }}>{moduleData.title}</h3> */}

      <img src={moduleData.iconUrl} className={classes.image} alt="Word Pulse" />
      <h4
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: moduleData.question }}
        style={{ marginBottom: '-50px' }}
        className={classes.textColor}
      />
      <div className={classes.inputBox}>
        <form onSubmit={(e) => {
          e.preventDefault();
          submitAnswer();
        }}
        >
          <TextField
            margin="dense"
            label="Your Response"
            type="text"
            value={input}
            multiline
            maxRows={4}
            inputProps={{
              className: classes.textField,
              maxLength: CHARACTER_LIMIT,
            }}
            helperText={`${input.length}/${CHARACTER_LIMIT}`}
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            onChange={(e) => filterInput(e.target.value)}
          />
        </form>
      </div>

      {showSubmitted ? (
        <Button
          color="rose"
        >
          Thank you
          {'  '}
          <CheckCircleIcon style={{ marginLeft: '10px' }} fontSize="large" />
        </Button>
      ) : (
        <Button
          type="submit"
          color="lightGreen"
          onClick={() => {
            submitAnswer();
          }}
        >
          Submit
        </Button>
      )}

    </div>
  );
};

WordPopcornQuestion.propTypes = {
  moduleId: PropTypes.number.isRequired,
  sessionId: PropTypes.number.isRequired,
  moduleData: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  setModuleCompleted: PropTypes.func.isRequired,
  charLimit: PropTypes.number,
};

export default WordPopcornQuestion;
