/* eslint-disable react/forbid-prop-types */
import React from 'react';
import GaugeChart from 'react-gauge-chart';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../../../components/CustomButtons/Button';
import Card from '../../../../components/Card/Card';
import CardBody from '../../../../components/Card/CardBody';
import CardHeader from '../../../../components/Card/CardHeader';
import GridContainer from '../../../../components/Grid/GridContainer';
import GridItem from '../../../../components/Grid/GridItem';

const useStyles = makeStyles(() => ({
  backBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '40px',
  },
  needle: {
    display: 'none',
  },

}));

const SurveyResults = ({ percents, setViewingFalse }) => {
  const classes = useStyles();
  return (
    <GridContainer justifyContent="flex-end" style={{ padding: '20px' }}>
      {percents.map((percentObj, index) => (
        <GridItem xs={12} sm={6} md={4}>

          <Card style={{ height: '100%' }}>
            <CardHeader
              className={`${classes.cardHeader}`}
              color="primary"
            >
              <p>{percentObj.question}</p>
            </CardHeader>
            <CardBody>
              <GaugeChart
                id={`gauge-chart${index + 1}`}
                nrOfLevels={420}
                arcsLength={[percentObj.positive, percentObj.neutral, percentObj.negative]}
                colors={['#5BE12C', '#F5CD19', '#EA4228']}
                percent={(percentObj.negative + percentObj.positive / 3)}
                arcPadding={0.02}
                textColor="#000"
              />
            </CardBody>

          </Card>
        </GridItem>

      ))}

      <div className={classes.backBtn}>
        <Button
          onClick={() => setViewingFalse()}
          // color="lightGreen"
        >
          View Module
        </Button>
      </div>
    </GridContainer>
  );
};

SurveyResults.propTypes = {
  percents: PropTypes.array.isRequired,
  setViewingFalse: PropTypes.func.isRequired,
};

export default SurveyResults;
