/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { AuthContext } from '../../Auth';

const InviteTable = (props) => {
  const context = useContext(AuthContext);
  const [inviteData, setInviteData] = useState([]);
  const { sessionId } = props;
  useEffect(() => {
    if (sessionId) {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_EVENT_SERVICE}/invite-stats?sessionId=${sessionId}`,
      })
        .then((res) => {
          setInviteData(res.data);
        }).catch((err) => {
          context.setMessageState('error');
          context.setSnackBarMessage(`${err.response.data.message}`);
          if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
            context.sendToLogin();
          }
          console.error(err);
        });
    }
  }, [sessionId]);
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Total Invites</TableCell>
            <TableCell>Started</TableCell>
            <TableCell>Completed</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              {inviteData.invites}
            </TableCell>
            <TableCell component="th" scope="row">
              {inviteData.started}
            </TableCell>
            <TableCell component="th" scope="row">
              {inviteData.completed}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

  );
};

InviteTable.propTypes = {
  sessionId: PropTypes.number,
};

export default InviteTable;
