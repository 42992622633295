/* eslint-disable react/forbid-prop-types */
import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { PulseLoader } from 'react-spinners';
import { makeStyles } from '@material-ui/core/styles';
import Card from '../../../../components/Card/Card';
import CardFooter from '../../../../components/Card/CardFooter';
import CardBody from '../../../../components/Card/CardBody';
import CardHeader from '../../../../components/Card/CardHeader';
import Button from '../../../../components/CustomButtons/Button';
import styles from '../../../../assets/jss/material-dashboard-pro-react/views/reportPageStyle';
import SurveyQuestionGrid from './SurveyQuestionGridNew';
import NewQuestionModal from '../NewQuestionModal';
import { AuthContext } from '../../../../Auth';
import { loadAuthToken } from '../../../../utils/auth';

const useStyles = makeStyles(styles);

function SurveyQuestionTable() {
  const classes = useStyles();
  const context = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isNewQuestionModalOpen, setIsNewQuestionModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(!isLoading);
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_MODULE_SERVICE}/survey-question?status=active`,
        headers: {
          Authorization: loadAuthToken(),
        },
      }).then((res) => {
        if (res.data.error === 'your token is not valid' || res.data.error === 'Your token is expired') {
          context.sendToLogin();
        } else {
          setData(res.data.data.map((surveyQuestion) => ({
            question: surveyQuestion.question,
            type: surveyQuestion.type,
            options: surveyQuestion.json_options.options ? surveyQuestion.json_options.options.map((options) => options.option) : '',
            id: surveyQuestion.id,
          })));
        }
        setIsLoading(false);
        return res;
      })
        .catch((e) => {
          console.log(e);
          context.setMessageState('error');
          context.setSnackBarMessage(`${e.response.data.message}`);
        });
    };

    fetchData();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <NewQuestionModal
        togalModal={() => setIsNewQuestionModalOpen(!isNewQuestionModalOpen)}
        open={isNewQuestionModalOpen}
      />
      <Card>
        <CardHeader className={classes.headerAlign}>
          <div className={classes.header}>
            <h3>Survey Questions</h3>
            <Button
              onClick={() => setIsNewQuestionModalOpen(!isNewQuestionModalOpen)}
            >
              Create New Question
            </Button>
          </div>

        </CardHeader>
        <CardBody className={classes.reportCardBody}>
          {isLoading ? (
            <PulseLoader
              sizeUnit="px"
              size={15}
              color="#6CCA98"
              loading
            />
          ) : <SurveyQuestionGrid data={data} />}
        </CardBody>

        <CardFooter stats>
          <div className={classes.stats}>
            <p className={classes.cardTitle}>
              Total:
              {data.length}
            </p>
          </div>
        </CardFooter>
      </Card>
    </>
  );
}

export default SurveyQuestionTable;
