/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';

// @material-ui/icons
import Email from '@material-ui/icons/Email';
// import LockOutline from "@material-ui/icons/LockOutline";
import PropTypes from 'prop-types';
// core components
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import CustomInput from '../../components/CustomInput/CustomInput';
import Button from '../../components/CustomButtons/Button';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import CardFooter from '../../components/Card/CardFooter';
// import { saveAuthToken } from '../../utils/auth';
import styles from '../../assets/jss/material-dashboard-pro-react/views/loginPageStyle';
import { AuthContext } from '../../Auth';

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  // const [isIncorrect, setIncorrect] = React.useState(false);
  setTimeout(() => {
    setCardAnimation('');
  }, 700);
  const { login } = useContext(AuthContext);
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form
            autoComplete="off"
            onSubmit={async (e) => {
              e.preventDefault();
              login(email, password)
                .then(() => {
                  props.history.push('/admin/sessions');
                });
            }}
          >
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="primary"
              >
                <h3 className={classes.cardTitle}>Log in</h3>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Email..."
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    classes: {
                      input: classes.resize,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    value: email,
                    onChange: (e) => {
                      setEmail(e.currentTarget.value);
                    },
                  }}
                />
                <CustomInput
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  value={password}
                  onChange={(e) => setPassword(e.currentTarget.value)}
                  inputProps={{
                    classes: {
                      input: classes.resize,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    value: password,
                    onChange: (e) => setPassword(e.currentTarget.value),
                    type: 'password',
                    autoComplete: 'off',
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button type="submit" color="darkGreen" simple size="lg" block>
                  Submit
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}

LoginPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object,
};
