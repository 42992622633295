/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import WalkOfPrivilege from './wop/WopReport';
import Survey from './survey/SurveyReport';
import Comfort from './comfort/ComfortReport';
import WordCloud from './wordCloud/WordCloudReport';
import Poll from './poll/PollReport';
import WordPopcorn from './wordPopcorn/WordPopcornReport';

const index = (props) => {
  const {
    sessionId, module, companyId,
  } = props;
  const { type, moduleType, eventId } = props.module;
  switch (type || moduleType) {
    case 'walkOfPrivilege': {
      return (
        <WalkOfPrivilege
          moduleInfo={module}
          eventId={eventId}
          companyId={companyId}
          sessionId={sessionId}
        />
      );
    }
    case 'survey': {
      return (
        <Survey
          moduleInfo={module}
          companyId={companyId}
          sessionId={sessionId}
        />
      );
    }
    case 'comfort': {
      return (
        <Comfort
          moduleInfo={module}
          companyId={companyId}
          sessionId={sessionId}
        />
      );
    }
    case 'wordCloud': {
      return (
        <WordCloud
          moduleInfo={module}
          companyId={companyId}
          sessionId={sessionId}
        />
      );
    }
    case 'poll': {
      return (
        <Poll
          moduleInfo={module}
          companyId={companyId}
          sessionId={sessionId}
        />
      );
    }
    case 'wordPopcorn': {
      return (
        <WordPopcorn
          moduleInfo={module}
          companyId={companyId}
          sessionId={sessionId}
        />
      );
    }
    default: {
      return <div />;
    }
  }
};

index.propTypes = {
  module: PropTypes.object.isRequired,
  sessionId: PropTypes.number,
  companyId: PropTypes.number,
};

export default index;
