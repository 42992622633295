import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CountUp from 'react-countup';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import { loadAuthToken } from '../../../../utils/auth';

const useStyles = makeStyles({
  outerDiv: {
    margin: 'auto',
    textAlign: 'center',
  },
  imageDiv: {
    // paddingTop: '1rem',
    paddingBottom: '2rem',
  },
  contentDiv: {
    paddingTop: '2.5rem',
  },
  text: {
    fontSize: '1.4rem',
  },
  largeText: {
    fontSize: '1.6rem',
  },
  messageIcon: {
    color: '#6CCA98',
    height: '100px',
    width: '100px',
  },
  completions: {
    fontSize: '1.4rem',
    marginTop: '40px',
  },
  image: {
    width: '200px',
    marginBottom: '5px',
  },
  number: {
    color: '#60A681',
    fontSize: 30,
  },
});

function WordCloudWelcome({ sessionId, moduleId, channel }) {
  const [startingAnswerCount, setStartingAnswerCount] = useState([]);
  const [answerCount, setAnswerCount] = useState([]);
  const [initialWordCount, setInitialWordCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(initialWordCount);
  const [wordCloudQs, setWordCloudQs] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    const binds = [];
    channel.bind(`cloud_completion_${sessionId}_${moduleId}`, (completed) => {
      setCompletedCount(completed);
    });
    wordCloudQs.forEach((question, index) => {
      binds.push(`cloud_question_${question.id}_${sessionId}_${moduleId}`);
      channel.bind(`cloud_question_${question.id}_${sessionId}_${moduleId}`, (count) => {
        const answerCountCopy = [...answerCount];
        const startingAnswerCountCopy = [...startingAnswerCount];
        startingAnswerCountCopy[index] = Number(answerCountCopy[index]);
        answerCountCopy[index] = count;
        setStartingAnswerCount(startingAnswerCountCopy);
        setAnswerCount(answerCountCopy);
      });
    });
    return () => {
      binds.forEach((bind) => {
        channel.unbind(bind);
      });
      channel.unbind(`cloud_completion_${sessionId}_${moduleId}`);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wordCloudQs, answerCount]);

  useEffect(() => {
    const array = [...wordCloudQs.map((q) => q.count)];
    setAnswerCount([...array]);
    setStartingAnswerCount([...array]);
    setCompletedCount(initialWordCount);
  }, [initialWordCount, wordCloudQs]);

  useEffect(() => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/get-host-word-cloud-data?moduleId=${moduleId}&sessionId=${sessionId}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).then((_res) => {
      setWordCloudQs(_res.data.questions);
      setInitialWordCount(_res.data.totalCompleted);
    })
      .catch((e) => console.log(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {
          wordCloudQs.map((question, index) => (
            <React.Fragment key={question.id + 2500}>
              <p className={classes.text} style={{ margin: '-10px 15px' }}>
                {' '}
                {answerCount[index] ? <CountUp start={startingAnswerCount[index]} end={answerCount[index]} className={classes.number} /> : <span className={classes.number}>0</span> }
                <br />
                {question.description}
              </p>
              {index + 1 !== wordCloudQs.length ? <Divider flexItem orientation="vertical" /> : null}
            </React.Fragment>
          ))
        }
      </div>
      <p className={classes.completions}>
        <span className={classes.number}>{completedCount}</span>
        <br />
        Completions
      </p>
    </div>
  );
}

WordCloudWelcome.propTypes = {
  sessionId: PropTypes.number.isRequired,
  moduleId: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  channel: PropTypes.object.isRequired,
};

export default WordCloudWelcome;
