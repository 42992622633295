import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// import DialogContentText from '@material-ui/core/DialogContentText';
import {
  List, DialogTitle, DialogContent, DialogActions, Dialog,
} from '@material-ui/core';
import Button from '../../../../components/CustomButtons/Button';
// import { AuthContext } from '../../Auth';
// import { loadAuthToken } from '../../utils/auth';

function PollQuestionsModal(props) {
  const { toggleModal, questions, open } = props;
  const questionsInOrder = _.orderBy(questions.poll_question_joins, ['order'], ['asc']);
  
  return (
    <div>
      <Dialog
        maxWidth="md"
        open={open}
        onClose={() => toggleModal()}
        togg
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: '100%',
            minHeight: '60%',
          },
        }}
      >
        <DialogTitle id="form-dialog-title">
          {'Title: '}
          {questions.title}
          <br />
          {'Description: '}
          {questions.description}
        </DialogTitle>
        <DialogContent>
          <List>
            {questions.poll_question_joins ? questionsInOrder.map((q) => (
              <ul key={q.order}>
                <li>
                  {q.poll_question.type}
                  {' '}
                  :
                  {' '}
                  {q.poll_question.question}
                </li>
                {q.poll_question.options.options.map((option) => (
                  <ul key={option.option}>
                    <li>{option.option}</li>
                  </ul>
                ))}
              </ul>
            )) : ' '}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggleModal()} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

PollQuestionsModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  questions: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default PollQuestionsModal;
