/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/core/styles';
// core components
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Button } from '@material-ui/core';
import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';
import { loadAuthToken } from '../../../utils/auth';

// const useStyles = makeStyles(styles);

function HeaderDropdowns(props) {
  // const classes = useStyles();
  // const context = useContext(AuthContext);

  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [sessions, setSessions] = useState([]);
  const [selectedSessions, setSelectedSessions] = useState([]);
  const [selectedSessionIds, setSelectedSessionIds] = useState([]);

  const { setSessionIds } = props;

  useEffect(() => {
    const fetchData = async () => {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_AUTH_SERVICE}/companies`,
        headers: {
          Authorization: `bearer ${loadAuthToken()}`,
        },
      }).then((res) => {
        // console.log('useEffect in HeaderDropdowns');
        setCompanies(res.data.data);
      });
    };
    fetchData();
  }, []);

  const setCompany = (event) => {
    setSelectedCompany(event.target.value);

    axios({
      method: 'get',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/event?companyId=${event.target.value.id}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).then((res) => {
      // console.log(res)
      // console.log('setCompany');
      setEvents(res.data);
    });
  };

  const setEvent = (event) => {
    setSelectedEvent(event.target.value);

    axios({
      method: 'get',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/session?eventId=${event.target.value.id}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).then((res) => {
      // console.log('setEvent');
      setSessions(res.data);
    });
  };

  const setselectedSessions = (event) => {
    // setSelectedEvent(event.target.value);

    setSelectedSessions(() => [...selectedSessions, event.target.value]);
    setSelectedSessionIds(() => [...selectedSessionIds, event.target.value.id]);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={3}>
          <FormControl>
            <InputLabel id="company-select-label">Select Company</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedCompany}
              onChange={setCompany}
            >
              {companies.map((company) => (
                <MenuItem value={company} key={company.id}>{company.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={3}>
          <FormControl>
            <InputLabel id="events-select-label">Select Event</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedEvent}
              onChange={setEvent}
            >
              {events.map((event) => (
                <MenuItem value={event} key={event.id}>{event.title}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={3}>
          <FormControl>
            <InputLabel id="sessions-select-label">Select Sessions</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedSessions}
              onChange={setselectedSessions}
            >
              {sessions.map((session) => (
                <MenuItem value={session} key={session.id}>{session.pin}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={3}>
          <Button onClick={() => setSessionIds(selectedSessionIds)} style={{ paddingLeft: '10px' }}>Run Report </Button>

          {selectedSessions.map((session) => (
            <div value={session} key={session.id}>{session.pin}</div>
          ))}

        </GridItem>
      </GridContainer>
    </>
  );
}

HeaderDropdowns.propTypes = {
  setSessionIds: PropTypes.func,
};

export default HeaderDropdowns;
