/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
// import { makeStyles } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/icons
import SettingsIcon from '@material-ui/icons/Settings';
import Face from '@material-ui/icons/Face';
import LockIcon from '@material-ui/icons/Lock';

// core components
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import Card from '../../../components/Card/Card';
import CardHeader from '../../../components/Card/CardHeader';
import CardBody from '../../../components/Card/CardBody';
import Accordion from '../../../components/Accordion/Accordion';

import { cardTitle } from '../../../assets/jss/material-dashboard-pro-react';

import InfoForm from './InfoForm';
import PasswordForm from './PasswordForm';

const styles = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: '#000',
    textDecoration: 'none',
    textAlign: 'center',
  },
  cardCategory: {
    margin: '0',
    color: '#999999',
  },
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center',
  },
  inputAdornmentIcon: {
    color: '#555',
  },
  inputAdornment: {
    position: 'relative',
  },
};

// const useStyles = makeStyles(styles);

function SettingsAccordian(props) {
  const { classes, history } = props;
  return (
    <GridContainer justifyContent="center">
      <GridItem xs={12} sm={12} md={10}>
        <Card>
          <CardHeader>
            <h4 className={classes.cardTitle}>
              <SettingsIcon />
              {' '}
              User Settings
            </h4>

          </CardHeader>
          <CardBody>
            <Accordion
              active={-1}
              collapses={[
                {
                  title:
  <div style={{ fontSize: '20px' }}>
    <Face />
    {' '}
    Edit Your User Information:
  </div>,
                  content: <InfoForm history={history} />,
                },
                {
                  title:
  <div style={{ fontSize: '20px' }}>
    <LockIcon />
    {' '}
    Edit Your Password:
  </div>,
                  content: <PasswordForm history={history} />,
                },
              ]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

SettingsAccordian.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withStyles(styles)(SettingsAccordian);
