const customListStyle = {

  selected: {
    height: '20vh',
    overflow: 'auto',
    border: '1px solid #ccc!important',
    borderRadius: '5px',
    padding: '0px',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
  },
  list: {
    listStyle: 'none',
    padding: '0',

  },

  listItem: {
    background: 'lightgray',
    margin: '1px',
    height: '100%',
    padding: '5px',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default customListStyle;
