import React from 'react';
import { PulseLoader } from 'react-spinners';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Card from '../../../../components/Card/Card';
import CardFooter from '../../../../components/Card/CardFooter';
import CardBody from '../../../../components/Card/CardBody';
import CardHeader from '../../../../components/Card/CardHeader';
import styles from '../../../../assets/jss/material-dashboard-pro-react/views/reportPageStyle';
import WordCloudWordGrid from './WordCloudWordGrid';

const useStyles = makeStyles(styles);

function WordCloudTagTable(props) {
  const classes = useStyles();
  const {
    data, isLoading, wordTags, tagWord,
  } = props;

  return (
    <Card style={{ minHeight: '80vh' }}>
      <CardHeader className={classes.headerAlign}>
        <div className={classes.header}>
          <h4>Untagged Word Cloud Words</h4>
        </div>
      </CardHeader>
      <CardBody className={classes.reportCardBody}>
        {isLoading ? (
          <div style={{ margin: 'auto' }}>
            <PulseLoader
              sizeUnit="px"
              size={15}
              color="#6CCA98"
              loading
            />
          </div>
        ) : <WordCloudWordGrid data={data} wordTags={wordTags} tagWord={tagWord} />}
      </CardBody>

      <CardFooter stats>
        <div className={classes.stats}>
          <p className={classes.cardTitle}>
            Total:
            {data.length}
          </p>
        </div>
      </CardFooter>
    </Card>
  );
}

WordCloudTagTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  wordTags: PropTypes.arrayOf(PropTypes.object),
  tagWord: PropTypes.func,
};

export default WordCloudTagTable;
