import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  DataGrid,
} from '@material-ui/data-grid';

export default function WordCloudReportGrid(props) {
  const {
    data,
  } = props;

  const headers = [
    {
      headerName: 'User', field: 'audienceUserId', width: 150, disableClickEventBubbling: true,
    },
    {
      headerName: 'Question Type', field: 'type', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Response', field: 'answer', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Word Cloud Tag', field: 'wordCloudTag', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Word Cloud Tag Id', field: 'wordCloudTagId', width: 200, disableClickEventBubbling: true,
    },
  ];

  // useEffect(() => {
  //   setNewHeaders(headers);
  // }, [data, headers]);

  return (
    <div style={{ height: 600, width: '100%' }}>
      <DataGrid
        rows={data}
        columns={headers}
        sortingOrder={['asc', 'desc']}
        sortModel={[
          {
            field: 'audienceUserId',
            sort: 'asc',
          },
        ]}
      />
    </div>
  );
}

WordCloudReportGrid.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object),
};
