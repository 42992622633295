import React from 'react';
import PropTypes from 'prop-types';
import Joyride from 'react-joyride';

const ComfortTutorial = (props) => (
  <>
    <Joyride
      continuous
      styles={{
        options: {
          padding: '60px',
          primaryColor: '#60A681',
          textColor: '#004a14',
        },
        buttonClose: {
          display: 'none',
        },
      }}
      locale={{
        back: 'Back', close: 'Close', last: 'Start!', next: 'Next', skip: 'Skip',
      }}
      callback={(step) => {
        if (step.action === 'reset') {
          window.localStorage.setItem('comfort_tutorial_completed', 'true');
          props.startComfort();
        }
      }}
      steps={[
        {
          target: 'body',
          placement: 'center',
          content: (
            <>
              <p style={{ fontSize: '20px' }}>
                Let’s get orientated to the platform!
              </p>
            </>
          ),
          disableBeacon: true,
          // disableScrolling: true,
          floaterProps: {
            disableAnimation: true,
          },
        },
        {
          target: '#question',
          placement: 'top',
          content: (
            <>
              <p style={{ fontSize: '20px' }}>
                Please read and consider each statement.
              </p>
            </>
          ),
          disableBeacon: true,
          // disableScrolling: true,
          floaterProps: {
            disableAnimation: true,
          },
        },
        {
          target: '#slider',
          placement: 'top',
          content: (
            <>
              <p style={{ fontSize: '20px' }}>
                Slide the marker left or right to indicate your level of comfort in that situation.
              </p>
            </>
          ),
          disableBeacon: true,
          // disableScrolling: true,
          floaterProps: {
            disableAnimation: true,
          },
        },
        {
          target: 'body',
          placement: 'center',
          content: (
            <>
              <h3>Your choices are:</h3>
              <ol style={{ textAlign: 'left' }}>
                <li style={{ fontSize: '18px' }}>Not Comfortable At All</li>
                <li style={{ fontSize: '18px' }}>Slightly Uncomfortable</li>
                <li style={{ fontSize: '18px' }}>Fairly Comfortable</li>
                <li style={{ fontSize: '18px' }}>Completely Comfortable</li>
              </ol>
            </>
          ),
          disableBeacon: true,
          // disableScrolling: true,
          floaterProps: {
            disableAnimation: true,
          },
        },
        {
          target: 'body',
          placement: 'center',
          content: (
            <>
              <p style={{ fontSize: '20px' }}>
                There isn’t a “skip” or “go back” option so answer each question carefully…
              </p>
            </>
          ),
          disableBeacon: true,
          // disableScrolling: true,
          floaterProps: {
            disableAnimation: true,
          },
        },
        {
          target: 'body',
          placement: 'center',
          content: (
            <>
              <p style={{ fontSize: '20px' }}>
                But don’t overthink it (answer what first comes to mind).
              </p>
            </>
          ),
          disableBeacon: true,
          // disableScrolling: true,
          floaterProps: {
            disableAnimation: true,
          },
        },
        {
          target: '#nextButton',
          placement: 'top',

          content: (
            <>
              <p style={{ fontSize: '20px' }}>
                Click the “next”  button to submit your answer.
              </p>
            </>
          ),
          disableBeacon: true,
          // disableScrolling: true,
          floaterProps: {
            disableAnimation: true,
          },
        },
        {
          target: 'body',
          placement: 'center',

          content: (
            <>
              <p style={{ fontSize: '20px' }}>
                Ready to get started?
              </p>
            </>
          ),
          disableBeacon: true,
          // disableScrolling: true,
          floaterProps: {
            disableAnimation: true,
          },
        },
      ]}
    />
  </>
);

ComfortTutorial.propTypes = {
  startComfort: PropTypes.func.isRequired,
};

export default ComfortTutorial;
