/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import { PulseLoader } from 'react-spinners';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../../../components/CustomButtons/Button';
import Comment from '../../../../assets/img/comment.png';

import translations from '../translations';

const useStyles = makeStyles({
  outerDiv: {
    textAlign: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  amazingWork: {
    fontSize: '22px',
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  largeText: {
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    fontSize: '22px',
  },
  messageIcon: {
    color: '#6CCA98',
    height: '100px',
    width: '100px',
  },
  image: {
    width: '160px',
    marginBottom: '5px',
  },
});
const SurveyCompleted = (props) => {
  const classes = useStyles();
  const {
    moduleTitle, isHosted, nextModule, lang,
  } = props;
  return (
    <div className={classes.outerDiv}>
      <div>
        <img src={Comment} className={classes.image} alt="Survey" />
      </div>

      <p className={classes.largeText}>
        {translations[lang].thankYouModuleSub}
      </p>
      {isHosted ? (
        <div className={classes.center}>
          <p className={classes.largeText}>
            {translations[lang].pleaseWait}
          </p>
          <PulseLoader
            sizeUnit="px"
            size={15}
            color="#60A681"
            loading
          />
        </div>
      ) : (
        <div>
          <Button
            type="submit"
            color="lightGreen"
            onClick={() => nextModule()}
          >
            Next Module
          </Button>
        </div>
      )}
    </div>
  );
};

SurveyCompleted.propTypes = {
  moduleTitle: PropTypes.string.isRequired,
  isHosted: PropTypes.bool.isRequired,
  nextModule: PropTypes.func,
};

export default SurveyCompleted;
