import React from 'react';
import PropTypes from 'prop-types';

import {
  DataGrid,
} from '@material-ui/data-grid';

export default function WordCloudReportGrid(props) {
  const {
    answers,
  } = props;

  const headers = [
    {
      headerName: 'Answer', field: 'answer', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Frequency', field: 'freq', width: 250, disableClickEventBubbling: true,
    },
  ];

  return (
    <DataGrid
      rows={answers}
      columns={headers}
      sortingOrder={['asc', 'desc']}
      sortModel={[
        {
          field: 'answer',
          sort: 'asc',
        },
      ]}
    />
  );
}

WordCloudReportGrid.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.object),
};
