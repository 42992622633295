/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Button from '../../../../components/CustomButtons/Button';
import WordCloudScreen from './WordCloudScreen';
import WordCloudWelcome from './WordCloudWelcome';


const useStyles = makeStyles({
  outerDiv: {
    margin: 'auto',
    textAlign: 'center',
  },
  imageDiv: {
    // paddingTop: '1rem',
    paddingBottom: '2rem',
  },
  contentDiv: {
    paddingTop: '2.5rem',
  },
  text: {
    fontSize: '1.4rem',
  },
  largeText: {
    fontSize: '1.6rem',
  },
  messageIcon: {
    color: '#6CCA98',
    height: '100px',
    width: '100px',
  },
  completions: {
    fontSize: '1.4rem',
    marginTop: '50px',
  },
  image: {
    width: '200px',
    marginBottom: '5px',
  },
});

const WordCloud = ({
  moduleTitle,
  wordCloudQuestions,
  sessionId,
  moduleId,
  wordCloudIcon,
  channel,
  isDemo,
}) => {
  const [viewingResults, setViewingResults] = useState(false);
  const [resultIndex, setResultIndex] = useState(0);
  const [currentResultQuestion, setCurrentResultQuestion] = useState({});

  const startViewingResults = () => {
    setViewingResults(true);
    setCurrentResultQuestion(wordCloudQuestions[resultIndex]);
    setResultIndex(0);
  };

  const nextResult = () => {
    if (wordCloudQuestions.length - 1 <= resultIndex) {
      setViewingResults(false);
      setResultIndex(0);
      setCurrentResultQuestion({});
    } else {
      setCurrentResultQuestion(wordCloudQuestions[resultIndex + 1]);
      setResultIndex(resultIndex + 1);
    }
  };

  const classes = useStyles();
  if (viewingResults) {
    return (
      <div className={classes.outerDiv}>
        <WordCloudScreen
          moduleTitle={moduleTitle}
          sessionId={sessionId}
          moduleId={moduleId}
          question={currentResultQuestion}
          isDemo={isDemo}
          nextResult={() => nextResult()}
        />
      </div>
    );
  }
  return (
    <div className={classes.outerDiv}>
      <div className={classes.imageDiv}>
        {wordCloudIcon ? (
          <img src={wordCloudIcon} className={classes.image} alt="Stereotype Mask" />
        ) : (
          <img src="https://s3.amazonaws.com/join.host/stereotypeMasks-sample.png" className={classes.image} alt="Stereotype Mask" />
        )}
      </div>
      <Divider />
      <div className={classes.contentDiv}>
        <p className={classes.largeText}><strong>{moduleTitle}</strong></p>
        <br />
        <p className={classes.text}>
          Thank you for your participation today!
        </p>
        <br />
        <p className={classes.text}>
          Please take your time and think about each word carefully!
        </p>
        <br />
        <WordCloudWelcome
          sessionId={sessionId}
          moduleId={moduleId}
          channel={channel}
        />
        <Button
          width="100%"
          onClick={() => startViewingResults()}
          align="flex-end"
          style={{ right: '5vw', bottom: '7vh', position: 'absolute' }}
        >
          Results
        </Button>
      </div>
    </div>
  );
};

WordCloud.propTypes = {
  moduleTitle: PropTypes.string.isRequired,
  sessionId: PropTypes.number.isRequired,
  moduleId: PropTypes.number.isRequired,
  wordCloudQuestions: PropTypes.array.isRequired,
  channel: PropTypes.object.isRequired,
  wordCloudIcon: PropTypes.string,
  isDemo: PropTypes.bool.isRequired,
};

export default WordCloud;
