import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Accordion, AccordionSummary, Typography, AccordionDetails, IconButton,
} from '@material-ui/core';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '../../../components/CustomButtons/Button';
import { AuthContext } from '../../../Auth';
import { loadAuthToken } from '../../../utils/auth';

import AddGroupsForm from './trainingGroupForm';

const UserInfoModal = ({
  open, toggleModal, user, history, data,
}) => {
  const [trainingCompanies, setTrainingCompanies] = useState([]);

  const {
    setMessageState, setSnackBarMessage,
  } = useContext(AuthContext);

  useEffect(() => {
    if (user.training_groups_users) {
      setTrainingCompanies(user.training_groups_users);
    }
  }, [user]);

  const fetchData = async () => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_AUTH_SERVICE}/training-groups-users?userId=${user.id}`,
      headers: {
        Authorization: loadAuthToken(),
      },
    }).then((res) => {
      setTrainingCompanies(res.data.data);
      return res;
    })
      .catch((e) => {
        console.log(e);
        setMessageState('error');
        setSnackBarMessage(`${e.response.data.message}`);
      });
  };

  const removeGroup = (group) => {
    axios({
      method: 'delete',
      url: `${process.env.REACT_APP_AUTH_SERVICE}/training-groups-users/${group.id}`,
      headers: {
        Authorization: loadAuthToken(),
      },
    }).then(() => {
      setMessageState('success');
      setSnackBarMessage('Successfully Removed Group');
      fetchData();
    })
      .catch((e) => {
        console.log(e);
        setMessageState('error');
        setSnackBarMessage(`${e.response.data.message}`);
      });
  };  
  
  const loginAsUser = () => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_AUTH_SERVICE}/login-as/${user.id}`,
      headers: {
        Authorization: loadAuthToken(),
      },
    }).then((res) => {
      setMessageState('success');
      setSnackBarMessage('Successfully switched users');
      localStorage.setItem('feathers-jwt', res.data.accessToken);
      history.push('admin/dashboard');
      window.location.reload()
      // fetchData();
    })
      .catch((e) => {
        console.log(e);
        setMessageState('error');
        setSnackBarMessage(`${e.response.data.message}`);
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => toggleModal()}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: '100%',
            minHeight: '60%',
          },
        }}
      >
        <DialogTitle id="form-dialog-title">{`${user.firstName}'s training groups`}</DialogTitle>
        <DialogContent>
          <div>
            {trainingCompanies.length ? trainingCompanies.map((group) => (
              <div key={group.id} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Accordion key={group.trainingGroupId} style={{ width: '95%', marginBottom: '10px' }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>
                      {group.training_group.name}
                    </Typography>
                  </AccordionSummary>
                  <h6>{`Role: ${group.role}`}</h6>
                  <h6>Companies:</h6>
                  {group.training_group.companies.map((company) => (
                    <AccordionDetails key={company.name}>
                      <Typography>
                        {company.name}
                      </Typography>
                    </AccordionDetails>
                  ))}

                </Accordion>

                <IconButton color="secondary" aria-label="upload picture" component="span" onClick={() => removeGroup(group)}>
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </div>
            )) : (<div>{`no training groups listed for ${user.firstName}`}</div>)}
          </div>
          <div>Add Traning Group:</div>
          <AddGroupsForm
            userId={user.id}
            fetchData={fetchData}
            trainingCompanies={trainingCompanies}
          />

        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <Button onClick={() => loginAsUser()} color="warning">
            Log in As User
          </Button>
          <Button onClick={() => toggleModal()} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

UserInfoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  history: PropTypes.object,
  user: PropTypes.object.isRequired,
};

export default UserInfoModal;
