import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  DataGrid,
} from '@material-ui/data-grid';

// import Button from '@material-ui/core/Button';
import Button from '../../components/CustomButtons/Button';
import EditCompanyModal from './EditCompanyModal';

export default function CompanyGrid(props) {
  const [newHeaders, setNewHeaders] = useState([]);
  const [companyToEdit, setCompanyToEdit] = useState({});
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const {
    data,
  } = props;

  const headers = [
    {
      headerName: 'Name', field: 'name', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Contact Name', field: 'contactName', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Contact Email', field: 'contactEmail', width: 250, disableClickEventBubbling: true,
    },

  ];

  useEffect(() => {
    const headersWithAddedButton = [
      {
        headerName: 'Edit Company',
        field: 'id',
        width: 200,
        disableClickEventBubbling: true,
        renderCell: (company) => (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                setCompanyToEdit(company.row);
                setIsEditModalOpen(!isEditModalOpen);
              }}
            >
              Edit
            </Button>
          </div>
        ),
      },
      ...headers];

    setNewHeaders(headersWithAddedButton);
  }, [data]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ height: 600, width: '100%' }}>
      <EditCompanyModal
        open={isEditModalOpen}
        company={companyToEdit}
        togalModal={() => {
          setIsEditModalOpen(!isEditModalOpen);
        }}
      />
      <DataGrid
        rows={data}
        columns={newHeaders}
        sortingOrder={['asc', 'desc']}
        sortModel={[
          {
            field: 'name',
            sort: 'asc',
          },
        ]}
      />
    </div>
  );
}

CompanyGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};
