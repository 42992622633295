/* eslint-disable max-len */
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  DataGrid,
} from '@material-ui/data-grid';

import { AuthContext } from '../../../../Auth';
import { loadAuthToken } from '../../../../utils/auth';

import Button from '../../../../components/CustomButtons/Button';
import Dialog from '../../../../components/Alert/alert';

import EditStatementModal from '../EditStatementModal';
import NewStatementModal from '../NewStatementModal';

export default function WOPGrid(props) {
  const [newHeaders, setNewHeaders] = useState([]);
  const [statementToEdit, setStatementToEdit] = useState({});
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isNewStatementModalOpen, setIsNewStatementModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  
  const {
    data, fetchData,
  } = props;

  const context = useContext(AuthContext);

  const headers = [
    {
      headerName: 'Statement', field: 'statement', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Image', field: 'image', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Options', field: 'options', width: 250, disableClickEventBubbling: true,
    },
  ];

// taken from ComfortMeterStatementTable.js 
  const archiveStatement = (statementId) => {
    axios({
      method: 'patch',
      url: `${process.env.REACT_APP_MODULE_SERVICE}/comfort-meter-question/${statementId}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
      data: {
        status: 'archived',
      },
    }).then(() => {
      context.setMessageState('success');
      context.setSnackBarMessage(`Successfully archived Comfort Statement: ${selectedRowId.statement}`);
      setFilteredData(filteredData.filter((el) => el.id !== statementId));
      // return this.getStatements();
    }).catch((e) => {
      context.setMessageState('error');
      context.setSnackBarMessage(`${e.response.data.message}`);
    });
  }

  useEffect(() => {
    const headersWithAddedButton = [
      {
        headerName: 'Archive Event',
        // eslint-disable-next-line no-useless-concat
        field: 'id' + 'ar',
        width: 175,
        disableClickEventBubbling: true,
        renderCell: (statement) => (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                setSelectedRowId(statement.row);
                setIsDialogOpen(!isDialogOpen);
              }}
            >
              Archive
            </Button>
          </div>
        ),
      },
      {
        headerName: 'Edit Statement',
        field: 'id',
        width: 200,
        disableClickEventBubbling: true,
        renderCell: (statement) => (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                setStatementToEdit(statement.row);
                setIsEditModalOpen(!isEditModalOpen);
              }}
            >
              Edit Statement
            </Button>
          </div>
        ),
      },
      ...headers];
    setFilteredData(data);
    setNewHeaders(headersWithAddedButton);
  }, [data]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ height: 600, width: '100%' }}>
      <Dialog
        open={isDialogOpen}
        toggleDialog={setIsDialogOpen}
        text={`Are you sure you want to archive ${selectedRowId.title ? selectedRowId.title : ''}`}
        rowId={selectedRowId.id ? selectedRowId.id : ''}
        action={archiveStatement}
      />
      <NewStatementModal
        togalModal={() => setIsNewStatementModalOpen(!isNewStatementModalOpen)}
        open={isNewStatementModalOpen}
      />
      <EditStatementModal
        togalModal={() => setIsEditModalOpen(!isEditModalOpen)}
        statementObj={statementToEdit}
        open={isEditModalOpen}
        fetchData={fetchData}

      />
      <Button onClick={() => setIsNewStatementModalOpen(!isNewStatementModalOpen)}>Create New Statement </Button>
      <DataGrid
        rows={filteredData}
        columns={newHeaders}
        sortingOrder={['asc', 'desc']}
        sortModel={[
          {
            field: 'statement',
            sort: 'asc',
          },
        ]}
      />
    </div>
  );
}

WOPGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  fetchData: PropTypes.func.isRequired,
};
