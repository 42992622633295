import React from 'react';
import axios from 'axios';
import {
  Dialog, DialogContent, DialogActions, Button,
} from '@material-ui/core';
import Cookies from 'universal-cookie';
import { loadAuthToken } from '../../utils/auth';

const cookies = new Cookies();

const endSession = ({
  toggleModal, sessionId, open, setSnackBarMessage, setMessageState, sessionDelete,
}) => {
  const endStartedSession = () => {
    axios({
      method: 'patch',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/session/${sessionId}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
      data: {
        completed: true,
        // started: false,
        completedAt: Date.now(),
      },
    }).then(() => {
      cookies.remove('accessToken', {
        domain: process.env.REACT_APP_DOMAIN,
        path: '/',
      });
      cookies.remove('user', {
        domain: process.env.REACT_APP_DOMAIN,
        path: '/',
      });
      cookies.remove('script', {
        domain: process.env.REACT_APP_DOMAIN,
        path: '/',
      });
      cookies.remove('slideIndex', {
        domain: process.env.REACT_APP_DOMAIN,
        path: '/',
      });
      cookies.remove('wopModuleId', {
        domain: process.env.REACT_APP_DOMAIN,
        path: '/',
      });
      cookies.remove('wordCloudModuleId', {
        domain: process.env.REACT_APP_DOMAIN,
        path: '/',
      });
      cookies.remove('pollModuleId', {
        domain: process.env.REACT_APP_DOMAIN,
        path: '/',
      });
      cookies.remove('czModuleId', {
        domain: process.env.REACT_APP_DOMAIN,
        path: '/',
      });
      cookies.remove('sessionId', {
        domain: process.env.REACT_APP_DOMAIN,
        path: '/',
      });
      const localStorageKeys = Object.keys(localStorage);
      localStorageKeys.forEach((lsKey) => {
        if (lsKey.includes('wop')) {
          localStorage.removeItem(lsKey);
        }
        if (lsKey.includes('slideIndex')) {
          localStorage.removeItem(lsKey);
        }
        if (lsKey.includes('wordCloud')) {
          localStorage.removeItem(lsKey);
        }
        if (lsKey.includes('poll')) {
          localStorage.removeItem(lsKey);
        }
        if (lsKey.includes('wordPopcorn')) {
          localStorage.removeItem(lsKey);
        }
        if (lsKey.includes('cz')) {
          localStorage.removeItem(lsKey);
        }
        if (lsKey.includes('rightbuttontext')) {
          localStorage.removeItem(lsKey);
        }
        if (lsKey.includes('sessionPin')) {
          localStorage.removeItem(lsKey);
        }
        if (lsKey.includes('videoPlaying')) {
          localStorage.removeItem(lsKey);
        }
      });
      setMessageState('success');
      setSnackBarMessage('Session successfully ended!');
      toggleModal();
    }).catch((err) => console.log(err));
  };

  const deleteSession = (() => {
    axios({
      method: 'delete',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/session/${sessionId}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).then(() => {
      setMessageState('success');
      setSnackBarMessage('Session successfull deleted Session!');
      toggleModal();
    });
  });

  const handleSubmit = () => {
    if (sessionDelete) {
      deleteSession();
    } else {
      endStartedSession();
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => toggleModal()}
      >
        <DialogContent>
          Are you sure you want to end this session?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggleModal()} color="primary">
            Oops! Nevermind
          </Button>
          <Button onClick={() => handleSubmit()} color="primary">
            End Session
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default endSession;
