import React, { useContext } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
// core components
import Wizard from '../../../components/Wizard/Wizard';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';

import Step1 from './Wizard/Step1';
// import Step2 from './Wizard/Step2';
import { loadAuthToken } from '../../../utils/auth';
import { AuthContext } from '../../../Auth';

export default function WizardView(props) {
  const { user, setMessageState, setSnackBarMessage } = useContext(AuthContext);
  const { history } = props;

  const createWordPopcorn = (state) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_MODULE_SERVICE}/word-popcorn`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
      data: {
        title: state.about.title,
        question: state.about.question,
        userId: user.id,
        answerLimit: state.about.answerLimit,
        iconUrl: state.about.iconUrl,
      },
    }).then(() => {
      setMessageState('success');
      setSnackBarMessage(`Successfully created ${state.about.name} Word popcorn`);
      setTimeout(() => history.push('/admin/wordPopcorn'), 500);
    }).catch((err) => {
      setMessageState('error');
      setSnackBarMessage(`${err.response.data.message}`);
      if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
        history.push('auth/login');
      }
    });
  };

  return (
    <GridContainer justifyContent="center">
      <GridItem xs={12} sm={8}>
        <Wizard
          history={history}
          validate
          steps={[
            { stepName: 'About', stepComponent: Step1, stepId: 'about' },
            // { stepName: 'Questions', stepComponent: Step2, stepId: 'questions' },
          ]}
          title="New Word Popcorn"
          subtitle=""
          finishButtonClick={(state) => createWordPopcorn(state)}
          color="primary"
        />
      </GridItem>
    </GridContainer>
  );
}

WizardView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};
