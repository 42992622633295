import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  DataGrid,
} from '@material-ui/data-grid';

import Button from '../../../../components/CustomButtons/Button';
import { loadAuthToken } from '../../../../utils/auth';
import { AuthContext } from '../../../../Auth';
import Dialog from '../../../../components/Alert/alert';
import WPStatementModal from './WOPStatementModal';

export default function WOPGrid(props) {
  const [newHeaders, setNewHeaders] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [statements, setStatements] = useState({});
  const [isStatementModalOpen, setIsStatementModalOpen] = useState(false);
  const context = useContext(AuthContext);

  const {
    data,
  } = props;

  const headers = [
    {
      headerName: 'Title', field: 'title', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Description', field: 'description', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Date Created', field: 'createdAt', width: 250, disableClickEventBubbling: true,
    },
  ];
  // archive event function taken from WOPTable.js... not tested.

  const archiveWop = (walkOfPrivilegeId) => axios({
    method: 'patch',
    url: `${process.env.REACT_APP_MODULE_SERVICE}/walk-of-privilege/${walkOfPrivilegeId}`,
    headers: {
      Authorization: `bearer ${loadAuthToken()}`,
    },
    data: {
      status: 'archived',
    },
  }).then(() => {
    context.setMessageState('success');
    context.setSnackBarMessage(`Successfully archived ${selectedRowId.title}!`);
    setFilteredData(filteredData.filter((el) => el.id !== walkOfPrivilegeId));
    setSelectedRowId({});
  });

  useEffect(() => {
    const headersWithAddedButton = [
      {
        headerName: 'Actions',
        field: 'id',
        width: 325,
        disableClickEventBubbling: true,
        renderCell: (wop) => (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                setIsDialogOpen(true);
                setSelectedRowId(wop.row);
              }}
            >
              Archive
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                setIsStatementModalOpen(true);
                setStatements(wop.row);
              }}
            >
              View
            </Button>
          </div>
        ),
      },
      ...headers];

    setNewHeaders(headersWithAddedButton);
    setFilteredData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isDialogOpen]);

  return (
    <div style={{ height: 600, width: '100%' }}>
      <WPStatementModal
        open={isStatementModalOpen}
        toggleModal={() => {
          setIsStatementModalOpen(!isStatementModalOpen);
        }}
        statements={statements}
      />
      <Dialog
        open={isDialogOpen}
        toggleDialog={setIsDialogOpen}
        text={`Are you sure you want to archive ${selectedRowId.title ? selectedRowId.title : ''}?`}
        rowId={selectedRowId.id ? selectedRowId.id : 0}
        action={archiveWop}
      />
      <DataGrid
        rows={filteredData}
        columns={newHeaders}
        sortingOrder={['asc', 'desc']}
        sortModel={[
          {
            field: 'title',
            sort: 'asc',
          },
        ]}
      />
    </div>
  );
}

WOPGrid.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object),
};
