/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Slider from './Slider';
import Button from '../../../../components/CustomButtons/Button';
import ComfortTutorial from './ComfortTutorial';

const useStyles = makeStyles({
  outerDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // width: '70%',
    justifyContent: 'space-evenly',
    '@media (max-width: 768px)': {
      height: '80vh',
      overflow: 'auto',
    },
  },
  imageDiv: {
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    width: '75%;',
    marginBottom: '5px',
    borderRadius: '10px',
    '@media (max-width: 768px)': {
      height: '30vh',
      width: '90vw',
    },
  },
  text: {
    textAlign: 'center',
    margin: '20px',
    '@media (max-width: 768px)': {
      paddingTop: '25px',
      // color: 'green',
    },
  },
  slider: {
    width: '93%',
    paddingLeft: '5px',
    // marginTop: '10px',
    // paddingBottom: '-5px',
  },
  sliderText: {
    marginBottom: '25px',
    marginTop: '-5px',
    textAlign: 'center',
  },
  button: {
    marginTop: '10px',
    // '@media (max-width: 768px)': {
    //   marginTop: '0px',
    // },
  },
});

const ComfortQuestion = ({
  question, setCurrentAnswer, nextQuestion, currentAnswer, tutorialCompleted, startComfort,
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.outerDiv}>
        {!tutorialCompleted ? <ComfortTutorial startComfort={startComfort} /> : null}
        <div className={classes.imageDiv}>
          <img src={question.image} className={classes.image} alt="Comfort Zone" />
        </div>
        <div className={classes.text} id="question">
          <h5>{question.statement}</h5>
        </div>
        <div className={classes.slider} id="slider">
          {question.options
            ? (
              <>
                <Slider
                  setAnswer={(answer) => setCurrentAnswer(answer)}
                  options={question.options}
                  question={question}
                />
                <h4 className={classes.sliderText}>{currentAnswer}</h4>
              </>
            )
            : ''}
        </div>

        <Button
          id="nextButton"
          type="submit"
          color="lightGreen"
          className={classes.button}
          onClick={() => nextQuestion()}
        >
          Next
        </Button>
      </div>
    </>
  );
};

ComfortQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  setCurrentAnswer: PropTypes.func.isRequired,
  nextQuestion: PropTypes.func.isRequired,
  currentAnswer: PropTypes.string.isRequired,
  tutorialCompleted: PropTypes.bool.isRequired,
  startComfort: PropTypes.func.isRequired,
};

export default ComfortQuestion;
