/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
// import Input from '../../../../components/CustomInput/CustomInput';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Input from '@material-ui/core/Input';

export default function MultipleChoice({ options, setAnswer }) {
  const [customAnswer, setCustomAnswer] = useState('');
  const [checkedArray, setCheckedArray] = useState(options.map((op) => false));
  return (
    <FormControl component="fieldset">
      <FormGroup>
        {options.map((option, index) => {
          if (option.type) {
            if (option.type === 'input') {
              return (
                <div style={{ color: '#000' }} key={index + 5000}>
                  {option.option}
                  <Input
                    value={customAnswer}
                    onChange={(e) => {
                      setCustomAnswer(e.target.value);
                      const checkedArrayCopy = [...checkedArray];
                      const answersToReturn = [];
                      checkedArrayCopy.forEach((bool, i) => {
                        if (bool) {
                          answersToReturn.push(options[i].option);
                        }
                      });
                      if (e.target.value) {
                        answersToReturn.push(e.target.value);
                      }
                      setAnswer(answersToReturn);
                    }}
                  />
                </div>
              );
            }
            return (
              <>
                <FormControlLabel
                  key={index + 5000}
                  value={option.option}
                  control={(
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<RadioButtonCheckedIcon />}
                      onChange={() => {
                        const checkedArrayCopy = [...checkedArray];
                        const isOptionChecked = checkedArrayCopy[index];
                        checkedArrayCopy[index] = !isOptionChecked;
                        const answersToReturn = [];
                        checkedArrayCopy.forEach((bool, i) => {
                          if (bool) {
                            answersToReturn.push(options[i].option);
                          }
                        });
                        if (customAnswer) {
                          answersToReturn.push(customAnswer);
                        }
                        setAnswer(answersToReturn);
                        setCheckedArray(checkedArrayCopy);
                      }}
                      checked={checkedArray[index]}
                    />
                )}
                  label={option.option}
                />
                <br />
              </>
            );
          }
          return (
            <FormControlLabel
              key={index + 5000}
              value={option}
              control={(
                <Checkbox
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<RadioButtonCheckedIcon />}
                  onChange={() => {
                    const checkedArrayCopy = [...checkedArray];
                    const isOptionChecked = checkedArrayCopy[index];
                    checkedArrayCopy[index] = !isOptionChecked;
                    const answersToReturn = [];
                    checkedArrayCopy.forEach((bool, i) => {
                      if (bool) {
                        answersToReturn.push(options[i]);
                      }
                    });
                    setAnswer(answersToReturn);
                    setCheckedArray(checkedArrayCopy);
                  }}
                  checked={checkedArray[index]}
                />
            )}
              label={option}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
}

MultipleChoice.propTypes = {
  options: PropTypes.array.isRequired,
  setAnswer: PropTypes.func.isRequired,
  // value: PropTypes.string.isRequired,
};
