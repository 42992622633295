import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import { withStyles } from '@material-ui/core/styles';

import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import _ from 'lodash';
import GreenRadio from '../../../../assets/jss/material-dashboard-pro-react/components/greenRadio';

export default function CheckboxLabels(props) {
  const {
    question, setUserAnswer, userAnswers, setCurrentQNotAnswered,
  } = props;
  
  const onChange = (e) => {
    setCurrentQNotAnswered(false);
    function isAnswered(answers) {
      return answers.questionId === question.id;
    }
    const found = userAnswers.find(isAnswered);
    const questionData = { questionId: question.id, answer: [e.target.value] };
    if (!found) {
      setUserAnswer([...userAnswers, questionData]);
    } else if (found) {
      const index = userAnswers.indexOf(e.target.value);
      const removeOption = userAnswers.splice(index, 1);
      const newArray = _.remove(userAnswers, (n) => n !== removeOption);
      setUserAnswer([...newArray, questionData]);
    }
  };

  //   console.log('answer', answer);

  return (
    <FormGroup>
      {/* <h3>{question.question}</h3> */}
      <RadioGroup
        onChange={(e) => {
          onChange(e);
        }}
      >
        {question.options.options.map((option, index) => (
          <div
            key={option.option}
            style={{ marginBottom: '1.0rem' }}
          >
            <FormControlLabel
              key={option.option}
              control={(
                <GreenRadio />
        )}
              label={option.option}
              value={option.option}
            />
          </div>
        ))}
      </RadioGroup>
    </FormGroup>
  );
}
