/* eslint-disable no-tabs */
/* eslint-disable max-len */
import React from 'react';
import Card from '../../components/Card/Card';

function TermsAndConditions() {
  return (
    <Card style={{
      overflow: 'auto', height: '100%', padding: '10px', margin: '10px',
    }}
    >
      {' '}
      <div>
        <div>
          <p>
            TRANSLATOR APPLICATION
            {' '}
            <br />
            {' '}
            TERMS OF USE
          </p>
        </div>
        <div>
          <p>
            Section 1. WHO WE ARE
          </p>
          <p>
            The Application (the “App”) that is governed by these Terms
            of Use is owned and operated by Translator, Inc. (referred to
            as “Translator,” “we,” “us” or “our” in these Terms of Use).
          </p>
        </div>
        <div>
          <p>
            Section 2. TERMS OF USE
          </p>
          <p>
            2.1          These Terms of Use set forth the basis on which we provide our Services through the
            App to individual users who receive access to the Services through their Employers,
            individually and collectively referred to as “Users,” “you,” “your,” and other similar
            words where the context requires such generic terms.
          </p>
          <p>
            2.2          By registering an account to use our Services through the App you agree to be
            bound by these Terms of Use. You may use the App (a) only in accordance with these Terms
            of Use, (b) only for their intended purposes, (c) only in accordance with all applicable
            laws and regulations, and (d) only in a responsible manner. IF YOU DO NOT AGREE TO THESE
            TERMS OF USE, THEN YOU MAY NOT USE THE APP AND YOU WILL NOT RECEIVE THE SERVICES AVAILABLE
            THROUGH THE APP. IF, AT ANY TIME AFTER AGREEING TO THESE TERMS OF USE, YOU FAIL TO COMPLY
            WITH THESE TERMS OF USE, WE RESERVE THE RIGHT TO SUSPEND AND/OR TERMINATE OUR SERVICES AND
            RESTRICT OR CANCEL YOUR ACCOUNT AND YOUR ACCESS TO THE APP WITHOUT NOTICE.
          </p>
          <p>
            2.3          We reserve the right to update or amend these Terms of Use from time to time.
            Updates and amendments to these Terms of Use are effective upon posting by us on the App, and
            your continued use of our Services and the App shall constitute your automatic acceptance of such
            updates and amendments. We may also, in certain circumstances, require you to expressly indicate
            your acceptance of certain updates or amendments when you log into your account, in which case
            you will not be able to continue using the Services until you have done so.
          </p>
        </div>

        <div>
          <p>
            Section 3. ADDITIONAL TERMS INCORPORATED BY REFERENCE
          </p>
          <p>
            The following terms, and any subsequent amendments thereto, also form part of these Terms of
            Use and are, where relevant, incorporated by reference:
          </p>
          <p>(a)           our Privacy Policy;</p>
          <p>(b)           any consents you provided (or that you may provide in the future) when you log into the App; and</p>
          <p>(c)           any amendments, modifications or updates made to the foregoing from time to time.</p>
        </div>
        <div>
          <p>Section 4. THE SERVICES AND THE APP</p>
          <p>
            4.1          We provide confidential “Diversity, Equity, & Inclusion” content and resources through the App
            (the “Services”). Employers contract with us to provide the Services to their employees. Particular provisions
            relating to these Services, including restrictions and limitations applicable to these Services, are further
            described in these Terms of Use.
          </p>
          <p>
            4.2          Your access to and use of the App is made available on an “as-is” and “as-available” basis
            at your sole risk. We do not warrant to you that your access to and use of the App will be uninterrupted
            or error-free.
          </p>
          <p>
            4.3          We (or your Employer) may change or cease to offer certain features or programs forming
            part of the Services from time to time and without notice. We do not warrant to you that any particular
            content will be available as part of the Services through the App.
          </p>
          <p>
            4.4          We reserve the right to change the design, features and/or functionality of the App
            and we may make available updates or replacement versions of the App. You are not obliged to download
            any updates or replacement versions of the App, but we may cease to provide or update content to prior
            versions of the App.
          </p>
          <p>
            4.5          The App may only be accessed and used on a device owned or controlled by you or your
            Employer and running the relevant operating system for which the App was designed, so it is your
            obligation to make sure that you have a compatible device that meets all of the necessary technical
            specifications to enable you to access and use the App and its various features.
          </p>
        </div>

        <div>
          <p>
            Section 5. REGISTERING AN ACCOUNT
          </p>
          <p>
            5.1          In order to use the Services, you must register a valid account and:
          </p>
          <p>
            (a)           be an individual;
          </p>
          <p>
            (b)           be at least 18 years old;
          </p>
          <p>
            (c)           at all times, be eligible to use the Services pursuant to the terms and
            conditions in the agreement between us and your Employer;
          </p>
          <p>
            (d)           agree to comply with these Terms of Use for as long as your account is open; and
          </p>
          <p>
            (e)           ensure the information in your account is accurate and updated regularly with any
            relevant changes.
          </p>
          <p>
            5.2          You must sign up directly with us using the App in order to be able to use \
            the Services. When signing up, you must provide:
          </p>
          <p>
            (a)           a valid and subsisting mobile phone number;
          </p>
          <p>
            (b)           any other information reasonably requested by us as part of the sign-up process.
          </p>
          <p>
            5.3          You warrant that all information you provide to us when registering an account is
            true and accurate. You must advise us if any of the information that you have provided to us in
            connection with your account changes at any time during the term of these Terms of Use.
          </p>
          <p>
            5.4          We reserve the right in our absolute discretion to refuse to register any prospective
            User if that prospective User refuses to provide any details requested pursuant to these Terms of Use
            or we have reason to believe that any details that are provided are deliberately false or otherwise
            inaccurate.
          </p>
          <p>
            5.5      	In establishing an account, you must create a User name (which will be your mobile phone number)
            which must comply with our security requirements. Your account is personal and non-transferrable, and you
            must keep your conversations confidential at all times. You are responsible for any abuse or misuse of your
            account and you must immediately report to us any actual or suspected abuse or misuse of your account. We
            reserve the right to suspend or terminate your account at any time, including for security purposes.
          </p>
          <p>
            5.6    	You may have only one account; registration of secondary accounts will be grounds for termination
            of all accounts connected to you.
          </p>
          <p>
            5.7          Notwithstanding anything to the contrary in these Terms of Use, if you deliberately provide
            any inaccurate or misleading information to us, or you conduct or facilitate any fraudulent, criminal,
            abusive or inappropriate activity using the App, we may suspend or terminate your Account without notice.
            IN ADDITION, IF YOU CONDUCT OR FACILITATE ANY FRAUDULENT, CRIMINAL, ABUSIVE OR INAPPROPRIATE ACTIVITY USING
            THE APP, WE RESERVE THE RIGHT TO DISCLOSE SUCH ACTIVITIES TO YOUR EMPLOYER, INCLUDING YOUR SPECIFIC INVOLVEMENT
            IN ANY SUCH ACTIVITIES.
          </p>

        </div>
        <div>
          <p>
            Section 6. DATA PROTECTION AND PRIVACY
          </p>
          <p>
            6.1          These Terms of Use, as well as our Privacy Policy and (as the same may be amended from time to time)
            govern our collection, confidentiality obligations, use, disclosure, retention, and destruction of any personal
            information you submit to us using the App, including any personal information you generated by your use of the
            Services.  Please review and refer to our Privacy Policy as it contains important information you should know about
            your data and other personal information about you.
          </p>
          <p>
            6.2          YOU MAY WITHHOLD YOUR CONSENT TO OUR COLLECTION, USE, PROCESSING, DISCLOSURE, RETENTION AND/OR DESTRUCTION
            OF YOUR PERSONAL INFORMATION BY CHOOSING TO NOT REGISTER AN ACCOUNT AND THEREFORE NOT ACCESSING ANY OF THE SERVICES.
            ONCE YOU HAVE GIVEN YOUR CONSENT TO US, YOU MAY WITHDRAW YOUR CONSENT IN WHOLE OR IN PART AT ANY TIME BUT ANY WITHDRAWAL
            OF YOUR CONSENT WILL AFFECT THE SERVICES THAT WE ARE ABLE TO PROVIDE TO YOU, AND MAY LIMIT YOUR ABILITY TO USE FEATURES
            ON THE APP. IF YOU WITHDRAW YOUR CONSENT ENTIRELY WE MAY TERMINATE YOUR ACCOUNT IMMEDIATELY.
          </p>
          <p>
            6.3          EXCEPT WHERE PROHIBITED OR LIMITED BY LAW, BY CREATING AN ACCOUNT AND AGREEING TO THESE TERMS OF USE,
            YOU RELEASE US FROM ANY AND ALL LIABILITY ARISING FROM OUR COLLECTION, USE, DISCLOSURE, PROCESSING, RETENTION AND/OR
            DESTRUCTION OF YOUR PERSONAL INFORMATION PURSUANT TO THESE TERMS OF USE AND IN OUR PRIVACY POLICY.
          </p>

        </div>
        <div>
          <p>
            Section 7. INFORMATION ON THE APP
          </p>
          <p>
            7.1       The information contained on the App is presented to Users for educational purposes. We disclaim any fiduciary
            duties, make no warranties as to the accuracy or completeness of the information and assume no liability for errors or
            omission in the content. The information on the App should not be relied on to suggest an authoritative course of action for
            an individual with legal, financial, educational, medical, or mental health concerns or to replace a consultation with a
            qualified expert in the field, such as an attorney, accountant, teacher, physician, health care provider, or therapist. All
            Users should consult with a physician or other qualified professional before engaging in changes to diet, physical activity
            levels or other similar lifestyle or behavioral changes.
          </p>
          <p>
            7.2       IF YOU ARE EXPERIENCING A CRISIS SUCH AS A MEDICAL EMERGENCY, OR YOU ARE HAVING THOUGHTS OF SUICIDE
            OR VIOLENCE, AND THINK YOU MAY PRESENT A DANGER TO YOURSELF OR OTHERS, OR IF YOU ARE IN AN ABUSIVE RELATIONSHIP
            OR ARE CONCERNED ABOUT DOMESTIC, CHILD OR ELDER ABUSE, PLEASE CONTACT THE APPROPRIATE EMERGENCY SERVICES OR AUTHORITIES
            PROMPTLY.
          </p>
        </div>
        <div>
          <p>
            Section 8. WHAT USERS ARE NOT ALLOWED TO DO
          </p>
          <p>
            8.1       Our Services are provided solely for non-commercial and personal use by Users. You may not:
          </p>
          <p>
            (a)           allow anyone to access or use your Account, including for your own personal economic gain or
            personal commercial benefit;
          </p>
          <p>
            (b)           republish, redistribute or re-transmit our App for any reason, including for your own personal
            economic gain or personal commercial benefit;
          </p>
          <p>
            (c)           redistribute or resell any Services to any other person or entity for any reason, including
            for your own personal economic gain or personal commercial benefit;
          </p>
          <p>
            (d)           copy, resell or resupply, reverse engineer, de-compile or create derivative works of any part of
            our App, or any part of the software that makes up our App regardless of how you obtained access to such
            software;
          </p>
          <p>
            (e)           use our App or Services in any way that may damage our brand reputation or trustworthiness;
          </p>
          <p>
            (f)           violate any applicable law or regulation through your use of the App or Services;
          </p>
          <p>
            (g)           copy or store our App other than for your own non-commercial, personal use (as may occur incidentally
            in the normal course of use by your browser or mobile device);
          </p>
          <p>
            (h)            copy or store our App on a server or other storage device connected to a network or create a
            database by systematically downloading and storing any data from the App;
          </p>
          <p>
            (i)            remove or change any content of the App or attempt to circumvent any security measure or
            interfere with the proper working of the App or any servers on which it is hosted; or
          </p>
          <p>
            (j)           otherwise do anything with our App or Services not expressly permitted by these Terms of Use.
          </p>
          <p>
            8.2       By creating an Account you agree to personally compensate us fully for any loss, damage or expense
            suffered or incurred by us as a result of any claim arising from or in relation to your breach of any of the
            prohibitions set forth in Section 8.1 above or any other any other provision in these Terms of Use.
          </p>
        </div>
        <div>
          <p>
            Section 9. POSTING CONTENT
          </p>

          <p>
            9.1       By creating an Account or using our App or Services you accept sole responsibility for:
          </p>
          <p>
            (a)           any and all content posted or submitted from your Account (whether by you or others who access
            your Account, whether or not you are aware of their access) to the App; and
          </p>
          <p>
            (b)           any and all activity through your Account when using the App or Services; and you agree to personally
            compensate us fully for any loss, damage or expense suffered or incurred by us as a result of any claim arising from
            or in relation to any such content or activity.
          </p>
          <p>
            9.2       Subject to any applicable law to the contrary, if you choose to post or submit content using the App then
            you must:
          </p>
          <p>
            (a)           keep any postings relevant to the purpose of the Services;
          </p>
          <p>
            (b)           not submit any content that is unlawful, threatening, abusive, libelous, pornographic, obscene, vulgar,
            indecent, offensive, or which infringes the intellectual property rights or any other rights of any third party;
          </p>
          <p>
            (c)           not submit any content that contains any viruses or other code that has contaminating or destructive elements;
          </p>
          <p>
            (d)           not submit any content containing any form of advertising; and
          </p>
          <p>
            (e)           not impersonate or misrepresent an affiliation with, any person or entity.
          </p>
        </div>
        <div>
          <p>
            Section 10. EXTERNAL LINKS
          </p>
          <p>
            10.1       The App may provide links to other websites as part of the Services we provide to Users, including for
            educational purposes. Users should exercise discretion when accessing links to other websites through the App.
          </p>
          <p>
            10.2       WE MAKE NO REPRESENTATIONS CONCERNING OPERATION OR CONTENT OF THESE EXTERNAL WEBSITES AND ARE NOT RESPONSIBLE FOR
            THE QUALITY OF INFORMATION ON ANY SUCH EXTERNAL WEBSITES OR ANY LINK CONTAINED IN SUCH EXTERNAL WEBSITES. THESE LINKS MAY LEAD
            UNINTENTIONALLY TO SITES CONTAINING INFORMATION THAT SOME PEOPLE MAY FIND INAPPROPRIATE OR OFFENSIVE. THESE LINKS MAY ALSO LEAD
            TO SITES THAT CONTAIN INACCURATE INFORMATION, FALSE OR MISLEADING ADVERTISING, OR INFORMATION THAT VIOLATES COPYRIGHT, LIBEL,
            OR DEFAMATION LAWS. THE SERVICES, PRODUCTS, AND RESOURCES AVAILABLE FROM THESE WEBSITES ARE NOT ENDORSED IN ANY WAY BY US, NOR
            WE ENDORSE ANY OF THE SPONSORS OR ADVERTISERS ON THESE SITES.
          </p>
        </div>
        <div>
          <p>
            Section 11. AVAILABILITY AND OPERATION OF THE SERVICES AND APP.
          </p>
          <p>
            11.1       We will use commercially reasonable efforts to make the Services and App available for use by you for as much of the
            time as is reasonably possible, but we do not give or enter into any condition, warranty, or other term to the effect that the
            Services or App are or will be:
          </p>
          <p>
            (a)           available all of the time;
          </p>
          <p>
            (b)           available on a continuous or uninterrupted basis;
          </p>
          <p>
            (c)           free from errors, defects, viruses or other destructive elements; or
          </p>
          <p>
            (d)           in compliance with any particular standard(s).
          </p>
          <p>
            11.2       By creating an Account and using the App, you agree that we will not be liable to you for any unavailability of or
            defect in our Services or App (however temporary), including due to scheduled maintenance, upgrades, security, legal or business
            reasons, and/or reasons beyond our control (such as hardware or software failures, other interruptions of internet or
            telecommunications service).
          </p>
          <p>
            11.3       It is your responsibility to implement appropriate information technology security safeguards to satisfy your
            particular requirements as to the safety and reliability of the App and its content.
          </p>
        </div>
        <div>
          <p>
            Section 12. INTELLECTUAL PROPERTY RIGHTS
          </p>
          <p>
            12.1       All intellectual property rights in or relating to the Services and the App (including text, graphics, software,
            photographs and other images, videos, sound, trademarks and logos) are owned by us or our licensors. You are given a non-exclusive
            license to use the intellectual property rights concerned only to the extent necessary to enable you to receive the Services and to
            use the App in accordance with these Terms of Use. You acknowledge and agree that you do not acquire any ownership of, or other rights
            in relation to, any such intellectual property rights by virtue of receiving the Services or by using the App.
          </p>

        </div>
        <div>
          <p>Section 13. OUR LIABILITY</p>
          <p>
            13.1       Except as expressly set out in these Terms of Use, we make or give no representation or warranty as to the accuracy,
            completeness, currency, correctness, reliability, integrity, quality, fitness for purpose or originality of any content of the
            App, and to the fullest extent permitted by law, all implied warranties, conditions or other terms of any kind are hereby
            excluded. To the fullest extent permitted by law, we accept no liability for any loss or damage of any kind incurred as a result
            of you or anyone else relying on the content of the App.
          </p>
          <p>
            13.2       Nothing in these Terms of Use shall exclude or limit our liability to you:
          </p>
          <p>
            (a)           for fraud or fraudulent misrepresentation; or
          </p>
          <p>
            (b)           for any liability that may not, under governing law, be excluded or limited.
          </p>
          <p>
            13.3       We shall not be liable to you (whether such liability arises as a result of breach of contract, negligence,
            misrepresentation or for any other reason) for any loss or damage which arises as a result of:
          </p>
          <p>
            (a)           any act or omission of any User, Employer, or other third party;
          </p>
          <p>
            (b)           any circumstance which we cannot reasonably be expected to control.
          </p>
          <p>
            13.4       You are solely responsible for protecting your private conversations and access to our App and we will not be held liable
            for any loss or damage that may result from your failure to do so.
          </p>
          <p>
            13.5       We shall not be liable to you (whether such liability arises as a result of breach of contract, negligence, misrepresentation
            or for any other reason) for any business losses and any liability we do have for losses you suffer is strictly limited to losses which
            were reasonably foreseeable.
          </p>
          <p>
            13.6       We shall not be liable to you or to any Employer for any loss, damage, liability, cost, claim or expense that you or any
            Employer suffers, incurs or pays as a result of the suspension or termination by us of your access to and use of the Services.
          </p>
        </div>
        <div>
          <p>
            Section 14. CLOSING YOUR ACCOUNT
          </p>
          <p>
            14.1       If you wish to close your Account, please contact us (Section 16). Please note that we may retain information concerning
            your Account in our records in accordance with our standard practices and in accordance with our Privacy Policy. Closing your Account
            will not obligate us to delete such information except in due course under our practices and policies.
          </p>
          <p>
            14.2       We may close your Account if:
          </p>
          <p>
            (a)           you are in breach of these Terms of Use;
          </p>
          <p>
            (b)           you cease to be an eligible User with respect to your Employer or we reasonably believe that to be the case;
          </p>
          <p>
            (c)           your Employer ceases to pay for the Services;
          </p>
          <p>
            (d)           your Employer is in breach or other default of its contract with us or with any third-party reseller of our Services
            such as to justify termination of that agreement; or
          </p>
          <p>
            (e)            for any other reason at our absolute discretion.
          </p>
        </div>
        <div>
          <p>Section 15. GENERAL</p>
          <p>
            15.1       You may not sub-license, transfer, or assign any of the rights or obligations under these Terms of Use.
          </p>
          <p>
            15.2       We may subcontract or delegate the performance of any of our obligations to you. We may transfer or assign any of our
            rights or obligations to you to someone else.
          </p>
          <p>
            15.3       These Terms of Use, which include any other terms expressly referred to in them, represent the entire agreement between you
            and us in relation to this subject matter.
          </p>
          <p>
            15.4       If we fail to enforce, or delay in enforcing, any of our rights under these Terms of Use, that does not result in a waiver
            of the rights concerned.
          </p>
          <p>
            15.5       If any provision of these Terms of Use is found to be unenforceable, it shall not affect the enforceability of any other
            provisions they contain.
          </p>
          <p>
            15.6       These Terms of Use and our other terms incorporated by reference shall be governed by the laws of New York and the courts
            in New York, New York, shall have exclusive jurisdiction to settle any disputes which may arise between you and us under these Terms of Use.
          </p>
        </div>
        <div>
          <p>Section 16. CONTACTING US</p>
          <p>
            Please submit any questions you have about these Terms of Use or any problems concerning the App and its use to us by email at:
            hello@translator.company with “Questions About The App” in the subject heading.
          </p>
        </div>
      </div>
    </Card>
  );
}

export default TermsAndConditions;
