/* eslint-disable react/no-unused-state */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
import React from 'react';
import AWS from 'aws-sdk';
// import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';


const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET;
const REGION = 'us-east-1';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY_KEY,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const style = {
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center',
  },
  inputAdornmentIcon: {
    color: '#555',
  },
  inputAdornment: {
    position: 'relative',
  },
  tagListItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  highLightIcon: {
    color: '#60A681',
  },
};

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 0,
      selectedFile: null,
      link: '',
    };

    this.handleFileInput = this.handleFileInput.bind(this);
  }

  handleFileInput(e) {
    // console.log('handle file input', e.target.files[0]);
    const file = e.target.files[0];
    this.setState({ selectedFile: file });
  }

  isValidated() {
    const {
      link,
    } = this.state;
    if (
      link.length
    ) {
      return true;
    }
    return false;
  }

  sendState() {
    return this.state;
  }


  uploadFile(file) {
    const params = {
      ACL: 'public-read',
      Body: file,
      Bucket: S3_BUCKET,
      Key: file.name,
      ContentType: file.type,
    };

    myBucket.putObject(params)
      .on('httpUploadProgress', (evt) => {
        // console.log(params, 'params');
        this.setState({ progress: Math.round((evt.loaded / evt.total) * 100) });
      })
      .on('success', (x) => {
        const { path, headers } = x.request.httpRequest;
        const fileString = `https://${headers.Host}${path}`;
        this.setState({ link: fileString });
      })
      .send((err) => {
        // console.log(err);
        if (err) console.log(err, 'err');
      });
  }

  render() {
    const {
      progress, selectedFile, link,
    } = this.state;
    // const { classes } = this.props;
    return (
      <>
        <GridContainer justifyContent="center" style={{ textAlign: 'center' }}>
          <GridItem xs={12}>
            Choose and upload a file.  Once the file has been uploaded click &apos;Finish&lsquo;.
            <h4>
              File Upload Progress is:
              {progress}
              %
            </h4>
          </GridItem>
          <GridItem xs={12}>
            <input type="file" onChange={this.handleFileInput} />
            <button type="submit" onClick={() => this.uploadFile(selectedFile)}> Upload File </button>
          </GridItem>
        </GridContainer>
        <p id="link">{link}</p>
      </>
    );
  }
}

export default withStyles(style)(Step2);
