/* eslint-disable react/forbid-prop-types */
import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { PulseLoader } from 'react-spinners';
import { makeStyles } from '@material-ui/core/styles';
import Card from '../../../components/Card/Card';
import CardFooter from '../../../components/Card/CardFooter';
import CardBody from '../../../components/Card/CardBody';
import CardHeader from '../../../components/Card/CardHeader';
import styles from '../../../assets/jss/material-dashboard-pro-react/views/reportPageStyle';
import ResourceTableGrid from './ResourceTableGrid';
import { AuthContext } from '../../../Auth';
import { loadAuthToken } from '../../../utils/auth';

const useStyles = makeStyles(styles);

function ResourceTable() {
  const classes = useStyles();
  const { setMessageState, setSnackBarMessage } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(!isLoading);
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_MODULE_SERVICE}/resource`,
        headers: {
          Authorization: loadAuthToken(),
        },
      }).then((res) => {
        setData(res.data.data);
        setIsLoading(false);
        return res;
      })
        .catch((e) => {
          setMessageState('error');
          setSnackBarMessage(`${e.response.data.message}`);
        });
    };

    fetchData();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card style={{ minHeight: '80vh' }}>
      <CardHeader className={classes.headerAlign}>
        <div className={classes.header}>
          <h3>Resource</h3>
        </div>

      </CardHeader>
      <CardBody className={classes.reportCardBody}>
        {isLoading ? (
          <div style={{ margin: 'auto' }}>
            <PulseLoader
              sizeUnit="px"
              size={15}
              color="#6CCA98"
              loading
            />
          </div>
        ) : <ResourceTableGrid data={data} />}
      </CardBody>

      <CardFooter stats>
        <div className={classes.stats}>
          <p className={classes.cardTitle}>
            Total:
            {data.length}
          </p>
        </div>
      </CardFooter>
    </Card>
  );
}

export default ResourceTable;
