/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import styles from '../../../../assets/jss/material-dashboard-pro-react/views/reportPageStyle';

const useStyles = makeStyles(styles);

const PollTable = (props) => {
  const classes = useStyles();
  const { results } = props;

  return (
    <>
      {results.map((row, index) => (
        <TableContainer component={Paper} key={index + 300000} className={classes.tableQuestion}>
          <Table aria-label="simple table" key={index}>
            <TableHead>
              <TableRow>
                <TableCell style={{ backgroundColor: 'lightgray' }}>{row.question ? row.question : 'question-unavailble'}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {row.answers.map((answer, i) => (
                <TableRow key={i}>
                  <TableCell component="th" scope="row" className={classes.questionTableRow}>
                    <p>{ Object.keys(answer) }</p>
                    <p>{Object.values(answer)}</p>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ))}
    </>
  );
};

PollTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  results: PropTypes.array.isRequired,
};

export default PollTable;
