/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';

// @material-ui/icons
import ExpandMore from '@material-ui/icons/ExpandMore';

import styles from '../../assets/jss/material-dashboard-pro-react/components/accordionStyle';

const useStyles = makeStyles(styles);

export default function ExpandableAccordion(props) {
  const [active, setActive] = React.useState(props.active);
  const handleChange = (panel) => (event, expanded) => {
    setActive(expanded ? panel : -1);
  };
  const classes = useStyles();
  const { collapses } = props;
  return (
    <div className={classes.root}>
      {collapses.map((prop, key) => (
        <Accordion
          expanded={active === key}
          onChange={handleChange(key)}
          key={key}
          classes={{
            root: classes.expansionPanel,
            expanded: classes.expansionPanelExpanded,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            classes={{
              root: classes.expansionPanelSummary,
              expanded: classes.expansionPanelSummaryExpaned,
              content: classes.expansionPanelSummaryContent,
              expandIcon: classes.expansionPanelSummaryExpandIcon,
            }}
          >
            <h4 className={classes.title}>{prop.title}</h4>
          </AccordionSummary>
          <AccordionDetails className={classes.expansionPanelDetails}>
            {prop.content}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

ExpandableAccordion.defaultProps = {
  active: -1,
};

ExpandableAccordion.propTypes = {
  // index of the default active collapse
  active: PropTypes.number,
  collapses: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node,
      content: PropTypes.node,
    }),
  ).isRequired,
};
