/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Tooltip from '@material-ui/core/Tooltip';
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
// import emojiStrip from 'emoji-strip';
import Button from '../../../../components/CustomButtons/Button';
import styles from '../../../../assets/jss/material-dashboard-pro-react/views/wordCloud';

const useStyles = makeStyles(styles);
const WordCloudQuestion = ({
  isLastQuestion, question, nextQuestion, moduleId, sessionId,
}) => {
  const [input, setInput] = useState('');
  const [answers, setAnswers] = useState([]);
  const [currentUserId, setCurrentUserId] = useState([]);
  const classes = useStyles();
  const CHARACTER_LIMIT = 20;

  useEffect(() => {
    setCurrentUserId(Number(window.localStorage.getItem('audience_user_id')));
    console.log('line 29', Number(window.localStorage.getItem('audience_user_id')));
  }, []);

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };

  const addAnswer = () => {
    if (input.length) {
      const answersCopy = [...answers];
      answersCopy.push(input.trim());
      setAnswers(answersCopy);
      setInput('');
    }
  };

  const removeAnswer = (index) => {
    const answersCopy = [...answers];
    answersCopy.splice(index, 1);
    setAnswers(answersCopy);
  };

  const filterInput = (str) => {
    setInput(str.replace(/[^a-zA-Z0-9!@#$&()`.+,/"-^\w+(\s\w+)*$]/g, ''));
  };

  const submitAnswers = async () => {
    // const audienceUserId = Number(window.localStorage.getItem('audience_user_id'));
    const answersCopy = [];
    answers.forEach((answer) => {
      answersCopy.push({
        answer,
        questionId: question.id,
        moduleId,
        isComplete: isLastQuestion,
        sessionId,
        audienceUserId: currentUserId > 1 ? currentUserId : 1,
      });
    });
    setAnswers([]);
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/word-cloud-answer`,
      data: answersCopy,
    });
    nextQuestion();
  };

  return (
    <div className={classes.subContainer}>

      <img src={question.icon} className={classes.image} alt="Word Cloud" />

      <h4>{question.question}</h4>
      <div className={classes.inputBox}>
        <form onSubmit={(e) => {
          e.preventDefault();
          addAnswer();
        }}
        >
          <TextField
            margin="dense"
            label="Your Response"
            type="text"
            value={input}
            inputProps={{
              className: classes.textField,
              maxLength: CHARACTER_LIMIT,
            }}
            helperText={`${input.length}/${CHARACTER_LIMIT}`}
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            onChange={(e) => filterInput(e.target.value)}
          />
          <IconButton type="submit" onClick={() => addAnswer()}>
            <AddCircleOutlineIcon className={classes.iconColor} />
          </IconButton>
        </form>
      </div>
      <ul className={classes.unorderedList}>
        {answers.map((answer, index) => (
          <div key={index + 90000} className={classes.answer}>
            <IconButton onClick={() => removeAnswer(index)}>
              <RemoveCircleOutlineRoundedIcon className={classes.iconColor} />
            </IconButton>
            <li>{answer}</li>
          </div>
        ))}
        <AlwaysScrollToBottom />

      </ul>
      {answers.length < question.minimum ? (
        <Tooltip title={`Please provide at least ${question.minimum} response(s)`}>
          <span>
            <Button
              type="submit"
              color="lightGreen"
              disabled
            >
              Submit
            </Button>
          </span>
        </Tooltip>
      ) : (
        <Button
          type="submit"
          color="lightGreen"
          onClick={() => {
            submitAnswers();
          }}
        >
          Submit
        </Button>
      )}
    </div>
  );
};

WordCloudQuestion.propTypes = {
  isLastQuestion: PropTypes.bool.isRequired,
  question: PropTypes.object.isRequired,
  nextQuestion: PropTypes.func.isRequired,
  moduleId: PropTypes.number.isRequired,
  sessionId: PropTypes.number.isRequired,
};

export default WordCloudQuestion;
