/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { CSVLink } from 'react-csv';
import AnswerDemographics from './AnswerDemographics';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function TabsWrappedLabel(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState('one');
  const { data, headers } = props;

  const { answersByOptions } = data;

  const csvHeaders = headers.map((header) => (
    {
      key: header,
      label: header,
    }
  ));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="wrapped label tabs example">

          {answersByOptions.map((option, i) => (
            <Tab
              value={i}
              label={`${option.option} : ${option.count}`}
              wrapped
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...a11yProps(i)}
            />
          ))}

        </Tabs>
      </AppBar>

      {answersByOptions.map((option, i) => (
        <>
          <TabPanel value={value} index={i}>
            <AnswerDemographics data={option.userData} headers={headers} />
            <CSVLink data={option.userData} headers={csvHeaders}>
              Download Table
            </CSVLink>
          </TabPanel>

        </>
      ))}

    </div>
  );
}

TabsWrappedLabel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object),
  headers: PropTypes.arrayOf(PropTypes.string),
};
