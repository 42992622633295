import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  DataGrid,
} from '@material-ui/data-grid';
// import Button from '@material-ui/core/Button';
import Button from '../../../../components/CustomButtons/Button';
import Dialog from '../../../../components/Alert/alert';
import EditQuestionModal from '../EditQuestionModal';

import { loadAuthToken } from '../../../../utils/auth';
import { AuthContext } from '../../../../Auth';

export default function SurveyQuestionGrid(props) {
  const {
    data,
  } = props;
  const [newHeaders, setNewHeaders] = useState([]);
  const [questionToEdit] = useState({});
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState({});
  const [filteredData, setFilteredData] = useState(data);

  const context = useContext(AuthContext);

  const headers = [
    {
      headerName: 'Question', field: 'question', width: 350, disableClickEventBubbling: true,
    },
    {
      headerName: 'Type', field: 'type', width: 150, disableClickEventBubbling: true,
    },
    {
      headerName: 'Options', field: 'options', width: 250, disableClickEventBubbling: true,
    },

  ];
  // taken from SurveyQuestionTable.js not tested
  const archiveQuestion = (questionObj) => {
    // console.log('questionObj', questionObj);
    axios({
      method: 'patch',
      url: `${process.env.REACT_APP_MODULE_SERVICE}/survey-question/${questionObj.id}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
      data: {
        status: 'archived',
      },
    }).then(() => {
      context.setMessageState('success');
      context.setSnackBarMessage(`Successfully archived survey question: ${questionObj.question}`);
      setFilteredData(filteredData.filter((el) => el.id !== questionObj.id));
      setSelectedRowId({});
      // return this.getQuestions();
    }).catch((e) => {
      context.setMessageState('error');
      context.setSnackBarMessage(`${e.response.data.message}`);
    });
  };

  useEffect(() => {
    const headersWithAddedButton = [
      // {
      //   headerName: 'Edit Question',
      //   field: 'id',
      //   width: 200,
      //   disableClickEventBubbling: true,
      //   renderCell: (question) => (
      //     <div>
      //       <Button
      //         disabled
      //         variant="contained"
      //         color="primary"
      //         onClick={(e) => {
      //           e.preventDefault();
      //           setQuestionToEdit(question.row);
      //           setIsEditModalOpen(!isEditModalOpen);
      //         }}
      //       >
      //         Edit
      //       </Button>
      //     </div>
      //   ),
      // },
      {
        headerName: 'Archive Question',
        // eslint-disable-next-line no-useless-concat
        field: 'id' + 'ar',
        width: 200,
        disableClickEventBubbling: true,
        renderCell: (sq) => (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                setSelectedRowId(sq.row);
                setIsDialogOpen(!isDialogOpen);
              }}
            >
              Archive
            </Button>
          </div>
        ),
      },
      ...headers];

    setNewHeaders(headersWithAddedButton);
  }, [data]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ height: 600, width: '100%' }}>
      <EditQuestionModal
        open={isEditModalOpen}
        question={questionToEdit}
        togalModal={() => {
          setIsEditModalOpen(!isEditModalOpen);
        }}
      />
      <Dialog
        open={isDialogOpen}
        toggleDialog={setIsDialogOpen}
        text={`Are you sure you want to archive: ${selectedRowId.question ? selectedRowId.question : ''}`}
        rowId={selectedRowId.id ? selectedRowId.id : ''}
        action={() => archiveQuestion(selectedRowId)}
      />
      <DataGrid
        rows={filteredData}
        columns={newHeaders}
        sortingOrder={['asc', 'desc']}
        sortModel={[
          {
            field: 'question',
            sort: 'asc',
          },
        ]}
      />
    </div>
  );
}

SurveyQuestionGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};
