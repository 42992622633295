/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// import InfoIcon from '@material-ui/icons/Info';
// import TranslationModal from './TranslationModal';

const useStyles = makeStyles({

  card: {
    // width: '50%',
    height: '95%',
    borderRadius: 15,
    background: 'white',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    '@media (max-width: 960px)': {
      width: '100%',
      fontSize: '20px',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      // backgroundSize: '100%',
    },
  },
  contentDiv: {
    opacity: 1,
    marginTop: '10vh',
    fontSize: '1.6rem',
    lineHeight: 1.2,
    fontWeight: '400',
    // padding: '5px',
  },
  content: {
    background: '#ffffffe8',
    padding: '5px',
    borderRadius: '10px',
  },

  cardStyles: {
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    userSelect: 'none',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
  },
});

function StyledCard({ zIndex = 0, children }) {
  const classes = useStyles();
  const { walk_of_privilege_question } = children;
  // const [openTranslation, setOpenTranslation] = useState(false);

  // const handleOpen = () => {
  //   setOpenTranslation(true);
  // };

  // const handleClose = () => {
  //   setOpenTranslation(false);
  // };

  return (
    <div align="center" className={classes.cardStyles} style={{ zIndex }}>
      {walk_of_privilege_question
        ? (
          <Card
            id="card"
            className={classes.card}
            style={{ backgroundImage: `url(${walk_of_privilege_question.image})` }}
          >
            <CardContent>
              <div className={classes.contentDiv}>
                <div align="center" className={classes.content}>
                  <p>
                    Swipe
                    {' '}
                    <b>{ walk_of_privilege_question.direction.toUpperCase()}</b>
                    {' '}
                    { walk_of_privilege_question.question}
                  </p>
                  {/* <div align="right">
                    <TranslationModal
                      open={openTranslation}
                      handleClose={handleClose}
                      translation={walk_of_privilege_question.translation}
                    />
                    <InfoIcon onClick={() => handleOpen()} />
                  </div> */}
                </div>
              </div>
            </CardContent>
          </Card>
        )
        : <Card />}
    </div>
  );
}

StyledCard.propTypes = {
  zIndex: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default StyledCard;
