/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import PersonPin from '@material-ui/icons/PersonPin';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import CustomInput from '../../components/CustomInput/CustomInput';
import Button from '../../components/CustomButtons/Button';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import CardFooter from '../../components/Card/CardFooter';
import Logo from '../../assets/img/full-logo-white.png';

import styles from '../../assets/jss/material-dashboard-pro-react/views/loginPageStyle';

const useStyles = makeStyles(styles);

export default function Join({ submitPin, isLoading }) {
  const [pin, setPin] = useState('');
  const [isIncorrect, setIncorrect] = useState(false);
  const [isNotStarted, setIsNotStarted] = useState(false);
  const [cardAnimaton, setCardAnimation] = useState('cardHidden');
  // const [isIncorrect, setIncorrect] = React.useState(false);
  const isValidPin = async () => {
    if (pin.length > 2) {
      setIncorrect(false);
      setIsNotStarted(false);
      const isCorrect = await submitPin(pin);
      if (isCorrect === 'not started') {
        setIsNotStarted(true);
      } else {
        setIncorrect(!isCorrect);
      }
    } else {
      setIncorrect(true);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (!isLoading) setCardAnimation('');
    }, 700);
  }, [isLoading]);

  const setErrorText = () => {
    if (isIncorrect) {
      return 'Incorrect Pin';
    } if (isNotStarted) {
      return 'Session Not Started Yet';
    }
    return 'Pin';
  };

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justifyContent="center">
        <GridItem style={{ display: 'flex', alignItems: 'center' }} xs={12} sm={6} md={4}>
          <form
            autoComplete="off"
            onSubmit={async (e) => {
              e.preventDefault();
              isValidPin();
            }}
          >
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="primary"
              >
                <img className={classes.headerLogo} src={Logo} alt="logo" />
              </CardHeader>
              <CardBody>
                <CustomInput
                  error={isIncorrect || isNotStarted}
                  labelText={setErrorText()}
                  id="pin"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    classes: {
                      input: classes.resize,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <PersonPin className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    value: pin,
                    onChange: (e) => {
                      setIncorrect(false);
                      setPin(e.currentTarget.value);
                    },
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button type="submit" color="darkGreen" simple size="lg" block>
                  Join
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}

Join.propTypes = {
  submitPin: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
