/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/static-property-placement */
import axios from 'axios';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from '../../Auth';
import SideBar from './SideBar';
import Stats from './Stats';
import Pin from './Pin';
import Modules from './ModuleScreens';
import { loadAuthToken } from '../../utils/auth';
// import sampleData from './ModuleScreens/sampleAnswers2';

function compare(a, b) {
  // Use toUpperCase() to ignore character casing
  const bandA = a.order;
  const bandB = b.order;

  let comparison = 0;
  if (bandA > bandB) {
    comparison = 1;
  } else if (bandA < bandB) {
    comparison = -1;
  }
  return comparison;
}

class Host extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);
    this.state = {
      loginLoading: false,
      sideBarOpen: true,
      pin: 0,
      title: '',
      wordCloudIcon: '',
      session: {},
      audienceUsers: 0,
      liveQuestions: 0,
      modules: [],
      walkOfPrivilegeQuestions: [],
      initialWordCloudCount: 0,
      surveyQuestions: [],
      isDemo: false,
      moduleClientModuleId: -1,
      wordCloudQuestions: [],
      moduleType: 'default',
      moduleTitle: 'default',
      moduleId: -1,
      pollQuestions: [],
      wordPopcornQuestion: '',
      wordPopcornAnswerLimit: null,
      wordPopcornCount: 0,
      wordPopcornIcon: '',
      flagshipModule: '',
    };
  }

  componentDidMount() {
    const { user, isLoading } = this.context;
    // this.channel = pusher.subscribe('test')
    if (user.id !== undefined) {
    //   this.findSession(user.id).then(() => {
    //     this.getSessionUsers();
    //     this.getSessionLiveQs();
    //   });
      // https://developers.google.com/web/updates/2018/07/page-lifecycle-api#event-pageshow
      // document.addEventListener(visibilityChange, () => {
      //   if (document.visibilityState === 'visible') findCurrentModule();
      // });
      document.addEventListener('visibilitychange', this.findSession(user.id));

      window.addEventListener('focus', this.findSession(user.id));
      document.addEventListener('focus', this.findSession(user.id));

      window.addEventListener('resume', this.findSession(user.id));
      document.addEventListener('resume', this.findSession(user.id));
      // document.removeEventListener(visibilityChange, findCurrentModule);
    } else if (isLoading) {
      this.setState({ loginLoading: true });
    } else {
      this.context.sendToLogin();
    }
  }

  componentDidUpdate() {
    const { user, isLoading } = this.context;
    const { loginLoading } = this.state;
    if (loginLoading) {
      if (user.id !== undefined) {
        // Session is unprotected on the back-end due to audience requiring the get.
        // Just making explicitly sure they're logged in.
        this.findSession(user.id).then(() => {
          this.getSessionUsers();
          this.getSessionLiveQs();
        });
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ loginLoading: false });
      } else if (!isLoading) {
        this.context.sendToLogin();
      }
    }
  }

  componentWillUnmount() {
    const { user } = this.context;
    document.removeEventListener('visibilitychange', this.findSession(user.id));
    window.removeEventListener('focus', this.findSession(user.id));
    document.removeEventListener('focus', this.findSession(user.id));
    window.removeEventListener('resume', this.findSession(user.id));
    document.removeEventListener('resume', this.findSession(user.id));
  }

  handleEndSession() {
    const {
      session, pin, audienceUsers, liveQuestions, flagshipModule,
    } = this.state;
    const { history } = this.props;
    const { user } = this.context;
    return axios({
      method: 'patch',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/session/${session.id}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
      data: {
        completed: true,
        started: false,
        completedAt: Date.now(),
      },
    }).then(() => {
      history.push('/admin/sessions');
      // axios call to send email to user.email generic email:
      return axios({
        method: 'post',
        url: `${process.env.REACT_APP_EVENT_SERVICE}/send-email-on-session-end`,
        headers: {
          Authorization: `bearer ${loadAuthToken()}`,
        },
        data: {
          user,
          sessionId: session.id,
          pin,
          audienceUsers,
          liveQuestions,
          flagshipModule,
        },
      }).catch((err) => console.log('send email error', err));
    })
      .catch((err) => {
        if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
          history.push('auth/login');
        }
      });
  }

  handleChangeModule(mod) {
    const { session } = this.state;
    return axios({
      method: 'patch',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/current-module/${session.currentModule.id}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
      data: {
        moduleId: mod.id,
        moduleType: mod.type,
        moduleTitle: mod.title,
      },
    }).then(async (res) => {
      // ('handlechange', mod);
      if (mod.type === 'walkOfPrivilege') {
        try {
          const { data } = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_EVENT_SERVICE}/get-walk-of-privilege-questions?moduleId=${mod.id}`,
          });
          this.setState({
            moduleType: mod.type,
            moduleTitle: mod.title,
            moduleId: mod.id,
            walkOfPrivilegeQuestions: data.map((questionObj) => ({
              id: questionObj.walk_of_privilege_question.id,
              question: questionObj.walk_of_privilege_question.question,
              direction: questionObj.walk_of_privilege_question.direction,
            })),
          });
        } catch (e) {
          console.error(e);
          this.setState({
            moduleType: mod.type,
            moduleTitle: mod.title,
            moduleId: mod.id,
          });
        }
      } else if (mod.type === 'sentimentSurvey') {
        const surveyQs = res.data.moduleData.sentiment_survey_joins
          .sort(compare).map((questionObj) => ({
            id: questionObj.sentiment_survey_question.id,
            question: questionObj.sentiment_survey_question.question,
          }));
        this.setState({
          moduleType: mod.type,
          moduleTitle: mod.title,
          moduleId: mod.id,
          surveyQuestions: surveyQs,
        });
      } else if (mod.type === 'wordCloud') {
        // const moduleId = await this.getModulesClientModuleId(mod.id);
        this.setState({
          flagshipModule: mod.type,
          moduleType: mod.type,
          moduleTitle: mod.title,
          moduleId: mod.id,
        }, async () => axios({
          method: 'get',
          url: `${process.env.REACT_APP_EVENT_SERVICE}/get-host-word-cloud-data?moduleId=${mod.id}&sessionId=${session.id}`,
          headers: {
            Authorization: `bearer ${loadAuthToken()}`,
          },
        }).then((_res) => {
          this.setState({
            wordCloudIcon: _res.data.icon,
            wordCloudQuestions: _res.data.questions,
            initialWordCloudCount: _res.data.totalCompleted,
          });
        }));
      } else if (mod.type === 'wordPopcorn') {
        // console.log('Host res', res);
        axios({
          method: 'get',
          url: `${process.env.REACT_APP_EVENT_SERVICE}/get-host-popcorn-data?moduleId=${mod.id}&sessionId=${session.id}`,
          headers: {
            Authorization: `bearer ${loadAuthToken()}`,
          },
        }).then((_res) => {
          // console.log('Host _res', _res.data);
          this.setState({
            moduleType: mod.type,
            moduleTitle: mod.title,
            moduleId: mod.id,
            wordPopcornQuestion: _res.data.data.question,
            wordPopcornAnswerLimit: _res.data.data.answerLimit,
            wordPopcornCount: _res.data.data.count,
            wordPopcornIcon: _res.data.data.iconUrl,
          });
        });
        // console.log('Host res', res);
      } else {
        this.setState({
          moduleType: mod.type,
          moduleTitle: mod.title,
          moduleId: mod.id,
        });
      }
    })
      .catch((err) => {
        if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
          this.context.sendToLogin();
        } else {
          console.log('handleChangeModuleErr', err);
        }
      });
  }

  // eslint-disable-next-line consistent-return
  async getModulesClientModuleId(modId) {
    let mod;
    if (this.state.moduleClientModuleId === -1) {
      if (this.state.moduleId === -1) {
        return axios({
          method: 'get',
          url: `${process.env.REACT_APP_EVENT_SERVICE}/module/${modId}`,
        }).then((res) => {
          mod = res.data;
          const { moduleId } = mod;
          return moduleId;
        });
      }
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_EVENT_SERVICE}/module/${this.state.moduleId}`,
      }).then((res) => {
        mod = res.data;
        const { moduleId } = mod;
        return moduleId;
      });
    }
  }

  findSession(userId) {
    const { history } = this.props;
    const { pusher } = this.context;
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/host-session`,
      data: { userId },
    }).then(async (res) => {
      // console.log('findSession Host.js res', res.data);
      if (!this.channel) {
        this.channel = pusher.subscribe(`${res.data.id}`);
      }
      if (res.data.currentModule.type === 'walkOfPrivilege') {
        const moduleId = await this.getModulesClientModuleId(res.data.currentModule.moduleId);
        axios({
          method: 'get',
          url: `${process.env.REACT_APP_MODULE_SERVICE}/wop-question-join?walkOfPrivilegeId=${moduleId}`,
          headers: {
            Authorization: `bearer ${loadAuthToken()}`,
          },
        }).then((_res) => {
          if (_res.data.error === 'Your token is not valid' || _res.data.error === 'Your token is expired') {
            this.context.sendToLogin();
          }
          const wopQuestions = _res.data.data.sort(compare).map((questionObj) => ({
            id: questionObj.walk_of_privilege_question.id,
            question: questionObj.walk_of_privilege_question.question,
            direction: questionObj.walk_of_privilege_question.direction,
          }));
          return this.setState({
            walkOfPrivilegeQuestions: wopQuestions,
            session: res.data,
            modules: res.data.modules,
            title: res.data.title,
            isDemo: res.data.event.demo,
            audienceUsers: res.data.audienceUsers,
            pin: res.data.pin,
            moduleType: res.data.currentModule.type,
            moduleTitle: res.data.currentModule.title,
            moduleId: res.data.currentModule.moduleId,
          });
        })
          .catch((e) => {
            if ((e.error === 'your token is not valid' || e.error === 'Your token is expired')) {
              console.log('ERROR ');
            }
            console.log(e);
          });
      } else if (res.data.currentModule.type === 'sentimentSurvey') {
        const moduleId = await this.getModulesClientModuleId(res.currentModule.moduleId);
        axios({
          method: 'get',
          url: `${process.env.REACT_APP_MODULE_SERVICE}/sentiment-survey-join?walkOfPrivilegeId=${moduleId}`,
          headers: {
            Authorization: `bearer ${loadAuthToken()}`,
          },
        })
          .then((_res) => {
            const surveyQs = _res.data.data.sort(compare).map((questionObj) => ({
              id: questionObj.sentiment_survey_question.id,
              question: questionObj.sentiment_survey_question.question,
            }));
            return this.setState({
              surveyQuestions: surveyQs,
              session: res.data,
              modules: res.data.modules,
              title: res.data.title,
              isDemo: res.data.event.demo,
              audienceUsers: res.data.audienceUsers,
              pin: res.data.pin,
              moduleType: res.data.currentModule.type,
              moduleTitle: res.data.currentModule.title,
              moduleId: res.data.currentModule.moduleId,
            });
          })
          .catch((e) => console.log(e));
      } else if (res.data.currentModule.type === 'wordCloud') {
        axios({
          method: 'get',
          url: `${process.env.REACT_APP_EVENT_SERVICE}/get-host-word-cloud-data?moduleId=${res.data.currentModule.moduleId}&sessionId=${res.data.id}`,
          headers: {
            Authorization: `bearer ${loadAuthToken()}`,
          },
        }).then((_res) => this.setState({
          wordCloudQuestions: _res.data.questions,
          initialWordCloudCount: _res.data.totalCompleted,
          wordCloudIcon: _res.data.icon,
          session: res.data,
          modules: res.data.modules,
          title: res.data.title,
          audienceUsers: res.data.audienceUsers,
          pin: res.data.pin,
          isDemo: res.data.event.demo,
          moduleType: res.data.currentModule.type,
          moduleTitle: res.data.currentModule.title,
          moduleId: res.data.currentModule.moduleId,
        }))
          .catch((e) => console.log(e));
      } else if (res.data.currentModule.type === 'poll') {
        axios({
          method: 'get',
          url: `${process.env.REACT_APP_EVENT_SERVICE}/get-host-poll-data?moduleId=${res.data.currentModule.moduleId}&sessionId=${res.data.id}`,
          headers: {
            Authorization: `bearer ${loadAuthToken()}`,
          },
        })
        // .then((_res) => console.log('res', _res))
          .then((_res) => this.setState({
            pollQuestions: _res.data.data.results,
            session: res.data,
            modules: res.data.modules,
            title: res.data.title,
            audienceUsers: res.data.audienceUsers,
            pin: res.data.pin,
            isDemo: res.data.event.demo,
            moduleType: res.data.currentModule.type,
            moduleTitle: res.data.currentModule.title,
            moduleId: res.data.currentModule.moduleId,
          }))
          .catch((e) => console.log(e));
      } else if (res.data.currentModule.type === 'wordPopcorn') {
        axios({
          method: 'get',
          url: `${process.env.REACT_APP_EVENT_SERVICE}/get-host-popcorn-data?moduleId=${res.data.currentModule.moduleId}&sessionId=${res.data.id}`,
          headers: {
            Authorization: `bearer ${loadAuthToken()}`,
          },
        }).then((_res) => {
          // console.log('Host _res', _res.data);
          this.setState({
            session: res.data,
            modules: res.data.modules,
            title: res.data.title,
            audienceUsers: res.data.audienceUsers,
            pin: res.data.pin,
            isDemo: res.data.event.demo,
            moduleType: res.data.currentModule.type,
            moduleTitle: res.data.currentModule.title,
            moduleId: res.data.currentModule.moduleId,
            wordPopcornQuestion: _res.data.data.question,
            wordPopcornAnswerLimit: _res.data.data.answerLimit,
            wordPopcornCount: _res.data.data.count,
            wordPopcornIcon: _res.data.data.iconUrl,
          });
        });
      } else {
        this.setState({
          session: res.data,
          modules: res.data.modules,
          title: res.data.title,
          audienceUsers: res.data.audienceUsers,
          pin: res.data.pin,
          isDemo: res.data.event.demo,
          moduleType: res.data.currentModule.type,
          moduleTitle: res.data.currentModule.title,
          moduleId: res.data.currentModule.moduleId,
        });
      }
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_LIVE_CHAT_SERVICE}/questions?sessionId=${res.data.id}`,
        headers: {
          Authorization: `bearer ${loadAuthToken()}`,
        },
      });
    })
      .then((res) => this.setState({ liveQuestions: res.data.data.length }))
      .catch((err) => {
        if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
          history.push('/auth/login');
        }
      });
  }

  render() {
    const { history } = this.props;
    const {
      modules,
      sideBarOpen,
      title,
      moduleId,
      audienceUsers,
      liveQuestions,
      pin,
      moduleTitle,
      moduleType,
      session,
      walkOfPrivilegeQuestions,
      surveyQuestions,
      isDemo,
      wordCloudQuestions,
      initialWordCloudCount,
      wordCloudIcon,
      pollQuestions,
      wordPopcornQuestion,
      wordPopcornAnswerLimit,
      wordPopcornCount,
      wordPopcornIcon,
    } = this.state;
    return (
      <>
        <Stats audienceUsers={audienceUsers} liveQuestions={liveQuestions} sessionId={session.id} channel={this.channel} />
        <SideBar
          selectedModule={moduleTitle}
          modules={modules}
          sideBarOpen={sideBarOpen}
          title={title}
          toggleSideBar={() => this.setState({ sideBarOpen: !sideBarOpen })}
          handleEndSession={() => this.handleEndSession()}
          handleChangeModule={(mod) => this.handleChangeModule(mod)}
        />
        <Modules
          channel={this.channel}
          isDemo={isDemo}
          moduleType={moduleType}
          moduleTitle={moduleTitle}
          moduleId={moduleId}
          wordCloudIcon={wordCloudIcon}
          history={history}
          surveyQuestions={surveyQuestions}
          wordCloudQuestions={wordCloudQuestions}
          initialWordCloudCount={initialWordCloudCount}
          walkOfPrivilegeQuestions={walkOfPrivilegeQuestions}
          sessionId={session.id}
          pollQuestions={pollQuestions}
          wordPopcornQuestion={wordPopcornQuestion}
          wordPopcornAnswerLimit={wordPopcornAnswerLimit}
          wordPopcornCount={wordPopcornCount}
          wordPopcornIcon={wordPopcornIcon}
        />
        <Pin pin={pin} />
      </>
    );
  }
}

Host.propTypes = {
  history: PropTypes.object,
};

export default Host;
