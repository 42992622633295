import React from 'react';
import CardBody from '../../../components/Card/CardBody';

function ThankYou() {
  return (
    <CardBody>
      <h4>
        Thank you for signing up! Shortly you will receive a welcome
        message from the number you can text for Diversity & Inclusion
        related questions.
      </h4>
      <br />
      <h4>
        Please program it into your phone with a name you will remember
        like: Translator Chat
      </h4>
      <br />

    </CardBody>
  );
}

export default ThankYou;
