import {
  container,
} from '../../material-dashboard-pro-react';

const wordCloudPageStyle = (theme) => ({
  container: {
    ...container,
    justifyContent: 'center',
    zIndex: '4',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '9vh',
      width: '100%',
      height: '100vh',
      alignContent: 'start',
    },
  },
  subContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: '85vh',
    textAlign: 'center',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '40%'
  },
  image: {
    width: '20vh',
  },
  errorText: {
    color: 'red',
  },
  textField: {
    borderColor: '#60A681',
  },
  addUserInput: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  inputBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconColor: {
    color: '#60A681',
  },
  unorderedList: {
    listStyleType: 'none',
    width: '100%',
    overflow: 'auto',
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    maxHeight: '50vh',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '25vh',
    },

  },
  answer: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: '15vw',
    textAlign: 'center',
    alignItems: 'center',
  },
  helperText: {
    textAlign: 'right',
  }
});

export default wordCloudPageStyle;
