/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';

// nodejs library to set properties for components
import PropTypes from 'prop-types';
import ScrollToBottom from 'react-scroll-to-bottom';

// material-ui components
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

// core components
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';

import styles from '../../assets/jss/material-dashboard-pro-react/components/navPillsStyle';

const useStyles = makeStyles(styles);

const customeStyles = {
  messesgeArea: {
    overflow: 'auto',
    position: 'absolute',
    top: '0',
    bottom: '0',
    width: '80%',
  },
  root: {
    height: '600',
    width: '400',
  },

};
const useCustomeStyles = makeStyles(customeStyles);


export default function NavPills(props) {
  const [active, setActive] = React.useState(props.active);
  const handleChange = (event, activeChange) => {
    setActive(activeChange);
    props.setConversation(props.conversations[activeChange]);
  };
  const classes = useStyles();
  const customeClasses = useCustomeStyles();
  const {
    tabs, color, horizontal, alignCenter,
  } = props;
  const flexContainerClasses = classNames({
    [classes.flexContainer]: true,
    [classes.horizontalDisplay]: horizontal !== undefined,
  });
  const tabButtons = (
    <Tabs
      classes={{
        root: classes.root,
        fixed: classes.fixed,
        flexContainer: flexContainerClasses,
        indicator: classes.displayNone,
      }}
      value={active}
      onChange={handleChange}
      centered={alignCenter}
    >
      {tabs.map((prop, key) => {
        const icon = {};
        if (prop.tabIcon !== undefined) {
          icon.icon = <prop.tabIcon className={classes.tabIcon} />;
        }
        const pillsClasses = classNames({
          [classes.pills]: true,
          [classes.horizontalPills]: horizontal !== undefined,
          [classes.pillsWithIcons]: prop.tabIcon !== undefined,
        });
        return (
          <Tab
            label={prop.tabButton}
            key={key}
            {...icon}
            classes={{
              root: pillsClasses,
              selected: classes[color],
            }}
          />
        );
      })}
    </Tabs>
  );
  const tabContent = (
    <div className={classes.contentWrapper}>
      <ScrollToBottom className={customeClasses.messesgeArea}>
        {tabs[active] ? tabs[active].tabContent : null}
      </ScrollToBottom>
    </div>
  );
  return horizontal !== undefined ? (
    <GridContainer>
      <GridItem {...horizontal.tabsGrid}>{tabButtons}</GridItem>
      <GridItem {...horizontal.contentGrid}>{tabContent}</GridItem>
    </GridContainer>
  ) : (
    <div>
      {tabButtons}
      {tabContent}
    </div>
  );
}

NavPills.defaultProps = {
  active: 0,
  color: 'primary',
};

NavPills.propTypes = {
  // index of the default active pill
  active: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  conversations: PropTypes.array.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabButton: PropTypes.string,
      tabIcon: PropTypes.object,
      tabContent: PropTypes.node,
    }),
  ).isRequired,
  color: PropTypes.oneOf([
    'primary',
    'warning',
    'danger',
    'success',
    'info',
    'rose',
  ]),
  direction: PropTypes.string,
  horizontal: PropTypes.shape({
    tabsGrid: PropTypes.object,
    contentGrid: PropTypes.object,
  }),
  setConversation: PropTypes.func,
  alignCenter: PropTypes.bool,
};
