import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
import Button from '../../../../components/CustomButtons/Button';
import styles from '../../../../assets/jss/material-dashboard-pro-react/views/wordCloud';
import WordCloudTutorial from './WordCloudTutorial';

const WordCloudWelcome = (props) => {
  const [, setTutorial] = useState('');
  const { startWordCloud } = props;
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const tutorialCompleted = Boolean(window.localStorage.getItem('word_cloud_tutorial_completed'));

  return (
    <div className={classes.container}>
      {!tutorialCompleted ? <WordCloudTutorial setTutorial={setTutorial} startWordCloud={startWordCloud} /> : null }
      <div className={classes.subContainer}>

        <img src="https://s3.amazonaws.com/join.host/Reflection2.png" style={{ width: '115px' }} alt="Word Cloud" />

        <h4 id="question">This is an example of a question or statement that might get asked.</h4>
        <div className={classes.inputBox}>
          <form>
            <TextField
              disabled
              autoFocus
              margin="dense"
              label="Your Response"
              type="text"
              value="Add Your Response Here"
            />
            <IconButton>
              <AddCircleOutlineIcon className={classes.iconColor} />
            </IconButton>
          </form>
        </div>
        <ul className={classes.unorderedList}>
          <div className={classes.answer}>
            <IconButton>
              <RemoveCircleOutlineRoundedIcon className={classes.iconColor} />
            </IconButton>
            <li>Sample Word 3</li>
          </div>
          <div className={classes.answer} id="list">
            <IconButton>
              <RemoveCircleOutlineRoundedIcon className={classes.iconColor} />
            </IconButton>
            <li>Sample Word 2</li>
          </div>
          <div className={classes.answer} id="list">
            <IconButton>
              <RemoveCircleOutlineRoundedIcon className={classes.iconColor} />
            </IconButton>
            <li>Sample Word 1</li>
          </div>
        </ul>
        <Button
          id="submitButton"
          type="submit"
          color="lightGreen"
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

WordCloudWelcome.propTypes = {
  startWordCloud: PropTypes.func.isRequired,
  // wordCloudIcon: PropTypes.string.isRequired,
  // // eslint-disable-next-line react/forbid-prop-types
  // question: PropTypes.object.isRequired,
};

export default WordCloudWelcome;
