import React from 'react';
import PropTypes from 'prop-types';
import ReactBubbleChart from 'react-bubble-chart';

import '../Audience/Modules/Comfort/style.css';
import 'react-bubble-chart/src/style.css';

// Green Gradient
const colorLegend = [
  '#2a7050',
  '#3B7E5F',
  '#48896C',
  '#519074',
  '#5C997E',
  '#5C997E',
  '#69A389',
  '#76AD94',
  '#84B8A1',
  '#92C4AE',
  '#A2D1BC',
];

function compare(a, b) {
  // Use toUpperCase() to ignore character casing
  const tagA = a.value;
  const tagB = b.value;
  let comparison = 0;
  if (tagA > tagB) {
    comparison = 1;
  } else if (tagA < tagB) {
    comparison = -1;
  }
  return comparison;
}

const tooltipProps = [{
  css: 'symbol',
  prop: '_id',
}, {
  css: 'change',
  prop: 'colorValue',
  display: 'Comfort Rank (0-10, least to most)',
}];

const BubbleGraph = ({ results }) => (
  <div style={{ width: '100vw', height: '75vh', marginTop: '-60px' }}>
    <ReactBubbleChart
        // {...props}
      fontSizeFactor={0.32}
      colorLegend={colorLegend}
      // legend={false}
      className="chart__bubble"
      key="start"
      data={
           results.sort(compare).map((tagData, index) => ({
             _id: tagData.tag,
             // eslint-disable-next-line no-restricted-properties
             value: Math.pow(tagData.value, 3),
             colorValue: index,
           }))
      }
      tooltip
      tooltipProps={tooltipProps}
      legend
      legendSpacing={0}

    />
  </div>
);

BubbleGraph.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  results: PropTypes.array.isRequired,
};

export default BubbleGraph;
