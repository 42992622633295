/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Pusher from 'pusher-js';
import Cookies from 'universal-cookie';
import feathersAuth from './feathers/authetication';
// import feathersChat from './feathers/chat';
// import feathersLiveChat from './feathers/liveChat';
// import feathersModules from './feathers/modules';
// import feathersEvents from './feathers/events';

export const AuthContext = React.createContext({});
const cookies = new Cookies();

export default function Auth({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState({});
  const [authClient, setAuthClient] = useState(null);
  // const [chatClient, setChatClient] = useState(null);
  // const [liveChatClient, setliveChatClient] = useState(null);
  // const [modulesClient, setModulesClient] = useState(null);
  const [messageState, setMessageState] = React.useState('');
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  // const [eventsClient, setEventsClient] = useState(null);
  const [pusher, setPusher] = useState(null);
  const setConnections = async () => {
    if (!authClient) {
      const auth = await feathersAuth.connect();
      setAuthClient(auth);
    }
    // if (!chatClient) {
    //   const chat = await feathersChat();
    //   setChatClient(chat);
    // }
    // if (!liveChatClient) {
    //   const liveChat = await feathersLiveChat();
    //   setliveChatClient(liveChat);
    // }
    // if (!modulesClient) {
    //   const modules = await feathersModules();
    //   setModulesClient(modules);
    // }
    // if (!eventsClient) {
    //   const events = await feathersEvents();
    //   setEventsClient(events);
    // }
  };

  const setAudienceConnections = async () => {
    // if (!liveChatClient) {
    //   const liveChat = await feathersLiveChat();
    //   setliveChatClient(liveChat);
    // }

    // if (!eventsClient) {
    //   const events = await feathersEvents();
    //   setEventsClient(events);
    // }

    // if (!chatClient) {
    //   const chat = await feathersChat();
    //   setChatClient(chat);
    // }
  };

  const removeConnections = async () => {
    if (authClient) {
      await setAuthClient(null);
    }
    // if (chatClient) {
    //   // const chat = await feathersChat();
    //   await setChatClient(null);
    // }
    // if (liveChatClient) {
    //   await setliveChatClient(null);
    // }
    // if (modulesClient) {
    //   // const modules = await feathersModules();
    //   await setModulesClient(null);
    // }
    // if (eventsClient) {
    //   await setEventsClient(null);
    // }
  };

  const setChatConnection = async () => {
    // if (!chatClient) {
    //   const chat = await feathersChat();
    //   setChatClient(chat);
    // }
  };

  // variable to create expiration 96 hours later
  const d = new Date();
  d.setTime(d.getTime() + (4 * 24 * 60 * 60 * 1000));

  const checkAuth = () => {
    if (authClient) {
      return authClient.reAuthenticate()
        .then(async (res) => {
          await setConnections(res);
          setUser(res.user);
          setIsAuthenticated(true);
          cookies.set('accessToken', res.accessToken, {
            expires: d,
            domain: process.env.REACT_APP_DOMAIN,
            path: '/',
          });
          cookies.set('user', res.user, { expires: d, domain: process.env.REACT_APP_DOMAIN, path: '/' });
          cookies.set('slideIndex', 0, { expires: d, domain: process.env.REACT_APP_DOMAIN, path: '/' });
        })
        .catch(async () => {
          if (window.location.pathname === '/' || window.location.pathname.includes('/event')) {
            setAudienceConnections();
          } else if (window.location.pathname.includes('letschat')) {
            setChatConnection();
          } else {
            removeConnections();
          }
          setIsAuthenticated(false);
        })
        .then(() => setIsLoading(false));
    }
    return feathersAuth.connect().reAuthenticate().then(async (res) => {
      await setConnections();
      setUser(res.user);
      setIsAuthenticated(true);
    })
      .catch(async () => {
        if (window.location.pathname === '/' || window.location.pathname.includes('/event')) {
          setAudienceConnections();
        } else if (window.location.pathname.includes('letschat')) {
          setChatConnection();
        } else {
          removeConnections();
        }
        setIsAuthenticated(false);
      })
      .then(() => setIsLoading(false));
  };
  useEffect(() => {
    if (!pusher) {
      setPusher(new Pusher(`${process.env.REACT_APP_PUSHER_KEY}`, {
        cluster: `${process.env.REACT_APP_PUSHER_CLUSTER}`,
      }));
    }
    checkAuth();
  }, []);
  // console.log('cookies', cookies.getAll());
  // console.log('env variable', process.env.REACT_APP_DOMAIN);


  const sendToLogin = async () => {
    await removeConnections();
    window.location.replace('/auth/login');
  };

  const login = (email, password) => feathersAuth.connect().authenticate({
    strategy: 'local',
    email,
    password,
  })
    .then(async (res) => {
      await setConnections(res);
      setUser(res.user);
      setIsAuthenticated(true);
      cookies.set('accessToken', res.accessToken, {
        expires: d,
        domain: process.env.REACT_APP_DOMAIN,
        path: '/',
      });
      cookies.set('user', res.user, { expires: d, domain: process.env.REACT_APP_DOMAIN, path: '/' });
      cookies.set('slideIndex', 0, { expires: d, domain: process.env.REACT_APP_DOMAIN, path: '/' });
    })
    .catch((error) => {
      alert(error);
      setIsAuthenticated(false);
    });
  /*
  const logout = () => {
    authLogout();
    setIsAuthenticated(false);
  };

  const signUp = (credentials) => authSignUp(credentials)
    .then(setIsAuthenticated(true))
    .catch((error) => {
      alert(error);
      setIsAuthenticated(false);
    });
*/
  return (
    <AuthContext.Provider value={{
      isAuthenticated,
      isLoading,
      login,
      user,
      pusher,
      // eventsClient,
      authClient,
      sendToLogin,
      // chatClient,
      // liveChatClient,
      // modulesClient,
      messageState,
      snackBarMessage,
      setMessageState,
      setSnackBarMessage,
    }}
    >
      {children}
    </AuthContext.Provider>
  );
}

Auth.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.array,
  ]),
};
