/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import { loadAuthToken } from '../../../utils/auth';
import Button from '../../../components/CustomButtons/Button';
import { AuthContext } from '../../../Auth';
// import Comment from '../../../assets/img/comment.png';
import ComfortResults from './Comfort/ComfortResults';

const useStyles = makeStyles({
  outerDiv: {
    margin: 'auto',
    textAlign: 'center',
  },
  imageDiv: {
    // paddingTop: '1rem',
    paddingBottom: '2rem',
  },
  contentDiv: {
    paddingTop: '2.5rem',
  },
  text: {
    fontSize: '1.4rem',
  },
  largeText: {
    fontSize: '1.6rem',
  },
  messageIcon: {
    color: '#6CCA98',
    height: '100px',
    width: '100px',
  },
  image: {
    width: '300px',
    marginBottom: '5px',
  },
});

const Comfort = ({
  moduleTitle,
  sessionId,
  moduleId,
  channel,
}) => {
  const [usersAnswered, setUsersAnswered] = useState(0);
  const [isViewingResults, setViewingResults] = useState(false);
  const context = useContext(AuthContext);

  const getFinishedUsers = () => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/comfort-answered?sessionId=${sessionId}&moduleId=${moduleId}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).then((res) => {
      if (res.data === 'your token is not valid' || res.data === 'Your token is expired') {
        context.sendToLogin();
      }
      setUsersAnswered(res.data.usersAnswered);
    }).catch((err) => {
      if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
        context.sendToLogin();
      }
    });
  };

  useEffect(() => {
    getFinishedUsers();
    channel.bind('comfort-answer', () => {
      getFinishedUsers();
    });
    return () => {
      channel.unbind('comfort-answer');
    };
  }, [moduleId]);

  const classes = useStyles();
  if (isViewingResults) {
    return (
      <ComfortResults
        setViewingFalse={() => setViewingResults(false)}
        isViewingResults={isViewingResults}
        sessionId={sessionId}
        moduleId={moduleId}
        usersAnswered={usersAnswered}
      />
    );
  }
  return (
    <div className={classes.outerDiv}>
      <div className={classes.imageDiv}>
        <img src="https://s3.amazonaws.com/join.host/comfortzone.png" className={classes.image} alt="Comfort" />
      </div>
      <Divider />
      <div className={classes.contentDiv}>
        <p className={classes.largeText}><strong>{moduleTitle}</strong></p>
        <br />
        <p className={classes.text}>
          Please reflect on the following statements
          {' '}
          <br />
          and decide how comfortable you are in each situation.
        </p>
        <br />
        <p className={classes.largeText}>COMPLETIONS</p>
        <p className={classes.largeText}>
          <strong>
            {' '}
            {usersAnswered
              ? String(usersAnswered)
              : '0'}
          </strong>
        </p>
        <div
          width="100%"
          align="flex-end"
          style={{ right: '2vw', bottom: '1vh', position: 'absolute' }}
        >
          <Button
            // color="lightGreen"
            onClick={() => {
              setViewingResults(true);
            }}
          >
            Results
          </Button>
        </div>
      </div>
    </div>
  );
};

Comfort.propTypes = {
  moduleTitle: PropTypes.string.isRequired,
  sessionId: PropTypes.number.isRequired,
  moduleId: PropTypes.number.isRequired,
  channel: PropTypes.object.isRequired,
};

export default Comfort;
