/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Comment from '../../../../assets/img/comment.png';
import { AuthContext } from '../../../../Auth';
import { loadAuthToken } from '../../../../utils/auth';

const useStyles = makeStyles({
  outerDiv: {
    margin: 'auto',
    textAlign: 'center',
  },
  imageDiv: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  contentDiv: {
    paddingTop: '.5rem',
  },
  text: {
    fontSize: '1.4rem',
  },
  largeText: {
    fontSize: '2rem',
  },
  messageIcon: {
    color: '#6CCA98',
    height: '100px',
    width: '100px',
  },
  image: {
    width: '160px',
    marginBottom: '5px',
  },
});

const Survey = ({
  moduleTitle,
  sessionId,
  moduleId,
}) => {
  const [usersAnswered, setUsersAnswered] = useState(0);
  const context = useContext(AuthContext);

  const getFinishedUsers = () => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/key-word-survey-answered?sessionId=${sessionId}&moduleId=${moduleId}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).then((res) => {
      if (res.data === 'your token is not valid' || res.data === 'Your token is expired') {
        context.sendToLogin();
      }
      setUsersAnswered(res.data.usersAnswered);
    }).catch((err) => {
      if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
        context.sendToLogin();
      }
    });
    context.eventsClient.service('key-word-survey-answer').once('created', () => getFinishedUsers());
  };

  useEffect(() => {
    getFinishedUsers();
  }, [moduleId]);

  const classes = useStyles();
  return (
    <div className={classes.outerDiv}>
      <div className={classes.imageDiv}>
        <img src={Comment} className={classes.image} alt="Survey" />
      </div>
      <Divider />
      <div className={classes.contentDiv}>
        <p className={classes.largeText}>{moduleTitle}</p>
        <br />
        <p className={classes.text}>
          Text for survey goes here
        </p>
        <p className={classes.text}>
          More text goes here
        </p>
        <p className={classes.text}>
          Even more text goes here
        </p>
        <br />
        <p className={classes.largeText}>RESPONSES</p>
        <strong>
          <p className={classes.text}>
            {usersAnswered
              ? String(usersAnswered)
              : '0'}
          </p>
        </strong>
        <div
          width="100%"
          align="flex-end"
          style={{ right: '5vw', bottom: '7vh', position: 'absolute' }}
        />
      </div>
    </div>
  );
};

Survey.propTypes = {
  moduleTitle: PropTypes.string.isRequired,
  sessionId: PropTypes.number.isRequired,
  moduleId: PropTypes.number.isRequired,
};

export default Survey;
