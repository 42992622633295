/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/static-property-placement */
/* eslint-disable no-lonely-if */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import FormControl from '@material-ui/core/FormControl';

// core components
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import CustomInput from '../../../components/CustomInput/CustomInput';

import customSelectStyle from '../../../assets/jss/material-dashboard-pro-react/customSelectStyle';
import customListStyle from '../../../assets/jss/material-dashboard-pro-react/customListStyle';
import customCheckboxRadioSwitch from '../../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch';
import { AuthContext } from '../../../Auth';

const style = {
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center',
  },
  inputAdornmentIcon: {
    color: '#555',
  },
  choiche: {
    textAlign: 'center',
    cursor: 'pointer',
    marginTop: '20px',
  },
  ...customListStyle,
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
};

class Step2 extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      contactName: '',
      contactEmail: '',
      createChatCompany: false,
    };
  }

  sendState() {
    return this.state;
  }

  // this is used by the wizard to see if all required are filled out
  isValidated() {
    return true;
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <h4 className={classes.infoText}>Some extra details about the company contact</h4>
        <GridContainer justifyContent="center">
          <GridItem xs={12} md={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <CustomInput
                labelText={(
                  <span>
                    Name of company contact
                    {' '}
                  </span>
            )}
                id="name"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (event) => {
                    this.setState({ contactName: event.target.value });
                  },
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      className={classes.inputAdornment}
                    >
                      <ViewModuleIcon className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                }}
              />
              <CustomInput
                labelText={(
                  <span>
                    Email of company contact
                    {' '}
                  </span>
                )}
                id="email"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (event) => {
                    this.setState({ contactEmail: event.target.value });
                  },
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      className={classes.inputAdornment}
                    >
                      <ViewModuleIcon className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Step2.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(style)(Step2);
