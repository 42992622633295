/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  DataGrid,
} from '@material-ui/data-grid';

import { AuthContext } from '../../../../Auth';
import { loadAuthToken } from '../../../../utils/auth';

import Button from '../../../../components/CustomButtons/Button';
import Dialog from '../../../../components/Alert/alert';

export default function WordCloudGrid(props) {
  const [newHeaders, setNewHeaders] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const {
    data,
  } = props;

  const context = useContext(AuthContext);

  const headers = [
    {
      headerName: 'Question', field: 'question', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Description', field: 'description', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Icon', field: 'icon', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Status', field: 'status', width: 250, disableClickEventBubbling: true,
    },
  ];

  const archiveWordCloud = (questionId) => {
    axios({
      method: 'patch',
      url: `${process.env.REACT_APP_MODULE_SERVICE}/word-cloud-question/${questionId}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
      data: {
        status: 'archived',
      },
    }).then(() => {
      context.setMessageState('success');
      context.setSnackBarMessage(`Successfully archived ${selectedRowId.title} info module.`);
      setFilteredData(filteredData.filter((el) => el.id !== questionId));
      setSelectedRowId({});
      // this.getInfoModules();
    }).catch((e) => {
      context.setMessageState('error');
      context.setSnackBarMessage(`${e.response.data.message}`);
    });
  };

  useEffect(() => {
    const headersWithAddedButton = [
      {
        headerName: 'Archive Question',
        // eslint-disable-next-line no-useless-concat
        field: 'id' + 'ar',
        width: 200,
        disableClickEventBubbling: true,
        renderCell: (wc) => (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                setSelectedRowId(wc.row);
                setIsDialogOpen(!isDialogOpen);
              }}
            >
              Archive
            </Button>
          </div>
        ),
      },
      ...headers];
    setFilteredData(data);
    setNewHeaders(headersWithAddedButton);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div style={{ height: 600, width: '100%' }}>
      <Dialog
        open={isDialogOpen}
        toggleDialog={setIsDialogOpen}
        text={`Are you sure you want to archive ${selectedRowId.title ? selectedRowId.title : ''}`}
        rowId={selectedRowId.id ? selectedRowId.id : ''}
        action={archiveWordCloud}
      />
      <DataGrid
        rows={filteredData}
        columns={newHeaders}
        sortingOrder={['asc', 'desc']}
        sortModel={[
          {
            field: 'question',
            sort: 'asc',
          },
        ]}
      />
    </div>
  );
}

WordCloudGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};
