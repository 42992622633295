/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-trailing-spaces */
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../../../components/CustomButtons/Button';
import CustomInput from '../../../components/CustomInput/CustomInput';
import { AuthContext } from '../../../Auth';
import { loadAuthToken } from '../../../utils/auth';
// import Tags from './Wizard/QuestionTags';

const EditStatementDialog = (props) => {
  const { open, togalModal, statementObj } = props;
  const [statementError, setStatementError] = useState(false);
  const [statement, setStatement] = useState('');
  const [options, setOptions] = useState(['Not Comfortable At All', 'Uneasy', 'Fairly Comfortable', 'Completely Comfortable']);
  const [optionsError, setOptionsError] = useState([]);
  const { user, setMessageState, setSnackBarMessage } = useContext(AuthContext);

  useEffect(() => {
    setStatement(statementObj.statement);
    if (statementObj.options) {
      setOptions(statementObj.options);
    } else {
      setOptions([]);
    }
  }, [statementObj]);

  const handleSubmit = () => {
    let isError = false;
    if (statement.length === 0) {
      isError = true;
      setStatementError(true);
    }
    const errorArray = [];
    options.forEach((option, index) => {
      if (option === '') {
        isError = true;
        errorArray.push(index);
      }
      if (typeof option !== 'string') {
        isError = true;
        errorArray.push(index);
      }
      setOptionsError(errorArray);
    });
    if (!isError) {
      axios({
        method: 'patch',
        url: `${process.env.REACT_APP_MODULE_SERVICE}/comfort-meter-question/${statementObj.id}`,
        headers: {
          Authorization: `bearer ${loadAuthToken()}`,
        },
        data: { 
          statement,
          options,
          image: statementObj.image,
          userId: user.id,
        },
      }).then(() => {
        setMessageState('success');
        setSnackBarMessage(`Successfully created comfort statement: ${statement}`);
        setStatement('');
        setOptions(['Not Comfortable At All', 'Uneasy', 'Fairly Comfortable', 'Completely Comfortable']);
        setOptionsError([]);
        setStatementError(false);
        togalModal();
      }).catch((err) => {
        setMessageState('error');
        setSnackBarMessage('Can not edit Statement! Contact an administrator!');
        if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
          props.history.push('auth/login');
        }
      });
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => togalModal()}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: '100%',
            minHeight: '60%',
          },
        }}
      >
        <DialogTitle id="form-dialog-title">Create new Comfort Statement</DialogTitle>
        <DialogContent>
          <CustomInput
            // success={userQuestionState === 'success'}
            error={statementError}
            labelText={(
              <span>
                Statement
                {' '}
                <small>(required)</small>
              </span>
            )}
            id="question"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: statement,
              onChange: (event) => {
                if (statementError) setStatementError(false);
                setStatement(event.target.value);
              },
            }}
          />
          <>
            {options.map((option, index) => {
              if (index === 0) {
                return (
                  <div key={`option ${index + 232}`} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <CustomInput
                      // style={}
                      // success={userQuestionState === 'success'}
                      error={optionsError.includes(index)}
                      key={`option ${index + 232}`}
                      labelText={(
                        <span>
                          Option 
                          {' '}
                          {index + 1}
                          {' '}
                          <small>(required)</small>
                        </span>
                      )}
                      id={`option ${index}`}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: options[index],
                        onChange: (event) => {
                          if (optionsError.includes(index)) {
                            const i = optionsError.indexOf(index);
                            if (i > -1) {
                              const errorCopy = [...optionsError];
                              errorCopy.splice(i, 1);
                              setOptionsError(errorCopy);
                            }
                          } 
                          const optionsCopy = [...options];
                          optionsCopy[index] = event.target.value;
                          setOptions(optionsCopy);
                        },
                      }}
                    />
                    <IconButton 
                      onClick={() => {
                        setOptions([...options, '']);
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </div>
                ); 
              } if (index + 1 === options.length) {
                return (
                  <div key={`option ${index + 303}`} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <CustomInput
                  // success={userQuestionState === 'success'}
                      error={optionsError.includes(index)}
                      labelText={(
                        <span>
                          Option 
                          {' '}
                          {index + 1}
                          {' '}
                          <small>(required)</small>
                        </span>
                    )}
                      id={`option ${index}`}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: options[index],
                        onChange: (event) => {
                          if (optionsError.includes(index)) {
                            const i = optionsError.indexOf(index);
                            if (i > -1) {
                              const errorCopy = [...optionsError];
                              errorCopy.splice(i, 1);
                              setOptionsError(errorCopy);
                            }
                          } 
                          const optionsCopy = [...options];
                          optionsCopy[index] = event.target.value;
                          setOptions(optionsCopy);
                        },
                      }}
                    />
                    <IconButton onClick={() => {
                      const newOptions = [...options];
                      newOptions.pop();
                      setOptions(newOptions);
                    }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                ); 
              } 
              return (
                <CustomInput
                // success={userQuestionState === 'success'}
                  error={optionsError.includes(index)}
                  key={`option ${index}`}
                  labelText={(
                    <span>
                      Option 
                      {' '}
                      {index + 1}
                      {' '}
                      <small>(required)</small>
                    </span>
                )}
                  id={`option ${index}`}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: options[index],
                    onChange: (event) => {
                      if (optionsError.includes(index)) {
                        const i = optionsError.indexOf(index);
                        if (i > -1) {
                          const errorCopy = [...optionsError];
                          errorCopy.splice(i, 1);
                          setOptionsError(errorCopy);
                        }
                      } 
                      const optionsCopy = [...options];
                      optionsCopy[index] = event.target.value;
                      setOptions(optionsCopy);
                    },
                  }}
                />
              );
            })} 
          </> 
        </DialogContent>
        <DialogActions>
          <Button onClick={() => togalModal()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleSubmit()}>
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

EditStatementDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  togalModal: PropTypes.func.isRequired,
  history: PropTypes.object,
  statementObj: PropTypes.object.isRequired,
};

export default EditStatementDialog;
