import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import AddIcon from '@material-ui/icons/Add';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CloseIcon from '@material-ui/icons/Close';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../../../components/CustomButtons/Button';
import CustomInput from '../../../components/CustomInput/CustomInput';
import { AuthContext } from '../../../Auth';
import { loadAuthToken } from '../../../utils/auth';
// import Tags from './Wizard/QuestionTags';

const AddQuestionDialog = (props) => {
  const { open, togalModal, history } = props;
  const [questionError, setQuestionError] = useState(false);
  const [optionsError, setOptionsError] = useState([]);
  const [userQuestion, setUserQuestion] = useState('');
  const [options, setOptions] = useState([]);
  const [type, setType] = useState('');
  const [chartType, setChartType] = useState('bar');
  const { setMessageState, setSnackBarMessage } = useContext(AuthContext);
  const [lang, setLang] = useState('');

  const languages = [
    { language: 'English', code: 'en' },
    { language: 'French', code: 'fr' },
    { language: 'Spanish', code: 'es' }];

  const handleSubmit = () => {
    let isError = false;
    if (userQuestion.length === 0) {
      isError = true;
      setQuestionError(true);
    }
    if (options.some((option) => option === '')) {
      isError = true;
      const errorArray = [];
      options.forEach((option, index) => {
        if (option === '') {
          errorArray.push(index);
        }
        setOptionsError(errorArray);
      });
    }
    if (!isError) {
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_MODULE_SERVICE}/poll-question`,
        headers: {
          Authorization: `bearer ${loadAuthToken()}`,
        },
        data: {
          question: userQuestion,
          lang,
          type,
          options: {
            options: options.map(((op) => ({
              option: op,
            }))),
          },
          chartType,
        },
      }).then(() => {
        setOptions([]);
        setType('');
        setMessageState('success');
        setSnackBarMessage(`Successfully created survey question: ${userQuestion}`);
        setUserQuestion('');
        setOptionsError([]);
        setQuestionError(false);
        // setTags([]);
        togalModal();
      }).catch((err) => {
        setMessageState('error');
        setSnackBarMessage(`${err.response.data.message}`);
        if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
          history.push('auth/login');
        }
      });
    }
  };

  return (
    <div>
      <Dialog
        maxWidth="md"
        open={open}
        onClose={() => togalModal()}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: '100%',
            minHeight: '60%',
          },
        }}
      >
        <DialogTitle id="form-dialog-title">Add New Question</DialogTitle>
        <DialogContent>
          <CustomInput
            error={questionError}
            labelText={(
              <span>
                Question
                {' '}
                <small>(required)</small>
              </span>
            )}
            id="question"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: userQuestion,
              onChange: (event) => {
                if (questionError) setQuestionError(false);
                setUserQuestion(event.target.value);
              },
            }}
          />
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', flexDirection: 'column', flexBasis: '60%' }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Question Type</FormLabel>
                <RadioGroup
                  value={type}
                  onChange={(e) => {
                    const selectedType = e.target.value;
                    if (!(options.length > 0)) {
                      setOptions(['']);
                    }
                    setType(selectedType);
                  }}
                >
                  <FormControlLabel value="multipleChoice" control={<Radio />} label="Multiple Choice Single Select" />
                  <FormControlLabel value="multiSelect" control={<Radio />} label="Multiple Choice Many Select" />
                </RadioGroup>
              </FormControl>
              <>
                {options.map((option, index) => {
                  if (index === 0) {
                    return (
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <CustomInput
                      // style={}
                      // success={userQuestionState === 'success'}
                          error={optionsError.includes(index)}
                          key={`option ${index}`}
                          labelText={(
                            <span>
                              Option
                              {' '}
                              {index + 1}
                              {' '}
                              <small>(required)</small>
                            </span>
                      )}
                          id={`option ${index}`}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: options[index],
                            onChange: (event) => {
                              if (optionsError.includes(index)) {
                                const i = optionsError.indexOf(index);
                                if (i > -1) {
                                  const errorCopy = [...optionsError];
                                  errorCopy.splice(i, 1);
                                  setOptionsError(errorCopy);
                                }
                              }
                              const optionsCopy = [...options];
                              optionsCopy[index] = event.target.value;
                              setOptions(optionsCopy);
                            },
                          }}
                        />
                        <IconButton
                          onClick={() => {
                            setOptions([...options, '']);
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      </div>
                    );
                  } if (index + 1 === options.length) {
                    return (
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <CustomInput
                  // success={userQuestionState === 'success'}
                          error={optionsError.includes(index)}
                          key={`option ${index}`}
                          labelText={(
                            <span>
                              Option
                              {' '}
                              {index + 1}
                              {' '}
                              <small>(required)</small>
                            </span>
                    )}
                          id={`option ${index}`}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: options[index],
                            onChange: (event) => {
                              if (optionsError.includes(index)) {
                                const i = optionsError.indexOf(index);
                                if (i > -1) {
                                  const errorCopy = [...optionsError];
                                  errorCopy.splice(i, 1);
                                  setOptionsError(errorCopy);
                                }
                              }
                              const optionsCopy = [...options];
                              optionsCopy[index] = event.target.value;
                              setOptions(optionsCopy);
                            },
                          }}
                        />
                        <IconButton onClick={() => {
                          const newOptions = [...options];
                          newOptions.pop();
                          setOptions(newOptions);
                        }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    );
                  }
                  return (
                    <CustomInput
                      error={optionsError.includes(index)}
                      key={option}
                      labelText={(
                        <span>
                          Option
                          {' '}
                          {index + 1}
                          {' '}
                          <small>(required)</small>
                        </span>
                )}
                      id={`option ${index}`}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: options[index],
                        onChange: (event) => {
                          if (optionsError.includes(index)) {
                            const i = optionsError.indexOf(index);
                            if (i > -1) {
                              const errorCopy = [...optionsError];
                              errorCopy.splice(i, 1);
                              setOptionsError(errorCopy);
                            }
                          }
                          const optionsCopy = [...options];
                          optionsCopy[index] = event.target.value;
                          setOptions(optionsCopy);
                        },
                      }}
                    />
                  );
                })}
              </>
            </div>
            <div style={{ flexBasis: '40%' }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Chart type for results display</FormLabel>
                <RadioGroup value={chartType} defaultChecked defaultValue={chartType} onChange={(e) => setChartType(e.target.value)}>
                  <FormControlLabel value="bar" control={<Radio />} label="Bar Chart" />
                  <FormControlLabel value="doughnut" control={<Radio />} label="Doughnut Chart" />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div>
            <InputLabel id="lang-select-label">Select Language</InputLabel>
            <Select
              style={{ width: 300 }}
              labelId="lang-select"
              id="lang-select"
              value={lang}
              onChange={(e) => setLang(e.target.value)}
            >
              <MenuItem value="" disabled>
                Select Language
              </MenuItem>
              {languages.map((language) => (
                <MenuItem value={language.code} key={language.code}>{language.language}</MenuItem>
              ))}
            </Select>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => togalModal()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleSubmit()}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AddQuestionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  togalModal: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object,
};

export default AddQuestionDialog;
