/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-plusplus */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import 'chartjs-plugin-labels';

/*
  Each label is each option.
  each dataSet is a count of how many are equal to that option
  if using json options. the other answers are free-form, add that to count
  example: {
    question: { are you a man }
    options: {yes, no, maybe, [input]}
    answers: {
      yes: 23,
      no: 22,
      maybe: 5,
      freeform: 2
    }
    labels: [
      yes,
      no,
      maybe,
      freeForm
    ]
    datasets: [{
      data: [23, 22, 5, 2]
    }]
  }

*/

const dataClosure = (labels = [], data = []) => ({
  labels,
  datasets: [{
    data,
    backgroundColor: [
      '#FF6384',
      '#36A2EB',
      '#FFCE56',
      '#1474E4',
      '#2A7948',
      '#A5C2E6',
      '#E6A77A',
      '#6B48D0',
      '#BF0CEB',
      '#ADBB48',
    ],
    hoverBackgroundColor: [
      '#FF6384',
      '#36A2EB',
      '#FFCE56',
      '#1474E4',
      '#2A7948',
      '#A5C2E6',
      '#E6A77A',
      '#6B48D0',
      '#BF0CEB',
      '#ADBB48',
    ],
  }],
});

const PieChart = (props) => {
  const { question, answers } = props;
  const [labels, setLabels] = useState([]);
  const [dataSet, setDataSet] = useState([]);
  // const [ setData, data] = useState([])
  useEffect(() => {
    // setLabels
    if (question?.json_options) {
      if (question?.json_options?.options?.length) {
        // setLabels(question.json_options.map(_option => _option.option))
        // let hasFreeForm = false;
        const _labels = question.json_options.options.map((_option) => {
          if (_option.type === 'select' || _option.type === 'multipleChoice' || _option.type === 'multiSelect' || _option.type === 'slider') {
            return _option.option;
          }
          // hasFreeForm = true;
          return 'other';
        });
        setLabels(_labels);
      } else if (question?.options) {
        if (question.options.length) {
          setLabels(question.options);
        }
      }
    } else if (question?.options) {
      if (question.options.length) {
        setLabels(question.options);
      }
    }
  }, []);
  useEffect(() => {
    if (labels?.length) {
      const obj = labels.reduce((a, key) => Object.assign(a, { [key]: 0 }), {});
      answers.forEach((answer) => {
        if (typeof obj[answer] === 'number') {
          obj[answer]++;
        } else {
          obj.other++;
        }
      });
      const returnArr = [];
      for (const property in obj) {
        const index = labels.indexOf(property);
        returnArr[index] = obj[property];
      }
      setDataSet(returnArr);
    }
  }, [labels]);
  return (
    <div style={{ width: '60vw', height: '70vh' }}>
      <Doughnut
        data={dataClosure(labels, dataSet)}
        options={{
          responsive: true,
          maintainAspectRatio: true,
          tooltips: {
            callbacks: {
              label(tooltipItem, data) {
                const dataset = data.datasets[tooltipItem.datasetIndex];
                const meta = dataset._meta[Object.keys(dataset._meta)[0]];
                const { total } = meta;
                const currentValue = dataset.data[tooltipItem.index];
                const percentage = parseFloat((currentValue / total * 100).toFixed(1));
                return `${currentValue} (${percentage}%)`;
              },
              title(tooltipItem, data) {
                return data.labels[tooltipItem[0].index];
              },
            },
          },
          legend: {
            position: 'bottom',
          },
          plugins: {
            labels: {
              render: 'percentage',
              fontColor: 'black',
              position: 'outside',
              precision: 2,
              fontSize: 16,
              textMargin: 10,
            },
          },
        }}
      />
    </div>
  );
};

PieChart.propTypes = {
  answers: PropTypes.array.isRequired,
  question: PropTypes.object.isRequired,
};

export default PieChart;
