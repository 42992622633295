/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
// import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Card from '../../../../components/Card/Card';
import CardFooter from '../../../../components/Card/CardFooter';
import { AuthContext } from '../../../../Auth';
import CardBody from '../../../../components/Card/CardBody';
import CardHeader from '../../../../components/Card/CardHeader';
import styles from '../../../../assets/jss/material-dashboard-pro-react/views/reportPageStyle';
import SurveyTable from './SurveyTable';
import SurveyGraph from './SurveyGraph';

const useStyles = makeStyles(styles);

function SurveyReport(props) {
  const classes = useStyles();
  const context = useContext(AuthContext);
  // const [data, setData] = useState({});
  const [toggle, setToggle] = useState(false);
  const [toggleCard, setToggleCard] = useState(false);
  const [qas, setQas] = useState([]);
  const [completed, setCompleted] = useState(0);
  const [shouldDisplay, setShouldDisplay] = useState(true);
  const {
    moduleInfo, sessionId, companyId,
  } = props;

  useEffect(() => {
    if (companyId) {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_EVENT_SERVICE}/company-survey-report?companyId=${companyId}&moduleId=${moduleInfo.id}`,
      }).then((res) => {
        if (res.data.answers.length) {
          setCompleted(res.data.completed);
          setQas(res.data.answers);
        } else {
          setShouldDisplay(false);
        }
      }).catch((e) => {
        try {
          context.setMessageState('error');
          context.setSnackBarMessage(`${e.response.data.message}`);
        } catch (err) {
          console.log(e);
        }
      });
    } else if (sessionId) {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_EVENT_SERVICE}/survey-report?sessionId=${sessionId}&moduleId=${moduleInfo.id}`,
      }).then((res) => {
        if (res.data.answers.length) {
          setCompleted(res.data.completed);
          setQas(res.data.answers);
        } else {
          setShouldDisplay(false);
        }
      }).catch((e) => {
        context.setMessageState('error');
        context.setSnackBarMessage(`${e.response.data.message}`);
      });
    }
  }, [companyId, sessionId]);
  const handleChange = (event) => {
    setToggle(event.target.checked);
  };
  return (
    <>
      {shouldDisplay ? (
        <>
          {qas ? (
            <Card>
              <CardHeader className={classes.headerAlign}>
                <div className={classes.cardCategory}>
                  {moduleInfo.title ? moduleInfo.title : moduleInfo.moduleTitle}
                  <Switch
                    checked={toggle}
                    name="table"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  {toggleCard
                    ? (
                      <KeyboardArrowUpIcon
                        onClick={() => setToggleCard(!toggleCard)}
                        className={classes.clickable}
                      />
                    )
                    : (
                      <KeyboardArrowDownIcon
                        onClick={() => setToggleCard(!toggleCard)}
                        className={classes.clickable}
                      />
                    )}
                </div>
              </CardHeader>
              <Collapse in={toggleCard} unmountOnExit>
                <CardBody className={classes.reportCardBody}>
                  {toggle ? <SurveyGraph questions={qas} /> : <SurveyTable questions={qas} />}
                </CardBody>
              </Collapse>
              <CardFooter stats>
                <div className={classes.stats}>
                  <p className={classes.cardTitle}>
                    Completed:
                    {' '}
                    {String(completed)}
                  </p>
                </div>
              </CardFooter>
            </Card>

          ) : <CircularProgress />}
        </>
      ) : (
        null
      )}

    </>
  );
}

SurveyReport.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  moduleInfo: PropTypes.object.isRequired,
  sessionId: PropTypes.number,
  companyId: PropTypes.number,
};

export default SurveyReport;
