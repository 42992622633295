/* eslint-disable react/no-danger */
/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import classNames from 'classnames';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Hidden from '@material-ui/core/Hidden';
import Popper from '@material-ui/core/Popper';
import Divider from '@material-ui/core/Divider';

// @material-ui/icons
import Person from '@material-ui/icons/Person';
import Notifications from '@material-ui/icons/Notifications';

// core components
import Button from '../CustomButtons/Button';
import { AuthContext } from '../../Auth';
import styles from '../../assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle';
import { loadAuthToken } from '../../utils/auth';

// import Updates from './MockNotifications';

const useStyles = makeStyles(styles);

// Getting updates within the last 30 days (now on back end)
// const today = new Date();
// const thirtyDaysAgo = new Date(new Date().setDate(today.getDate() - 30));

export default function HeaderLinks() {
  const context = useContext(AuthContext);

  const [openNotification, setOpenNotification] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);
  const [lastSeenNotification, setLastSeenNotification] = useState(window.localStorage.getItem('lastSeenUpdate'));
  const [badgeVisibility, setBadgeVisibility] = useState('hidden');
  const [data, setData] = useState([]);
  const [lastItemId, setLastItemId] = useState(0);
  const [indexOfLastSeen, setIndexOfLastSeen] = useState(0);
  const [numberOfNewUpdates, setNumberOfNewUpdates] = useState(0);

  useEffect(() => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_AUTH_SERVICE}/platform-updates`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).then((res) => {
      // console.log(res.data);
      setData(res.data.map((update) => ({
        id: update.id, createdAt: new Date(update.createdAt), update: update.text, resourceId: update.resourceId,
      })));
      return res;
    })
      .catch((e) => {
        console.log(e);
        context.setMessageState('error');
        context.setSnackBarMessage(`${e.response.data.message}`);
      });
  }, []);

  useEffect(() => {
    setLastItemId(data.length ? data.slice(-1)[0].id : 0);
  }, [data]);

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    setIndexOfLastSeen(data.length ? data.findIndex((x) => x.id == lastSeenNotification) : 0);
  }, [lastSeenNotification, data]);

  useEffect(() => {
    setNumberOfNewUpdates(data.length ? data.slice(indexOfLastSeen + 1).length : 0);
  }, [indexOfLastSeen, data]);

  useEffect(() => {
    if (numberOfNewUpdates > 0) {
      setBadgeVisibility('visible');
    } else {
      setBadgeVisibility('hidden');
    }
  }, [numberOfNewUpdates, indexOfLastSeen]);

  const { sendToLogin } = useContext(AuthContext);
  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
      window.localStorage.setItem('lastSeenUpdate', lastItemId);
      setLastSeenNotification(lastItemId);
    } else {
      setOpenNotification(event.currentTarget);
      window.localStorage.setItem('lastSeenUpdate', lastItemId);
      setLastSeenNotification(lastItemId);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };

  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  const classes = useStyles();
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover);

  const managerClasses = classNames({
    [classes.managerClasses]: true,
  });

  const reverse = (array) => [...array].reverse();
  return (
    <div>
      <div className={managerClasses}>
        <Button
          color="transparent"
          justIcon
          aria-label="Notifications"
          aria-owns={openNotification ? 'notification-menu-list' : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={classes.buttonLink}
        >
          <Notifications
            className={
              `${classes.headerLinksSvg
              } ${classes.links}`
            }
          />
          <span id="update-badge" className={classes.notifications} style={{ visibility: `${badgeVisibility}` }}>
            {numberOfNewUpdates}
          </span>
          <Hidden mdUp implementation="css">
            <span
              onClick={handleClickNotification}
              className={classes.linkText}
              role="button"
              onKeyPress={handleClickNotification}
              tabIndex="0"
            >
              Notification
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openNotification,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...TransitionProps}
              id="notification-menu-list"
              style={{ transformOrigin: '0 0 0' }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">
                    {reverse(data).map((update) => (
                      <MenuItem
                        style={{ cursor: 'default', maxWidth: '450px', whiteSpace: 'normal' }}
                        key={update.update}
                        onClick={handleCloseNotification}
                        className={dropdownItem}
                      >
                        <div dangerouslySetInnerHTML={{ __html: update.update }} />
                        {/* {update.update}
                        { ' '} */}
                        {update.resourceId > 0 ? <div><Link to={`/admin/resource/${update.resourceId}`} style={{ color: 'blue' }}>view resource</Link></div> : '' }
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>

      <div className={managerClasses}>
        <Button
          color="transparent"
          aria-label="Person"
          justIcon
          aria-owns={openProfile ? 'profile-menu-list' : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <Person
            className={
              `${classes.headerLinksSvg
              } ${classes.links}`
            }
          />
          <Hidden mdUp implementation="css">
            <span
              onClick={handleClickProfile}
              className={classes.linkText}
              onKeyPress={handleClickProfile}
              role="button"
              tabIndex="0"
            >
              {' '}
              Profile
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openProfile,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...TransitionProps}
              id="profile-menu-list"
              style={{ transformOrigin: '0 0 0' }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    {/* <MenuItem
                      onClick={handleCloseProfile}
                      className={dropdownItem}
                    >
                      Profile
                    </MenuItem> */}
                    <Link to="/admin/settings" style={{ color: '#333' }}>
                      <MenuItem
                        onClick={() => {
                          handleCloseProfile();
                        }}
                        className={dropdownItem}
                      >
                        Settings
                      </MenuItem>
                    </Link>
                    <Divider light />
                    <MenuItem
                      onClick={() => {
                        window.localStorage.removeItem('feathers-jwt');
                        sendToLogin();
                      }}
                      className={dropdownItem}
                    >
                      Log out
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

HeaderLinks.propTypes = {
};
