/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
/* eslint-disable global-require */
import React, { useContext, useState, useEffect } from 'react';
import cx from 'classnames';
import { Switch, Route, Redirect } from 'react-router-dom';
// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
// import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import AdminNavbar from '../components/Navbars/AdminNavbar';
import Footer from '../components/Footer/Footer';
// eslint-disable-next-line import/no-named-as-default-member
import Sidebar from '../components/Sidebar/Sidebar';
import Logo from '../assets/img/full-logo-color.png';

import routes from '../routes';

import styles from '../assets/jss/material-dashboard-pro-react/layouts/adminStyle';
import SnackBar from '../components/Snackbar/Snackbar';

import { AuthContext } from '../Auth';

let ps;
const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const {
    messageState,
    snackBarMessage,
    setMessageState,
    setSnackBarMessage,
    user,
  } = useContext(AuthContext);

  const [mobileOpen, setMobileOpen] = useState(false);
  const [miniActive, setMiniActive] = useState(false);
  const [color] = useState('translatorGreen');
  const [bgColor] = useState('white');
  const [allowedRoutes, setAllowedRoutes] = useState([]);

  const classes = useStyles();
  const mainPanelClasses = `${classes.mainPanel
  } ${
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf('Win') > -1,
    })}`;
  // ref for main panel div
  const mainPanel = React.createRef();
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = 'hidden';
    }
    window.addEventListener('resize', resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
      }
      window.removeEventListener('resize', resizeFunction);
    };
  });

  // set the allowed routes for logged in user
  useEffect(() => {
    const newarray = [];
    routes.map((route) => {
      if (route.collapse && route.role.includes(user.role)) {
        newarray.push(route);
      }
      if (route.layout === '/admin' && route.role.includes(user.role)) {
        newarray.push(route);
      }
      return null;
    });

    setAllowedRoutes(newarray);
  }, [user.role]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getActiveRoute = (allRoutes) => {
    const activeRoute = '';
    for (let i = 0; i < allRoutes.length; i++) {
      if (allRoutes[i].collapse) {
        const collapseActiveRoute = getActiveRoute(allRoutes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (
        window.location.href.indexOf(allRoutes[i].layout + allRoutes[i].path) !== -1
      ) {
        return allRoutes[i].name;
      }
    }
    return activeRoute;
  };

  const getRoutes = (allRoutes) => allRoutes.map((prop, key) => {
    if (prop.collapse) {
      return getRoutes(prop.views);
    }
    if (prop.layout === '/admin' && prop.role.includes(user.role)) {
      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    }
    return null;
  });

  return (
    <div className={classes.wrapper}>
      <SnackBar
        setMessageState={(mState) => setMessageState(mState)}
        setMessage={(m) => setSnackBarMessage(m)}
        messageState={messageState}
        message={snackBarMessage}
      />
      <Sidebar
        routes={allowedRoutes}
        // logoText="Translator"
        logo={Logo}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize}
          miniActive={miniActive}
          brandText={getActiveRoute(allowedRoutes)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        <div className={classes.content}>
          <div className={classes.container}>
            <Switch>
              {getRoutes(allowedRoutes)}
              {/* <Redirect from="/admin" to="/admin/dashboard" /> */}
            </Switch>
          </div>
        </div>
        <Footer fluid />
      </div>
    </div>
  );
}
