import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

export default function BasicTable(options) {
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Option</TableCell>
            <TableCell align="right">Percent</TableCell>
            {/* <TableCell align="right">Total</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {options.options.map((row) => (
            <TableRow key={row.option}>
              <TableCell component="th" scope="row">
                {row.option}
              </TableCell>
              <TableCell align="right">{row.percent}</TableCell>
              {/* <TableCell align="right">
                {row.countOfAudienceUsersThatAnsweredCorrectDirection}
                /
                {row.countOfAudienceUsersThatAnsweredWithThisOption}
              </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
