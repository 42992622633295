import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { utils, write } from 'xlsx';
import { saveAs } from 'file-saver';

const BarGraph = (props) => {
  const { data } = props;

  const truncateString = (str, maxLength) => (str.length > maxLength ? str.slice(0, maxLength) : str);

  const camelCaseToTitleCase = (str) => str
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (match) => match.toUpperCase())
    .trim();

  function removeHtmlTags(text) {
    return text.replace(/<br\s*\/?>/gi, ' ');
  }

  function shortenSheetName(name) {
    if (name.length > 31) {
      return name.substring(0, 31);
    }
    return name;
  }

  const exportFile = useCallback(() => {
    const workbook = utils.book_new();

    // For Top Stats
    const topStatsSheetData = Object.entries(data.topStats).map(([key, value]) => [camelCaseToTitleCase(key), value]);
    const topStatsSheet = utils.aoa_to_sheet([['Top Stats'], [], ...topStatsSheetData]);
    utils.book_append_sheet(workbook, topStatsSheet, 'Top Stats');
    // Set column width
    topStatsSheet['!cols'] = [
      { wch: 20 },
      { wch: 10 },
    ];

    // For Live Chats
    const liveChatWorkSheetData = [['Live Chats'], [], ...data.liveChats.map((chat) => [chat])];
    const liveChatsSheet = utils.aoa_to_sheet(liveChatWorkSheetData);
    utils.book_append_sheet(workbook, liveChatsSheet, 'Live Chats');
    // Set column width
    liveChatsSheet['!cols'] = [
      { wch: 90 },
    ];

    // For Each Module
    data.modules.forEach((module, index) => {
      const moduleName = truncateString(module.title || `Module ${index + 1}`, 31);
      const sheetData = [];

      if (module.type && module.title) {
        sheetData.push(['Type', module.type]);
        sheetData.push(['Title', module.title]);
        if (module.type === 'comfort') {
          sheetData.push(['Key', '1 least comfortable - 11 most comfortable']);
        }
        sheetData.push([]);
      }

      if (module.type === 'survey') {
        const combinedObject = module.surveyAnswers.map(sur => sur.answers).flat().reduce((acc, obj) => ({ ...acc, ...obj }), {});
        const headers = ['Question', 'Number of Data Points', 'Answers'];
        sheetData.push(headers);
        sheetData.push(['', '', ...Object.keys(combinedObject)]);
        const answerKeys = Object.keys(combinedObject);
        module.surveyAnswers.forEach((survey) => {
          let answersRow = [survey.question, survey.numberOfDataPoints];
          answerKeys.map((key,index) => {
            return answersRow.push(survey.answers.find(ans => ans[key])?.[key]);
          });
          sheetData.push(answersRow);
        });
        const sheet = utils.aoa_to_sheet([...sheetData]);

        // Set column width
        sheet['!cols'] = [
          { wch: 40 },
          { wch: 20 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 20 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
        ];
        utils.book_append_sheet(workbook, sheet, moduleName);

        // Merge cells
        const mergeConfig = [
          { s: { r: 3, c: 0 }, e: { r: 4, c: 0 } },
          { s: { r: 3, c: 1 }, e: { r: 4, c: 1 } },
          { s: { r: 3, c: 2 }, e: { r: 3, c: 6 } },
        ];

        if (!sheet['!merges']) sheet['!merges'] = [];
        sheet['!merges'] = sheet['!merges'].concat(mergeConfig);
      } else if (module.type === 'walkOfPrivilege') {
        const headers = ['UserID', 'Points', 'Number of Data Points'];
        sheetData.push(headers);
        module.walkResults.forEach((result) => {
          sheetData.push([
            result.userId,
            result.points,
            result.numberOfDataPoints,
          ]);
        });
        const sheet = utils.aoa_to_sheet([...sheetData]);
        // Set column width
        sheet['!cols'] = [
          { wch: 10 },
          { wch: 15 },
          { wch: 20 },
        ];
        utils.book_append_sheet(workbook, sheet, moduleName);
      } else if (module.type === 'comfort') {
        const headers = ['Tag', 'value', ''];
        sheetData.push(headers);
        module.comfortResults.forEach((result, index) => {
          sheetData.push([
            result.tag,
            result.value,
            index + 1,
          ]);
        });
        const sheet = utils.aoa_to_sheet([...sheetData]);
        // Set column width
        sheet['!cols'] = [
          { wch: 10 },
          { wch: 15 },
          { wch: 20 },
        ];
        utils.book_append_sheet(workbook, sheet, moduleName);
      } else if (module.type === 'wordCloud') {
        const combinedObject = module.tagResults[0].answers.reduce((acc, obj) => ({ ...acc, ...obj }), {});
        const headers = ['QuestionID', 'Question', 'Description', 'NumberOfDataPoints', 'Answers'];
        sheetData.push(headers);
        sheetData.push(['', '', '', '', ...Object.keys(combinedObject)]);
        const answerKeys = Object.keys(combinedObject);
        module.tagResults.forEach((tagResult) => {
          sheetData.push([
            tagResult.questionId,
            tagResult.question,
            tagResult.description,
            tagResult.numberOfDataPoints,
            tagResult.answers[0][answerKeys[0]],
            tagResult.answers[1][answerKeys[1]],
            tagResult.answers[2][answerKeys[2]],
            tagResult.answers[3][answerKeys[3]],
            tagResult.answers[4][answerKeys[4]],
          ]);
        });
        const sheet = utils.aoa_to_sheet([...sheetData]);

        // Set column width
        sheet['!cols'] = [
          { wch: 10 },
          { wch: 40 },
          { wch: 20 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 20 },
        ];
        utils.book_append_sheet(workbook, sheet, moduleName);
        const mergeConfig = [
          { s: { r: 3, c: 0 }, e: { r: 4, c: 0 } },
          { s: { r: 3, c: 1 }, e: { r: 4, c: 1 } },
          { s: { r: 3, c: 2 }, e: { r: 4, c: 2 } },
          { s: { r: 3, c: 3 }, e: { r: 4, c: 3 } },
          { s: { r: 3, c: 4 }, e: { r: 3, c: 4 + Object.keys(combinedObject).length - 1 } },
        ];
        if (!sheet['!merges']) sheet['!merges'] = [];
        sheet['!merges'] = sheet['!merges'].concat(mergeConfig);
      } else if (module.type === 'poll') {
        module.pollAnswers.forEach((pollAnswer, index) => {
          sheetData.push([`Question ${index + 1}`, pollAnswer.question]);

          pollAnswer.answers.forEach((answer) => {
            const answerKey = Object.keys(answer)[0];
            const answerValue = answer[answerKey];
            sheetData.push([answerKey, answerValue]);
          });

          sheetData.push([]);
        });
        const sheet = utils.aoa_to_sheet([...sheetData]);
        const sanitizeSheetName= (name) => {
          return name.replace(/[:\\\/?*\[\]]/g, function(match) {
            switch (match) {
              case ':': return ' -';
              case '\\': return '';
              case '/': return '';
              case '?': return '';
              case '*': return '';
              case '[': return '';
              case ']': return '';
              default: return '';
            }
          });
        }
        utils.book_append_sheet(workbook, sheet, sanitizeSheetName(module.title));
      } else if (module.type === 'wordPopcorn') {
        const { wordPulseResults } = module;
        const { question } = wordPulseResults;
        sheetData.push(['Question', removeHtmlTags(question)]);

        wordPulseResults.popcornAnswers.forEach((answer) => {
          const answerKey = answer.value;
          const answerValue = answer.count;
          sheetData.push([answerKey]);
        });

        sheetData.push([]);

        const sheet = utils.aoa_to_sheet([...sheetData]);
        sheet['!cols'] = [
          { wch: 60 },
          { wch: 60 },
        ];
        utils.book_append_sheet(workbook, sheet, shortenSheetName(module.title));
      }
    });

    const xlsxBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([xlsxBuffer], { type: 'application/octet-stream' });
    const fileName = `report-${Date.now()}.xlsx`;
    saveAs(blob, fileName);
  }, [data]);

  return (
    <>
      <button
        onClick={exportFile}
        className="MuiButtonBase-root MuiButton-root MuiButton-contained makeStyles-button-92 makeStyles-primary-94"
      >
        Export Report
      </button>

    </>
  );
};

BarGraph.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
};

export default BarGraph;
