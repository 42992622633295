import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
// core components
import Wizard from '../../../components/Wizard/Wizard';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';

import Step1 from './Step1';
import Step2 from './Step2';
// import Step3 from './Step3';
import { loadAuthToken } from '../../../utils/auth';
import { AuthContext } from '../../../Auth';

export default function ResourceWizardView(props) {
  const { setMessageState, setSnackBarMessage } = useContext(AuthContext);
  const { history } = props;
  const createResource = async (state) => {
    // console.log(state, 'state');
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_MODULE_SERVICE}/resource`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
      data: {
        title: state.about.title,
        description: state.about.description,
        image: state.about.image,
        link: state.url.link,
        tags: state.about.tags.length ? state.about.tags : ['Other Resources'],
      },
    }).then(() => {
      setMessageState('success');
      setSnackBarMessage(`Successfully created ${state.about.title}.`);
      history.push('/admin/resources');
    }).catch((err) => {
      setMessageState('error');
      console.log(err, 'error!!!!!!!');
      setSnackBarMessage(`${err.response.data.message}`);
      if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
        history.push('auth/login');
      }
    });
  };

  return (
    <GridContainer justifyContent="center">
      <GridItem xs={12} sm={8}>
        <Wizard
          validate
          history={history}
          steps={[
            { stepName: 'About', stepComponent: Step1, stepId: 'about' },
            { stepName: 'Upload Video or PDF', stepComponent: Step2, stepId: 'url' },
          ]}
          title="New Resource"
          subtitle=""
          finishButtonClick={(state) => createResource(state)}
          color="primary"
        />
      </GridItem>
    </GridContainer>
  );
}

ResourceWizardView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};
