/* eslint-disable no-lonely-if */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// core components

import customSelectStyle from '../../../../assets/jss/material-dashboard-pro-react/customSelectStyle';
import customListStyle from '../../../../assets/jss/material-dashboard-pro-react/customListStyle';
import customCheckboxRadioSwitch from '../../../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch';

const style = {
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center',
  },
  inputAdornmentIcon: {
    color: '#555',
  },
  choiche: {
    textAlign: 'center',
    cursor: 'pointer',
    marginTop: '20px',
  },
  ...customListStyle,
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
};

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
    };
  }

  sendState() {
    return this.state;
  }

  // this is used by the wizard to see if all required are filled out
  isValidated() {
    const { text } = this.state;
    if (text.length > 0) {
      return true;
    }
    return false;
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <h4 className={classes.infoText}>What text would you like to add?</h4>
        <CKEditor
          editor={ClassicEditor}
          data={this.state.text}
          onInit={(editor) => {
            console.log('Editor is ready to use!', editor);
          }}
          onChange={(event, editor) => {
            this.setState({ text: editor.getData() });
          }}
          onBlur={(event, editor) => {
            console.log('Blur.', editor);
          }}
          onFocus={(event, editor) => {
            console.log('Focus.', editor);
          }}
        />
      </div>
    );
  }
}

Step2.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(style)(Step2);
