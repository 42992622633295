/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
// import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Card from '../../../components/Card/Card';
import CardHeader from '../../../components/Card/CardHeader';
import CardBody from '../../../components/Card/CardBody';
import CardFooter from '../../../components/Card/CardFooter';
import { AuthContext } from '../../../Auth';
import styles from '../../../assets/jss/material-dashboard-pro-react/views/reportPageStyle';

const useStyles = makeStyles(styles);

function LiveChatReport(props) {
  const classes = useStyles();
  const context = useContext(AuthContext);
  // const [data, setData] = useState({});
  const [questionCount, setQuestionsCount] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [shouldDisplay, setShouldDisplay] = useState(true);
  const {
    sessionId, companyId,
  } = props;
  const [toggleCard, setToggleCard] = useState(false);

  useEffect(() => {
    if (companyId) {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_LIVE_CHAT_SERVICE}/get-live-chat-by-company?companyId=${companyId}`,
      }).then((res) => {
        console.log(res);
        if (res.data?.data?.length) {
          setQuestionsCount(res.data.data.length);
          setQuestions(res.data.data.map((questionObj) => questionObj.question));
        } else {
          setShouldDisplay(false);
        }
      }).catch((e) => {
        try {
          context.setMessageState('error');
          context.setSnackBarMessage(`${e?.response?.data?.message} ***`);
        } catch (err) {
          console.log(e);
        }
      });
    } else if (sessionId) {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_LIVE_CHAT_SERVICE}/questions?sessionId=${sessionId}`,
      }).then((res) => {
        if (res.data?.data?.length) {
          setQuestionsCount(res.data.data.length);
          setQuestions(res.data.data.map((questionObj) => questionObj.question));
        } else {
          setShouldDisplay(false);
        }
      }).catch((e) => {
        context.setMessageState('error');
        context.setSnackBarMessage(`${e?.response?.data?.message}`);
      });
    }
  }, [companyId, sessionId]);
  return (
    <>
      {shouldDisplay ? (
        <>
          {questionCount ? (
            <Card>
              <CardHeader className={classes.headerAlign}>
                <div className={classes.cardCategory}>
                  Live Chat Report
                </div>
                <div>
                  {toggleCard
                    ? (
                      <KeyboardArrowUpIcon
                        onClick={() => setToggleCard(!toggleCard)}
                        className={classes.clickable}
                      />
                    )
                    : (
                      <KeyboardArrowDownIcon
                        onClick={() => setToggleCard(!toggleCard)}
                        className={classes.clickable}
                      />
                    )}
                </div>
              </CardHeader>
              <Collapse in={toggleCard} unmountOnExit>
                <CardBody style={{ overflow: 'auto' }}>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ backgroundColor: 'lightgray' }}>Questions / Statements</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {questions.map((question, index) => (
                          <TableRow>
                            <TableCell key={index} component="th" scope="row">
                              {question}
                            </TableCell>
                          </TableRow>
                        ))}

                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Collapse>
              <CardFooter stats>
                <div className={classes.stats}>
                  <p className={classes.cardTitle}>
                    Questions Asked / Statements Made:
                    {' '}
                    {String(questionCount)}
                  </p>
                </div>
              </CardFooter>
            </Card>
          ) : <CircularProgress />}
        </>
      ) : (
        null
      )}

    </>
  );
}

LiveChatReport.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  // moduleInfo: PropTypes.object.isRequired,
  sessionId: PropTypes.number,
  companyId: PropTypes.number,
};

export default LiveChatReport;
