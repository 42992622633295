const translations = {
  es: {
    welcome: 'Bienvenidos',
    welcomeSessionInfo: 'Usted ha iniciado sesión:',
    pleaseWait: 'Por favor, espere las instrucciones de su anfitrión.',
    copyInfo: 'Copiar Info',
    oneWayMsg: 'Este es un mensaje unidireccional para su anfitrión.',
    hostResponds: 'Su anfitrión responderá "en voz alta" si el tiempo lo permite.',
    thankYouMsgSent: 'Gracias por su mensaje.',
    anonymousMessages: 'Mensajes Anónimos',
    typeMsg: 'Escribe un mensaje...',
    readScenario: 'Leer escenario',
    next: 'Siguiente',
    submit: 'Enviar',
    of: 'de',
    thankYouModuleSub: 'Gracias por enviar.',

  },
  en: {
    welcome: 'Welcome',
    welcomeSessionInfo: 'You are logged into session:',
    pleaseWait: 'Please wait for instructions from your Host.',
    copyInfo: 'Copy Info',
    oneWayMsg: 'This is a one-way message to your Host.',
    hostResponds: 'Your Host will respond "out loud" if time permits.',
    thankYouMsgSent: 'Thank you for your submission.',
    anonymousMessages: 'Anonymous Messages',
    typeMsg: 'Type a message...',
    readScenario: 'Read Scenario',
    next: 'Next',
    submit: 'Submit',
    of: 'of',
    thankYouModuleSub: 'Thank you for your submission.',
  },
  fr: {
    welcome: 'Accueillir',
    welcomeSessionInfo: 'Vous êtes connecté à la session:',
    pleaseWait: 'Veuillez attendre les instructions de votre hôte.',
    copyInfo: 'Copiar Info',
    oneWayMsg: 'This is a one-way message to your Host.',
    hostResponds: 'Your Host will respond "out loud" if time permits.',
    thankYouMsgSent: 'Thank you for your submission.',
    anonymousMessages: 'Anonymous Messages',
    typeMsg: 'Type a message...',
    readScenario: 'Read Scenario',
    next: 'Suivant',
    submit: 'Submit',
    of: 'of',
    thankYouModuleSub: 'Thank you for your submission.',

  },
};

export default translations;
