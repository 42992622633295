import React from 'react';
import PropTypes from 'prop-types';

// import DialogContentText from '@material-ui/core/DialogContentText';
import {
  List, DialogTitle, DialogContent, DialogActions, Dialog,
} from '@material-ui/core';
import Button from '../../../../components/CustomButtons/Button';
// import { AuthContext } from '../../Auth';
// import { loadAuthToken } from '../../utils/auth';

function CloudStatementsModal(props) {
  const { toggleModal, statements, open } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => toggleModal()}
        maxWidth="md"
        togg
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: '100%',
            minHeight: '60%',
          },
        }}
      >
        <DialogTitle id="form-dialog-title">
          {'Title: '}
          {statements.title}
          <br />
          {'Description: '}
          {statements.description}
          <br />
        </DialogTitle>
        <DialogContent>
          <List>
            {statements.cloud_question_joins ? statements.cloud_question_joins.map((s) => (
              <ul key={s.id}><li>{s.word_cloud_question.question}</li></ul>
            )) : ' '}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggleModal()} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

CloudStatementsModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  statements: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default CloudStatementsModal;
