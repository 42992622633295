/* eslint-disable react/button-has-type */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';

// @material-ui/icons
import ViewModuleIcon from '@material-ui/icons/ViewModule';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';

// core components
// import { PlusOne } from '@material-ui/icons';
import GridContainer from '../../../../components/Grid/GridContainer';
import GridItem from '../../../../components/Grid/GridItem';
import CustomInput from '../../../../components/CustomInput/CustomInput';
// import { Input } from '@material-ui/core';

const style = {
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center',
  },
  inputAdornmentIcon: {
    color: '#555',
  },
  inputAdornment: {
    position: 'relative',
  },
  numberInput: {
    width: '25px',
    textAlign: 'center',
  },
};

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      titleState: '',
      questionState: '',
      iconUrlState: '',
      answerLimit: 1,
    };
  }

  sendState() {
    return this.state;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'length':
        if (validator.isLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [`${stateName}State`]: 'success' });
        } else {
          this.setState({ [`${stateName}State`]: 'error' });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }

  plusOne() {
    this.setState((prevState) => ({ answerLimit: prevState.answerLimit + 1 }));
  }

  minusOne() {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.answerLimit > 1) {
      this.setState((prevState) => ({ answerLimit: prevState.answerLimit - 1 }));
    }
  }

  render() {
    const { classes } = this.props;
    const {
      titleState, questionState, iconUrlState, answerLimit,
    } = this.state;
    return (
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>
            Let &apos;s start with the basic information about your module
          </h4>
        </GridItem>

        <GridItem xs={12} sm={6}>
          <CustomInput
            success={titleState === 'success'}
            error={titleState === 'error'}
            labelText={(
              <span>
                Title of the module
                {' '}
                <small>(required)</small>
              </span>
            )}
            id="name"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => this.change(event, 'title', 'length', 3),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <ViewModuleIcon className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />
          <CustomInput
            success={questionState === 'success'}
            error={questionState === 'error'}
            labelText={(
              <span>
                Question
                {' '}
                <small>(required)</small>
              </span>
            )}
            id="name"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => this.change(event, 'question', 'length', 3),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <ViewModuleIcon className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />
          <CustomInput
            type="url"
            success={iconUrlState === 'success'}
            error={iconUrlState === 'error'}
            labelText={(
              <span>
                Icon Url
                {' '}
                <small>(required)</small>
              </span>
            )}
            id="name"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => this.change(event, 'iconUrl', 'length', 3),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <ViewModuleIcon className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />
          <div>
            <p>Answer Limit </p>
            <button className="minus" onClick={() => this.minusOne()}>-</button>
            <input
              className={classes.numberInput}
              disabled
              type="text"
              value={answerLimit}
              onChange={(event) => this.setState(({ answerLimit: event.target.value }))}

            />

            <button className="plus" onClick={() => this.plusOne()}>+</button>
          </div>

        </GridItem>
      </GridContainer>
    );
  }
}

Step1.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(style)(Step1);
