/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import PieChart from './PieChart';

const SurveyGraph = (props) => {
  const { questions } = props;
  return (
    <div>
      {questions.map((questionObj) => {
        if (questionObj.question.type === 'multipleChoice' || questionObj.question.type === 'slider' || questionObj.question.type === 'multiSelect') {
          return (
            <Accordion key={questionObj.question.id + 2000}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p>{questionObj.question.question}</p>
              </AccordionSummary>
              <AccordionDetails>
                <PieChart question={questionObj.question} answers={questionObj.answers} />
              </AccordionDetails>
            </Accordion>
          );
        }
      })}
    </div>
  );
};

SurveyGraph.propTypes = {
  questions: PropTypes.array.isRequired,
};

export default SurveyGraph;
