/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';

import { useHistory } from 'react-router-dom';

import { PulseLoader } from 'react-spinners';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import CardActions from '@material-ui/core/CardActions';
import { loadAuthToken } from '../../utils/auth';
import { AuthContext } from '../../Auth';
// import PropTypes from 'prop-types';

// import data from './ResourceData';
import './ResourcesStyle.css';

const useStyles = makeStyles(() => ({
  root: {
    width: '75vw',
    margin: '5px',
  },
  media: {
    display: 'flex',
    justifyContent: 'center',
  },
  videoPlayer: {
    maxWidth: '85%',
    maxHeight: '85%',
  },
  actionArea: {
    display: 'flex',
    justifyContent: 'end',
  },
}));

const Resource = () => {

  const classes = useStyles();
  const history = useHistory();
  const videoRef = useRef();

  const [currentResource, setCurrentResource] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [id] = useState(window.location.href.substring(window.location.href.lastIndexOf('/') + 1));
  const { setMessageState, setSnackBarMessage } = useContext(AuthContext);

  useEffect(() => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_MODULE_SERVICE}/resource`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).then((res) => {
      setCurrentResource(res.data.data.filter((resource) => resource.id == id)[0]);
      setIsLoading(false);
    }).catch((e) => {
      setMessageState('error');
      setSnackBarMessage(`${e.response.data.message}`);
    });
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    videoRef.current?.load();
  }, [currentResource]);

  return (
    <div>
      {isLoading ? (
        <PulseLoader
          sizeUnit="px"
          size={15}
          color="#6CCA98"
          loading
        />
      ) : (
        <>
          <Card className={classes.root}>
            <CardHeader
              title={currentResource.title}
              subheader="Updated: September 14, 2016"
            />
            {/* <video id="myVideo" ref={videoRef} className={classes.videoPlayer}>
              <source src={currentResource.link} type="video/mp4" />
            </video> */}
            <CardMedia className={classes.media}>

              <video id="myVideo" ref={videoRef} controls className={classes.videoPlayer}>
                <source src={currentResource.link} type="video/mp4" />
                <source src="movie.ogg" type="video/ogg" />
                Your browser does not support the video tag.
              </video>
            </CardMedia>

            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                {currentResource.description}
              </Typography>
            </CardContent>
            <CardActions className={classes.actionArea}>
              <Button
                size="small"
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  history.push('/admin/resources');
                }}

              >
                Back
              </Button>
            </CardActions>

          </Card>
        </>
      )}
    </div>

  );
};

export default Resource;
