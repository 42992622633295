/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';
// core components
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';

import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import styles from '../../assets/jss/material-dashboard-pro-react/views/loginPageStyle';
import Form from './signup/Form';
import ThankYou from './signup/ThankYou';

const useStyles = makeStyles(styles);

export default function LetsChat(props) {
  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden');
  const [signedUp, setSignedUp] = React.useState(false);
  // const [isIncorrect, setIncorrect] = React.useState(false);
  setTimeout(() => {
    setCardAnimation('');
  }, 700);
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={8} md={5}>

          <Card login className={classes[cardAnimaton]}>
            <CardHeader
              className={`${classes.cardHeader} ${classes.textCenter}`}
              color="primary"
            >
              <h3 className={classes.cardTitle}>Chat Sign Up</h3>
            </CardHeader>
            {signedUp
              ? <ThankYou />
              : <Form chatUserId={Number(props.location.search.slice(4))} signedUp={setSignedUp} />}

          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

LetsChat.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
};
