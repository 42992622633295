/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
// @material-ui/icons
import Input from '@material-ui/icons/Input';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';

// core components
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import CustomInput from '../../../components/CustomInput/CustomInput';
import Card from '../../../components/Card/Card';
import CardBody from '../../../components/Card/CardBody';
import CardFooter from '../../../components/Card/CardFooter';
import Button from '../../../components/CustomButtons/Button';
import { AuthContext } from '../../../Auth';

const style = {
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center',
  },
  inputAdornmentIcon: {
    color: '#555',
  },
  inputAdornment: {
    position: 'relative',
  },
};

function PasswordForm(props) {
  const [password, setPassword] = useState('');
  const [passwordVerify, setPasswordVerify] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [userId, setUserId] = useState('');

  const context = useContext(AuthContext);
  const { history, classes } = props;

  useEffect(() => {
    setUserId(context.user.id);
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (password === passwordVerify && password.length > 6) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [password, passwordVerify]);

  const handleSubmitClick = () => {
    const token = localStorage.getItem('feathers-jwt');
    const userUpdateObj = {};
    if (password.length > 6
        && password === passwordVerify) {
      userUpdateObj.password = password;

      if (Object.entries(userUpdateObj).length > 0) {
        axios(`${process.env.REACT_APP_AUTH_SERVICE}/users/${userId}`, {
          method: 'patch',
          headers: { Authorization: `Bearer ${token}` },
          data: { ...userUpdateObj },
        })
          .then(() => {
            context.setMessageState('success');
            context.setSnackBarMessage('Password successfully updated');
            history.push('admin/sessions');
          })
          .catch((err) => {
            context.setMessageState('error');
            context.setSnackBarMessage(`${err.response.data.message}`);
            if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
              history.push('auth/login');
            }
          });
      }
    } else {
      context.setMessageState('error');
      context.setSnackBarMessage('Your info has not been updated');
    }
  };

  return (
    <GridContainer justifyContent="center">
      <GridItem xs={12} md={10}>
        <Card>
          <CardBody>
            <GridContainer justifyContent="center">
              <GridItem xs={10}>
                <CustomInput
                  success={(
                password === passwordVerify && password.length > 6)}
                  error={(password !== passwordVerify || password.length <= 6)}
                  labelText={(
                    <span>
                      New Password
                      {' '}
                      <small>(minimum 7 characters)</small>
                    </span>
                )}
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'password',
                    value: password,
                    placeholder: 'New Password',
                    onChange: (event) => setPassword(event.target.value),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={classes.inputAdornment}
                      >
                        <Input className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomInput
                  success={(password === passwordVerify && password.length > 6)}
                  error={(password !== passwordVerify || password.length <= 6)}
                  labelText={(
                    <span>
                      Verify New Password
                    </span>
                )}
                  id="verifyPassword"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'password',
                    value: passwordVerify,
                    placeholder: 'Verify New Password',
                    onChange: (event) => setPasswordVerify(event.target.value),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={classes.inputAdornment}
                      >
                        <Input className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
          <GridContainer justifyContent="flex-end">
            <CardFooter>
              <GridItem xs={12} sm={2}>
                <Button disabled={buttonDisabled} onClick={() => handleSubmitClick()}>
                  Update Password
                </Button>
              </GridItem>
            </CardFooter>
          </GridContainer>
        </Card>
      </GridItem>

    </GridContainer>
  );
}

PasswordForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withStyles(style)(PasswordForm);
