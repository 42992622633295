/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import localData from './ResourceData';
import { loadAuthToken } from '../../utils/auth';
import { AuthContext } from '../../Auth';
import ResourceCard from './ResourceCard/ResourceCard';

import './ResourcesStyle.css';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  filterBox: {
    display: 'flex',
    justifyContent: 'end',
  },
}));

export default function Resources() {
  const classes = useStyles();
  const { setMessageState, setSnackBarMessage } = useContext(AuthContext);

  const [data, setData] = useState([]);
  const [tags, setTags] = useState([]);

  const [filteredTag, setFilteredTag] = React.useState([]);

  useEffect(() => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_MODULE_SERVICE}/resource`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).then((res) => {
      setData(res.data.data);
      setTags(_.uniq(_.flattenDeep(_.map(data, 'tags'))));
    }).catch((e) => {
      setMessageState('error');
      setSnackBarMessage(`${e.response.data.message}`);
    });
  }, []);

  useEffect(() => {
    setTags(_.uniq(_.flattenDeep(_.map(data, 'tags'))));
  }, [data]);

  const handleChange = (event) => {
    if (event.target.value === 'none') {
      setFilteredTag([]);
    } else {
      setFilteredTag([event.target.value]);
    }
  };

  // set the cards by each tag
  const sortByTag = (tag, resource) => {
    if (resource.tags.includes(tag.toString())) {
      return (
        <ResourceCard resource={resource} />
      );
    }
    return null;
  };

  return (
    <div>
      <div className={classes.filterBox}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">Tags</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={filteredTag}
            onChange={handleChange}
            label="Tags"
          >
            <MenuItem value="none">
              <em>None</em>
            </MenuItem>
            {tags.map((tag) => <MenuItem value={tag}>{tag}</MenuItem>)}
          </Select>
        </FormControl>

      </div>
      {filteredTag.length ? filteredTag.map((tag) => (
        <div key={tag}>
          <h4>{tag}</h4>
          <div className="media-scroller snaps-inline">
            {data.map((resource) => sortByTag(tag, resource))}
          </div>
        </div>
      ))
        : tags.map((tag) => (
          <>
            <h4>{tag}</h4>
            <div className="media-scroller snaps-inline">
              {data.map((resource) => sortByTag(tag, resource))}
            </div>
          </>
        ))}
    </div>

  );
}

Resources.propTypes = {
  // index of the default active collapse
  // active: PropTypes.number,
  // collapses: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     title: PropTypes.node,
  //     content: PropTypes.node,
  //   }),
  // ).isRequired,
};
