import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '../CustomButtons/Button';

export default function AlertDialog(props) {
  const {
    open, text, rowId, action, toggleDialog,
  } = props;

  return (
    <Dialog
    //   onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">{text}</DialogTitle>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '10px',
      }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            action(rowId);
            toggleDialog(false);
          }}
        >
          OK
        </Button>
        <Button
          variant="contained"
          onClick={() => toggleDialog(false)}
        >
          Cancel
        </Button>
      </div>

    </Dialog>
  );
}

AlertDialog.propTypes = {
  toggleDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  rowId: PropTypes.number.isRequired,
};
