import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import { PulseLoader } from 'react-spinners';
// import Carousel from 'react-material-ui-carousel';
import {
  CarouselProvider, Slider, ButtonBack, ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import { AuthContext } from '../../Auth';

import Card from '../../components/Card/Card';
import CardFooter from '../../components/Card/CardFooter';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';

import styles from '../../assets/jss/material-dashboard-pro-react/views/reportPageStyle';
import './Analytics.css';

import DoughnutGraph from './Doughnut';
import LiveChatDisplay from './LiveChatDisplay';
// import BarGraph from './BarGraph';
// import WalkOfPrivilegeGraph from './WalkBarGraph';

import MainModuleCard from './MainModuleCard';
import DownloadLink from './DownloadLink';

const useStyles = makeStyles(styles);

export default function Analytics() {
  const classes = useStyles();
  const context = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [date, setDate] = useState('');
  const [surveyResults, setSurveyResults] = useState([]);

  const history = useHistory();

  const mainDisplayModules = data.modules ? data.modules.filter((mod) => mod.type === 'wordCloud' || mod.type === 'walkOfPrivilege' || mod.type === 'comfort' || mod.type === 'poll' || mod.type === 'wordPopcorn') : [];

  const userId = context.user.id;

  useEffect(() => {
    const locaction = history.location.pathname;

    let pin = '';
    if (locaction.match(/analytics\/(.*)/)) {
      pin = locaction.match(/analytics\/(.*)/)[1];
    }

    const fetchData = async () => {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_EVENT_SERVICE}/analytics?userId=${userId}&pin=${pin}`,
      }).then((res) => {
        setData(res.data);
        setDate(new Date(res.data.dateOfSession));
        setIsLoading(false);
        setSurveyResults(res.data.modules.filter((mod) => mod.type === 'survey'));
        return res;
      }).catch((e) => {
        console.log(e);
        context.setMessageState('error');
        context.setSnackBarMessage(`${e.response}`);
      });
    };
    fetchData();
  }, []);

  return (
    <Card style={{ minHeight: '80vh' }}>
      {isLoading ? (
        <div style={{ margin: 'auto' }}>
          <PulseLoader
            sizeUnit="px"
            size={15}
            color="#6CCA98"
            loading
          />
        </div>

      )
        : (

          <div>

            {data.sessionPin
              ? (
                <div>
                  <CardHeader className={classes.headerAlign}>

                    <div className={classes.header}>
                      <h3>{`Analytics for session pin : ${data.sessionPin}, date: ${date.toDateString()}`}</h3>
                    </div>

                  </CardHeader>

                  <CardBody className={classes.reportCardBody}>
                    <GridContainer>
                      <GridItem xs={12} sm={4}>
                        <Card className="innerCard">
                          <CardHeader>Live Chat Count:</CardHeader>
                          <CardBody className="innerContent">{data.topStats.liveChatCount}</CardBody>
                        </Card>
                      </GridItem>
                      <GridItem xs={12} sm={4}>
                        <Card className="innerCard">
                          <CardHeader>Total Participants:</CardHeader>
                          <CardBody className="innerContent">{data.topStats.participants}</CardBody>
                        </Card>
                      </GridItem>
                      <GridItem xs={12} sm={4}>
                        <Card className="innerCard">
                          <CardHeader>Total Data Points:</CardHeader>
                          <CardBody className="innerContent">{data.topStats.totalDataPoints}</CardBody>
                        </Card>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={7}>
                        {mainDisplayModules.length ? (
                          <CarouselProvider
                            naturalSlideWidth={100}
                            naturalSlideHeight={125}
                            visibleSlides={1}
                            totalSlides={mainDisplayModules.length}
                          >
                            <Slider>
                              {mainDisplayModules.map((module, index) => <MainModuleCard module={module} ind={index} />)}
                            </Slider>
                            <ButtonBack>Back</ButtonBack>
                            <ButtonNext>Next</ButtonNext>
                          </CarouselProvider>
                        ) : (
                          <div>
                            <h4>No module data available</h4>
                          </div>
                        )}

                      </GridItem>
                      <GridContainer xs={12} sm={5}>
                        <GridItem xs={12}>
                          <Card className="sideBox">
                            <CardHeader>Live Chats</CardHeader>
                            <CardBody>
                              {data.liveChats.length ? (
                                <>
                                  <LiveChatDisplay liveChats={data.liveChats} />
                                </>
                              ) : (
                                <div><h6>No live chat data available</h6></div>
                              )}
                            </CardBody>
                          </Card>
                        </GridItem>
                        <GridItem xs={12}>
                          <Card className="sideBox2">
                            <CardHeader>Survey Data</CardHeader>
                            <CardBody>
                              {surveyResults.length ? surveyResults.map((survey) => survey.surveyAnswers.map((answer) => (
                                <>
                                  <h6>{answer.question}</h6>
                                  <DoughnutGraph answers={answer.answers} />
                                </>
                              ))) : (
                                <div><h6>No survey data available</h6></div>
                              )}

                            </CardBody>
                          </Card>
                        </GridItem>

                      </GridContainer>

                    </GridContainer>
                    <CardFooter  style={{justifyContent: 'end'}}>
                      <DownloadLink data={data} />
                    </CardFooter>
                  </CardBody>
                </div>
              )

              : <div style={{ margin: '30px' }}>No Session found</div>}
          </div>
        )}
    </Card>
  );
}
