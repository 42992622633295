/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-trailing-spaces */
import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '../../../components/CustomButtons/Button';

const useStyles = makeStyles({

  card: {
    // width: '50%',
    height: '95%',
    borderRadius: 15,
    background: 'white',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    '@media (max-width: 960px)': {
      width: '100%',
      fontSize: '20px',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      // backgroundSize: '100%',
    },
  },
  contentDiv: {
    opacity: 1,
    marginTop: '25vh',
    fontSize: '1.6rem',
    lineHeight: 1.2,
    fontWeight: '400',
    // padding: '5px',
  },
  content: {
    background: '#ffffffe8',
    borderRadius: '10px',
  },
  
  cardStyles: {
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    userSelect: 'none',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
  },
});

const AddQuestionDialog = (props) => {
  const classes = useStyles();
  const { open, togalModal, questionToPreview } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => togalModal()}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: '100%',
            height: '100%',
          },
        }}
      >
        <DialogContent>
          {questionToPreview.question ? (
            <Card
              id="card"
              className={classes.card}
              style={{ backgroundImage: `url(${questionToPreview.image})` }}
            >
              <CardContent>
                <div className={classes.contentDiv}>
                  <div align="center" className={classes.content}>
                    <p>
                      Swipe
                      {' '}
                      <b>{ questionToPreview.direction.toUpperCase()}</b>
                      {' '}
                      { questionToPreview.question}
                    </p>
                  </div>
                </div>
              </CardContent>
            </Card>
          ) : <Card /> }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => togalModal()} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AddQuestionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  togalModal: PropTypes.func.isRequired,
  questionToPreview: PropTypes.object,
};

export default AddQuestionDialog;
