/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import CSVReader from 'react-csv-reader';
import { loadAuthToken } from '../../utils/auth';
import { AuthContext } from '../../Auth';
import Button from '../CustomButtons/Button';

const styles = {
  unorderedList: {
    listStyleType: 'none',
    overflow: 'auto',
    maxHeight: '200px',
  },
  emailListItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  highLightIcon: {
    color: '#60A681',
  },
  addUserInput: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  select: {
    width: '100%',
  },
  errorText: {
    color: 'red',
  },

};

const useStyles = makeStyles(styles);

const AddUserDialog = (props) => {
  const [isOpen, setOpen] = useState(false);
  const [emails, setEmails] = useState([]);
  const [userInputBox, setInput] = useState('');
  const [companies, setCompanies] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(-1);
  const [selectedCompanyName, setSelectedCompanyName] = useState('');
  const [selectCompanyError, setSelectCompanyError] = useState(false);
  // const [isByFile, setByFile] = useState(false);
  const context = useContext(AuthContext);
  const classes = useStyles();
  const { history, getChatUsers } = props;

  const handleAddUserByInput = () => {
    if (userInputBox.includes('@')) {
      if (!emails.includes(userInputBox)) setEmails([...emails, userInputBox]);
    }
    setInput('');
  };

  const createChatUsers = async () => {
    // axios({
    //   method: 'get',
    //   url: `${process.env.REACT_APP_CHAT_SERVICE}/chat-users`,

    // })
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < emails.length; i++) {
      // eslint-disable-next-line no-await-in-loop
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_CHAT_SERVICE}/chat-users`,
        headers: {
          Authorization: `bearer ${loadAuthToken()}`,
        },
        data: {
          email: emails[i],
          chatCompanyId: selectedCompanyId,
        },
      }).catch((e) => {
        context.setMessageState('error');
        context.setSnackBarMessage(`${e.response.data.message}`);
      });
    }
    context.setMessageState('success');
    context.setSnackBarMessage('Successfully created chat users!');
  };

  const handleSubmit = async () => {
    if (selectedCompanyId === -1) {
      setSelectCompanyError(true);
    } else {
      await createChatUsers();
      getChatUsers();
      setSelectedCompanyId(-1);
      setSelectedCompanyName('');
      setEmails([]);
      setOpen(false);
      setInput('');
    }
  };

  const handleUpload = (upload) => {
    const localEmails = [];
    upload.forEach((row) => {
      row.forEach((email) => {
        if (email.includes('@')) {
          if (!emails.includes(email)) localEmails.push(email);
        }
      });
    });
    setEmails([...emails, ...localEmails]);
  };

  const removeEmail = (index) => {
    const emailsCopy = [...emails];
    emailsCopy.splice(index, 1);
    setEmails(emailsCopy);
  };

  useEffect(() => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_CHAT_SERVICE}/chat-companies`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).then((res) => {
      setCompanies(res.data.data);
    })
      .catch((err) => {
        context.setMessageState('error');
        context.setSnackBarMessage(`${err.response.data.message}`);
        if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
          history.push('auth/login');
        }
      });
  }, []);

  const closeModal = () => {
    setSelectedCompanyId(-1);
    setSelectedCompanyName('');
    setEmails([]);
    setOpen(false);
    setInput('');
  };

  const handleChange = (e) => {
    const company = companies.find((comp) => comp.name === e.target.value);
    setSelectedCompanyId(company.id);
    setSelectedCompanyName(e.target.value);
    setSelectCompanyError(false);
  };

  return (
    <div>
      <Tooltip title="Add">
        <IconButton aria-label="add" onClick={() => setOpen(true)}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={isOpen}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add User</DialogTitle>
        <DialogContent>
          <DialogContentText>Create a Chat User</DialogContentText>
          <FormControl fullWidth>
            {selectCompanyError ? (
              <div className={classes.errorText}>
                You Must Select A Company
              </div>
            ) : null}
            <InputLabel
              htmlFor="selectCompany"
            >
              Select Chat Company
            </InputLabel>
            <Select
              style={{ width: '100%' }}
              inputProps={{
                name: 'selectCompany',
                id: 'selectCompany',
              }}
              value={selectedCompanyName}
              onChange={handleChange}
            >
              {companies.map((company) => (
                <MenuItem
                  key={company.id + 5000}
                  value={company.name}
                >
                  {company.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className={classes.addUserInput}>
            <TextField
              autoFocus
              margin="dense"
              label="Email"
              type="text"
              fullWidth
              value={userInputBox}
              onChange={(e) => setInput(e.target.value)}
            />
            <Button onClick={() => handleAddUserByInput()} color="primary">
              Add
            </Button>
          </div>
          <CSVReader
            cssClass="csv-reader-input"
            label="Select CSV file for upload"
            onFileLoaded={(upload) => handleUpload(upload)}
            onError={(err) => console.error(err)}
            inputId="ObiWan"
            inputStyle={{ color: 'red' }}
          />
        </DialogContent>
        <ul className={classes.unorderedList}>
          {emails.map((email, index) => (
            <div key={index + 90000} className={classes.emailListItem}>
              <li>{email}</li>
              <IconButton onClick={() => removeEmail(index)}>
                <HighlightOffIcon className={classes.highLightIcon} />
              </IconButton>
            </div>
          ))}
        </ul>
        <DialogActions>
          <Button onClick={() => closeModal()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleSubmit()} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AddUserDialog.propTypes = {
  history: PropTypes.object,
  getChatUsers: PropTypes.func.isRequired,
};

export default AddUserDialog;
