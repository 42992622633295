import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const Input = (props) => {
  const { setAnswer, value, isMaxed } = props;
  return (
    <>
      <TextField
        id="outlined-textarea"
        value={value}
        onChange={(e) => setAnswer(e)}
        multiline
        rows={4}
        style={{ width: '100%' }}
        variant="outlined"
      />
      {isMaxed ? (
        <p style={{ color: 'red' }}>
          {`${value ? value.length : 0} / 500`}
        </p>
      ) : (
        <p style={{ fontColor: 'red' }}>
          {`${value ? value.length : 0} / 500`}
        </p>
      )}
    </>
  );
};

Input.propTypes = {
  setAnswer: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  isMaxed: PropTypes.bool.isRequired,
};

export default Input;
