/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { PulseLoader } from 'react-spinners';
// import TextField from '@material-ui/core/TextField';
import Input from './Input';
import GridContainer from '../../../../components/Grid/GridContainer';
import GridItem from '../../../../components/Grid/GridItem';
import Button from '../../../../components/CustomButtons/Button';
import Card from '../../../../components/Card/Card';
import CardBody from '../../../../components/Card/CardBody';
import CardHeader from '../../../../components/Card/CardHeader';
import styles from '../../../../assets/jss/material-dashboard-pro-react/views/loginPageStyle';
import SurveyCompleted from './SurveyCompleted';
import Slider from './Slider';
import MultipleChoice from './MultipleChoice';
import MultiSelectChoice from './MultiSelectChoice';
import translations from '../translations';

const useStyles = makeStyles(styles);

export default function Survey({
  sessionId,
  moduleTitle,
  questions,
  moduleId,
  isHosted,
  nextModule,
  lang,
}) {
  const [hasSubmitted, setHasSubmitted] = useState([]);
  const [hasCompleted, setHasCompleted] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [maxCharactersArray, setMaxCharactersArray] = useState([]);
  const [completeSurveyQuestions, setCompleteSurveyQuestions] = useState(questions);
  const [currentUserId, setCurrentUserId] = useState(1);

  // const [isIncorrect, setIncorrect] = React.useState(false);

  useEffect(() => {
    if (questions.length === 0) {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_EVENT_SERVICE}/current-module?sessionId=${sessionId}`,
      })
        .then((res) => {
          // eslint-disable-next-line max-len
          const filteredQuestions = res.data.moduleData.survey_question_joins.map((obj) => obj.survey_question);
          setCompleteSurveyQuestions(filteredQuestions);
          const startAnswers = filteredQuestions.map(
            (questionObj) => {
              const { options } = questionObj.json_options;
              if (questionObj.type === 'multiSelect') {
                return {
                  questionId: questionObj.id,
                  multiAnswers: [],
                  answer: '',
                };
              }
              if (questionObj.type === 'slider') {
                const val = Math.ceil(options.length / 2);
                return {
                  questionId: questionObj.id,
                  answer: options[val - 1].option,
                };
              }
              return {
                questionId: questionObj.id,
                answer: '',
              };
            },
          );
          setAnswers(startAnswers);
        }).catch((err) => {
          console.log('error', err);
        });
    } else {
      setCompleteSurveyQuestions(questions);
    }
  }, [questions]);

  useEffect(() => {
    setCurrentUserId(Number(window.localStorage.getItem('audience_user_id')));

    const startAnswers = completeSurveyQuestions.map(
      (questionObj) => {
        const { options } = questionObj.json_options;
        if (questionObj.type === 'multiSelect') {
          return {
            questionId: questionObj.id,
            multiAnswers: [],
            answer: '',
          };
        }
        if (questionObj.type === 'slider') {
          const val = Math.ceil(options.length / 2);
          return {
            questionId: questionObj.id,
            answer: options[val - 1].option,
          };
        }
        return {
          questionId: questionObj.id,
          answer: '',
        };
      },
    );
    setAnswers(startAnswers);
  }, []);

  useEffect(() => {
    // const audienceUserId = Number(window.localStorage.getItem('audience_user_id'));
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/survey-answer?audienceUserId=${currentUserId}&sessionId=${sessionId}&moduleId=${moduleId}`,
    }).then((res) => {
      setHasSubmitted(
        [...hasSubmitted, { module: moduleId, completed: Boolean(res.data.data.length) }],
      );
    })
      .catch((err) => {
        console.error(err);
      });
  }, [moduleId, currentUserId]);

  const submitAnswers = async () => {
    setHasSubmitted(
      [...hasSubmitted, { module: moduleId, completed: Boolean(true) }],
    );
    // const audienceUserId = Number(window.localStorage.getItem('audience_user_id'));
    const answersCopy = [];
    answers.forEach((answer) => {
      answersCopy.push({
        ...answer,
        moduleId,
        sessionId,
        audienceUserId: currentUserId > 1 ? currentUserId : 1,
      });
    });
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/survey-answer`,
      data: answersCopy,
    }).then((res) => {
      console.log(res);
    });
  };

  const classes = useStyles();

  useEffect(() => {
    setHasCompleted(hasSubmitted.reverse().find(({ module }) => module === moduleId));
  }, [hasSubmitted]);

  return (
    <>
      {(hasCompleted ? hasCompleted.completed : false) ? (
        <SurveyCompleted
          nextModule={nextModule}
          isHosted={isHosted}
          moduleTitle={moduleTitle}
          lang={lang}
        />
      ) : (
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={6} md={4}>
              <form
                autoComplete="off"
                onSubmit={async (e) => {
                  e.preventDefault();
                  submitAnswers();
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <h2>{moduleTitle}</h2>
                </div>
                {completeSurveyQuestions.length === answers.length ? (
                  <>
                    {completeSurveyQuestions.map((questionObj, index) => {
                      if (questionObj.type === 'input') {
                        return (
                          <Card login key={questionObj.id} style={{ marginBottom: '50px' }}>
                            <CardHeader
                              className={`${classes.cardHeader}`}
                              color="primary"
                            >
                              <h4 className={classes.cardTitle}>{questionObj.question}</h4>
                            </CardHeader>
                            <CardBody>
                              <Input
                                value={answers[index] ? answers[index].answer : ''}
                                setAnswer={(e) => {
                                  if (e.target.value.length <= 500) {
                                    if (maxCharactersArray.includes(index)) {
                                      const questionIndex = maxCharactersArray.indexOf(index);
                                      const maxCharactersArrayCopy = [...maxCharactersArray];
                                      if (questionIndex > -1) {
                                        maxCharactersArrayCopy.splice(questionIndex, 1);
                                        setMaxCharactersArray(maxCharactersArrayCopy);
                                      }
                                    }
                                    const newAnswers = [...answers];
                                    newAnswers[index] = {
                                      questionId: questionObj.id,
                                      answer: e.target.value,
                                    };
                                    setAnswers(newAnswers);
                                    if (e.target.value.length === 500) {
                                      setMaxCharactersArray([...maxCharactersArray, index]);
                                    }
                                  } else if (!maxCharactersArray.includes(index)) {
                                    setMaxCharactersArray([...maxCharactersArray, index]);
                                  }
                                }}
                                isMaxed={maxCharactersArray.includes(index)}
                              />
                            </CardBody>
                          </Card>
                        );
                      }
                      if (questionObj.type === 'slider') {
                        return (
                          <Card login key={questionObj.id} style={{ marginBottom: '50px' }}>
                            <CardHeader
                              className={`${classes.cardHeader}`}
                              color="primary"
                            >
                              <h4 className={classes.cardTitle}>{questionObj.question}</h4>
                            </CardHeader>
                            <CardBody>
                              <Slider
                                setAnswer={(answer) => {
                                  const newAnswers = [...answers];
                                  newAnswers[index] = {
                                    questionId: questionObj.id,
                                    answer,
                                  };
                                  setAnswers(newAnswers);
                                }}
                                json_options={questionObj.json_options.options}
                                options={questionObj.options}
                              />
                            </CardBody>
                          </Card>
                        );
                      }
                      if (questionObj.type === 'multipleChoice') {
                        return (
                          <Card login key={questionObj.id} style={{ marginBottom: '50px' }}>
                            <CardHeader
                              className={`${classes.cardHeader}`}
                              color="primary"
                            >
                              <h4 className={classes.cardTitle}>{questionObj.question}</h4>
                            </CardHeader>
                            <CardBody>
                              <MultipleChoice
                                setAnswer={(answer) => {
                                  const newAnswers = [...answers];
                                  newAnswers[index] = {
                                    questionId: questionObj.id,
                                    answer,
                                  };
                                  setAnswers(newAnswers);
                                }}
                                value={answers[index] ? answers[index].answer : 0}
                                options={questionObj.json_options
                                  ? questionObj.json_options.options
                                  : questionObj.options}
                              />
                            </CardBody>
                          </Card>
                        );
                      }
                      if (questionObj.type === 'multiSelect') {
                        return (
                          <Card login key={questionObj.id} style={{ marginBottom: '50px' }}>
                            <CardHeader
                              className={`${classes.cardHeader}`}
                              color="primary"
                            >
                              <h4 className={classes.cardTitle}>{questionObj.question}</h4>
                            </CardHeader>
                            <CardBody>
                              <MultiSelectChoice
                                setAnswer={(answer) => {
                                  const newAnswers = [...answers];
                                  newAnswers[index] = {
                                    questionId: questionObj.id,
                                    multiAnswers: answer,
                                    answer: '',
                                  };
                                  setAnswers(newAnswers);
                                }}
                                value={answers[index] ? answers[index].answer : 0}
                                options={questionObj?.json_options?.options
                                  ? questionObj.json_options.options
                                  : questionObj.options}
                              />
                            </CardBody>
                          </Card>
                        );
                      }
                      return <div>This Question is unavailable</div>;
                    })}
                  </>
                ) : (
                  <PulseLoader
                    sizeUnit="px"
                    size={15}
                    color="#60A681"
                    loading
                  />
                )}
                <div style={{ marginBottom: '50px', display: 'flex', justifyContent: 'center' }}>
                  <Button
                    type="submit"
                    color="lightGreen"
                  >
                    {isHosted ? translations[lang].submit : translations[lang].next}
                  </Button>
                </div>

              </form>
            </GridItem>
          </GridContainer>
        </div>
      )}
    </>
  );
}

Survey.propTypes = {
  sessionId: PropTypes.number,
  questions: PropTypes.array.isRequired,
  moduleTitle: PropTypes.string.isRequired,
  moduleId: PropTypes.number.isRequired,
  nextModule: PropTypes.func,
  isHosted: PropTypes.bool.isRequired,
  lang: PropTypes.string,
};
