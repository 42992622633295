/* eslint-disable react/no-unused-state */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
// import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
// import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
// import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
// core components

import customSelectStyle from '../../../assets/jss/material-dashboard-pro-react/customSelectStyle';
import customListStyle from '../../../assets/jss/material-dashboard-pro-react/customListStyle';
import customCheckboxRadioSwitch from '../../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch';

import { loadAuthToken } from '../../../utils/auth';
import { AuthContext } from '../../../Auth';

const style = {
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center',
  },
  inputAdornmentIcon: {
    color: '#555',
  },
  choiche: {
    textAlign: 'center',
    cursor: 'pointer',
    marginTop: '20px',
  },
  ...customListStyle,
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
};

// Remove a few plugins from the default setup.
// ClassicEditor
//   .create(document.querySelector('#editor'), {
//     removePlugins: ['Heading', 'Link'],
//     toolbar: ['bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote'],
//   })
//   .catch((error) => {
//     console.log(error);
//   });

class Step1 extends React.Component {
  // eslint-disable-next-line react/static-property-placement
   static contextType = AuthContext;

   constructor(props) {
     super(props);
     this.state = {
       text: '',
     };
   }

   componentDidMount() {
     const { sendToLogin } = this.context;
     axios({
       method: 'get',
       url: `${process.env.REACT_APP_MODULE_SERVICE}/resource`,
       headers: {
         Authorization: `bearer ${loadAuthToken()}`,
       },
     }).then((res) => {
       if (res.error === 'your token is not valid' || res.error === 'Your token is expired') {
         sendToLogin();
       } else {
         console.log('resource data', res.data);
         this.setState({ resources: res.data.data });
       }
     }).catch((err) => this.setError(err));
   }

   sendState() {
     return this.state;
   }

   // this is used by the wizard to see if all required are filled out
   // eslint-disable-next-line class-methods-use-this
   isValidated() {
     const { text } = this.state;
     if (text.length > 2) {
       return true;
     }
     return false;
   }

   render() {
     const { classes } = this.props;
     const { text } = this.state;
     return (
       <>
         <div>
           <h4 className={classes.infoText}>Create the text for the updates</h4>
           <CKEditor
             editor={ClassicEditor}
             id="editor"
             data={text}
             onChange={(event, editor) => {
               this.setState({ text: editor.getData() });
             }}
           />
         </div>
       </>
     );
   }
}

Step1.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Step1);
