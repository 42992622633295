import {
  tooltip,
} from '../../material-dashboard-pro-react';

import hoverCardStyle from '../hoverCardStyle';
import dashboardStyle from './dashboardStyle';

const reportStyle = {
  ...dashboardStyle,
  ...hoverCardStyle,
  tooltip,
  reportCardBody: {
    overflow: 'visible',
    padding: '0 5vw',
  },
  tableQuestion: {
    marginBottom: '15px',
  },
  questionTableRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  clickable: {
    cursor: 'pointer',
  },
  headerAlign: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

};

export default reportStyle;
