import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  DataGrid,
} from '@material-ui/data-grid';

import Button from '../../components/CustomButtons/Button';
import Dialog from '../../components/Alert/alert';

import { loadAuthToken } from '../../utils/auth';
import { AuthContext } from '../../Auth';
import ViewEventModal from './ViewEventModal';

export default function EventsGrid(props) {
  const {
    data,
  } = props;
  const [newHeaders, setNewHeaders] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [filteredData, setFilteredData] = useState(data);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(0);

  const context = useContext(AuthContext);

  const headers = [
    {
      headerName: 'Title', field: 'title', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Type', field: 'type', width: 150, disableClickEventBubbling: true,
    },
    {
      headerName: 'Users', field: 'audienceUsers', width: 100, disableClickEventBubbling: true,
    },
    {
      headerName: 'Description', field: 'description', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Language', field: 'lang', width: 150, disableClickEventBubbling: true,
    },
  ];


  const archiveEvent = (eventId) => axios({
    method: 'patch',
    url: `${process.env.REACT_APP_EVENT_SERVICE}/event/${eventId}`,
    headers: {
      Authorization: `bearer ${loadAuthToken()}`,
    },
    data: {
      status: 'archived',
    },
  }).then(() => {
    context.setMessageState('success');
    context.setSnackBarMessage(`Successfully archived event: ${selectedRow.title}`);
    setFilteredData(filteredData.filter((ev) => ev.id !== eventId));
    setSelectedRow({});
    // return this.getEvents();
  }).catch((e) => {
    context.setMessageState('error');
    context.setSnackBarMessage(`${e.response.data.message}`);
    console.log(data);
  });

  useEffect(() => {
    const headersWithAddedButton = [
      {
        headerName: 'Actions',
        field: 'id',
        width: 300,
        disableClickEventBubbling: true,
        renderCell: (event) => (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                setIsDialogOpen(!isDialogOpen);
                setSelectedRow(event.row);
              }}
            >
              Archive
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                setIsModalOpen(!isModalOpen);
                setSelectedRowId(event.row.id);
                setSelectedRow(event.row);
              }}
            >
              View
            </Button>
          </div>
        ),
      },
      ...headers];

    setNewHeaders(headersWithAddedButton);
  }, [data, isDialogOpen]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ height: 600, width: '100%' }}>
      <ViewEventModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} eventId={selectedRowId} eventTitle={selectedRow.title} eventDescription={selectedRow.description} />
      <Dialog
        open={isDialogOpen}
        toggleDialog={setIsDialogOpen}
        text={`Are you sure you want to archive ${selectedRow.title ? selectedRow.title : ''}?`}
        rowId={selectedRow.id ? selectedRow.id.toString() : ''}
        action={() => archiveEvent(selectedRow.id)}
      />
      <DataGrid
        rows={filteredData}
        columns={newHeaders}
        sortingOrder={['asc', 'desc']}
        sortModel={[
          {
            field: 'title',
            sort: 'asc',
          },
        ]}
      />
    </div>
  );
}

EventsGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};
