/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import AdminLayout from './layouts/Admin';
import HostLayout from './layouts/Host';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import Auth from './Auth';

const theme = createTheme({
  typography: {
    fontFamily: [
      '"Filson Pro Regular"',
      'Roboto',
      '"Helvetica"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

export default function AuthRouter() {
  return (
    <Auth>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route path="/auth" component={AuthLayout} />
            <PrivateRoute path="/admin" component={AdminLayout} />
            <PublicRoute path="/host" component={HostLayout} />
            <Route path="/terms" component={HostLayout} />
            <Route path="/privacy" component={HostLayout} />
            <Route path="/letschat" component={HostLayout} />
            <Route path="/event" component={HostLayout} />
            <PublicRoute exact path="/" component={HostLayout} />
            <Route render={() => <Redirect to="/" />} />
          </Switch>
        </Router>
      </ThemeProvider>
    </Auth>
  );
}
