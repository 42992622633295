import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { loadAuthToken } from '../../utils/auth';
import { AuthContext } from '../../Auth';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function SimpleSelect(props) {
  const classes = useStyles();
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({});
  // const [allEvents, setAllEvents] = useState([]);
  const [isEventsLoading, setIsEventsLoading] = useState(false);
  const [selectedCompanyEvents, setSelectedCompanyEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({});

  const context = useContext(AuthContext);
  const history = useHistory();

  const { user, setMessageState, setSnackBarMessage } = context;
  const { findSessions } = props;
  // console.log(selectedCompanyEvents, 'Events');
  // console.log('user info', user);
  useEffect(() => {
    const fetchData = () => {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_AUTH_SERVICE}/companies`,
        headers: {
          Authorization: `bearer ${loadAuthToken()}`,
        },
      }).catch((err) => {
        try {
          setMessageState('error');
          setSnackBarMessage(`${err.response.data.message}`);
        } catch (e) {
          setMessageState('error');
          setSnackBarMessage(e.message);
        }
        if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
          history.push('auth/login');
        }
        return { data: [] };
      }).then((res) => {
        // console.log(res.data.data);
        setCompanies(res.data.data);
        // setAllEvents(res.data.events);
      });
    };
    fetchData();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const companySelect = (e) => {
    setSelectedCompanyEvents([]);
    setIsEventsLoading(true);
    const fetchData = () => {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_EVENT_SERVICE}/EVENT?companyId=${e.target.value.id}`,
        headers: {
          Authorization: `bearer ${loadAuthToken()}`,
        },
      }).catch((err) => {
        try {
          setMessageState('error');
          setSnackBarMessage(`${err.response.data.message}`);
        } catch (error) {
          setMessageState('error');
          setSnackBarMessage(error.message);
        }
        if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
          history.push('auth/login');
        }
        return { data: [] };
      }).then((res) => {
        console.log(res.data);
        setIsEventsLoading(false);

        // setCompanies(res.data.data);
        setSelectedCompanyEvents(res.data);

        // setAllEvents(res.data.events);
      });
    };
    fetchData();
    setSelectedCompany(e.target.value);
    // setSelectedCompanyEvents(filteredEvents);
  };

  const eventSelect = async (e) => {
    setSelectedEvent(e.target.value);
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/session`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
      data: {
        eventId: e.target.value.id,
        userId: user.id,
      },
    }).catch((err) => {
      try {
        setMessageState('error');
        setSnackBarMessage(`${err.response.data.message}`);
      } catch {
        setMessageState('error');
        setSnackBarMessage(err.message);
      }
      if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
        history.push('auth/login');
      }
    }).then(() => {
      findSessions();
    });
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="company-select-label">Select Company</InputLabel>
        <Select
          style={{ width: 300 }}
          labelId="company-select"
          id="company-select"
          value={selectedCompany}
          onChange={(e) => companySelect(e)}
        >
          <MenuItem value="" disabled>
            Select Company
          </MenuItem>
          {companies.map((company) => (
            <MenuItem value={company} key={company.id}>{company.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="event-select-label">
          { isEventsLoading ? 'events loading...' : 'Select Event'}
        </InputLabel>
        <Select
          style={{ width: 300 }}
          disabled={!selectedCompanyEvents.length}
          labelId="event-select"
          id="event-select"
          value={selectedEvent}
          onChange={(e) => eventSelect(e)}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value="" disabled>
            Select event
          </MenuItem>
          {
          user.role === 'admin' ? (
            selectedCompanyEvents.map((event) => (
              <MenuItem value={event} key={event.title}>
                {event.title}
                {' '}
                {event.type === 'autohosted' ? '' : `- ${event.type}` }
              </MenuItem>
            ))
          ) : (
            selectedCompanyEvents.map((event) => {
              if (event.type === 'autohosted') {
                return (
                  <MenuItem value={event} key={event.title}>
                    {event.title}
                  </MenuItem>
                );
              }
              return null;
            })
          )
          }
        </Select>
      </FormControl>
    </div>
  );
}

SimpleSelect.propTypes = {
  findSessions: PropTypes.func,
};
