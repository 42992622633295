/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Collapse from '@material-ui/core/Collapse';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '../../../../components/Card/Card';
import CardFooter from '../../../../components/Card/CardFooter';
import { AuthContext } from '../../../../Auth';
import CardBody from '../../../../components/Card/CardBody';
import CardHeader from '../../../../components/Card/CardHeader';
import styles from '../../../../assets/jss/material-dashboard-pro-react/views/reportPageStyle';
import ComfortTable from './ComfortTable2';
import BubbleGraph from './graph/ComfortBubbleChart';
import NavPills from '../../../../components/NavPills/NavPillsOriginal';

const useStyles = makeStyles(styles);

function ComfortReport(props) {
  const classes = useStyles();
  const context = useContext(AuthContext);
  const [data, setData] = useState({});
  const [toggleCard, setToggleCard] = useState(false);
  const [results, setResults] = useState([]);
  const [tags, setTags] = useState([]);
  const [completed, setCompleted] = useState(0);
  const [shouldDisplay, setShouldDisplay] = useState(true);
  const {
    moduleInfo, sessionId,
  } = props;

  const tabs = [
    {
      tabButton: 'Grid',
      tabContent: (<ComfortTable data={data} />),
    },
    {
      tabButton: 'Graph',
      tabContent: (<BubbleGraph tags={tags} results={results} />),
    },
  ];

  useEffect(() => {
    if (sessionId) {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_EVENT_SERVICE}/comfort-report?sessionId=${sessionId}&moduleId=${moduleInfo.id}`,
      }).then((res) => {
        if (res.data.completedCount) {
          // console.log('res.data', res.data);
          setCompleted(res.data.completedCount);
          setResults(res.data.results);
          setTags(res.data.tagValues);
        } else {
          setShouldDisplay(false);
        }
      }).catch((e) => {
        context.setMessageState('error');
        context.setSnackBarMessage(`${e.response.data.message}`);
      });
    } else {
      setShouldDisplay(false);
    }
  }, [sessionId]);

  useEffect(() => {
    if (sessionId) {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_REPORT_SERVICE}/get-comfort-answers-by-statement?sessionId=${sessionId}`,
      }).then((res) => {
        setData(res.data.data);
      }).catch((e) => {
        context.setMessageState('error');
        context.setSnackBarMessage(`${e.response.data.message}`);
      });
    } else {
      // setShouldDisplay(false);
    }
  }, [sessionId]);

  return (
    <>
      {shouldDisplay ? (
        <>
          {results ? (
            <Card>
              <CardHeader className={classes.headerAlign}>
                <div className={classes.cardCategory}>
                  {moduleInfo.title ? moduleInfo.title : moduleInfo.moduleTitle}
                </div>
                <div>
                  {toggleCard
                    ? (
                      <KeyboardArrowUpIcon
                        onClick={() => setToggleCard(!toggleCard)}
                        className={classes.clickable}
                      />
                    )
                    : (
                      <KeyboardArrowDownIcon
                        onClick={() => setToggleCard(!toggleCard)}
                        className={classes.clickable}
                      />
                    )}
                </div>

              </CardHeader>

              <Collapse in={toggleCard} unmountOnExit>
                <CardBody style={{ overflow: 'auto' }}>
                  <NavPills
                    tabs={tabs}
                    alignCenter="true"
                  />
                </CardBody>
              </Collapse>
              <CardFooter stats>
                <div className={classes.stats}>
                  <p className={classes.cardTitle}>
                    Completed:
                    {' '}
                    {String(completed)}
                  </p>
                </div>
              </CardFooter>
            </Card>
          ) : <CircularProgress />}
        </>
      ) : (
        null
      )}

    </>
  );
}

ComfortReport.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  moduleInfo: PropTypes.object.isRequired,
  sessionId: PropTypes.number,
  // companyId: PropTypes.number,
};

export default ComfortReport;
