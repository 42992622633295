/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import _ from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import TagCloud from 'react-tag-cloud';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { PulseLoader } from 'react-spinners';
import { loadAuthToken } from '../../../../utils/auth';
import Button from '../../../../components/CustomButtons/Button';

import { stereoTypeWords, authenticWords, words } from './SampleWords';

const useStyles = makeStyles({
  tagCloud: {
    height: '50vh',
    width: '100vw',
    flex: '1',
  },

  image: {
    height: '120px',
  },

  sup: {
    fontSize: '20px',
  },
});

const WordCloudScreen = ({
  question,
  moduleId,
  sessionId,
  nextResult,
  isDemo,
}) => {
  const [answers, setAnswers] = useState([]);
  const [answersOnDisplay, setAnswersOnDisplay] = useState([]);
  const [currentPaginationIndex, setCurrentPaginationIndex] = useState(0);
  const [splitAnswers, setSplitAnswers] = useState([]);
  const [isLoading, setIsLoading] = useState(null);
  const paginationPoint = 15;

  const paginateLeft = () => {
    if (currentPaginationIndex !== 0) {
      setAnswersOnDisplay(splitAnswers[currentPaginationIndex - 1]);
      setCurrentPaginationIndex(currentPaginationIndex - 1);
    }
  };

  const paginateRight = () => {
    if (currentPaginationIndex < splitAnswers.length - 1) {
      setAnswersOnDisplay(splitAnswers[currentPaginationIndex + 1]);
      setCurrentPaginationIndex(currentPaginationIndex + 1);
    }
  };

  const setWords = (props) => {
    const totalAnswers = [...Object.entries(props)];
    setAnswers([...totalAnswers]);
    setAnswersOnDisplay([...totalAnswers].splice(0, paginationPoint));
    setSplitAnswers(_.chunk(totalAnswers, paginationPoint));
  };

  const getWordCloudAnswers = () => {
    setIsLoading(!isLoading);
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/word-cloud-answer?questionId=${question.id}&moduleId=${moduleId}&sessionId=${sessionId}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).then((res) => {
      setWords(res.data);
      setIsLoading(false);
    }).catch((err) => {
      console.log('error', err);
    });
  };

  useEffect(() => {
    if (isDemo) {
      if (question.id === 1) {
        setWords(stereoTypeWords);
      } else if (question.id === 2) {
        setWords(authenticWords);
      } else {
        setWords(words);
      }
    } else {
      getWordCloudAnswers();
    }
  }, [question]);

  const nextQuestion = () => {
    setCurrentPaginationIndex(0);
    nextResult();
  };

  const classes = useStyles();
  return (
    <div>

      <img src={question.icon} className={classes.image} alt="StereoTypeMask" />
      <h3>
        {question.description}
      </h3>
      {isLoading ? (
        <PulseLoader
          sizeUnit="px"
          size={15}
          color="#6CCA98"
          loading
        />
      ) : (
        <>
          <TagCloud
            className={classes.tagCloud}
            style={{
              fontFamily: 'sans-serif',
              fontSize: 30,
              color: '#60A681',
              padding: 15,
            }}
          >
            {answersOnDisplay.map((answer, index) => (
              <div
                key={index + 20203}
                style={{
                  fontFamily: 'Filson Pro Regular',
                  fontStyle: 'italic',
                  color: '#60A681',
                }}
              >
                <p>
                  {answer[1].word}
                  {' '}
                  <sup className={classes.sup}>
                    (
                    {answer[1].count}
                    )
                  </sup>
                </p>
              </div>
            ))}
          </TagCloud>
          <IconButton
            onClick={() => paginateLeft()}
            disabled={!(currentPaginationIndex !== 0)}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          {`${currentPaginationIndex * paginationPoint + 1} - ${answersOnDisplay.length + (currentPaginationIndex * paginationPoint)} of ${answers.length}`}
          <IconButton
            onClick={() => paginateRight()}
            disabled={!(currentPaginationIndex < splitAnswers.length - 1)}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </>
      )}

      <Button
        width="100%"
        onClick={() => nextQuestion()}
        align="flex-end"
        style={{ right: '5vw', bottom: '7vh', position: 'absolute' }}
      >
        Next
      </Button>
    </div>
  );
};

WordCloudScreen.propTypes = {
  nextResult: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  sessionId: PropTypes.number.isRequired,
  moduleId: PropTypes.number.isRequired,
  isDemo: PropTypes.bool.isRequired,
};

export default WordCloudScreen;
