/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import PersonPin from '@material-ui/icons/PersonPin';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import CustomInput from '../../../components/CustomInput/CustomInput';
import Button from '../../../components/CustomButtons/Button';
import Card from '../../../components/Card/Card';
import CardBody from '../../../components/Card/CardBody';
import CardHeader from '../../../components/Card/CardHeader';
import CardFooter from '../../../components/Card/CardFooter';
import styles from '../../../assets/jss/material-dashboard-pro-react/views/loginPageStyle';
import SignUpCompleted from './SignUpCompleted';

const useStyles = makeStyles(styles);

export default function SignUp({ sessionId }) {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isIncorrect, setIncorrect] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [cardAnimaton, setCardAnimation] = useState('cardHidden');
  // const [isIncorrect, setIncorrect] = React.useState(false);
  const isValid = async () => {
    const phoneRegex = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;
    const digits = phoneNumber.replace(/\D/g, '');
    if (phoneRegex.test(digits)) {
      setIncorrect(false);
      const audienceUserId = Number(window.localStorage.getItem('audience_user_id'));
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_EVENT_SERVICE}/sign-up`,
        data: {
          phoneNumber: `+1${phoneNumber}`,
          audienceUserId,
          sessionId,
        },
      }).catch((err) => console.log(err));
      setHasSubmitted(true);
    } else {
      setIncorrect(true);
    }
  };

  useEffect(() => {
    const audienceUserId = Number(window.localStorage.getItem('audience_user_id'));
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/sign-up?audienceUserId=${audienceUserId}&sessionId=${sessionId}`,
    }).then((res) => setHasSubmitted(Boolean(res.data.data.length)))
      .catch((err) => {
        console.error(err);
      });
    setTimeout(() => {
      setCardAnimation('');
    }, 700);
  }, []);

  const classes = useStyles();
  return (
    <>
      {hasSubmitted ? (
        <SignUpCompleted
          isHosted
        />
      ) : (
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={6} md={4}>
              <form
                autoComplete="off"
                onSubmit={async (e) => {
                  e.preventDefault();
                  isValid();
                }}
              >
                <Card login className={classes[cardAnimaton]}>
                  <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color="primary"
                  >
                    <h4 className={classes.cardTitle}>Chat Sign Up</h4>
                  </CardHeader>
                  <CardBody>
                    <CustomInput
                      error={isIncorrect}
                      labelText={isIncorrect ? 'That is not a valid phone number' : 'Please Enter Your Phone Number'}
                      id="phoneNumber"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <PersonPin className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        value: phoneNumber,
                        onChange: (e) => {
                          setIncorrect(false);
                          setPhoneNumber(e.currentTarget.value);
                        },
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.justifyContentCenter}>
                    <Button type="submit" disabled={hasSubmitted} color="primary" simple size="lg" block>
                      Sign Up
                    </Button>
                  </CardFooter>
                </Card>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      )}
    </>
  );
}

SignUp.propTypes = {
  sessionId: PropTypes.number,
};
