/* eslint-disable max-len */
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import {
  DataGrid,
} from '@material-ui/data-grid';

import { AuthContext } from '../../../Auth';
import { loadAuthToken } from '../../../utils/auth';

import Button from '../../../components/CustomButtons/Button';
import Dialog from '../../../components/Alert/alert';

// import EditInfoModal from './EditInfoModuleModal';
import ViewInfoModal from './ViewInfoModal';

export default function InfoGrid(props) {
  const [newHeaders, setNewHeaders] = useState([]);
  // const [infoToEdit, setInfoToEdit] = useState({});
  // const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [info, setInfo] = useState({});

  const {
    rawData,
  } = props;

  const context = useContext(AuthContext);

  const headers = [
    {
      headerName: 'Title', field: 'title', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Date Created', field: 'createdAt', width: 250, disableClickEventBubbling: true,
    },
  ];
    // archive event function taken from InfoModule.js... not tested.
  const archiveInfoModule = (infoModuleId) => {
    axios({
      method: 'patch',
      url: `${process.env.REACT_APP_MODULE_SERVICE}/info-module/${infoModuleId}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
      data: {
        status: 'archived',
      },
    }).then(() => {
      context.setMessageState('success');
      context.setSnackBarMessage(`Successfully archived ${selectedRowId.title} info module.`);
      setFilteredData(filteredData.filter((el) => el.id !== infoModuleId));
      setSelectedRowId({});
      // this.getInfoModules();
    }).catch((e) => {
      context.setMessageState('error');
      context.setSnackBarMessage(`${e.response.data.message}`);
    });
  };

  useEffect(() => {
    const headersWithAddedButton = [
      {
        headerName: 'Actions',
        field: 'id',
        width: 325,
        disableClickEventBubbling: true,
        renderCell: (inf) => (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                setSelectedRowId(inf.row);
                setIsDialogOpen(!isDialogOpen);
              }}
            >
              Archive
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                setInfo(inf.row);
                setIsInfoModalOpen(!isInfoModalOpen);
              }}
            >
              View
            </Button>
          </div>
        ),
      },
      // {
      //   headerName: 'Edit Event',
      //   field: 'id',
      //   width: 200,
      //   disableClickEventBubbling: true,
      //   renderCell: (info) => (
      //     <div>
      //       <Button
      //         disabled
      //         variant="contained"
      //         color="primary"
      //         onClick={(e) => {
      //           e.preventDefault();
      //           setInfoToEdit(info.row);
      //           setIsEditModalOpen(!isEditModalOpen);
      //         }}
      //       >
      //         Edit Question
      //       </Button>
      //     </div>
      //   ),
      // },
      ...headers];
    setFilteredData(rawData);
    setNewHeaders(headersWithAddedButton);
  }, [rawData]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ height: 600, width: '100%' }}>
      <ViewInfoModal
        open={isInfoModalOpen}
        info={info}
        toggleModal={() => {
          setIsInfoModalOpen(!isInfoModalOpen);
        }}
      />
      {/* <EditInfoModal
        togalModal={() => setIsEditModalOpen(!isEditModalOpen)}
        infoModule={infoToEdit}
        open={isEditModalOpen}
      /> */}
      <Dialog
        open={isDialogOpen}
        toggleDialog={setIsDialogOpen}
        text={`Are you sure you want to archive ${selectedRowId.title ? selectedRowId.title : ''}`}
        rowId={selectedRowId.id ? selectedRowId.id : ''}
        action={archiveInfoModule}
      />
      <DataGrid
        rows={filteredData}
        columns={newHeaders}
        sortingOrder={['asc', 'desc']}
        sortModel={[
          {
            field: 'title',
            sort: 'asc',
          },
        ]}
      />
    </div>
  );
}

InfoGrid.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  rawData: PropTypes.arrayOf(PropTypes.object),
};
