/* eslint-disable react/forbid-prop-types */
import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Card from '../../../components/Card/Card';
import CardFooter from '../../../components/Card/CardFooter';
import CardBody from '../../../components/Card/CardBody';
import CardHeader from '../../../components/Card/CardHeader';
import styles from '../../../assets/jss/material-dashboard-pro-react/views/reportPageStyle';
import CompaniesTable from './support/CompaniesTable';
import { AuthContext } from '../../../Auth';
import { loadAuthToken } from '../../../utils/auth';

const useStyles = makeStyles(styles);

function CompanpaniesTable() {
  const classes = useStyles();
  const context = useContext(AuthContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_AUTH_SERVICE}/companies`,
        headers: {
          Authorization: loadAuthToken(),
        },
      }).then((res) => {
        setData(res.data.data);
        return res;
      })
        .catch((e) => {
          console.log(e);
          context.setMessageState('error');
          context.setSnackBarMessage(`${e.response.data.message}`);
        });
    };

    fetchData();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card>
      <CardHeader className={classes.headerAlign}>
        <div className={classes.header}>
          <h3>Companies</h3>
        </div>

      </CardHeader>
      <CardBody className={classes.reportCardBody}>
        <CompaniesTable data={data} />
      </CardBody>

      <CardFooter stats>
        <div className={classes.stats}>
          <p className={classes.cardTitle}>
            Total:
            {data.length}
          </p>
        </div>
      </CardFooter>
    </Card>
  );
}

export default CompanpaniesTable;
