import React, { useContext } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
// core components
import Wizard from '../../components/Wizard/Wizard';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';

import Step1 from './Wizard/Step1';
import Step2 from './Wizard/Step2';
import { loadAuthToken } from '../../utils/auth';
import { AuthContext } from '../../Auth';

export default function WizardView(props) {
  const { history } = props;
  const context = useContext(AuthContext);
  const createSurvey = (state) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_AUTH_SERVICE}/companies`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
      data: {
        name: state.about.name,
        contactName: state.details.contactName,
        contactEmail: state.details.contactEmail,
      },
    }).then(() => {
      context.setMessageState('success');
      context.setSnackBarMessage(`Successfully created company: ${state.about.name}`);
      setTimeout(() => history.push('/admin/companies'), 500);
    }).catch((err) => {
      context.setMessageState('error');
      context.setSnackBarMessage(`${err.response.data.message}`);
      if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
        history.push('auth/login');
      }
    });
  };

  return (
    <GridContainer justifyContent="center">
      <GridItem xs={12} sm={10}>
        <Wizard
          history={history}
          validate
          steps={[
            { stepName: 'About', stepComponent: Step1, stepId: 'about' },
            { stepName: 'Details', stepComponent: Step2, stepId: 'details' },
          ]}
          title="New Company"
          subtitle=""
          finishButtonClick={(state) => createSurvey(state)}
          color="primary"
        />
      </GridItem>
    </GridContainer>
  );
}

WizardView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};
