/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Slide from '@material-ui/core/Slide';

function TransitionUp(props) {
  return <Slide {...props} direction="down" />;
}

const SnackBar = ({
  messageState, message, setMessage, setMessageState,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsOpen(false);
    setMessage('');
    setMessageState('');
  };

  useEffect(() => {
    if (message !== '' && messageState !== '') {
      setIsOpen(true);
    }
  }, [messageState, message]);

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        transitioncomponent={TransitionUp()}
        severity={messageState}
      >
        { message }
      </MuiAlert>
    </Snackbar>
  );
};

SnackBar.propTypes = {
  message: PropTypes.string,
  messageState: PropTypes.string,
};

export default SnackBar;
