import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  Widget, addResponseMessage, addUserMessage, toggleWidget,
} from 'react-chat-widget';
// import { AuthContext } from '../../../Auth';
import 'react-chat-widget/lib/styles.css';

import translations from '../Modules/translations';

export default function LiveChat(props) {
  // const context = useContext(AuthContext);
  // const { pusher } = context;
  const {
    sessionId,
    moduleData,
    lang,
  } = props;
  const [audienceUserId, setAudienceUserId] = useState(1);
  const [chatToggle, setChatToggle] = useState(false);
  const [userText, setUserText] = useState('');

  // const channel = pusher.subscribe(`${sessionId}`);

  const setMessage = (() => {
    const elements = document.getElementsByClassName('rcw-input');
    if (elements[0]) {
      elements[0].innerHTML = userText;
    }
  });

  useEffect(() => {
    setMessage();
    // channel.bind('current-module-change', () => {
    if (chatToggle) {
      toggleWidget();
      setChatToggle(false);
    }
    // });
    // return () => {
    //   channel.unbind('current-module-change');
    // };
  }, [moduleData]);

  useEffect(() => {
    addResponseMessage(translations[lang].oneWayMsg);
    addResponseMessage(translations[lang].hostResponds);
    const userId = Number(window.localStorage.getItem('audience_user_id'));
    setAudienceUserId(Number(userId));

    axios({
      method: 'get',
      url: `${process.env.REACT_APP_LIVE_CHAT_SERVICE}/questions?audienceUserId=${userId}&sessionId=${sessionId}`,
    }).then((data) => {
      data.data.data.forEach((message) => {
        addUserMessage(message.question);
      });
    }).catch((err) => console.log(err));
  }, []);

  const handleNewUserMessage = (message) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_LIVE_CHAT_SERVICE}/questions`,
      data: {
        question: message,
        sessionId,
        audienceUserId,
      },
    }).then(() => {
      addResponseMessage(translations[lang].thankYouMsgSent);
      setUserText('');
    })
      .catch((err) => console.log(err));
  };

  return (
    <Widget
      handleToggle={(state) => setChatToggle(state)}
      // handleToggle={newChatToggle}
      handleNewUserMessage={handleNewUserMessage}
      showTimeStamp={false}
      title={false}
      showBadge={false}
      handleTextInputChange={(e) => setUserText(e.target.innerHTML)}
      subtitle={(
        <div>
          {translations[lang].anonymousMessages}
        </div>
    )}
      senderPlaceHolder={(
          translations[lang].typeMsg
    )}
      autofocus={false}
    />
  );
}

LiveChat.propTypes = {
  sessionId: PropTypes.number,
  lang: PropTypes.string,
};
