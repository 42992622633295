export const demoData = [
  35, 33, 33, 33, 33, 33, 31, 29,
  29, 29, 29, 29, 27, 27,
  27, 27, 27, 27, 27, 25, 25, 23,
  23, 23, 23, 23, 23, 21, 21, 19,
  17, 17, 15, 15, 15, 15, 13, 13,
  13, 13, 13, 13, 11, 11, 9, 9,
  7, 5, 5, 3, 3, 1, -1, -1, -5,
  -5, -5, -7, -9, -9, -11, -19,
];

export const allTimeDistributionData = [
  35, 35, 35, 33, 33, 31, 31, 31, 31,
  31, 31, 31, 29, 29, 29, 29, 29, 29,
  29, 29, 29, 27, 27, 27, 27, 27, 27,
  27, 27, 27, 27, 27, 27, 27, 27, 27,
  27, 25, 25, 25, 25, 25, 25, 25, 25,
  25, 25, 23, 23, 23, 23, 23, 23, 23,
  21, 21, 21, 21, 21, 21, 21, 21, 21,
  21, 21, 21, 19, 19, 19, 19, 19, 19,
  19, 17, 17, 17, 17, 17, 17, 15, 15,
  15, 15, 13, 13, 13, 13, 13, 13, 11,
  11, 11, 11, 11, 11, 11, 11, 9, 9,
  9, 9, 9, 9, 7, 7, 5, 5, 5, 5, 5,
  5, 3, 3, 3, 3, 3, 3, 1, 1, 1, 1,
  1, -1, -1, -1, -1, -3, -3, -3, -3,
  -3, -5, -5, -5, -5, -5, -5, -7, -7,
  -7, -7, -7, -9, -9, -9, -9, -11, -11,
  -11, -11, -11, -13, -13, -15, -15, -17,
  -17, -19, -19, -19, -21, -21, -23, -25,
  -27,
];
