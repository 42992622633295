import React from 'react';
import PropTypes from 'prop-types';
import Joyride from 'react-joyride';

const WordCloudTutorial = ({ setTutorial, startWordPopcorn }) => (
  <>
    <Joyride
      continuous
      styles={{
        options: {
          padding: '60px',
          primaryColor: '#60A681',
          textColor: '#004a14',
        },
        buttonClose: {
          display: 'none',
        },
      }}
      locale={{
        last: 'Start!',
      }}
      callback={(step) => {
        setTutorial(step.step.target);
        if (step.action === 'reset') {
          window.localStorage.setItem('word_popcorn_tutorial_completed', 'true');
          setTutorial('');
          startWordPopcorn();
        }
      }}
      steps={[
        {
          target: 'body',
          placement: 'center',

          content: (
            <>
              <p style={{ fontSize: '24px' }}>
                Ready to get started?
              </p>
            </>
          ),
          disableBeacon: true,
          floaterProps: {
            disableAnimation: true,
          },
        },
      ]}
    />
  </>
);

WordCloudTutorial.propTypes = {
  setTutorial: PropTypes.func.isRequired,
  startWordPopcorn: PropTypes.func.isRequired,
};

export default WordCloudTutorial;
