/* eslint-disable consistent-return */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Table from './WalkOfPrivilegeTable';
import Chart from './WalkOfPrivilegeBarGraph';
import WalkOfPrivilegeDoughNutGraph from './WalkOfPrivilegeDoughNut';

const WopSwitch = (props) => {
  const {
    displayType,
    usersAnswered,
    companyId,
    eventId,
    selfIdData,
    moduleInfo,
    selfIdSplitData,
    sessionId,
    questions,
  } = props;
  if (displayType === 'list') {
    return (
      <Table
        companyId={companyId}
        eventId={eventId}
        moduleInfo={moduleInfo}
        sessionId={sessionId}
        questions={questions}
      />
    );
  } if (displayType === 'barchart') {
    return (
      <Chart
        usersAnswered={usersAnswered}
        selfIdData={selfIdData}
      />
    );
  } if (displayType === 'doughnut') {
    return (
      <WalkOfPrivilegeDoughNutGraph
        selfIdSplitData={selfIdSplitData}
      />
    );
  }
};

WopSwitch.propTypes = {
  displayType: PropTypes.string.isRequired,
  setDisplayType: PropTypes.func.isRequired,
  usersAnswered: PropTypes.array.isRequired,
  companyId: PropTypes.number.isRequired,
  eventId: PropTypes.number.isRequired,
  selfIdSplitData: PropTypes.array.isRequired,
  selfIdData: PropTypes.array.isRequired,
  moduleInfo: PropTypes.object.isRequired,
  sessionId: PropTypes.number.isRequired,
  questions: PropTypes.array.isRequired,
};

export default WopSwitch;
