import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import WopChart from './WopChart';
import WopFinalScore from './WopFinalScore';
// import Button from '../../../../components/CustomButtons/Button';

const useStyles = makeStyles({
  outerDiv: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    height: '100%',
  },
});

const WopFinished = (props) => {
  const classes = useStyles();
  const [viewGraph, setViewGraph] = useState(false);
  const {
    points, moduleTitle, isHosted, nextModule, sessionId, moduleId,
  } = props;

  const handleNext = () => {
    if (!viewGraph) {
      setViewGraph(true);
    } else {
      nextModule();
    }
  };
  return (
    <div className={classes.outerDiv}>
      {(viewGraph && !isHosted) ? (
        <WopChart
          points={points}
          sessionId={sessionId}
          moduleId={moduleId}
          moduleTitle={moduleTitle}
          isHosted={isHosted}
          handleNext={handleNext}
        />
      ) : (
        <WopFinalScore
          points={points}
          moduleTitle={moduleTitle}
          isHosted={isHosted}
          handleNext={handleNext}
        />
      )}

    </div>
  );
};

WopFinished.propTypes = {
  points: PropTypes.number.isRequired,
  nextModule: PropTypes.func.isRequired,
  moduleTitle: PropTypes.string.isRequired,
  isHosted: PropTypes.bool.isRequired,
  moduleId: PropTypes.number.isRequired,
  sessionId: PropTypes.number.isRequired,
};

export default WopFinished;
