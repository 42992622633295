import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import _ from 'lodash';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import {
  DataGrid,
} from '@material-ui/data-grid';
import { PulseLoader } from 'react-spinners';
import { AuthContext } from '../../../../Auth';
import { loadAuthToken } from '../../../../utils/auth';

const headers = [
  { field: 'answer', headerName: 'Word', width: 200 },
  { field: 'wordCloudTagId', headerName: 'Tag Id', width: 100 },
];

const TagContent = (props) => {
  const {
    tagId, wordTags, setIsDialogOpen, setSelectedTag, setSelectedWord, isDialogOpen,
  } = props;
  const context = useContext(AuthContext);
  const [words, setWords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newHeaders, setNewHeaders] = useState([]);

  useEffect(() => {
    const headersWithAddedButton = [...headers,
      {
        headerName: 'Update Tag',
        // eslint-disable-next-line no-useless-concat
        field: 'id',
        width: 200,
        disableClickEventBubbling: true,
        renderCell: (word) => (
          <div>
            <FormControl>
              <InputLabel id="word-tag-label">Update Tag</InputLabel>
              <Select
                labelId="word-tag-label"
                id="demo-simple-select"
                onChange={(e) => {
                  setSelectedTag(e.target.value);
                  setSelectedWord(word.row);
                  setIsDialogOpen(!isDialogOpen);
                }}
                fullWidth
              >
                <MenuItem disabled>Select Tag</MenuItem>
                {wordTags.map((t) => (
                  <MenuItem key={t.tag} value={t}>
                    {t.tag}
                  </MenuItem>
                ))}

              </Select>
            </FormControl>
          </div>
        ),
      },
    ];
    setNewHeaders(headersWithAddedButton);
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsLoading(true);
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/word-cloud-tag/${tagId}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).then((res) => {
      // console.log('inside useEffect', res.data);
      setWords(res.data);
      setIsLoading(false);
      return res;
    })
      .catch((e) => {
        console.log(e);
        context.setMessageState('error');
        context.setSnackBarMessage(`ERROR ${e.response}`);
        if (e.error === 'your token is not valid' || e.error === 'Your token is expired') {
          context.sendToLogin();
        }
      });
  }, [wordTags]);// eslint-disable-line react-hooks/exhaustive-deps

  const uniqueTaggedWords = _.uniqBy(
    words.word_cloud_answers,
    (a) => a.answer.toLowerCase().trim(),
  );

  return (
    <>
      { isLoading ? (
        <PulseLoader
          sizeUnit="px"
          size={15}
          color="#6CCA98"
          loading
        />
      ) : (
        <div style={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={uniqueTaggedWords}
            columns={newHeaders}
            pageSize={50}
            sortingOrder={['asc', 'desc']}
            sortModel={[
              {
                field: 'answer',
                sort: 'asc',
              },
            ]}
          />
        </div>
      )}

    </>
  );
};

TagContent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  wordTags: PropTypes.arrayOf(PropTypes.object),
  tagId: PropTypes.number.isRequired,
  setIsDialogOpen: PropTypes.func,
  setSelectedTag: PropTypes.func,
  setSelectedWord: PropTypes.func,
  isDialogOpen: PropTypes.bool,
};

export default TagContent;
