/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PulseLoader } from 'react-spinners';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import Button from '../../../../components/CustomButtons/Button';

const useStyles = makeStyles({
  outerDiv: {
    textAlign: 'center',
    display: 'flex',
    // height: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '50px',
  },
  textBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '50px',
    '@media (min-width: 900px)': {
      width: '65%',
    },
  },
  amazingWork: {
    fontSize: '22px',
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  largeText: {
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    fontSize: '22px',
    // zIndex: '2',
  },
  messageIcon: {
    color: '#6CCA98',
    height: '100px',
    width: '100px',
  },
  image: {
    width: '260px',
    marginBottom: '5px',
  },
  Slider: {
    width: '97%',
  },
  SliderContainer: {
    width: '100%',
    marginBottom: '100px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    // overflow: 'auto',
    '@media (max-width: 1100px)': {
      flexDirection: 'row',
      marginTop: '35px',
      justifyContent: 'center',
    },
  },
  color: {
    height: '95px',
    width: '95px',
    margin: '2px',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50px',
  },
  scoreContainer: {
    display: 'flex',
    // width: '100%',
    // flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: '75px',
    '@media (max-width: 1100px)': {
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      marginTop: '15px',
    },

  },
  textBox: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '@media (max-width: 1100px)': {
      flexDirection: 'column',
      height: '140vh',
      display: 'none',
    },
  },
  mobileText: {
    display: 'inherent',
    '@media (min-width: 1100px)': {
      display: 'none',
    },
  },
});
const ComfortCompleted = (props) => {
  const classes = useStyles();
  const {
    moduleTitle, isHosted, nextModule, results,
  } = props;

  const [sortedData, setSortedData] = useState([]);
  const [scorable, setScorable] = useState(false);

  useEffect(() => {
    const obj = _.countBy(results, (rec) => rec.value === 0);
    if (obj.false >= 5) {
      setScorable(true);
      const sortedArray = _.sortBy(results, ['value', 'tag']);
      setSortedData(sortedArray);
    } else {
      const sortedArray = _.sortBy(results, ['tag']);
      setSortedData(sortedArray);
    }
  }, [results]);

  // const demoData = [
  //   { tag: 'Age', tagPoints: 2, totalTagPoints: 2 },
  //   { tag: 'Race', tagPoints: 9, totalTagPoints: 15 },
  //   { tag: 'Ethnicity', tagPoints: 10, totalTagPoints: 13 },
  //   { tag: 'Gender', tagPoints: 9, totalTagPoints: 15 },
  //   { tag: 'About You', tagPoints: 7, totalTagPoints: 9 },
  //   { tag: 'Mental Health', tagPoints: 6, totalTagPoints: 19 },
  //   { tag: 'Sexual Orientation', tagPoints: 2, totalTagPoints: 8 },
  //   { tag: 'Ability', tagPoints: 8, totalTagPoints: 9 },
  //   { tag: 'Religion & Values', tagPoints: 1, totalTagPoints: 9 },
  //   { tag: 'Family Status', tagPoints: 6, totalTagPoints: 9 },
  //   { tag: 'Appearance', tagPoints: 3, totalTagPoints: 7 },
  // ];

  // const sortedData = _.sortBy(demoData, ['tagPoints', 'tag']);

  // green gradient
  const colorLegend = [
    '#2a7050',
    '#3B7E5F',
    '#48896C',
    '#519074',
    '#5C997E',
    '#5C997E',
    '#69A389',
    '#76AD94',
    '#84B8A1',
    '#92C4AE',
    '#A2D1BC',
  ];

  return (
    <div className={classes.outerDiv}>
      <div>
        <img src="https://s3.amazonaws.com/join.host/comfortzone.png" className={classes.image} alt="Comfort Zone" />
      </div>
      <p className={classes.amazingWork}>
        AMAZING WORK!
      </p>
      <p className={classes.largeText}>
        You've just completed the
        {' '}
        {moduleTitle}
        {' '}
        module.
      </p>
      {isHosted ? (
        <div className={classes.textBody}>
          <p className={classes.largeText}>
            Thank you for your participation! Please wait for instructions from your host,
            {' '}
            In the meantime here are your personal Comfort Zone scores.
          </p>
          <div className={classes.SliderContainer}>
            {results.length ? (
              <div>
                <h6 className={classes.mobileText}>Less Comfortable</h6>
                <div className={classes.scoreContainer}>
                  {sortedData.map((option, i) => (
                    <span
                      key={option.tag}
                      className={classes.color}
                      style={{

                        backgroundColor: scorable ? colorLegend[i] : '#2a7050',

                      }}
                    >
                      {option.tag}
                    </span>
                  ))}
                </div>
                <h6 className={classes.mobileText}>More Comfortable</h6>
                <div className={classes.textBox}>
                  <h6 style={{ width: '150px', marginRight: '20px' }}>
                    Less Comfortable
                  </h6>
                  <h6 style={{ width: '150px' }}>More Comfortable</h6>
                </div>
              </div>
            ) : (
              <PulseLoader
                sizeUnit="px"
                size={15}
                color="#60A681"
                loading
              />
            )}
          </div>
        </div>
      ) : (
        <div>
          <Button
            type="submit"
            color="lightGreen"
            onClick={() => nextModule()}
          >
            Next Module
          </Button>
        </div>
      )}
    </div>
  );
};

ComfortCompleted.propTypes = {
  moduleTitle: PropTypes.string.isRequired,
  isHosted: PropTypes.bool.isRequired,
  nextModule: PropTypes.func,
  results: PropTypes.array.isRequired,
};

export default ComfortCompleted;
