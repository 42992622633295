/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// core components

import customSelectStyle from '../../../assets/jss/material-dashboard-pro-react/customSelectStyle';
import customListStyle from '../../../assets/jss/material-dashboard-pro-react/customListStyle';
import customCheckboxRadioSwitch from '../../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch';

const style = {
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center',
  },
  inputAdornmentIcon: {
    color: '#555',
  },
  choiche: {
    textAlign: 'center',
    cursor: 'pointer',
    marginTop: '20px',
  },
  ...customListStyle,
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
};

class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
    };
  }

  sendState() {
    return this.state;
  }

  // this is used by the wizard to see if all required are filled out
  // eslint-disable-next-line class-methods-use-this
  isValidated() {
    return true;
  }

  render() {
    const { classes } = this.props;
    const { text } = this.state;
    return (
      <div>
        <h4 className={classes.infoText}>Create the text for the email invites</h4>
        <h5 className={classes.infoText}>
          To create the link to the session,
          create a link using the paper clip, and name it linkToSession
        </h5>
        <CKEditor
          editor={ClassicEditor}
          data={text}
          onInit={(editor) => {
            console.log('Editor is ready to use!', editor);
          }}
          onChange={(event, editor) => {
            this.setState({ text: editor.getData() });
          }}
          onBlur={(event, editor) => {
            console.log('Blur.', editor);
          }}
          onFocus={(event, editor) => {
            console.log('Focus.', editor);
          }}
        />
      </div>
    );
  }
}

Step3.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Step3);
