import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import {
  Select, MenuItem, InputLabel, FormControl, IconButton,
} from '@material-ui/core';
import { AuthContext } from '../../../Auth';
import { loadAuthToken } from '../../../utils/auth';

const styles = {

};

const useStyles = makeStyles(styles);

const TrainingGroupForm = (props) => {
  const [trainingGroups, setTrainingGroups] = useState([]);
  const classes = useStyles();
  const context = useContext(AuthContext);

  const {
    userId, fetchData, trainingCompanies,
  } = props;

  const {
    setMessageState, setSnackBarMessage,
  } = context;

  useEffect(() => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_AUTH_SERVICE}/training-groups`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).then((res) => {
      if (res.error === 'your token is not valid' || res.error === 'Your token is expired') {
        // sendToLogin();
      } else {
        const newArray = res.data.data;

        // filter out companies already assigned
        const results = newArray.filter(({ id: id1 }) => !trainingCompanies.some(({ trainingGroupId: id2 }) => id2 === id1));
        setTrainingGroups(results);
      }
    }).catch((err) => console.log(err));
  }, [trainingCompanies]);


  const handleSubmit = (group) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_AUTH_SERVICE}/training-groups-users`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
      data: {
        userId,
        trainingGroupId: group.id,
      },
    }).then(() => {
      setMessageState('success');
      setSnackBarMessage('Successfully Added Group');
      fetchData();
    //   toggleModal();
    }).catch((err) => {
      setMessageState('error');
      setSnackBarMessage(`${err.response.data.message}`);
      if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
        // history.push('auth/login');
      }
    });
  };

  return (
    <div>
      <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel
          htmlFor="training-groups"
        //   className={classes.selectLabel}
        >
          Select Traning Group(s) (optional)
        </InputLabel>
        <Select
          MenuProps={{
            className: classes.selectMenu,
          }}
          onChange={(e) => handleSubmit(e.target.value)}
          classes={{
            select: classes.select,
          }}
          inputProps={{
            name: 'trainingGroup',
            id: 'training-group',
          }}
        >
          <MenuItem
            disabled
            classes={{
              root: classes.selectMenuItem,
            }}
          >
            Select Traning Group(s) (optional)
          </MenuItem>
          {trainingGroups.map((trainingGroup) => (
            <MenuItem
              key={trainingGroup.name}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={trainingGroup}
            >
              {`${trainingGroup.name}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default TrainingGroupForm;
