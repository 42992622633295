/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-trailing-spaces */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../../components/CustomButtons/Button';
import CustomInput from '../../components/CustomInput/CustomInput';
import { AuthContext } from '../../Auth';
import { loadAuthToken } from '../../utils/auth';

const EditCompanyModal = ({
  open, togalModal, company, history, 
}) => {
  const [name, setName] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const context = useContext(AuthContext);

  useEffect(() => {
    if (company.name) {
      setName(company.name);
      setContactEmail(company.contactEmail);
      setContactName(company.contactName);
    }
  }, [company]);

  const handleSubmit = () => {
    axios({
      method: 'patch',
      url: `${process.env.REACT_APP_AUTH_SERVICE}/companies/${company.id}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
      data: { 
        name,
        contactEmail,
        contactName,
      },
    }).then(() => {
      context.setMessageState('success');
      context.setSnackBarMessage(`Successfully edited survey company: ${name}`);
      togalModal();
    }).catch((err) => {
      context.setMessageState('error');
      context.setSnackBarMessage(`${err.response.data.message}`);
      if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
        history.push('auth/login');
      }
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => togalModal()}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: '100%',
            minHeight: '60%',
          },
        }}
      >
        <DialogTitle id="form-dialog-title">Add New Question</DialogTitle>
        <DialogContent>
          {name.length === 0 ? (
            <p style={{ color: 'red' }}>Company Must have a name!</p>
          ) : null}
          <CustomInput
            error={name.length === 0}
            labelText={(
              <span>
                Name of the client
                {' '}
                <small>(required)</small>
              </span>
            )}
            id="name"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: name,
              onChange: (event) => setName(event.target.value),
            }}
          />
          <CustomInput
            error={name.length === 0}
            labelText={(
              <span>
                Name of contact
              </span>
            )}
            id="contact name"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: contactName,
              onChange: (event) => setContactName(event.target.value),
            }}
          />
          <CustomInput
            error={name.length === 0}
            labelText={(
              <span>
                Email of the contact
              </span>
            )}
            id="email"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: contactEmail,
              onChange: (event) => setContactEmail(event.target.value),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => togalModal()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleSubmit()}>
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

EditCompanyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  togalModal: PropTypes.func.isRequired,
  history: PropTypes.object,
  company: PropTypes.object.isRequired,
};

export default EditCompanyModal;
