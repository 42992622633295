import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { PulseLoader } from 'react-spinners';

// import DialogContentText from '@material-ui/core/DialogContentText';
import {
  DialogTitle, DialogContent, DialogActions, Dialog,
  Accordion, AccordionSummary, AccordionDetails, Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '../../components/CustomButtons/Button';
import { AuthContext } from '../../Auth';

import { loadAuthToken } from '../../utils/auth';
import CompaniesDropDown from './CompaniesDropdown';

function ViewEventModal(props) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [disableCopyButton, setDisableCopyButton] = useState(true);

  const {
    isModalOpen, setIsModalOpen, eventId, eventTitle, eventDescription,
  } = props;

  const context = useContext(AuthContext);

  const {
    setMessageState, setSnackBarMessage,
  } = context;

  useEffect(() => {
    setIsLoading(!isLoading);
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/event-details?eventId=${eventId}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).then((res) => {
      console.log('res', res.data.data);
      setData(res.data.data);
      setIsLoading(false);
      // return res;
    })
      .catch((e) => {
        console.log(e);
        // context.setMessageState('error');
        // context.setSnackBarMessage(`${e.response.data.message}`);
      });
  }, [eventId]);// eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (selectedCompany.id) {
      setDisableCopyButton(false);
    }
  }, [selectedCompany]);
  useEffect(() => {
    setDisableCopyButton(true);
  }, [isModalOpen]);

  const copyModule = () => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/copy-event?eventId=${eventId}&company=${selectedCompany.id}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).then(() => {
      setMessageState('success');
      setSnackBarMessage(`Successfully copied event: ${eventTitle}`);
      setIsModalOpen();

    // return this.getEvents();
    }).catch((e) => {
      setMessageState('error');
      setSnackBarMessage(`${e.response.data.message}`);
      console.log(data);
    });
  };

  return (
    <div>
      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen()}
        togg
        maxWidth="md"
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: '100%',
            minHeight: '60%',
          },
        }}
      >
        <DialogTitle id="form-dialog-title">
          {'Event Title: '}
          {eventTitle}
          <br />
          {'Description: '}
          {eventDescription}
        </DialogTitle>

        <DialogContent>
          {isLoading ? (
            <PulseLoader
              sizeUnit="px"
              size={15}
              color="#6CCA98"
              loading
            />
          ) : data.map((mod) => (
            <Accordion key={mod.updatedAt}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  {'Module Title: '}
                  {mod.title}
                  <br />
                  { ' ' }
                  {'Description: '}
                  {mod.description ? mod.description : ''}
                  <br />
                  { ' ' }
                  {'Module Type: '}
                  {mod.wop_question_joins ? 'Slider' : ''}
                  {mod.comfort_meter_joins ? 'Likert' : ''}
                  {mod.survey_question_joins ? 'Survey' : ''}
                  {mod.cloud_question_joins ? 'Word Cloud' : ''}
                  {mod.poll_question_joins ? 'Poll' : ''}
                  {mod.slide_question_joins ? 'Auto-Host Slide' : ''}

                  {
                    // not the best way to determine type for word pop
                  mod.answerLimit ? 'Word Popcorn' : ''
                  }
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <ul>
                    {mod.comfort_meter_joins ? mod.comfort_meter_joins.map((wp) => (

                      <li key={wp.comfort_meter_question.statement}>
                        {wp.comfort_meter_question.statement}
                      </li>

                    )) : ''}
                    {mod.wop_question_joins ? mod.wop_question_joins.map((wp) => (
                      <li key={wp.walk_of_privilege_question.question}>
                        {wp.walk_of_privilege_question.question}
                        {' '}
                        --- Swipe
                        {' '}
                        {wp.walk_of_privilege_question.direction}
                      </li>
                    )) : ''}
                    {mod.survey_question_joins ? mod.survey_question_joins.map((wp) => (
                      <li key={wp.survey_question.question}>
                        {wp.survey_question.question}
                      </li>
                    )) : ''}
                    {mod.cloud_question_joins ? mod.cloud_question_joins.map((wp) => (
                      <li key={wp.word_cloud_question.question}>
                        {wp.word_cloud_question.question}
                      </li>
                    )) : ''}
                    {mod.spectrum_question_joins ? mod.spectrum_question_joins.map((wp) => (
                      <li key={wp.spectrum_question.question}>
                        {wp.spectrum_question.question}
                      </li>
                    )) : ''}
                    {mod.poll_question_joins ? mod.poll_question_joins.map((poll) => (

                      <li key={poll.poll_question.question}>
                        {poll.poll_question.question}
                        <ul>

                          {poll.poll_question.options.options.map((options) => (
                            <li>
                              {' '}
                              {options.option}
                              {' '}
                            </li>
                          ))}

                        </ul>
                      </li>

                    )) : ''}
                    {mod.slide_question_joins ? mod.slide_question_joins.map((wp) => (
                      <li key={wp.slide_question.questionText}>
                        {wp.slide_question.questionText}
                      </li>
                    )) : ''}
                    {mod.question ? (
                      <li>{`Question: ${mod.question}`}</li>
                    ) : ' '}
                  </ul>
                  {mod.text ? mod.text : ''}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}

        </DialogContent>
        <DialogActions>
          <CompaniesDropDown setSelectedCompany={setSelectedCompany} selectedCompany={selectedCompany} />

          <Button
            disabled={disableCopyButton}
            color="secondary"
            onClick={(e) => {
              e.preventDefault();
              copyModule();
            }}
          >

            Copy
          </Button>
          <Button onClick={() => setIsModalOpen()} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ViewEventModal.propTypes = {
//   questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool,
  eventId: PropTypes.number.isRequired,
  eventTitle: PropTypes.string.isRequired,
  eventDescription: PropTypes.string,
};

export default ViewEventModal;
