/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-trailing-spaces */
import React, { useState, useEffect, useContext } from 'react';
import AWS from 'aws-sdk';
import PropTypes from 'prop-types';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '../../../components/CustomButtons/Button';
import CustomInput from '../../../components/CustomInput/CustomInput';
import { AuthContext } from '../../../Auth';
import { loadAuthToken } from '../../../utils/auth';

const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET;
const REGION = 'us-east-1';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY_KEY,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const EditResourceModal = ({
  open, toggleModal, resource, history,
}) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [tags, setTags] = useState([]);
  const [link, setLink] = useState('');
  const [resourceId, setResourceId] = useState('');
  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState({});
  const context = useContext(AuthContext);

  const uploadFile = (file) => {
    const params = {
      ACL: 'public-read',
      Body: file,
      Bucket: S3_BUCKET,
      Key: file.name,
      ContentType: file.type,
    };
    myBucket.putObject(params)
      .on('httpUploadProgress', (evt) => {
      // console.log(params, 'params');
        setProgress(Math.round((evt.loaded / evt.total) * 100));
      })
      .on('success', (x) => {
        const { path, headers } = x.request.httpRequest;
        const fileString = `https://${headers.Host}${path}`;
        setLink(fileString);
      })
      .send((err) => {
      // console.log(err);
        if (err) console.log(err, 'err');
      });
  };

  const handleFileInput = (e) => {
    // console.log('handle file input', e.target.files[0]);
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  useEffect(() => {
    if (resource.title) {
      setTitle(resource.title);
      setDescription(resource.description);
      setTags(resource.tags);
      setLink(resource.link);
      setResourceId(resource.id);
    }
  }, [resource]);

  const handleSubmit = () => {
    console.log(title, description, tags, link, 'resource inputs');
    axios({
      method: 'patch',
      url: `${process.env.REACT_APP_MODULE_SERVICE}/resource/${resourceId}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
      data: { 
        title,
        description,
        link,
      },
    }).then(() => {
      context.setMessageState('success');
      context.setSnackBarMessage(`Successfully edited resource: ${title}`);
      toggleModal();
      history.push('/admin/resources');
    }).catch((err) => {
      context.setMessageState('error');
      context.setSnackBarMessage(`${err.response.data.message}`);
      if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
        history.push('auth/login');
      }
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => toggleModal()}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: '100%',
            minHeight: '60%',
          },
        }}
      >
        <DialogTitle id="form-dialog-title">Edit Resource</DialogTitle>
        <DialogContent>
          {title.length === 0 ? (
            <p style={{ color: 'red' }}>Resource must have a title!</p>
          ) : null}
          <CustomInput
            error={title.length === 0}
            labelText={(
              <span>
                Edit Title
              </span>
            )}
            id="title"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: title,
              onChange: (event) => setTitle(event.target.value),
            }}
          />
          <CustomInput
            error={description.length === 0}
            labelText={(
              <span>
                Edit Description
              </span>
            )}
            id="description"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: description,
              onChange: (event) => setDescription(event.target.value),
            }}
          />
          <br />
          <p style={{ fontSize: 'medium', color: '#000' }}> Choose file and upload new resource:</p>
          <input type="file" onChange={handleFileInput} />
          <button 
            type="submit"
            onClick={() => uploadFile(selectedFile)}
          >
            {' '}
            Upload File
            {' '}

          </button>
          <p style={{ fontSize: 'medium', color: '#000' }}>
            File Upload Progress is:
            {progress}
            %
          </p>
          <CustomInput
            error={description.length === 0}
            labelText={(
              <span>
                Resource URL
              </span>
            )}
            id="link"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: link,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggleModal()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleSubmit()}>
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ); 
};

EditResourceModal.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  history: PropTypes.object,
  resource: PropTypes.object.isRequired,
};

export default EditResourceModal;
