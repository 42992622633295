import React from 'react';
import PropTypes from 'prop-types';

// import DialogContentText from '@material-ui/core/DialogContentText';
import {
  DialogTitle, DialogContent, DialogActions, Dialog,
} from '@material-ui/core';
import Button from '../../../components/CustomButtons/Button';
// import { AuthContext } from '../../Auth';
// import { loadAuthToken } from '../../utils/auth';

function InfoModuleInfoModal(props) {
  const { toggleModal, info, open } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => toggleModal()}
        maxWidth="md"
        togg
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: '100%',
            minHeight: '60%',
          },
        }}
      >
        <DialogTitle id="form-dialog-title">
          {'Title: '}
          {info.title}
        </DialogTitle>
        <DialogContent>
          <p>
            {info.text}
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggleModal()} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

InfoModuleInfoModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  info: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default InfoModuleInfoModal;