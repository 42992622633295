/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  DataGrid,
} from '@material-ui/data-grid';

import { AuthContext } from '../../../../Auth';
import { loadAuthToken } from '../../../../utils/auth';

import Button from '../../../../components/CustomButtons/Button';
import Dialog from '../../../../components/Alert/alert';
import ComfortStatementsModal from './ViewComfortStatementsModal';

export default function ComfortGrid(props) {
  const [newHeaders, setNewHeaders] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [statements, setStatements] = useState({});
  const [isStatementModalOpen, setIsStatementModalOpen] = useState(false);

  const {
    data,
  } = props;
  // console.log('comfort table data', data);
  const context = useContext(AuthContext);

  const headers = [
    {
      headerName: 'Title', field: 'title', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Description', field: 'description', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Date Created', field: 'createdAt', width: 250, disableClickEventBubbling: true,
    },
  ];

  const archiveComfortZone = (comfortId) => {
    axios({
      method: 'patch',
      url: `${process.env.REACT_APP_MODULE_SERVICE}/comfort-meter/${comfortId}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
      data: {
        status: 'archived',
      },
    }).then(() => {
      context.setMessageState('success');
      context.setSnackBarMessage(`Successfully archived ${selectedRowId.title} info module.`);
      setFilteredData(filteredData.filter((el) => el.id !== comfortId));
      setSelectedRowId({});
    }).catch((e) => {
      context.setMessageState('error');
      context.setSnackBarMessage(`${e.response.data.message}`);
    });
  };

  useEffect(() => {
    const headersWithAddedButton = [
      {
        headerName: 'Actions',
        // eslint-disable-next-line no-useless-concat
        field: 'id',
        width: 325,
        disableClickEventBubbling: true,
        renderCell: (cz) => (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                setSelectedRowId(cz.row);
                setIsDialogOpen(true);
              }}
            >
              Archive
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                setIsStatementModalOpen(true);
                setStatements(cz.row);
              }}
            >
              View
            </Button>
          </div>
        ),
      },
      ...headers];
    setFilteredData(data);
    setNewHeaders(headersWithAddedButton);
  }, [data]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ height: 600, width: '100%' }}>
      <ComfortStatementsModal
        open={isStatementModalOpen}
        toggleModal={() => {
          setIsStatementModalOpen(!isStatementModalOpen);
        }}
        statements={statements}
      />
      <Dialog
        open={isDialogOpen}
        toggleDialog={setIsDialogOpen}
        text={`Are you sure you want to archive ${selectedRowId.title ? selectedRowId.title : ''}`}
        rowId={selectedRowId.id ? selectedRowId.id : ''}
        action={archiveComfortZone}
      />
      <DataGrid
        rows={filteredData}
        columns={newHeaders}
        sortingOrder={['asc', 'desc']}
        sortModel={[
          {
            field: 'title',
            sort: 'asc',
          },
        ]}
      />
    </div>
  );
}

ComfortGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};
