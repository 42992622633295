import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '../../../../components/CustomButtons/Button';

import GridContainer from '../../../../components/Grid/GridContainer';
import GridItem from '../../../../components/Grid/GridItem';
import Card from '../../../../components/Card/Card';

import Graph from './WalkOfPrivilegeBarGraph';
import Summary from './WopSummary';

const useStyles = makeStyles((theme) => ({
  results: {
    height: '80vh',
    overflow: 'auto',
  },
  container: {
    width: '80vw',
    margin: 0,
  },
  backBtn: {
    right: '2vw',
    bottom: '1vh',
    position: 'absolute',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function Results(props) {
  const classes = useStyles();
  const {
    setViewingFalse, usersAnswered, percents, isDemo, isAllTime,
    setIsAllTime, isCompany, setIsCompany,
  } = props;

  return (
    <div className={classes.results}>
      <GridContainer className={classes.container}>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <Graph
              usersAnswered={usersAnswered}
              isAllTime={isAllTime}
              isCompany={isCompany}
              isDemo={isDemo}
              setViewingFalse={() => setViewingFalse()}
            />
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <Summary
              isDemo={false}
              percents={percents}
            />
          </Card>
        </GridItem>
      </GridContainer>
      <div className={classes.backBtn}>
        <Button
          onClick={() => {
            setViewingFalse();
            setIsAllTime();
            setIsCompany();
          }}
        >
          View Module
        </Button>
      </div>
    </div>
  );
}

Results.propTypes = {
  setViewingFalse: PropTypes.func.isRequired,
  usersAnswered: PropTypes.arrayOf(PropTypes.object).isRequired,
  percents: PropTypes.arrayOf(PropTypes.object).isRequired,
  isDemo: PropTypes.bool.isRequired,
  isAllTime: PropTypes.bool.isRequired,
  setIsAllTime: PropTypes.func.isRequired,
  isCompany: PropTypes.bool.isRequired,
  setIsCompany: PropTypes.func.isRequired,
};
