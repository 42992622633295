import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import withStyles from '@material-ui/core/styles/withStyles';

import {
  Select, MenuItem, InputLabel, FormControl, Paper, IconButton,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { loadAuthToken } from '../../../utils/auth';
import { AuthContext } from '../../../Auth';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';

const style = {
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center',
  },
  inputAdornmentIcon: {
    color: '#555',
  },
  inputAdornment: {
    position: 'relative',
  },
  emailListItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  highLightIcon: {
    color: '#60A681',
  },
};

class Step2 extends React.Component {
   // eslint-disable-next-line react/static-property-placement
   static contextType = AuthContext;

   constructor(props) {
     super(props);
     this.state = {
       trainingGroups: [],
       groupsToAdd: [],
       //    group: {},
     };
   }

   componentDidMount() {
     const { sendToLogin } = this.context;
     axios({
       method: 'get',
       url: `${process.env.REACT_APP_AUTH_SERVICE}/training-groups`,
       headers: {
         Authorization: `bearer ${loadAuthToken()}`,
       },
     }).then((res) => {
       if (res.error === 'your token is not valid' || res.error === 'Your token is expired') {
         sendToLogin();
       } else {
        //  console.log('training group data', res.data);
         this.setState({ trainingGroups: res.data.data });
       }
     }).catch((err) => this.setError(err));
   }

   handleAddTrainingGroup(group) {
     const { groupsToAdd } = this.state;
     this.setState({ groupsToAdd: [...groupsToAdd, group] });
   }

   setError(err) {
     const { setMessageState, setSnackBarMessage } = this.context;
     setMessageState('error');
     setSnackBarMessage(`${err.response.data.message}`);
   }

   removeGroup(index) {
     const { groupsToAdd } = this.state;
     const groupsToAddCopy = [...groupsToAdd];
     groupsToAddCopy.splice(index, 1);
     this.setState({ groupsToAdd: groupsToAddCopy });
   }

   sendState() {
     return this.state;
   }

   render() {
     const { trainingGroups, groupsToAdd } = this.state;
     const { classes } = this.props;
     return (
       <>
         <GridContainer justifyContent="center">
           <GridItem xs={12} md={12}>
             <FormControl fullWidth className={classes.selectFormControl}>
               <InputLabel
                 htmlFor="training-groups"
                 className={classes.selectLabel}
               >
                 Select Traning Group(s) (optional)
               </InputLabel>
               <Select
                 MenuProps={{
                   className: classes.selectMenu,
                 }}
                 onChange={(e) => this.handleAddTrainingGroup(e.target.value)}
                 classes={{
                   select: classes.select,
                 }}
                 inputProps={{
                   name: 'trainingGroup',
                   id: 'training-group',
                 }}
               >
                 <MenuItem
                   disabled
                   classes={{
                     root: classes.selectMenuItem,
                   }}
                 >
                   Select Traning Group(s) (optional)
                 </MenuItem>
                 {trainingGroups.map((trainingGroup) => (
                   <MenuItem
                     key={trainingGroup.name}
                     classes={{
                       root: classes.selectMenuItem,
                       selected: classes.selectMenuItemSelected,
                     }}
                     value={trainingGroup}
                   >
                     {`${trainingGroup.name}`}
                   </MenuItem>
                 ))}
               </Select>
             </FormControl>
           </GridItem>
         </GridContainer>
         <Paper elevation={3} variant="outlined" square style={{ margin: '0 50px 0 50px' }}>
           {/* <h6 style={{ textAlign: 'center' }}>Traing Groups:</h6> */}
           {groupsToAdd.length === 0 ? (
             <div className={classes.emailListItem}><ul>None Selected</ul></div>
           ) : (
             <ul className={classes.unorderedList}>
               {groupsToAdd.map((g, index) => (
                 <div key={g.name} className={classes.emailListItem}>
                   <li>{g.name}</li>
                   <IconButton onClick={() => this.removeGroup(index)}>
                     <HighlightOffIcon className={classes.highLightIcon} />
                   </IconButton>
                 </div>
               ))}
             </ul>
           )}

         </Paper>
       </>
     );
   }
}

Step2.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object,
};

export default withStyles(style)(Step2);
