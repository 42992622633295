/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider';

const styles = () => ({
  text: {
    margin: 0,
  },
  spectrumSlider: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  lastText: {
    margin: 0,
    display: 'inline',
    textAlign: 'end',
  },
  centerText: {
    margin: 0,
    textAlign: 'center',
    display: 'inline',
  },
});

const PrettoSlider = withStyles({
  root: {
    color: '#52af77',
    width: '100%',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid #52af77',
    marginTop: -8,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const SpectrumCompletedSlider = ({
  options, classes, answer,
}) => {
  return (
    <>
      <PrettoSlider
        min={1}
        step={1}
        max={100}
        onChange={() => console.log("DON'T CHANGE THAT!")}
        // defaultValue={answer.answer}
        value={answer.answer}
        name="Demo"
      />
      <div className={classes.spectrumSlider}>
        {options.map((option, optionIndex) => {
          if (
            optionIndex % 2 === 1
                && optionIndex
                === Math.floor((options.length * 1) / 2)
          ) {
            return (
              <p
                key={optionIndex + 1000}
                className={classes.text}
              >
                {option.type ? option.option : option}
              </p>
            );
          }
          if (optionIndex % 2 === 0) {
            const last = options.length === (optionIndex + 1);
            if (optionIndex === 2) {
              return (
                <p
                  key={optionIndex + 1000}
                  className={classes.centerText}
                >
                  {option.type ? option.option : option}
                </p>
              );
            }
            return (
              <p
                key={optionIndex + 1000}
                className={!last ? classes.text : classes.lastText}
              >
                {option.type ? option.option : option}
              </p>
            );
          }
        })}
      </div>
    </>
  );
};

SpectrumCompletedSlider.propTypes = {
  options: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired,
};

export default withStyles(styles)(SpectrumCompletedSlider);
