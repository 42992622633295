import React from 'react';
import Card from '../../components/Card/Card';

function Privacy() {
  return (
    <Card style={{
      overflow: 'auto', height: '100%', padding: '10px', margin: '10px',
    }}
    >
      {' '}
      {/* <div style={{overflow: 'scroll'}} bg="primary" width="100vw" p={3} align="center"> */}
      <div>
        <div justifyContent="center" row>
          <p size="xxxLarge" weight="xBold">
            Privacy Policy
          </p>
        </div>
        <div pb={1}>
          <p size="large">
            Translator LLC (“Translator,” “us,” or “we“) is committed to
            protecting your privacy. This Privacy Policy describes Translator’s
            information practices and the choices available to you regarding
            Translator’s use of your personally identifiable information (that
            is, information that can be used to identify a person, hereinafter
            referred to as “PII”). We also collect, use and share “aggregated
            information” such as statistical or demographic information for any
            purpose. Aggregated information may be derived from your PII but is
            not considered personally-identifiable information as this
            information does not directly or indirectly reveal your identity.
            However, if we combine or connect aggregated information with your
            PII so that it can directly or indirectly identify you, we treat the
            combined information as PII which will be used in accordance with
            this Privacy Policy.
          </p>
        </div>
        <div pb={1}>
          <p size="large">
            Translator provides a mobile-based service (the “App”) designed
            specifically to address confidential human resource issues (the
            “Services”) of employees in the workplace (the “Employees“) whose
            employers (the “Employers”) have retained Translator to provide the
            Services for their Employees. The Services allow Employees to
            communicate with Translator-Coaches (“Coaches“) with respect to
            their confidential human resource issues. The information exchanged
            between the Employees and the Coaches is referred to as the
            “Content”. The Content may include PII. Translator will not disclose
            Content to any third party including the Employer except as
            described in this Privacy Policy or pursuant to the agreement
            between Translator and the Employer.
          </p>
        </div>

        <div pb={1}>
          <p size="large">
            The use of information collected through the Services shall be
            limited to the purpose of providing the Services to Employees, and
            as otherwise described in this Privacy Policy.
          </p>
        </div>

        <div pb={1}>
          <p size="xLarge" weight="xBold">
            How Does Translator Collect Your PII
          </p>
        </div>

        <div pb={1}>
          <p size="large">
            We use different methods to collect information from and about you
            including:
          </p>
        </div>

        <div pb={1}>
          <p size="large">
            Direct interactions: You may give us your PII when you use and
            interact with Translator Coaches by using the App. This includes PII
            you provide when you request sessions with a Translator Coach and
            participate in sessions with a Translator Coach.
          </p>
        </div>

        <div pb={1}>
          <p size="large">
            Automated technologies or interactions: As you interact with our
            App, we may automatically collect technical information from and
            about your equipment.
          </p>
        </div>

        <div pb={1}>
          <p size="large">
            Third parties and publicly available sources: We may receive PII
            about you from various third parties, including your Employer or
            from publicly available sources (e.g. social media).
          </p>
        </div>

        <div pb={1}>
          <p size="xLarge" weight="xBold">
            How Information You Provide to Translator Can Be Used
          </p>
        </div>

        <div pb={1}>
          <p size="large">
            We only use your PII when the law in your jurisdiction allows us to.
            We use your PII:
          </p>
        </div>

        <div pb={1}>
          <p size="large">
            - to create and administer your account when you register
          </p>
        </div>
        <div pb={1}>
          <p size="large">
            - to identify you when you sign-in to your account and to allow you
            to conduct sessions with a Translator-Coach
          </p>
        </div>
        <div pb={1}>
          <p size="large">
            -to comply with our contracts or applicable law, including to
            investigate, prevent or take action in connection with a legal
            proceeding or legally binding request, the enforcement of our Terms
            of Service with you, requests relating to technical issues,
            protecting the rights, property or personal safety of Translator,
            you as a user or the public, defending legal claims or as otherwise
            permitted or required by law
          </p>
        </div>

        <div pb={1}>
          <p size="xLarge" weight="xBold">
            Translator Does Not Provide Any PII from Sessions between You and
            Translator Coaches to Your Employer.
          </p>
        </div>
        <div pb={1}>
          <p size="large">
            Other Statistical Information: Translator may monitor your use of
            the Services, analyze data, and compile statistical and performance
            information related to the provision and operation of the Services.
            Translator may use and provide to third parties such statistical and
            performance information in an anonymized and aggregated manner,
            provided that such information does not incorporate PII.
          </p>
        </div>
        <div pb={1}>
          <p size="xLarge" weight="xBold">
            With Whom Will We Share Your Information?
          </p>
        </div>

        <div pb={1}>
          <p size="large">
            We require all third parties to respect the security of your PII and
            to treat it in accordance with the law. We do not allow our
            third-party service providers to use your PII for their own purposes
            and only permit them to process your PII for specified purposes and
            in accordance with our instructions. Translator may have to share
            your PII with the parties forth below for the purposes set forth
            above.
          </p>
        </div>
        <div pb={1}>
          <p size="large">
            • Translator Coaches: Translator shares your PII with Translator
            Coaches who may provide you with human resource advice as part of
            the Services.
          </p>
        </div>
        <div pb={1}>
          <p size="large">
            • Service Providers: Translator may share your PII with companies or
            individuals that provide us with services. These services may
            include, among other things, creating or maintaining our
            telecommunications infrastructure, databases, researching and
            analyzing the usage and performance of the App, preparing and
            distributing communications, and responding to inquiries.
          </p>
        </div>

        <div pb={1}>
          <p size="large">
            • Successor Organizations following a Business Transition: In the
            event that Translator, or any portion of our assets, are acquired by
            a third party, Translator may share your PII with the acquiring
            company or business subject to applicable law.
          </p>
        </div>

        <div pb={1}>
          <p size="large">
            • Law Enforcement Agencies: Translator may report to law enforcement
            agencies any activities that we are obligated to disclose under
            mandatory reporting rules, that we reasonably believe to be
            unlawful, or that we reasonably believe may aid a law enforcement
            investigation into unlawful activity or to protect your health or
            safety or that of others. In addition, subject to applicable law,
            Translator reserves the right to release your PII to law enforcement
            agencies if Translator determines that you have violated our
            policies or terms of use, or the release of your PII may protect the
            rights, property, or safety of Translator, its employees and agents
            or any other person. We may be required to disclose PII in response
            to a legal inquiry by public authorities, including but not limited
            to meeting national security or law enforcement requirements.
          </p>
        </div>

        <div pb={1}>
          <p size="large">
            • Government, Regulatory or Court Authorities: Translator may share
            your PII with governmental entities, regulatory authorities or third
            parties in response to subpoenas, court orders, other legal process,
            or as Translator believes is necessary to exercise our legal rights,
            to defend against legal claims that have been brought against
            Translator, or to defend against possible legal claims that
            Translator determines might be brought against Translator.
          </p>
        </div>

        <div pb={1}>
          <p size="large">
            We may share aggregated, pseudonymous and/or de-identified
            information (including demographic information) about you with your
            Employer (for contractually specified reporting and statistical
            usage purposes), our service providers and subcontractors,
            measurement analytics providers, apps, or other companies including
            for use in creating marketing materials, cases studies and
            statistical analyses.
          </p>
        </div>
        <div pb={1}>
          <p size="xLarge" weight="xBold">
            More questions?
          </p>
          <p size="large">
            Send us an email: clientsuccess@translator.company
          </p>
        </div>
        <div pb={1}>
          <p size="xLarge" weight="xBold">
            Security
          </p>
        </div>

        <div pb={1}>
          <p size="large">
            We take reasonable steps to prevent your PII from being accidentally
            lost, used or accessed in an unauthorized way, altered or disclosed.
            In addition, we limit access to your PII to our employees, agents,
            contractors and other third parties who have a business need to
            know. They will only process your PII on our instructions and they
            are subject to a duty of confidentiality.
          </p>
        </div>

        <div pb={1}>
          <p size="large">
            We also maintain procedures to deal with any suspected PII breaches
            and will notify you and any applicable regulator of a breach where
            we are legally required to do so.
          </p>
        </div>
        <div pb={1}>
          <p size="xLarge" weight="xBold">
            Changes to this Privacy Policy
          </p>
        </div>

        <div pb={1}>
          <p size="large">
            Translator reserves the right to modify this Privacy Policy at any
            time in our sole discretion. If Translator makes any material
            changes to this Privacy Policy those changes will apply to PII we
            have already collected from you unless we are required to obtain
            your consent before making those changes. Translator will endeavor
            to notify you of material changes, nevertheless we encourage you to
            periodically rediv this page for the latest information on this
            Privacy Policy.
          </p>
        </div>

        <div pb={1}>
          <p size="xLarge" weight="xBold">
            Contacting Us about this Privacy Policy
          </p>
        </div>

        <div pb={1}>
          <p size="large">
            In the event you have any questions about this Privacy Policy or
            Translator’s information practices, please contact us email at
            info@translator.company.
          </p>
        </div>

        <div pb={1}>
          <p size="xLarge" weight="xBold">
            Effective Date of this Privacy Policy: August 25, 2019
          </p>
        </div>
      </div>
      {/* </div> */}
    </Card>
  );
}

export default Privacy;
