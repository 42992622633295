import React from 'react';
import { Bar } from 'react-chartjs-2';
import { demoData, allTimeDistributionData } from './chartData';

const CurrentData = (props) => {
  let chartData = [];
  const { isDemo, isAllTime } = props;
  if (isDemo) {
    chartData = demoData;
  } else if (isAllTime) {
    chartData = allTimeDistributionData;
  } else if (props.usersAnswered.length) {
    chartData = props.usersAnswered.map((answer) => answer.points);
  } else {
    chartData = demoData;
  }
  return {
    labels: chartData.sort((a, b) => b - a),
    datasets: [
      {
        label: 'Privilege Distribution',
        backgroundColor: '#6CCA98',
        // borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: chartData.sort((a, b) => b - a),
      },
    ],
  };
};

const options = {
  responsive: true,
  tooltips: {
    mode: 'label',
  },
  plugins: {
    labels: false,
  },
  legend: {
    display: false,
    labels: {
      fontSize: 25,
      fontFamily: '"Filson Pro Regular","Roboto", "Helvetica", "Arial", sans-serif',
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          suggestedMin: -10,
          suggestedMax: 10,
          stepSize: 5,
          fontSize: 25,
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          fontSize: 25,
        },
      },
    ],
  },
};

const setTitle = (props) => {
  const { isCompany, isAllTime } = props;
  if (isCompany) {
    return <h3>Company-wide Privilege Distribution</h3>;
  } if (isAllTime) {
    return <h3>All-time Privilege Distribution</h3>;
  }
  return <h3>Privilege Distribution</h3>;
};

const WalkOfPrivilegeBarGraph = (props) => (
  <>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {setTitle(props)}
    </div>
    <Bar
      data={CurrentData(props)}
      width={1000}
      height={450}
      options={options}
    />
  </>
);

export default WalkOfPrivilegeBarGraph;
