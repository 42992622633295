/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-trailing-spaces */
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import AddIcon from '@material-ui/icons/Add';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../../../components/CustomButtons/Button';
import CustomInput from '../../../components/CustomInput/CustomInput';
import { AuthContext } from '../../../Auth';
import { loadAuthToken } from '../../../utils/auth';
import Tags from './Wizard/QuestionTags';

const AddQuestionDialog = (props) => {
  const { open, togalModal } = props;
  const [questionError, setQuestionError] = useState(false);
  const [optionsError, setOptionsError] = useState([]);
  const [userQuestion, setUserQuestion] = useState('');
  const [options, setOptions] = useState([]);
  const [type, setType] = useState('input');
  const [tags, setTags] = useState([]);
  const { user, setMessageState, setSnackBarMessage } = useContext(AuthContext);

  // useEffect(() => {
  //   console.log(options);
  //   console.log(type);
  // }, [options, type]);

  const handleDelete = (i) => {
    const tagsCopy = tags.slice(0);
    tagsCopy.splice(i, 1);
    setTags(tagsCopy);
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleSubmit = () => {
    let isError = false;
    if (userQuestion.length === 0) {
      isError = true;
      setQuestionError(true);
    }
    if (type !== 'input' && options.some((option) => option === '')) {
      isError = true;
      const errorArray = [];
      options.forEach((option, index) => {
        if (option === '') {
          errorArray.push(index);
        }
        setOptionsError(errorArray);
      });
    }
    if (!isError) {
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_MODULE_SERVICE}/survey-question`,
        headers: {
          Authorization: `bearer ${loadAuthToken()}`,
        },
        data: { 
          question: userQuestion,
          type,
          json_options: {
            options: options.map(((op) => ({
              type,
              option: op,
            }))), 
          },
          userId: user.id,
          tags,
        },
      }).then(() => {
        setOptions([]);
        setType('input');
        setMessageState('success');
        setSnackBarMessage(`Successfully created survey question: ${userQuestion}`);
        setUserQuestion('');
        setOptionsError([]);
        setQuestionError(false);
        setTags([]);
        togalModal();
      }).catch((err) => {
        setMessageState('error');
        setSnackBarMessage(`${err.response.data.message}`);
        if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
          props.history.push('auth/login');
        }
      });
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => togalModal()}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: '100%',
            minHeight: '60%',
          },
        }}
      >
        <DialogTitle id="form-dialog-title">Add New Question</DialogTitle>
        <DialogContent>
          <CustomInput
            // success={userQuestionState === 'success'}
            error={questionError}
            labelText={(
              <span>
                Question
                {' '}
                <small>(required)</small>
              </span>
            )}
            id="question"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: userQuestion,
              onChange: (event) => {
                if (questionError) setQuestionError(false);
                setUserQuestion(event.target.value);
              },
            }}
          />
          <div>
            <FormControl component="fieldset">
              <FormLabel component="legend">Question Type</FormLabel>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={type}
                onChange={(e) => {
                  const selectedType = e.target.value;
                  if (selectedType === 'input') {
                    setOptions([]);
                  } else if (!(options.length > 0)) {
                    setOptions(['']);
                  }
                  setType(selectedType);
                }}
              >
                <FormControlLabel value="input" control={<Radio />} label="Input" />
                <FormControlLabel value="slider" control={<Radio />} label="Slider" />
                <FormControlLabel value="multipleChoice" control={<Radio />} label="Multiple Choice" />
                <FormControlLabel value="multiSelect" control={<Radio />} label="Multiple Choice Many Select" />
              </RadioGroup>
            </FormControl>
            <p>
              Selected Type:
              {' '}
              {type}
            </p>
          </div>
          {type !== 'input' ? (
            <>
              {options.map((option, index) => {
                if (index === 0) {
                  return (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <CustomInput
                      // style={}
                      // success={userQuestionState === 'success'}
                        error={optionsError.includes(index)}
                        key={`option ${index}`}
                        labelText={(
                          <span>
                            Option 
                            {' '}
                            {index + 1}
                            {' '}
                            <small>(required)</small>
                          </span>
                      )}
                        id={`option ${index}`}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: options[index],
                          onChange: (event) => {
                            if (optionsError.includes(index)) {
                              const i = optionsError.indexOf(index);
                              if (i > -1) {
                                const errorCopy = [...optionsError];
                                errorCopy.splice(i, 1);
                                setOptionsError(errorCopy);
                              }
                            } 
                            const optionsCopy = [...options];
                            optionsCopy[index] = event.target.value;
                            setOptions(optionsCopy);
                          },
                        }}
                      />
                      <IconButton 
                        onClick={() => {
                          setOptions([...options, '']);
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    </div>
                  ); 
                } if (index + 1 === options.length) {
                  return (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <CustomInput
                  // success={userQuestionState === 'success'}
                        error={optionsError.includes(index)}
                        key={`option ${index}`}
                        labelText={(
                          <span>
                            Option 
                            {' '}
                            {index + 1}
                            {' '}
                            <small>(required)</small>
                          </span>
                    )}
                        id={`option ${index}`}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: options[index],
                          onChange: (event) => {
                            if (optionsError.includes(index)) {
                              const i = optionsError.indexOf(index);
                              if (i > -1) {
                                const errorCopy = [...optionsError];
                                errorCopy.splice(i, 1);
                                setOptionsError(errorCopy);
                              }
                            } 
                            const optionsCopy = [...options];
                            optionsCopy[index] = event.target.value;
                            setOptions(optionsCopy);
                          },
                        }}
                      />
                      <IconButton onClick={() => {
                        const newOptions = [...options];
                        newOptions.pop();
                        setOptions(newOptions);
                      }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  ); 
                } 
                return (
                  <CustomInput
                // success={userQuestionState === 'success'}
                    error={optionsError.includes(index)}
                    key={`option ${index}`}
                    labelText={(
                      <span>
                        Option 
                        {' '}
                        {index + 1}
                        {' '}
                        <small>(required)</small>
                      </span>
                )}
                    id={`option ${index}`}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: options[index],
                      onChange: (event) => {
                        if (optionsError.includes(index)) {
                          const i = optionsError.indexOf(index);
                          if (i > -1) {
                            const errorCopy = [...optionsError];
                            errorCopy.splice(i, 1);
                            setOptionsError(errorCopy);
                          }
                        } 
                        const optionsCopy = [...options];
                        optionsCopy[index] = event.target.value;
                        setOptions(optionsCopy);
                      },
                    }}
                  />
                );
              })} 
            </> 
          ) : null}
          <Tags
            tags={tags}
            handleAddition={(tag) => handleAddition(tag)}
            handleDelete={(i) => handleDelete(i)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => togalModal()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleSubmit()}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AddQuestionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  togalModal: PropTypes.func.isRequired,
  history: PropTypes.object,
};

export default AddQuestionDialog;
