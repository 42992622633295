import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  DataGrid,
} from '@material-ui/data-grid';
import { useHistory } from 'react-router-dom';
// import moment from 'moment';

// import Button from '@material-ui/core/Button';
import Button from '../../../components/CustomButtons/Button';

export default function SessionsGrid(props) {
  const [newHeaders, setNewHeaders] = useState([]);
  const history = useHistory();

  const {
    data,
  } = props;

  const headers = [
    {
      headerName: 'Pin', field: 'pin', width: 125, disableClickEventBubbling: true,
    },
    {
      headerName: 'Company', field: 'company', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Event Name', field: 'event', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Date Completed', field: 'completedAt', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Type', field: 'type', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Audience Users', field: 'audienceUsers', width: 250, disableClickEventBubbling: true,
    },
  ];

  useEffect(() => {
    const headersWithAddedButton = [
      {
        headerName: 'Session Report',
        field: 'id',
        width: 200,
        disableClickEventBubbling: true,
        renderCell: (session) => (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                history.push(`/admin/sessionReports/${session.row.pin}`);
              }}
            >
              View Report
            </Button>
          </div>
        ),
      },
      ...headers];

    setNewHeaders(headersWithAddedButton);
  }, [data]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ height: 600, width: '100%' }}>
      <DataGrid
        rows={data}
        columns={newHeaders}
        sortingOrder={['asc', 'desc']}
        sortModel={[
          {
            field: 'company',
            sort: 'asc',
          },
        ]}
      />
    </div>
  );
}

SessionsGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};
