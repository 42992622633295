import React from 'react';
// import axios from 'axios';
import PropTypes from 'prop-types';
import {
  DataGrid,
} from '@material-ui/data-grid';

export default function ChatUserGrid(props) {

  const {
    data,
  } = props;

  const headers = [
    {
      headerName: 'Email', field: 'email', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Verified', field: 'verified', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Company', field: 'company', width: 250, disableClickEventBubbling: true,
    },
  ];

  return (
    <div style={{ height: 600, width: '100%' }}>
      <DataGrid
        rows={data}
        columns={headers}
        sortingOrder={['asc', 'desc']}
        sortModel={[
          {
            field: 'email',
            sort: 'asc',
          },
        ]}
      />
    </div>
  );
}

ChatUserGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};
