import React from 'react';
import PropTypes from 'prop-types';
import { Slide } from 'pure-react-carousel';
import Card from '../../components/Card/Card';
// import CardFooter from '../../components/Card/CardFooter';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
// import GridItem from '../../components/Grid/GridItem';
// import BarGraph from './BarGraph';
import WalkOfPrivilegeGraph from './WalkBarGraph';
import DoughnutGraph from './Doughnut';
import BubbleGraph from './BubbleChart';
import BarGraph from './BarGraph';
import SimpleCloud from './WordCloudForWordPulse';

const MainModule = (props) => {
  const { module, ind } = props;
  // console.log(module, ind, 'main module');
  // eslint-disable-next-line no-unused-expressions
  return (
    <Slide index={ind} className="mainMod">
      <Card className="innerCard">
        <CardHeader>{`${module.title} results:`}</CardHeader>
        <CardBody className="innerContent">
          {(() => {
            if (module.type === 'walkOfPrivilege') {
              return (
                <WalkOfPrivilegeGraph
                  usersAnswered={module.walkResults}
                />
              );
            }
            if (module.type === 'wordCloud') {
              return module.tagResults.map((tag) => (
                <>
                  <h4>{tag.description}</h4>
                  <DoughnutGraph answers={tag.answers} />
                </>
              ));
            }
            if (module.type === 'comfort') {
              return (
                <>
                  {/* <h4>{module.title}</h4> */}
                  <BubbleGraph results={module.comfortResults} />
                </>
              );
            }
            if (module.type === 'poll') {
              return module.pollAnswers.map((poll) => (
                <>
                  <h4>{poll.question}</h4>
                  <BarGraph answers={poll.answers} />
                </>
              ));
            }
            if (module.type === 'wordPopcorn') {
              // console.log('inside if');
              return (
                <div>
                  <h4>{module.wordPulseResults.question}</h4>
                  <SimpleCloud data={module.wordPulseResults.popcornAnswers}/>
                </div>
              );
            }
            return null;
          })()}
        </CardBody>
      </Card>
    </Slide>
  );
};

MainModule.propTypes = {
  ind: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  module: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string.isRequired,
    walkResults: PropTypes.arrayOf(),
    tagResults: PropTypes.arrayOf(),
    wordPulseResults: PropTypes.arrayOf(),
    comfortResults: PropTypes.arrayOf(
      PropTypes.shape({
        tag: PropTypes.string,
        value: PropTypes.number,
        numberOfDataPoints: PropTypes.number,
      }),
    ),
    pollAnswers: PropTypes.arrayOf(),

  }),
};

export default MainModule;
