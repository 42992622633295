/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
// import axios from 'axios';
import PropTypes from 'prop-types';
import {
  DataGrid,
} from '@material-ui/data-grid';

import Button from '../../../../components/CustomButtons/Button';

import EditQuestionModal from './EditQuestionModal';


export default function WOPGrid(props) {
  const [newHeaders, setNewHeaders] = useState([]);
  const [questionToEdit, setQuestionToEdit] = useState({});
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  

  const {
    data, fetchData,
  } = props;

  const headers = [
    {
      headerName: 'Question', field: 'question', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Direction', field: 'direction', width: 100, disableClickEventBubbling: true,
    },
    {
      headerName: 'Points', field: 'points', width: 100, disableClickEventBubbling: true,
    },
    {
      headerName: 'Image', field: 'image', width: 250, disableClickEventBubbling: true,
    },
  ];
  // archive event function taken from WOPQuestionsTable.js... not tested.
  //   archiveQuestion(questionObj) {
  //     axios({
  //       method: 'patch',
  //       url: `${process.env.REACT_APP_MODULE_SERVICE}/walk-of-privilege-question/${questionObj.id}`,
  //       headers: {
  //         Authorization: `bearer ${loadAuthToken()}`,
  //       },
  //       data: {
  //         status: 'archived',
  //       },
  //     }).then(() => {
  //       this.context.setMessageState('success');
  //       this.context.setSnackBarMessage(`Successfully archived wop question: ${questionObj.question}`);
  //       return this.getQuestions();
  //     }).catch((e) => {
  //       this.context.setMessageState('error');
  //       this.context.setSnackBarMessage(`${e.response.data.message}`);
  //     });
  //   }

  useEffect(() => {
    const headersWithAddedButton = [
      {
        headerName: 'Archive Event',
        field: 'id',
        width: 200,
        disableClickEventBubbling: true,
        renderCell: (question) => (
          <div>
            {/* <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              Archive
            </Button> */}
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                setQuestionToEdit(question.row);
                setIsEditModalOpen(!isEditModalOpen);
              }}
            >
              Edit Question
            </Button>
          </div>
        ),
      },
      ...headers];

    setNewHeaders(headersWithAddedButton);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div style={{ height: 600, width: '100%' }}>
      <EditQuestionModal
        togalModal={() => setIsEditModalOpen(!isEditModalOpen)}
        question={questionToEdit}
        open={isEditModalOpen}
        fetchData={fetchData}

      />

      <DataGrid
        rows={data}
        columns={newHeaders}
        sortingOrder={['asc', 'desc']}
        sortModel={[
          {
            field: 'question',
            sort: 'asc',
          },
        ]}
      />
    </div>
  );
}

WOPGrid.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object),
  fetchData: PropTypes.func.isRequired,
};
