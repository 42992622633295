import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';

import Modal from '@material-ui/core/Modal';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import tooltip from './tooltip';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function CompanyTable(props) {
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [newHeaders, setNewHeaders] = useState([]);
  const {
    data, headers,
  } = props;
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      User:
      {selectedRow.value}
    </div>
  );

  useEffect(() => {
    const headerWithWidthAndRender = _.map(headers, (element) => _.extend({}, element, {
      width: ((element.headerName.length * 8) > 100 ? element.headerName.length * 8 : 100),
      renderCell: tooltip,
    }));

    const headersWithAddedButton = [
      {
        headerName: 'User Info',
        field: 'id',
        width: 120,
        renderCell: (params) => (
          <div>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={(e) => {
                e.preventDefault();
                setSelectedRow(params);
                handleOpen();
              }}

            >
              Open
            </Button>
          </div>
        ),
      },
      ...headerWithWidthAndRender];

    const rowWithId = _.map(data, (element) => _.extend({}, element, { id: element.userId }));

    // example of how to do a link

    // const newArr2 = _.map(headersWithAddedButton, (element) => {
    //   if (element.field === 'userId') {
    //     const newRow = {
    //       headerName: 'abv',
    //       field: 'userId',
    //       width: 100,
    //       renderCell: (params) => (
    //         <a href="https://www.w3schools.com">
    //           {' '}
    //           {params.value}
    //         </a>

    //       ),
    //     };
    //     return newRow;
    //   }
    //   return element;
    // });

    setNewHeaders(headersWithAddedButton);
    setRows(rowWithId);
  }, [headers, data]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ height: 600, width: '100%' }}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <DataGrid
        disableColumnSelector
        rows={rows}
        columns={newHeaders}
        checkboxSelection
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
}

CompanyTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  headers: PropTypes.arrayOf(PropTypes.object),
};
