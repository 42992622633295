import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
// import Tooltip from '@material-ui/core/Tooltip';

// import emojiStrip from 'emoji-strip';
import Button from '../../../../components/CustomButtons/Button';
import styles from '../../../../assets/jss/material-dashboard-pro-react/views/wordPopcornStyle';
import WordPopcornTutorial from './WordPopcornTutorial';

const WordPopcornWelcome = ({ startWordPopcorn }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const [, setTutorial] = useState('');
  const tutorialCompleted = Boolean(window.localStorage.getItem('word_popcorn_tutorial_completed'));

  return (
    <>
      {!tutorialCompleted
        ? <WordPopcornTutorial setTutorial={setTutorial} startWordPopcorn={startWordPopcorn} />
        : null}
      <div className={classes.subContainer}>
        {/* <h3 className={classes.textColor} style={{ marginBottom: '-35px' }}>Word Popcorn</h3> */}

        <img src="https://s3.amazonaws.com/join.host/pulse.png" className={classes.image} alt="Word Pulse" />

        <h4 style={{ marginBottom: '-50px' }} className={classes.textColor} id="question">Example question?</h4>
        <div className={classes.inputBox}>
          <form>
            <TextField
              id="textField"
              disabled
            // autoFocus
              margin="dense"
              label="Your Response"
              type="text"
              value="Add Your Response Here"
              helperText="0/20"
              FormHelperTextProps={{
                className: classes.helperText,
              }}
            />
          </form>
        </div>

        <Button
          id="submitButton"
          type="submit"
          color="lightGreen"
        >
          Submit
        </Button>
      </div>
    </>
  );
};

WordPopcornWelcome.propTypes = {
  startWordPopcorn: PropTypes.func.isRequired,
  charLimit: PropTypes.number,
  moduleData: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};

export default WordPopcornWelcome;
