/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-danger */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { isIOS } from 'react-device-detect';
import Swipeable from './swipe/Swipeable';
import Card from './swipe/Card';
import HostedFinished from './WopFinished';
import Button from './swipe/Button';
import GridContainer from '../../../../components/Grid/GridContainer';
import GridItem from '../../../../components/Grid/GridItem';
import WalkOfPrivilegeTutorial from './WalkOfPrivilegeTutorial';

const useStyles = makeStyles({
  center: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: '1rem',
  },
  wrapperStyles: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
  },
  actionsStyles: {
    width: '70%',
    justifyContent: 'space-around !important',
    display: 'flex',
    marginTop: '13px',
  },
  progressBar: {
    width: '100%',
    height: '15px',
  },
  progressBarDiv: {
    width: '100%',
    height: '15px',
  },
  footer: {
    width: '95%',
    marginTop: '5vh',
    // position: 'fixed',
    // bottom: '50px',
    '@media (max-width: 600px)': {
      // bottom: '10px',
      // backgroundSize: '100%',
    },
  },
  score: {
    margin: '6px 0 0 0',
    display: 'flex',
    justifyContent: 'center',
  },
  questionBox: {
    marginTop: '25px',
    '@media (max-width: 600px)': {
      margin: 0,
      // backgroundSize: '100%',
    },
  },
  red: {
    outlineColor: 'red',
    outlineStyle: 'groove',
  },
  highlight: {
    backgroundColor: '#ffec03e3', /* Black background with opacity */
    padding: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9,
  },
  overlay: {
    position: 'fixed', /* Sit on top of the page content */
    // display: 'none', /* Hidden by default */
    width: '100%', /* Full width (cover the whole page) */
    height: '100%', /* Full height (cover the whole page) */
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)', /* Black background with opacity */
    zIndex: 10,
    /* Specify a stack order in case you're using a different order for other elements */
    cursor: 'pointer', /* Add a pointer on hover */
  },
});

function LinearProgressWithLabel(props) {
  const { allquestions, currentquestionindex } = props;
  return (
    <Box display="flex" alignItems="center" style={{ width: '100%' }}>
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={45}>
        <Typography variant="body2" color="textSecondary">{`${currentquestionindex + 1} / ${allquestions}`}</Typography>
      </Box>
    </Box>
  );
}

// const useStyles = makeStyles(styles);
const Wop = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  const {
    isHosted,
    moduleTitle,
    sessionId,
    moduleId,
    rawQuestions,
    nextModule,
  } = props;
  const allQuestions = rawQuestions;
  const sorted = rawQuestions;

  const [questions, setQuestions] = useState(rawQuestions);
  const [answers, setAnswers] = useState([]);
  const [points, setPoints] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isWopFinished, setWopFinished] = useState(false);
  // const context = useContext(AuthContext);
  // const [isTenOrLess, setIsTenOrLess] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const [tutorial, setTutorial] = React.useState('');
  const tutorialCompleted = Boolean(window.localStorage.getItem('wop_completed'));
  const [currentUserId, setCurrentUserId] = useState(1);

  const classes = useStyles();

  useEffect(() => {
    setCurrentUserId(Number(window.localStorage.getItem('audience_user_id')));
    console.log('line 145', Number(window.localStorage.getItem('audience_user_id')));
  }, []);

  const storeAnswer = (direction) => {
    // check if there is already wop_${id}
    const stringifiedAnswers = window.localStorage.getItem(`wop_${moduleId}_${sessionId}`);
    if (stringifiedAnswers) {
      let parsedAnswers = JSON.parse(stringifiedAnswers);
      parsedAnswers = [...parsedAnswers, direction];
      window.localStorage.setItem(`wop_${moduleId}_${sessionId}`, JSON.stringify(parsedAnswers));
    } else {
      window.localStorage.setItem(`wop_${moduleId}_${sessionId}`, JSON.stringify([direction]));
    }
  };

  const submitAnswers = async (submissionArr) => {
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/walk-of-privilege-answer`,
      data: submissionArr,
    });
  };

  const answerQuestion = async (direction, isFromHydrate) => {
    const currentQuestion = questions[0].walk_of_privilege_question;
    const answerPoints = direction === currentQuestion.direction
      ? currentQuestion.points
      : (currentQuestion.points - (currentQuestion.points * 2));
    // const audienceUserId = Number(window.localStorage.getItem('audience_user_id'));
    if (questions.length > 1) {
      setAnswers([...answers, {
        questionDirection: currentQuestion.direction,
        questionId: currentQuestion.id,
        direction,
        points: answerPoints,
        sessionId,
        moduleId,
        audienceUserId: currentUserId > 1 ? currentUserId : 1,
      }]);
      setQuestions(questions.slice(1, questions.length));
      setPoints(points + answerPoints);
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setProgress(Math.min(((currentQuestionIndex + 1) / rawQuestions.length) * 100));
      if (!isFromHydrate) await storeAnswer(direction);
    } else {
      setPoints(points + answerPoints);
      setWopFinished(true);
      await submitAnswers([...answers, {
        questionDirection: currentQuestion.direction,
        questionId: currentQuestion.id,
        direction,
        points: answerPoints,
        sessionId,
        moduleId,
        audienceUserId: currentUserId > 1 ? currentUserId : 1,
      }]);
      window.localStorage.removeItem(`wop_${moduleId}_${sessionId}`);
    }
  };

  // eslint-disable-next-line no-shadow
  const hydrateAnswer = (questions = rawQuestions) => {
    if (questions.length > 5) {
      const stringifiedAnswers = window.localStorage.getItem(`wop_${moduleId}_${sessionId}`);
      // const audienceUserId = Number(window.localStorage.getItem('audience_user_id'));
      if (stringifiedAnswers) {
        const parsedAnswers = JSON.parse(stringifiedAnswers);
        let answersToStore = [];
        let answeredPoints = 0;
        parsedAnswers.forEach((direction, index) => {
          const currentQuestion = questions[index].walk_of_privilege_question;
          const answerPoints = direction === currentQuestion.direction
            ? currentQuestion.points
            : (currentQuestion.points - (currentQuestion.points * 2));
          answeredPoints += answerPoints;
          answersToStore = [...answersToStore, {
            questionDirection: currentQuestion.direction,
            questionId: currentQuestion.id,
            direction,
            points: answerPoints,
            sessionId,
            moduleId,
            audienceUserId: currentUserId > 1 ? currentUserId : 1,
          }];
        });
        setQuestions(questions.slice(answersToStore.length, questions.length));
        setAnswers(answersToStore);
        setPoints(answeredPoints);
        setCurrentQuestionIndex(answersToStore.length);
        setProgress(Math.min(((answersToStore.length) / sorted.length) * 100));
      }
    }
  };

  useEffect(() => {
    setQuestions(rawQuestions);
    hydrateAnswer(rawQuestions);
  }, [rawQuestions]);

  useEffect(() => {
    // const audienceUserId = Number(window.localStorage.getItem('audience_user_id'));
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_EVENT_SERVICE}/walk-of-privilege-answer?audienceUserId=${currentUserId}&sessionId=${sessionId}&moduleId=${moduleId}`,
    })
      .then((res) => {
        if (res.data.data.length > 0) {
          let answeredPoints = 0;
          res.data.data.forEach((question) => {
            answeredPoints = Number(question.points) + answeredPoints;
          });
          setPoints(answeredPoints);
          setWopFinished(true);
        } else {
          setAnswers([]);
          setCurrentQuestionIndex(0);
          setPoints(0);
          setWopFinished(false);
          hydrateAnswer();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [moduleTitle]);

  return (
    <>
      <div className={clsx(!tutorialCompleted ? classes.overlay : null)} />
      <GridContainer justifyContent="center">
        <GridItem xs={12} md={6} lg={4}>
          <div className={clsx(classes.wrapperStyles, isIOS ? 'innerDivIos' : 'innerDiv')}>
            {!isWopFinished ? (
              <>
                <div className={clsx((tutorial === '#progress') ? classes.highlight : '', classes.progressBarDiv)}>
                  <LinearProgress
                    variant="determinate"
                    className={classes.progressBar}
                    value={progress}
                    id="progress"
                    currentquestionindex={currentQuestionIndex}
                    allquestions={sorted.length}
                  />
                </div>
                {!tutorialCompleted ? <WalkOfPrivilegeTutorial setTutorial={setTutorial} /> : null }
                <h5 className={classes.questionBox}>
                  QUESTION:
                  {' '}
                  {currentQuestionIndex + 1}
                  /
                  {allQuestions.length}
                </h5>
                <Swipeable
                  background={tutorial === '#card'}
                  cards={questions}
                  buttons={({ up, down }) => (
                    <div className={clsx((tutorial === '#buttons') ? classes.highlight : '', classes.actionsStyles)} id="buttons">
                      <Button onClick={up}>
                        <ArrowUpwardIcon />
                      </Button>
                      <Button onClick={down}>
                        <ArrowDownwardIcon />
                      </Button>
                    </div>
                  )}
                  onSwipe={(direction) => answerQuestion(direction)}
                  onAfterSwipe={() => console.log('AFTER')}
                >
                  <Card score={1}>{questions ? questions[0] : ''}</Card>
                </Swipeable>
                <div id="score" className={clsx((tutorial === '#score') ? classes.highlight : '', classes.footer)}>
                  <h5 className={classes.score}>
                    PRIVILEGE:
                    {' '}
                    {points}
                  </h5>
                </div>
                { questions > 1 ? (
                  <Card zIndex={-1} score={2}>
                    {questions[1]}
                  </Card>
                ) : null}
              </>
            ) : (
              <HostedFinished
                isHosted={isHosted}
                points={points}
                moduleId={moduleId}
                sessionId={sessionId}
                moduleTitle={moduleTitle}
                nextModule={nextModule}
              />
            )}
          </div>
        </GridItem>
      </GridContainer>
    </>
  );
};

Wop.propTypes = {
  isHosted: PropTypes.bool,
  rawQuestions: PropTypes.arrayOf(PropTypes.object),
  moduleTitle: PropTypes.string,
  moduleId: PropTypes.number.isRequired,
  sessionId: PropTypes.number.isRequired,
  nextModule: PropTypes.func,
};
LinearProgressWithLabel.propTypes = {
  currentquestionindex: PropTypes.number.isRequired,
  allquestions: PropTypes.number.isRequired,
};

export default Wop;
