/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-trailing-spaces */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '../../../components/CustomButtons/Button';
import CustomInput from '../../../components/CustomInput/CustomInput';
import { AuthContext } from '../../../Auth';
import { loadAuthToken } from '../../../utils/auth';

const EditUserModal = ({
  open, toggleModal, user, history,
}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [phoneNumber, setPhone] = useState('');
  const [userId, setUserId] = useState('');
  const [active, setActive] = useState(true);
  const context = useContext(AuthContext);

  const {
    setMessageState, setSnackBarMessage,
  } = context;

  useEffect(() => {
    if (user.firstName) {
      setFirstName(user.firstName);
      setEmail(user.email);
      setLastName(user.lastName);
      setRole(user.role);
      setActive(user.active);
      setUserId(user.id);
      setPhone(user.phoneNumber);
    }
  }, [user]);

  const handleSubmit = () => {
    axios({
      method: 'patch',
      url: `${process.env.REACT_APP_AUTH_SERVICE}/users/${userId}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
      data: { 
        firstName,
        email,
        lastName,
        role,
        active,
        phoneNumber,
      },
    }).then(() => {
      setMessageState('success');
      setSnackBarMessage(`Successfully edited user: ${firstName}`);
      toggleModal();
    }).catch((err) => {
      setMessageState('error');
      setSnackBarMessage(`${err.response.data.message}`);
      if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
        history.push('auth/login');
      }
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => toggleModal()}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: '100%',
            minHeight: '60%',
          },
        }}
      >
        <DialogTitle id="form-dialog-title">Edit User</DialogTitle>
        <DialogContent>
          {firstName.length === 0 ? (
            <p style={{ color: 'red' }}>User must have a name!</p>
          ) : null}
          <CustomInput
            error={firstName.length === 0}
            labelText={(
              <span>
                First Name
                {' '}
                <small>(required)</small>
              </span>
            )}
            id="firstName"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: firstName,
              onChange: (event) => setFirstName(event.target.value),
            }}
          />
          <CustomInput
            error={lastName.length === 0}
            labelText={(
              <span>
                Last Name
              </span>
            )}
            id="lastName"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: lastName,
              onChange: (event) => setLastName(event.target.value),
            }}
          />
          <CustomInput
            error={email.length === 0}
            labelText={(
              <span>
                Email Address
              </span>
            )}
            id="email"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: email,
              onChange: (event) => setEmail(event.target.value),
            }}
          />
          <CustomInput
            // error={(value) => /^$|^\d{10}$/.test(value)}
            labelText={(
              <span>
                Phone Number
                {' '}
                <small>(optional)</small>
              </span>
            )}
            id="phone"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: phoneNumber,
              onChange: (event) => setPhone(event.target.value),
            }}
          />
          <FormHelperText>ex: 5551234567</FormHelperText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggleModal()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleSubmit()}>
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ); 
};

EditUserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  history: PropTypes.object,
  user: PropTypes.object.isRequired,
};

export default EditUserModal;
