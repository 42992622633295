import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from '../../../../components/Grid/GridContainer';
import styles from '../../../../assets/jss/material-dashboard-pro-react/views/wordCloud';
import WordPopcornCompleted from './WordPopcornCompleted';
import WordPopcornWelcome from './WordPopcornWelcome';
import WordPopcornQuestion from './WordPopcornQuestion';

const useStyles = makeStyles(styles);

function WordPopcorn({
  sessionId,
  // moduleTitle,
  moduleId,
  isHosted,
  moduleData,
}) {
  const [hasCompleted, setHasCompleted] = useState(false);
  const [tutorialCompleted, setTutorialCompleted] = useState(Boolean(localStorage.getItem('word_popcorn_tutorial_completed')));
  // Instance of word popcorn module completed
  const [moduleCompleted, setModuleCompleted] = useState(false);

  // const CHARACTER_LIMIT = 20;
  useEffect(() => {
    // console.log('inside useEffect 1 completed:', moduleCompleted);
    setModuleCompleted(window.localStorage.getItem(`word_popcorn_completed_${moduleData.id}`));
  }, [moduleData]);

  useEffect(() => {
    // console.log('inside useEffect 2 completed:', moduleCompleted);
    if (moduleCompleted) {
      setHasCompleted(true);
    } else {
      setHasCompleted(false);
    }
  }, [moduleCompleted]);

  const startWordPopcorn = () => {
    setTutorialCompleted(true);
  };

  const classes = useStyles();

  return (
    <>
      {(hasCompleted) ? (
        <WordPopcornCompleted
          isHosted={isHosted}
          moduleData={moduleData}
        />
      ) : (
        <GridContainer className={classes.container}>
          {tutorialCompleted ? (
            <WordPopcornQuestion
              moduleId={moduleId}
              sessionId={sessionId}
              moduleData={moduleData}
              setModuleCompleted={setModuleCompleted}
              // charLimit={CHARACTER_LIMIT}
            />
          ) : (
            <WordPopcornWelcome
              startWordPopcorn={startWordPopcorn}
              // moduleTitle={moduleTitle}
              // moduleData={moduleData}
              // charLimit={CHARACTER_LIMIT}
            />
          )}
        </GridContainer>
      )}
    </>
  );
}

WordPopcorn.propTypes = {
  isHosted: PropTypes.bool,
  sessionId: PropTypes.number.isRequired,
  // moduleTitle: PropTypes.string.isRequired,
  moduleId: PropTypes.number.isRequired,
  // question: PropTypes.string.isRequired,
  moduleData: PropTypes.shape({
    id: PropTypes.number,
    question: PropTypes.string,
    answerLimit: PropTypes.number,
  }).isRequired,
};

export default WordPopcorn;
