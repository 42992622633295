import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import { PulseLoader } from 'react-spinners';
import { makeStyles } from '@material-ui/core/styles';
import Logo from '../../../assets/img/translator-color-stacked.png';

import translations from './translations';

const styles = {
  outerContainer: {
    // paddingLeft: '2rem',
    // paddingRight: '2rem',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: '-30px',
  },
  imageDiv: {
    paddingTop: '1rem',
  },
  image: {
    height: '120px',
  },

};

const useStyles = makeStyles(styles);

const Default = ({ pin, lang }) => {
  const classes = useStyles();
  return (
    <div className={classes.outerContainer}>
      <div className={classes.imageDiv}>
        <img className={classes.image} src={Logo} alt="Logo" />
      </div>
      <Divider />
      <div>
        <br />
        <h3>
          {translations[lang].welcome}
          !
        </h3>
        <h3>
          {translations[lang].welcomeSessionInfo}
          {' '}
          {pin}
        </h3>
        <h3>
          {translations[lang].pleaseWait}
        </h3>
        <br />
        <PulseLoader
          sizeUnit="px"
          size={15}
          color="#6CCA98"
          loading
        />
        <br />
      </div>
    </div>
  );
};

Default.propTypes = {
  pin: PropTypes.number.isRequired,
  lang: PropTypes.string.isRequired,

};

export default Default;
