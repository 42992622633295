/* eslint-disable react/forbid-prop-types */
import {
  Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CustomInput from '../../../../components/CustomInput/CustomInput';
import { AuthContext } from '../../../../Auth';
import { loadAuthToken } from '../../../../utils/auth';
import Button from '../../../../components/CustomButtons/Button';

const EditQuestionModal = ({
  open, toggleModal, question, history
}) => {
  const [text, setText] = useState('');
  const [slideQuestionId, setSlideQuestionId] = useState('');
  // const [filteredData, setFilteredData] = useState(data)
  const context = useContext(AuthContext);

  useEffect(() => {
    if (question.questionText) {
      setText(question.questionText);
      setSlideQuestionId(question.id);
    }
    
  }, [question]);

  const handleSubmit = () => {
    axios({
      method: 'patch',
      url: `${process.env.REACT_APP_MODULE_SERVICE}/slide-question/${slideQuestionId}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
      data: {
        questionText: text,
      },
    }).then(() => {
      context.setMessageState('success');
      context.setSnackBarMessage('Successfully edited question');
      // setFilteredData(filteredData.filter((el) => el.id !== slideQuestionId));
      toggleModal();
    }).catch((err) => {
      context.setMessageState('error');
      context.setSnackBarMessage(`${err.response.data.message}`);
      if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
        history.push('auth/login');
      }
    });
  };

  console.log('question', question);

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => toggleModal}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: '100%',
            minHeight: '60%',
          },
        }}
      >
        <DialogTitle>Edit Question</DialogTitle>
        <DialogContent>
          <CustomInput
            labelText={(
              <span>
                Question
              </span>
            )}
            id="questionText"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: text,
              onChange: (event) => setText(event.target.value),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => toggleModal()}
          >
            Cancel
          </Button>
          <Button onClick={() => handleSubmit()}>
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

EditQuestionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  history: PropTypes.object,
  question: PropTypes.object.isRequired,
};

export default EditQuestionModal;
