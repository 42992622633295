/* eslint-disable consistent-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
// import FormLabel from '@material-ui/core/FormLabel';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Card from '../../../../components/Card/Card';
import CardFooter from '../../../../components/Card/CardFooter';
import CardBody from '../../../../components/Card/CardBody';
import { AuthContext } from '../../../../Auth';
import CardHeader from '../../../../components/Card/CardHeader';
import styles from '../../../../assets/jss/material-dashboard-pro-react/views/reportPageStyle';
import WopSwitch from './WopSwitch';

const useStyles = makeStyles(styles);

function WopReport(props) {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [selfIdData, setSelfIdData] = useState([]);
  const [selfIdSplitData, setSelfIdSplitData] = useState([]);
  const [displayType, setDisplayType] = useState('list'); // 'bar', 'doughnut'
  const [shouldDisplay, setShouldDisplay] = useState(true);
  const [toggleCard, setToggleCard] = useState(false);

  const {
    moduleInfo, sessionId, companyId, eventId,
  } = props;

  const context = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      if (companyId) {
        return axios({
          method: 'get',
          url: `${process.env.REACT_APP_EVENT_SERVICE}/company-wop-report?companyId=${companyId}&moduleId=${moduleInfo.id}`,
        }).then((res) => {
          if (res.data) {
            setData(res.data);
            if (res.data.userAnswers.length === 0) {
              setShouldDisplay(false);
            }
          }
        }).catch((e) => {
          try {
            context.setMessageState('error');
            context.setSnackBarMessage(`${e.response.data.message}`);
          } catch (err) {
            console.log('error');
          }
        });
      } if (sessionId) {
        return axios({
          method: 'get',
          url: `${process.env.REACT_APP_EVENT_SERVICE}/wop-report?sessionId=${sessionId}&moduleId=${moduleInfo.id}`,
        }).then((res) => {
          setData(res.data);
          if (res.data.userAnswers.length === 0) {
            setShouldDisplay(false);
          }
          return axios({
            method: 'get',
            url: `${process.env.REACT_APP_REPORT_SERVICE}/get-self-id-range-report?sessionId=${sessionId}`,
          });
        }).then((__res) => {
          setSelfIdData(__res.data);
          return axios({
            method: 'get',
            url: `${process.env.REACT_APP_REPORT_SERVICE}/get-self-id-split-by-wop?sessionId=${sessionId}`,
          });
        }).then((_res) => {
          setSelfIdSplitData(_res.data);
        })
          .catch((e) => {
            try {
              context.setMessageState('error');
              context.setSnackBarMessage(`${e?.response?.data?.message}`);
            } catch {
              console.log(e);
            }
          });
      }
    };
    try {
      fetchData();
    } catch (e) {
      context.setMessageState('error');
      context.setSnackBarMessage(`${e}`);
    }
  }, [companyId, sessionId]);

  const handleChange = (event) => {
    // setTable(event.target.checked);
    setDisplayType(event.target.value);
  };
  return (
    <>
      {shouldDisplay ? (
        <>
          {data.userAnswers ? (
            <Card>
              <CardHeader className={classes.headerAlign}>
                <div className={classes.cardCategory}>
                  {companyId ? moduleInfo.moduleTitle : moduleInfo.title}
                  <FormControl component="fieldset" style={{ padding: '0 10px' }}>
                    <RadioGroup aria-label="gender" row name="gender1" value={displayType} onChange={handleChange}>
                      <FormControlLabel value="list" control={<Radio />} label="List" />
                      <FormControlLabel value="barchart" control={<Radio />} label="Bar Chart" />
                      <FormControlLabel value="doughnut" control={<Radio />} label="Doughnut" />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div>
                  {toggleCard
                    ? (
                      <KeyboardArrowUpIcon
                        onClick={() => setToggleCard(!toggleCard)}
                        className={classes.clickable}
                      />
                    )
                    : (
                      <KeyboardArrowDownIcon
                        onClick={() => setToggleCard(!toggleCard)}
                        className={classes.clickable}
                      />
                    )}
                </div>
              </CardHeader>
              <Collapse in={toggleCard} unmountOnExit>
                <CardBody className={classes.reportCardBody}>
                  <WopSwitch
                    displayType={displayType}
                    usersAnswered={data.userAnswers}
                    companyId={companyId}
                    eventId={eventId}
                    moduleInfo={moduleInfo}
                    sessionId={sessionId}
                    selfIdData={selfIdData}
                    selfIdSplitData={selfIdSplitData}
                    questions={data.questions}
                  />
                </CardBody>
              </Collapse>
              <CardFooter stats>
                <div className={classes.stats}>
                  <p className={classes.cardTitle}>
                    Completed:
                    {' '}
                    {data.userAnswers.length}
                  </p>
                </div>
              </CardFooter>
            </Card>

          )
            : <CircularProgress />}
        </>
      ) : (
        null
      )}

    </>
  );
}

WopReport.propTypes = {
  moduleInfo: PropTypes.object.isRequired,
  sessionId: PropTypes.number,
  companyId: PropTypes.number,
  eventId: PropTypes.number,
};

export default WopReport;
