import React, { useState, useEffect } from 'react';
// import axios from 'axios';
import PropTypes from 'prop-types';
import {
  DataGrid,
} from '@material-ui/data-grid';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/CustomButtons/Button';


export default function SessionHistoryGrid(props) {
  const {
    data,
  } = props;
  const [newHeaders, setNewHeaders] = useState([]);
  const history = useHistory();

  const headers = [
    {
      headerName: 'Event Title', field: 'title', width: 250, disableClickEventBubbling: true,
    },
    {
      headerName: 'Company', field: 'company', width: 175, disableClickEventBubbling: true,
    },
    {
      headerName: 'Date Completed', field: 'completedAt', width: 175, disableClickEventBubbling: true,
    },
    {
      headerName: 'Pin', field: 'pin', width: 175, disableClickEventBubbling: true,
    },
    {
      headerName: 'Type', field: 'type', width: 175, disableClickEventBubbling: true,
    },
    {
      headerName: 'Users', field: 'audienceUsers', width: 175, disableClickEventBubbling: true,
    },
  ];

  useEffect(() => {
    const headersWithAddedButton = [
      {
        headerName: 'Company Report',
        field: 'id',
        width: 200,
        disableClickEventBubbling: true,
        renderCell: (params) => (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                history.push(`/admin/analytics/${params.row.pin}`);
              }}
            >
              View Report
            </Button>
          </div>
        ),
      },
      ...headers];

    setNewHeaders(headersWithAddedButton);
  }, [data]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ height: 600, width: '100%' }}>
      <DataGrid
        rows={data}
        columns={newHeaders}
        sortingOrder={['asc', 'desc']}
        sortModel={[
          {
            field: 'completedAt',
            sort: 'desc',
          },
        ]}
      />
    </div>
  );
}

SessionHistoryGrid.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object),
};
