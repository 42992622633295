import React from 'react';
import PropTypes from 'prop-types';

// import DialogContentText from '@material-ui/core/DialogContentText';
import {
  List, DialogTitle, DialogContent, DialogActions, Dialog,
} from '@material-ui/core';
import Button from '../../../../components/CustomButtons/Button';
// import { AuthContext } from '../../Auth';
// import { loadAuthToken } from '../../utils/auth';

function SurveyQuestionsModal(props) {
  const { toggleModal, questions, open } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => toggleModal()}
        togg
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: '100%',
            minHeight: '60%',
          },
        }}
      >
        <DialogTitle id="form-dialog-title">
          {'Title: '}
          {questions.title}
          <br />
          {'Description: '}
          {questions.description}
        </DialogTitle>
        <DialogContent>
          <List>
            {questions.survey_question_joins ? questions.survey_question_joins.map((q) => (
              <ul><li key={q.id}>{q.survey_question.question}</li></ul>
            )) : ' '}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggleModal()} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

SurveyQuestionsModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  questions: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default SurveyQuestionsModal;
