/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import Carousel from 'react-material-ui-carousel';
import SelfId from './selfIdCarousel/selfId';

const CurrentData = (props) => {
  let chartData = [];
  if (props.usersAnswered && props.usersAnswered.length) {
    chartData = props.usersAnswered.map((answer) => answer.points);
  } else {
    chartData = [
      3,
      0,
      6,
      -2,
      -2,
      5,
      8,
      1,
      0,
      -1,
      5,
      7,
      -6,
      3,
      6,
      9,
      4,
      2,
      -1,
      7,
      -1,
      7,
      4,
      0,
    ];
  }
  const getColors = () => {
    const colors = [];
    chartData.forEach((item) => {
      if (item >= 20) {
        colors.push('#36a2eb');
      }
      if (item <= 19 && item >= 4) {
        colors.push('#ff6384');
      }
      if (item <= 3) {
        colors.push('#ffce56');
      }
    });
    return colors;
  };
  return {
    labels: chartData.sort((a, b) => b - a),
    datasets: [
      {
        label: 'Privilege Distribution',
        backgroundColor: getColors,
        // borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: chartData.sort((a, b) => b - a),
      },
    ],
  };
};

const options = {
  responsive: true,
  tooltips: {
    mode: 'label',
  },
  scales: {
    yAxes: [
      {
        ticks: {
          suggestedMin: -10,
          suggestedMax: 10,
          stepSize: 2,
        },
      },
    ],
  },
  plugins: {
    labels: false,
  },
};

const WalkOfPrivilegeBarGraph = (props) => (
  <>
    <Bar
      data={CurrentData(props)}
      width={1000}
      height={450}
      options={options}
    />
    {props.selfIdData.length ? (
      <Carousel
        autoPlay={false}
        navButtonsAlwaysVisible
        indicators={false}
      >
        {props.selfIdData.map((questionObj, index) => (
          <div key={index + 500000}>
            <SelfId questionObj={questionObj} />
          </div>
        ))}
      </Carousel>
    ) : null}
  </>
);

export default WalkOfPrivilegeBarGraph;

WalkOfPrivilegeBarGraph.propTypes = {
  selfIdData: PropTypes.array.isRequired,
};
