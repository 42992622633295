/* eslint-disable no-plusplus */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
// javascript plugin used to create scrollbars on windows
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import Collapse from '@material-ui/core/Collapse';
import Icon from '@material-ui/core/Icon';

// @material-ui/icons
import SettingsIcon from '@material-ui/icons/Settings';

// core components
import SidebarWrapper from './SidebarWrapper';
import AdminNavbarLinks from '../Navbars/AdminNavbarLinks';

import sidebarStyle from '../../assets/jss/material-dashboard-pro-react/components/sidebarStyle';


import { AuthContext } from '../../Auth';

// static contextType = AuthContext;

class Sidebar extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      miniActive: true,
      user: {},
      ...this.getCollapseStates(props.routes),
    };
    this.mainPanel = React.createRef();
  }

  componentDidMount() {
    const { user } = this.context;
    // It will get the data from context, and put it into the state.
    this.setState({ user });
  }

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => (window.location.href.indexOf(routeName) > -1 ? 'active' : '');

  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => {
    const { classes, color } = this.props;
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.showInSidebar === false) {
        return null;
      }
      if (prop.collapse) {
        const st = {};
        st[prop.state] = !this.state[prop.state];
        const navLinkClasses = `${classes.itemLink
        } ${
          cx({
            [` ${classes.collapseActive}`]: this.getCollapseInitialState(
              prop.views,
            ),
          })}`;
        const itemText = `${classes.itemText
        } ${
          cx({
            [classes.itemTextMini]:
              this.props.miniActive && this.state.miniActive,
          })}`;
        const collapseItemText = `${classes.collapseItemText
        } ${
          cx({
            [classes.collapseItemTextMini]:
              this.props.miniActive && this.state.miniActive,
          })}`;
        const itemIcon = `${classes.itemIcon}`;
        const caret = `${classes.caret}`;
        const collapseItemMini = `${classes.collapseItemMini}`;
        return (
          <ListItem
            key={key}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined },
            )}
          >
            <NavLink
              to="#"
              className={navLinkClasses}
              onClick={(e) => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === 'string' ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : (
                  <prop.icon className={itemIcon} />
                )
              ) : (
                <span className={collapseItemMini}>
                  {prop.mini}
                </span>
              )}
              <ListItemText
                primary={prop.name}
                secondary={(
                  <b
                    className={
                      `${caret
                      } ${
                        this.state[prop.state] ? classes.caretActive : ''}`
                    }
                  />
                )}
                disableTypography
                className={cx(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined },
                )}
              />
            </NavLink>
            <Collapse in={this.state[prop.state]} unmountOnExit>
              <List className={`${classes.list} ${classes.collapseList}`}>
                {this.createLinks(prop.views)}
              </List>
            </Collapse>
          </ListItem>
        );
      }
      const innerNavLinkClasses = `${classes.collapseItemLink
      } ${
        cx({
          [` ${classes[color]}`]: this.activeRoute(prop.path),
        })}`;
      const collapseItemMini = `${classes.collapseItemMini}`;
      const navLinkClasses = `${classes.itemLink
      } ${
        cx({
          [` ${classes[color]}`]: this.activeRoute(prop.path),
        })}`;
      const itemText = `${classes.itemText
      } ${
        cx({
          [classes.itemTextMini]:
            this.props.miniActive && this.state.miniActive,
        })}`;
      const collapseItemText = `${classes.collapseItemText
      } ${
        cx({
          [classes.collapseItemTextMini]:
            this.props.miniActive && this.state.miniActive,
        })}`;
      const itemIcon = `${classes.itemIcon}`;
      return (
        <ListItem
          key={key}
          className={cx(
            { [classes.item]: prop.icon !== undefined },
            { [classes.collapseItem]: prop.icon === undefined },
          )}
        >
          <NavLink
            to={prop.layout + prop.path}
            className={cx(
              { [navLinkClasses]: prop.icon !== undefined },
              { [innerNavLinkClasses]: prop.icon === undefined },
            )}
          >
            {prop.icon !== undefined ? (
              typeof prop.icon === 'string' ? (
                <Icon className={itemIcon}>{prop.icon}</Icon>
              ) : (
                <prop.icon className={itemIcon} />
              )
            ) : (
              <span className={collapseItemMini}>
                {prop.mini}
              </span>
            )}
            <ListItemText
              primary={prop.name}
              disableTypography
              className={cx(
                { [itemText]: prop.icon !== undefined },
                { [collapseItemText]: prop.icon === undefined },
              )}
            />
          </NavLink>
        </ListItem>
      );
    });
  };

  openCollapse(collapse) {
    const st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }

  render() {
    const {
      classes,
      logo,
      image,
      routes,
      bgColor,
    } = this.props;
    const itemText = `${classes.itemText
    } ${
      cx({
        [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
      })}`;
    const collapseItemText = `${classes.collapseItemText
    } ${
      cx({
        [classes.collapseItemTextMini]:
          this.props.miniActive && this.state.miniActive,
      })}`;
    const userWrapperClass = `${classes.user
    } ${
      cx({
        [classes.whiteAfter]: bgColor === 'white',
      })}`;
    const caret = `${classes.caret}`;
    const collapseItemMini = `${classes.collapseItemMini}`;
    const user = (
      <div className={userWrapperClass}>
        {/* <div className={photo}>
          <img src={avatar} className={classes.avatarImg} alt="..." />
        </div> */}
        <List className={classes.list}>
          <ListItem className={`${classes.item} ${classes.userItem}`}>
            <NavLink
              to="#"
              className={`${classes.itemLink} ${classes.userCollapseButton}`}
              onClick={() => this.openCollapse('openAvatar')}
            >
              <ListItemText
                primary={this.state.user.email || ''}
                secondary={(
                  <b
                    className={
                      `${caret
                      } ${
                        classes.userCaret
                      } ${
                        this.state.openAvatar ? classes.caretActive : ''}`
                    }
                  />
                )}
                disableTypography
                className={`${itemText} ${classes.userItemText}`}
              />
            </NavLink>
            <Collapse in={this.state.openAvatar} unmountOnExit>
              <List className={`${classes.list} ${classes.collapseList}`}>
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to="/admin/settings"
                    className={
                      `${classes.itemLink} ${classes.userCollapseLinks}`
                    }
                  >
                    <span className={collapseItemMini}>
                      <SettingsIcon />
                    </span>
                    <ListItemText
                      primary="Settings"
                      disableTypography
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
              </List>
            </Collapse>
          </ListItem>
        </List>
      </div>
    );
    const links = (
      <List className={classes.list}>{this.createLinks(routes)}</List>
    );
    const logoClasses = `${classes.logo
    } ${
      cx({
        [classes.whiteAfter]: bgColor === 'white',
      })}`;
    const brand = (
      <div className={logoClasses}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={logo} alt="logo" className={classes.img} style={{ width: '60%' }} />
        </div>
      </div>
    );
    const drawerPaper = `${classes.drawerPaper
    } ${
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
      })}`;
    const sidebarWrapper = `${classes.sidebarWrapper
    } ${
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf('Win') > -1,
      })}`;
    return (
      <div ref={this.mainPanel}>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor="left"
            open={this.props.open}
            classes={{
              paper: `${drawerPaper} ${classes[`${bgColor}Background`]}`,
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              headerLinks={<AdminNavbarLinks />}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: `url(${image})` }}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onFocus={() => undefined}
            onBlur={() => undefined}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor="right"
            variant="permanent"
            open
            classes={{
              paper: `${drawerPaper} ${classes[`${bgColor}Background`]}`,
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: `url(${image})` }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: 'blue',
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(['white', 'black', 'blue']),
  color: PropTypes.oneOf([
    'white',
    'red',
    'orange',
    'green',
    'translatorGreen',
    'blue',
    'purple',
    'rose',
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  miniActive: PropTypes.bool,
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
};

export default withStyles(sidebarStyle)(Sidebar);
