/* eslint-disable react/static-property-placement */
/* eslint-disable no-lonely-if */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';

// core components
import ReactTables from '../../../../components/Table/ReactTable';
import GridContainer from '../../../../components/Grid/GridContainer';
import GridItem from '../../../../components/Grid/GridItem';
import Button from '../../../../components/CustomButtons/Button';

import customSelectStyle from '../../../../assets/jss/material-dashboard-pro-react/customSelectStyle';
import customListStyle from '../../../../assets/jss/material-dashboard-pro-react/customListStyle';
import customCheckboxRadioSwitch from '../../../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch';
import PreviewQuestionModal from '../PreviewQuestionModal';
import { loadAuthToken } from '../../../../utils/auth';
import { AuthContext } from '../../../../Auth';

const style = {
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center',
  },
  inputAdornmentIcon: {
    color: '#555',
  },
  choiche: {
    textAlign: 'center',
    cursor: 'pointer',
    marginTop: '20px',
  },
  ...customListStyle,
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
};

const SortableItem = SortableElement(({ value, sortIndex }) => (
  <li style={style.listItem}>
    {value}
    {' '}
    - #
    {sortIndex + 1}
  </li>
));

const SortableList = SortableContainer(({ selectedQuestions, removeQuestion }) => (
  <ul style={{ listStyle: 'none', padding: '0' }}>
    {selectedQuestions.map((value, index) => (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <SortableItem
        // eslint-disable-next-line react/no-array-index-key
          key={`item-${index}`}
          index={index}
          sortIndex={index}
          value={value.question}
        />
        <IconButton onClick={() => removeQuestion(value)}>
          <RemoveCircleOutlineRoundedIcon />
        </IconButton>
      </div>
    ))}
  </ul>
));

function compare(a, b) {
  const bandA = a.id;
  const bandB = b.id;
  let comparison = 0;
  if (bandA > bandB) {
    comparison = 1;
  } else if (bandA < bandB) {
    comparison = -1;
  }
  return comparison;
}

class Step2 extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      previewOpen: false,
      selectedQuestions: [],
      questionToPreview: {},
      data: [],
      headers: [
        {
          Header: 'Question',
          accessor: 'question',
        },
      ],
    };
  }

  componentDidMount() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_MODULE_SERVICE}/walk-of-privilege-question?status=active`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    }).then((res) => {
      const { data } = res;
      if (res.error === 'your token is not valid' || res.error === 'Your token is expired') {
        this.context.sendToLogin();
      } else {
        this.setState({
          data: data.data.map((walkOfPrivilegeQuestion) => ({
            question: `SWIPE ${walkOfPrivilegeQuestion.direction.toUpperCase()} ${walkOfPrivilegeQuestion.question}`,
            id: walkOfPrivilegeQuestion.id,
            actions: [
              <Button onClick={() => (this.addQuestion(walkOfPrivilegeQuestion))}>
                Add
              </Button>,
              <Button onClick={() => (this.previewQuestion(walkOfPrivilegeQuestion))}>
                Preview
              </Button>,
            ],
          })).sort(compare),
        });
      }
    }).catch((e) => {
      this.context.setMessageState('error');
      this.context.setSnackBarMessage(`${e.response.data.message}`);
    });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ selectedQuestions }) => ({
      selectedQuestions: arrayMove(selectedQuestions, oldIndex, newIndex),
    }));
  };

  addQuestion(newQuestion) {
    if (!this.state.selectedQuestions.find((question) => question.id === newQuestion.id)) {
      this.setState({ selectedQuestions: [...this.state.selectedQuestions, newQuestion] }, () => {
        const tableDataCopy = [...this.state.data];
        this.setState({ data: tableDataCopy.filter((question) => question.id !== newQuestion.id) });
      });
    }
  }

  previewQuestion(questionToPreview) {
    this.setState({ questionToPreview, previewOpen: true });
  }

  removeQuestion(questionToRemove) {
    if (this.state.selectedQuestions.find((question) => question.id === questionToRemove.id)) {
      const selectedQuestionsCopy = [...this.state.selectedQuestions];
      this.setState({
        selectedQuestions: [
          ...selectedQuestionsCopy
            .filter((question) => question.id !== questionToRemove.id),
        ],
      }, () => {
        const tableDataCopy = [...this.state.data];
        tableDataCopy.push(
          {
            question: `SWIPE ${questionToRemove.direction.toUpperCase()} ${questionToRemove.question}`,
            id: questionToRemove.id,
            actions: [
              <Button onClick={() => (this.addQuestion(questionToRemove))}>
                Add
              </Button>,
              <Button onClick={() => (this.previewQuestion(questionToRemove))}>
                Preview
              </Button>,
            ],
          },
        );
        this.setState({ data: tableDataCopy });
      });
    }
  }

  sendState() {
    return this.state;
  }

  // this is used by the wizard to see if all required are filled out
  isValidated() {
    const { selectedQuestions } = this.state;
    if (selectedQuestions.length > 0) {
      return true;
    }
    return false;
  }

  closeModal() {
    this.setState({ previewOpen: false });
  }

  render() {
    const { classes, history } = this.props;
    const {
      selectedQuestions, previewOpen, headers, data, questionToPreview,
    } = this.state;
    return (
      <div>
        <PreviewQuestionModal
          open={previewOpen}
          questionToPreview={questionToPreview}
          history={history}
          togalModal={() => this.closeModal()}
        />
        <h4 className={classes.infoText}>What questions would you like to add?</h4>
        <GridContainer justifyContent="center">
          <div style={{ width: '100%', marginBottom: '10px' }}>
            <ReactTables
              data={data}
              headers={headers}
              name="Select Questions"
            />
          </div>
          <GridItem xs={10} md={6} className={classes.selected}>
            <SortableList selectedQuestions={selectedQuestions} removeQuestion={(questionToRemove) => this.removeQuestion(questionToRemove)} onSortEnd={this.onSortEnd} />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Step2.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
};

export default withStyles(style)(Step2);
