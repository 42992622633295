/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Moment from 'react-moment';
import Modules from './modules';
// core components
import AudienceUserTable from './AudienceUserTable';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardFooter from '../../components/Card/CardFooter';
import { AuthContext } from '../../Auth';
import styles from '../../assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import LiveChatReport from './liveChat/LiveChatReport';
import InviteTable from './InviteTable';

const useStyles = makeStyles(styles);

function Report() {
  const classes = useStyles();
  const context = useContext(AuthContext);
  const [data, setData] = useState({});
  const [audienceUserData, setAudienceUserData] = useState({});
  const pin = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

  useEffect(() => {
    const fetchData = async () => {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_EVENT_SERVICE}/session?pin=${pin}`,
      }).then((res) => {
        setData(res.data[0]);
        return res;
      }).catch((e) => {
        console.log(e);
        context.setMessageState('error');
        context.setSnackBarMessage(`${e.response.data.message}`);
      }).then((res) => {
        if (res.data[0]) {
          return axios({
            method: 'get',
            url: `${process.env.REACT_APP_EVENT_SERVICE}/get-audience-user-report?sessionId=${res.data[0].id}`,
          }).then((_res) => {
            setAudienceUserData(_res.data);
          }).catch((e) => {
            context.setMessageState('error');
            try {
              context.setSnackBarMessage(`${e.response.data.message}`);
            } catch (err) {
              context.setSnackBarMessage(`${e}`);
            }
          });
        }
      });
      // setData(session[0]);
    };

    fetchData();
  }, []);

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={4}>
          <Card>
            <CardHeader>
              <p className={classes.cardCategory}>Session Report</p>
              <h3 className={classes.cardTitle}> {data.title}</h3>
              <p className={classes.cardTitle}>Session: {data.pin}</p>
              <p className={classes.cardTitle}>Audience user count: {data.audienceUsers}</p>
              {/* <p className={classes.cardTitle}>Company Id: {data.companyId}</p> */}
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <p className={classes.cardTitle}>Completed: <Moment format="MM/DD/YY h:mma">{data.completedAt}</Moment></p>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={8}>
          <Card>
            <InviteTable sessionId={data.id} />
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        {data.modules ? data.modules.map((module) => {
          if (module.type !== 'infoModule') {
            return (
              <Modules key={module.title} module={module} sessionId={data.id} />
            );
          }
        })
          : ''}
        <LiveChatReport sessionId={data.id} />
        {audienceUserData.headers ? (
          <Card>
            <AudienceUserTable
              csvHeaders={audienceUserData.csvHeaders}
              data={audienceUserData.data}
              headers={audienceUserData.headers}
            />
          </Card>
        ) : null}
      </GridContainer>
    </>
  );
}

export default Report;
