import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

const useStyles = makeStyles({
  outerDiv: {
    textAlign: 'center',
    display: 'flex',
    // height: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '50px',
  },
  textBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '50px',
    '@media (min-width: 900px)': {
      width: '65%',
    },
  },
  amazingWork: {
    fontSize: '22px',
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  largeText: {
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    fontSize: '22px',
    // zIndex: '2',
  },
  messageIcon: {
    color: '#6CCA98',
    height: '100px',
    width: '100px',
  },
  image: {
    width: '300px',
    marginBottom: '5px',
  },
  Slider: {
    width: '97%',
  },
  SliderContainer: {
    width: '100%',
    marginBottom: '100px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    // overflow: 'auto',
    '@media (max-width: 1100px)': {
      // flexDirection: 'row',
      marginTop: '35px',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  color: {
    height: '95px',
    width: '95px',
    margin: '2px',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50px',
  },
  scoreContainer: {
    display: 'flex',
    // width: '100%',
    // flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: '75px',
    '@media (max-width: 1100px)': {
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      marginTop: '15px',
    },

  },
  textBox: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '@media (max-width: 1100px)': {
      flexDirection: 'column',
      height: '145vh',
      display: 'none',
    },
  },
  mobileText: {
    display: 'inherent',
    '@media (min-width: 1100px)': {
      display: 'none',
    },
  },
});

const colorLegend = [
  '#2a7050',
  '#3B7E5F',
  '#48896C',
  '#519074',
  '#5C997E',
  '#5C997E',
  '#69A389',
  '#76AD94',
  '#84B8A1',
  '#92C4AE',
  '#A2D1BC',
];

const ComfortChart = (props) => {
  const [sortedData, setSortedData] = useState([]);
  const classes = useStyles();
  const { tags } = props;

  useEffect(() => {
    const sortedArray = _.sortBy(tags, ['value', 'tag']);
    setSortedData(sortedArray);
  }, [tags]);

  return (
    <div className={classes.outerDiv}>
      <div className={classes.SliderContainer}>
        <div>
          <h6 className={classes.mobileText}>Less Comfortable</h6>
          <div className={classes.scoreContainer}>
            {sortedData.map((option, i) => (
              <span
                key={option.tag}
                className={classes.color}
                style={{
                  backgroundColor: colorLegend[i],
                }}
              >
                {option.tag}
              </span>
            ))}
          </div>
          <h6 className={classes.mobileText}>More Comfortable</h6>
          <div className={classes.textBox}>
            <h6 style={{ width: '150px', marginRight: '20px' }}>
              Less Comfortable
            </h6>
            <h6 style={{ width: '150px' }}>More Comfortable</h6>
          </div>
        </div>

      </div>

    </div>
  );
};

ComfortChart.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.object),
};

export default ComfortChart;
