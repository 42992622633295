/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from '../../../../components/Grid/GridContainer';
import styles from '../../../../assets/jss/material-dashboard-pro-react/views/wordCloud';
import WordCloudCompleted from './WordCloudCompleted';
import WordCloudWelcome from './WordCloudWelcome';
import WordCloudQuestion from './WordCloudQuestion';

const useStyles = makeStyles(styles);

export default function WordCloud({
  sessionId,
  moduleTitle,
  questions,
  moduleId,
  isHosted,
  nextModule,
  wordCloudIcon,
}) {
  const [hasCompleted, setHasCompleted] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const [isLastQuestion, setIsLastQuestion] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState({});

  useEffect(() => {
    const index = window.localStorage.getItem(`wordcloud_index_${moduleId}_${sessionId}`);
    if (index) {
      if (Number(index) >= questions.length) {
        setHasCompleted(true);
      } else {
        setHasCompleted(false);
        if (Number(index) === questions.length - 1) {
          setIsLastQuestion(true);
        } else {
          setIsLastQuestion(false);
        }
        setHasStarted(true);
        setCurrentQuestionIndex(Number(index));
        setCurrentQuestion(questions[Number(index)]);
      }
    }
  }, [moduleId, sessionId, questions]);

  const startWordCloud = () => {
    setHasStarted(true);
    window.localStorage.setItem(`wordcloud_index_${moduleId}_${sessionId}`, '0');
    setCurrentQuestionIndex(0);
    setCurrentQuestion(questions[0]);
  };

  const nextQuestion = () => {
    const nextIndex = currentQuestionIndex + 1;
    window.localStorage.setItem(`wordcloud_index_${moduleId}_${sessionId}`, `${nextIndex}`);
    setCurrentQuestionIndex(nextIndex);
    if (Number(nextIndex) >= questions.length) {
      setHasCompleted(true);
    } else {
      if (Number(nextIndex) === questions.length - 1) setIsLastQuestion(true);
      setCurrentQuestion(questions[nextIndex]);
    }
  };

  const classes = useStyles();

  return (
    <>
      {(hasCompleted) ? (
        <WordCloudCompleted
          isHosted={isHosted}
          wordCloudIcon={wordCloudIcon}
          nextModule={() => nextModule()}
        />
      ) : (
        <GridContainer className={classes.container}>
          {hasStarted ? (
            <WordCloudQuestion
              isLastQuestion={isLastQuestion}
              moduleId={moduleId}
              sessionId={sessionId}
              nextQuestion={() => nextQuestion()}
              question={currentQuestion}
            />
          ) : (
            <WordCloudWelcome
              startWordCloud={startWordCloud}
              moduleTitle={moduleTitle}
              wordCloudIcon={wordCloudIcon}
              question={questions[0]}
            />
          )}
        </GridContainer>
      )}
    </>
  );
}

WordCloud.propTypes = {
  sessionId: PropTypes.number,
  questions: PropTypes.array.isRequired,
  moduleTitle: PropTypes.string,
  moduleId: PropTypes.number.isRequired,
  nextModule: PropTypes.func,
  isHosted: PropTypes.bool.isRequired,
  wordCloudIcon: PropTypes.string.isRequired,
};
