/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Collapse from '@material-ui/core/Collapse';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CSVLink } from 'react-csv';
import Card from '../../../../components/Card/Card';
import CardFooter from '../../../../components/Card/CardFooter';
import { AuthContext } from '../../../../Auth';
import CardBody from '../../../../components/Card/CardBody';
import CardHeader from '../../../../components/Card/CardHeader';
import styles from '../../../../assets/jss/material-dashboard-pro-react/views/reportPageStyle';

import WordPopcornReportGrid from './WordPopcornReportGrid';
// import WordCloudFreqReport from './wordCloudFreqReport';
// import RadioGroup from './RadioSelect';

const useStyles = makeStyles(styles);

function WordPopcornReport(props) {
  const classes = useStyles();
  const context = useContext(AuthContext);
  const [toggleCard, setToggleCard] = useState(false);
  const [results, setResults] = useState([]);
  const [completed, setCompleted] = useState(0);
  const [ question, setQuestion ] = useState('');
  const [shouldDisplay, setShouldDisplay] = useState(true);
  const {
    moduleInfo, sessionId,
  } = props;

  const csvHeaders = [
    {
      key: 'audienceUserId',
      label: 'User',
    },
    {
      key: 'answer',
      label: 'Response',
    },
  ];

  useEffect(() => {
    if (sessionId) {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_REPORT_SERVICE}/word-popcorn-answer?sessionId=${sessionId}&moduleId=${moduleInfo.id}`,
      }).then((res) => {
        if (res.data.total > 0) {
          setCompleted(res.data.uniqueUsers);
          setResults(res.data.data);
          setQuestion(res.data.data[0].question);
        } else {
          setShouldDisplay(false);
        }
      }).catch((e) => {
        context.setMessageState('error');
        context.setSnackBarMessage(`${e.response.data.message}`);
      });
    } else {
      setShouldDisplay(false);
    }
  }, [sessionId]);

  return (
    <>
      {shouldDisplay ? (
        <>
          {results ? (
            <Card>
              <CardHeader className={classes.headerAlign}>
                <div className={classes.cardCategory}>
                  {moduleInfo.title ? moduleInfo.title : moduleInfo.moduleTitle}
                </div>
                <div>
                  {toggleCard
                    ? (
                      <KeyboardArrowUpIcon
                        onClick={() => setToggleCard(!toggleCard)}
                        className={classes.clickable}
                      />
                    )
                    : (
                      <KeyboardArrowDownIcon
                        onClick={() => setToggleCard(!toggleCard)}
                        className={classes.clickable}
                      />
                    )}
                </div>

              </CardHeader>
              <Collapse in={toggleCard} unmountOnExit>
                <CardHeader>
                  {question}
                </CardHeader>
                <CardBody style={{ overflow: 'auto' }}>
                  <WordPopcornReportGrid data={results} />
                </CardBody>
              </Collapse>
              <CardFooter stats>
                <div className={classes.stats}>
                  <p className={classes.cardTitle}>
                    Completed:
                    {' '}
                    {String(completed)}
                  </p>
                </div>
                <CSVLink data={results} headers={csvHeaders}>Download Answers by User Id</CSVLink>
              </CardFooter>
            </Card>
          ) : <CircularProgress />}
        </>
      ) : (
        null
      )}

    </>
  );
}

WordPopcornReport.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  moduleInfo: PropTypes.object.isRequired,
  sessionId: PropTypes.number,
  // companyId: PropTypes.number,
};

export default WordPopcornReport;
